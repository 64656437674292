import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { ResetPasswordPayload } from "@/lib/api/dto";

interface UseResetPasswordMutation
  extends UseMutationOptions<unknown, Error, ResetPasswordPayload> {}

export const useResetPasswordMutation = (
  options: UseResetPasswordMutation = {}
) => {
  return useMutation({
    ...options,
    mutationFn: Api.auth.resetPassword,
  });
};
