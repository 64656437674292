import { Navigate } from "react-router-dom";

import { PermissionControl } from "@/components/PermissionControl";
import { useAuth } from "@/hooks/useAuth";
import { Claim } from "@/lib/api/dto";
import { TenantTable } from "@/widgets/TenantTable";

const rerouteMap: Partial<Record<Claim, string>> = {
  [Claim.ORG_ADMIN]: "/entity/organizations",
  [Claim.SYS_ADMIN]: "/entity/tenants",
};

export const TenantsPage = () => {
  const {
    me: { data: me },
  } = useAuth();

  return (
    <PermissionControl
      permission={[Claim.SUPERADMIN, Claim.SYS_ADMIN]}
      fallbackContent={
        <Navigate
          to={
            Object.entries(rerouteMap).find(([key]) =>
              me?.authorities.includes(key as Claim)
            )?.[1] || "/entities/compartment"
          }
        />
      }
    >
      <TenantTable />
    </PermissionControl>
  );
};
