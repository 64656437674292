import { Select, SelectProps, CheckIcon } from "@mantine/core";

import { useTenantContext } from "@/context/TenantContext";

export const TenantSelect = () => {
  const { tenants, tenant, setTenant } = useTenantContext();
  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <span className="relative w-full truncate pl-5" title={option.label}>
      {checked && (
        <CheckIcon
          opacity={0.4}
          size="0.8em"
          className="absolute left-0 top-1/2 flex-shrink-0 -translate-y-1/2"
        />
      )}

      {option.label}
    </span>
  );

  return (
    <Select
      className="w-[16.25rem]"
      classNames={{
        option: "w-[15rem]",
      }}
      value={tenant?.ocid}
      onChange={(value) => value && setTenant(value)}
      data={tenants?.map((tenant) => ({
        value: tenant.ocid,
        label: tenant.name,
      }))}
      allowDeselect={false}
      scrollAreaProps={{ type: "auto" }}
      renderOption={renderSelectOption}
    />
  );
};
