import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { TenantResponse } from "@/lib/api/dto";

import { monitorKeys } from "./queryKeys";

interface UseFetchTenantsQueryProps<T = TenantResponse[]>
  extends Partial<UseQueryOptions<TenantResponse[], Error, T>> {}

export const useFetchTenantsQuery = <T = TenantResponse[]>(
  props?: UseFetchTenantsQueryProps<T>
) => {
  return useQuery<TenantResponse[], Error, T>({
    queryKey: monitorKeys.allTenants(),
    queryFn: () => Api.monitor.tenantList().then((res) => res.data),
    ...props,
  });
};
