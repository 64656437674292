import { Box, Button, MultiSelect, TextInput } from "@mantine/core";
import { IconLoader2 } from "@tabler/icons-react";
import { zodResolver } from "mantine-form-zod-resolver";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EnumSelect } from "@/components/ui/enum-select";
import { errorToast, successToast } from "@/components/ui/use-toast";
import { useTenantContext } from "@/context/TenantContext";
import { useCreateSCNotificationMutation } from "@/hooks/api/useCreateSCNotificationMutation";
import { useFetchCodebookResourcesQuery } from "@/hooks/api/useFetchCodebookResourcesQuery";
import { useFetchSCNotificationsValueTypesQuery } from "@/hooks/api/useFetchSCNotificationsValueTypesQuery";
import { useTForm } from "@/hooks/useTForm";
import {
  CommitmentResponse,
  SCNotificationType,
  SCNotificationValueType,
  SubscriptionResponse,
} from "@/lib/api/dto";

import {
  type SCNotificationFormValues,
  scNotificationFormSchema,
} from "./validation-schema";

interface SCNotificationFormProps {
  subscription?: SubscriptionResponse;
  commitment?: CommitmentResponse;
  notificationType: SCNotificationType;
  onSettled: () => void;
}

export const SCNotificationForm = ({
  subscription,
  commitment,
  notificationType,
  onSettled,
}: SCNotificationFormProps) => {
  const { t } = useTranslation(["common", "notification"]);
  const { tenant } = useTenantContext();

  const { mutate: createSCNotification, isPending } =
    useCreateSCNotificationMutation({
      onSuccess: () => successToast.create(),
      onError: () => errorToast.create(),
      onSettled,
    });

  const { data: resources = [], isFetching: areResourcesFetching } =
    useFetchCodebookResourcesQuery();

  const { data: notificationValueTypeEnum, isFetching: areValueTypesFetching } =
    useFetchSCNotificationsValueTypesQuery();

  const resourceOptions = useMemo(
    () =>
      resources
        ?.filter((r) => !tenant || r.tenant.tenantId === tenant.id)
        .map((r) => ({ value: r.id, label: r.name || r.ocid })),
    [resources, tenant],
  );

  const scNotificationForm = useTForm<SCNotificationFormValues>({
    initialValues:
      notificationType === SCNotificationType.Subscription ?
        {
          value: 0,
          valueType: SCNotificationValueType.Percent,
          notificationType: notificationType,
          subscriptionId: subscription?.id ?? null,
          resources: [],
        }
      : {
          value: 0,
          valueType: SCNotificationValueType.Percent,
          notificationType: notificationType,
          subscriptionId: subscription?.id ?? null,
          commitmentId: commitment?.id ?? null,
          resources: [],
        },
    validate: zodResolver(scNotificationFormSchema),
  });

  const hanldeSubmit = (data: SCNotificationFormValues) => {
    if (data.notificationType === SCNotificationType.Subscription) {
      createSCNotification({
        resourceIds: data.resources,
        subscriptionId: data.subscriptionId!,
        value: data.value,
        sourceType: data.notificationType,
        valueType: data.valueType,
      });
    } else {
      createSCNotification({
        resourceIds: data.resources,
        subscriptionId: data.subscriptionId!,
        commitmentId: data.commitmentId!,
        value: data.value,
        sourceType: data.notificationType,
        valueType: data.valueType,
      });
    }
  };

  return (
    <form
      className="flex flex-col gap-2"
      onSubmit={scNotificationForm.onSubmit(hanldeSubmit)}
    >
      <TextInput
        label={t("notification:sc.subscription.title")}
        {...scNotificationForm.getInputProps("subscriptionId")}
        value={t("notification:sc.subscriptionOption", {
          value: subscription?.subscriptionID,
        })}
        readOnly
      />
      {notificationType === SCNotificationType.Commitment && (
        <TextInput
          label={t("notification:sc.commitment.title")}
          {...scNotificationForm.getInputProps("commitmentId")}
          value={t("notification:sc.commitmentOption", {
            value: commitment?.orderNumber,
          })}
          readOnly
        />
      )}
      <EnumSelect
        label={t("notification:sc.valueType")}
        {...scNotificationForm.getInputProps("valueType")}
        data={notificationValueTypeEnum}
        disabled={areValueTypesFetching}
      />
      <TextInput
        label={t("notification:sc.value")}
        {...scNotificationForm.getInputProps("value")}
      />
      <MultiSelect
        searchable
        label={t("notification:sc.resources")}
        {...scNotificationForm.getInputProps("resources")}
        data={resourceOptions}
        disabled={areResourcesFetching}
        rightSection={
          areResourcesFetching && <IconLoader2 className="animate-spin" />
        }
      />
      <Box className="mt-2 flex justify-between gap-2">
        <Button color="gray" onClick={onSettled}>
          {t("userActions.cancel")}
        </Button>
        <Button type="submit" color="blue" disabled={isPending}>
          {t("userActions.save")}
        </Button>
      </Box>
    </form>
  );
};
