import {
  Divider,
  Progress,
  Text,
  Tooltip,
  Notification,
  Popover,
  UnstyledButton,
  Box,
  useMantineTheme,
} from "@mantine/core";
import { useMemo } from "react";
import { ChartProps, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchCommitmentConsumptionQuery } from "@/hooks/api/useFetchCommitmentConsumptionQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { CommitmentStatus, CommitmentPricingModel } from "@/lib/api/dto";
import { formatToDate } from "@/lib/dateUtils";
import {
  formatNumberAsCurrency,
  formatNumberAsPercentage,
  formatNumber,
} from "@/lib/utils";

interface CommitmentWidgetProps {
  avaliableAmount: number;
  commitmentId: string;
  csiNumber: number;
  endDate: string;
  name: string;
  orderNumber: string;
  startDate: string;
  status: CommitmentStatus;
  subscriptionId: number;
  quantity: number;
  usedAmount: number;
  pricingModel: CommitmentPricingModel;
}

export const CommitmentWidget = ({
  avaliableAmount,
  csiNumber,
  endDate,
  name,
  orderNumber,
  startDate,
  status,
  subscriptionId,
  quantity,
  usedAmount,
  pricingModel,
  commitmentId,
}: CommitmentWidgetProps) => {
  const theme = useMantineTheme();
  const { t } = useTranslation("dashboard");
  const { tenant } = useTenantContext();
  const { data } = useFetchCommitmentConsumptionQuery({
    params: {
      subscriptionid: `${subscriptionId}`,
    },
  });

  const currencyLocale = useCurrencyLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;
  const usedPercentage = (usedAmount / quantity) * 100;
  const availablePercentage = 100 - usedPercentage;
  const isActiveCommitmentStatus = status === CommitmentStatus.Active;
  const isAnnualPricingModel = pricingModel === CommitmentPricingModel.Annual;

  const quantityAsCurrency = formatNumberAsCurrency({
    value: quantity,
    currency,
    options: { locale: currencyLocale },
  });
  const usedAmountAsCurrency = formatNumberAsCurrency({
    value: usedAmount,
    currency,
    options: { locale: currencyLocale },
  });
  const availableAmountAsCurrency = formatNumberAsCurrency({
    value: avaliableAmount,
    currency,
    options: { locale: currencyLocale },
  });

  const commitmentConsumption = useMemo(
    () => data?.filter((item) => item.commitmentId === `${commitmentId}`),
    [data, commitmentId],
  );

  const chartOptions: ChartProps<"line">["options"] = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    elements: {
      point: {
        radius: 0,
        hoverRadius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (context) => formatToDate(context[0].label),
          label: (context) =>
            formatNumberAsCurrency({
              value: context.parsed.y,
              currency: currency,
              options: { locale: currencyLocale },
            }),
        },
      },
    },
    scales: {
      y: {
        min: 0,
        ticks: {
          font: {
            size: 8,
          },
          callback: (tickValue) =>
            formatNumberAsCurrency({
              value: tickValue,
              currency,
              options: { locale: currencyLocale },
            }),
        },
      },
      x: {
        display: false,
      },
    },
  };

  const chartData: ChartProps<"line">["data"] = {
    labels: commitmentConsumption?.map((item) => item.date),
    datasets: [
      {
        data: commitmentConsumption?.map((item) => item.usedAmount) || [],
        fill: "origin",
        borderWidth: 1,
        borderColor:
          isActiveCommitmentStatus ?
            theme.other.chartColors.green
          : theme.other.chartColors.red,
        backgroundColor:
          isActiveCommitmentStatus ?
            theme.other.chartColors.greenTransparent
          : theme.other.chartColors.redTransparent,
      },
    ],
  };

  return (
    <Popover width="target" position="bottom" withArrow shadow="md">
      <Popover.Target>
        <UnstyledButton>
          <Notification
            color={isActiveCommitmentStatus ? "green.5" : "brand.5"}
            title={
              <Text c="gray.6" size="xs">
                {isAnnualPricingModel ?
                  `${t("commitment.orderNumber")} ${orderNumber}`
                : `${t("commitment.csiNumber")} ${csiNumber}`}
              </Text>
            }
            withCloseButton={false}
            className="pl-5 shadow-md"
          >
            <Box className="mt-2 flex flex-col gap-2">
              <Box className="flex flex-col">
                <Box className="flex items-center gap-2">
                  {isAnnualPricingModel && (
                    <>
                      <Tooltip
                        label={`${t("commitment.usedAmount")}: ${usedAmountAsCurrency}`}
                        c={isActiveCommitmentStatus ? "green.3" : "brand.3"}
                      >
                        <Text>{formatNumberAsPercentage(usedPercentage)}</Text>
                      </Tooltip>
                      <Progress.Root className="flex-1" size="xl" radius="lg">
                        <Tooltip
                          label={`${t("commitment.usedAmount")}: ${usedAmountAsCurrency}`}
                          c={isActiveCommitmentStatus ? "green.3" : "brand.3"}
                        >
                          <Progress.Section
                            value={usedPercentage}
                            color={
                              isActiveCommitmentStatus ? "green.5" : "brand.5"
                            }
                          >
                            <Progress.Label>
                              {formatNumber({ value: usedAmount })}
                            </Progress.Label>
                          </Progress.Section>
                        </Tooltip>
                        <Tooltip
                          label={`${t("commitment.availibleAmount")}: ${availableAmountAsCurrency}`}
                          c="gray.4"
                        >
                          <Progress.Section
                            value={availablePercentage}
                            color="gray.4"
                          >
                            <Progress.Label>
                              {formatNumber({
                                value: avaliableAmount,
                              })}
                            </Progress.Label>
                          </Progress.Section>
                        </Tooltip>
                      </Progress.Root>
                    </>
                  )}
                  <Tooltip
                    label={`${t("commitment.quantity")}: ${quantityAsCurrency}`}
                    c="gray.4"
                  >
                    <Text>{quantityAsCurrency}</Text>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Notification>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown className="flex flex-col p-0">
        <Notification
          color={isActiveCommitmentStatus ? "green.5" : "brand.5"}
          withCloseButton={false}
          className="pl-5 shadow-none"
        >
          <Box>
            <Box className="flex items-center justify-between">
              <Text variant="text" size="md">
                {isAnnualPricingModel ?
                  t("commitment.commitment")
                : t("commitment-pricing-model.payAsYouGo", {
                    ns: "enum",
                  })
                }
              </Text>
              <Text
                className="truncate rounded border px-1 py-0.5 font-mono text-white"
                bg={isActiveCommitmentStatus ? "green.5" : "brand.5"}
                size="xs"
              >
                {t(`commitment-status.${status}`, {
                  ns: "enum",
                }).toUpperCase()}
              </Text>
            </Box>

            <Divider my={"sm"} />

            <Box className="mb-3 flex flex-col justify-between gap-1">
              <Text variant="text" size="xs">
                <b>{t("common.tenant")}:</b> {name}
              </Text>
              {isAnnualPricingModel && (
                <Text size="xs">
                  <b>{t("commitment.orderNumber")}</b>: {orderNumber}
                </Text>
              )}
              <Text variant="text" size="xs">
                <b>{t("common.subscription")}:</b> {subscriptionId}
              </Text>
              <Text size="xs">
                <b>{t("commitment.csiNumber")}</b>: {csiNumber}
              </Text>
            </Box>
            {isAnnualPricingModel && (
              <>
                <Divider my={"sm"} />
                <Box className="mb-3 flex flex-col justify-between gap-1">
                  <Text size="xs">
                    <b>{t("commitment.startDate")}</b>:{" "}
                    {formatToDate(startDate)}
                  </Text>
                  <Text size="xs">
                    <b>{t("commitment.endDate")}</b>: {formatToDate(endDate)}
                  </Text>
                </Box>
              </>
            )}

            <Divider my={"sm"} />
            <Box className="flex flex-col justify-between gap-1">
              <Text size="xs">
                <b>{t("commitment.usedAmount")}</b>: {usedAmountAsCurrency}{" "}
                {isAnnualPricingModel &&
                  `(${formatNumberAsPercentage(usedPercentage)})`}
              </Text>
              {isAnnualPricingModel && (
                <>
                  <Text size="xs">
                    <b>{t("commitment.availibleAmount")}</b>:{" "}
                    {availableAmountAsCurrency} (
                    {formatNumberAsPercentage(availablePercentage)})
                  </Text>
                  <Text size="xs">
                    <b>{t("commitment.quantity")}</b>: {quantityAsCurrency}
                  </Text>
                </>
              )}
            </Box>
            <Divider my={"sm"} />
            <Box className="flex flex-col justify-between gap-1">
              <Text size="xs">
                <b>{t("commitment.consumtionTrend")}</b>
              </Text>
              <div className="h-20">
                <Line options={chartOptions} data={chartData} />
              </div>
            </Box>
          </Box>
        </Notification>
      </Popover.Dropdown>
    </Popover>
  );
};
