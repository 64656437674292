import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";

import { regionKeys } from "./queryKeys";

interface UseDeleteRegionMutationOptions
  extends Omit<UseMutationOptions<unknown, Error, string>, "mutationFn"> {}

export const useDeleteRegionMutation = ({
  onSettled,
  ...options
}: UseDeleteRegionMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: Api.regions.delete,
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: regionKeys.all(),
      });
      onSettled?.(...args);
    },
  });
};
