import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { SCNotificationResponse } from "@/lib/api/dto";

import { scNotificationKeys } from "./queryKeys";

interface UseFetchSCNotificationsQueryOptions<T>
  extends Omit<
    UseQueryOptions<SCNotificationResponse[], Error, T>,
    "queryKey" | "queryFn"
  > {}

export const useFetchSCNotificationsQuery = <T = SCNotificationResponse[]>(
  options: UseFetchSCNotificationsQueryOptions<T> = {}
) => {
  return useQuery({
    ...options,
    queryKey: scNotificationKeys.all(),
    queryFn: () => Api.scNotifications.list().then((res) => res.data),
  });
};
