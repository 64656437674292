import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CreateLicensePayload } from "@/lib/api/dto";

import { licenseKeys } from "./queryKeys";

export const useUploadLicenceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CreateLicensePayload) =>
      Api.licenses.upload(payload.organizationId, payload.file),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: licenseKeys.all() });
    },
  });
};
