import { ScrollArea } from "@mantine/core";
import { DatePickerInput, DatesRangeValue } from "@mantine/dates";
import subMonths from "date-fns/subMonths";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { EnumSelect } from "@/components/ui/enum-select";
import { Separator } from "@/components/ui/separator";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchTimeTypesQuery } from "@/hooks/api/useFetchTimeTypesQuery";
import { TimeUnit } from "@/lib/api/dto";
import { removeDateOffset } from "@/lib/dateUtils";
import { DashboardFifthDiagramReport } from "@/widgets/DashboardReports/DashboardFifthDiagramReport";
import { DashboardFirstDiagramReport } from "@/widgets/DashboardReports/DashboardFirstDiagramReport";
import { DashboardFourthDiagramReport } from "@/widgets/DashboardReports/DashboardFourthDiagramReport";
import { DashboardKPIReport } from "@/widgets/DashboardReports/DashboardKPIReport";
import { DashboardPolarReport } from "@/widgets/DashboardReports/DashboardPolarReport";
import { DashboardSecondDiagramReport } from "@/widgets/DashboardReports/DashboardSecondDiagramReport";
import { DashboardThirdDiagramReport } from "@/widgets/DashboardReports/DashboardThirdDiagramReport";

const now = new Date();
const oneMonthAgo = subMonths(now, 1);

export const DashboardPage = () => {
  const { t } = useTranslation("dashboard");
  const [datefrom, setFrom] = useState(oneMonthAgo);
  const [dateto, setTo] = useState(now);
  const [datePickerValue, setDatePickerValue] = useState<DatesRangeValue>([
    datefrom,
    dateto,
  ]);
  const [timeUnit, setTimeUnit] = useState<TimeUnit | null>(null);
  const { tenant } = useTenantContext();
  const { data: timeUnitEnum, isFetching } = useFetchTimeTypesQuery();

  const handleDateRangeChange = ([from, to]: DatesRangeValue) => {
    setDatePickerValue([from, to]);

    if (from && to) {
      setFrom(from);
      setTo(to);
    }
  };

  const firstDiagramTitle = `${t(`consumptionPer.title`, {
    value: timeUnit ? t(`consumptionPer.${timeUnit}`) : "...",
  })}`;

  const firstDiagramHeader = (
    <div className="flex justify-between gap-2">
      <h2 className="text-2xl">{firstDiagramTitle}</h2>
      <div className="flex gap-2">
        <DatePickerInput
          type="range"
          valueFormat="DD MMM YYYY"
          value={datePickerValue}
          onChange={handleDateRangeChange}
          maxDate={now}
        />
        <EnumSelect
          value={timeUnit}
          onChange={setTimeUnit}
          data={timeUnitEnum}
          disabled={isFetching}
        />
      </div>
    </div>
  );

  return (
    <ScrollArea className="flex flex-1 bg-gray-100 *:*:h-full">
      <div className="mx-auto grid max-w-screen-2xl gap-2 p-4 md:grid-cols-12">
        <div className="md:col-span-6 md:row-span-2">
          <DashboardFourthDiagramReport
            subscriptionid={tenant?.currentSubscription?.subscriptionID}
          />
        </div>
        <div className="md:col-span-3 md:row-span-2">
          <DashboardPolarReport
            className="md:h-full"
            subscriptionid={tenant?.currentSubscription?.subscriptionID}
          />
        </div>
        <div className="md:col-span-3">
          <DashboardKPIReport
            className="md:h-full"
            subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
          />
        </div>
        <div className="md:col-span-3">
          <DashboardFifthDiagramReport
            className="md:h-full"
            subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
          />
        </div>
        <div className="md:col-span-9">
          <div className="flex h-full basis-1/3 flex-col gap-2 rounded-md border bg-white p-3 shadow-md">
            {firstDiagramHeader}
            <Separator />
            <h3 className="text-center text-sm text-gray-500">
              {tenant?.name}
            </h3>
            <div className="grid h-48 grid-cols-[5fr_1fr] gap-2">
              {timeUnit && (
                <>
                  <DashboardFirstDiagramReport
                    header={firstDiagramHeader}
                    timeUnit={timeUnit}
                    datefrom={+removeDateOffset(datefrom)}
                    dateto={+removeDateOffset(dateto)}
                    tenantocid={tenant?.ocid || ""}
                  />
                  <DashboardSecondDiagramReport
                    timeUnit={timeUnit}
                    datefrom={+removeDateOffset(datefrom)}
                    dateto={+removeDateOffset(dateto)}
                    tenantocid={tenant?.ocid || ""}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="md:col-span-3">
          <DashboardThirdDiagramReport
            subscriptionid={tenant?.currentSubscription?.subscriptionID}
          />
        </div>
      </div>
    </ScrollArea>
  );
};
