import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { ProductDescriptionResponse } from "@/lib/api/dto";

import { productDescriptionKeys } from "./queryKeys";

interface UseFetchProductDescriptionQueryOptions<
  TSelectData = ProductDescriptionResponse[]
> extends UseQueryOptions<ProductDescriptionResponse[], Error, TSelectData> {}

export const UseFetchProductDescriptionQuery = (
  options?: UseFetchProductDescriptionQueryOptions
) => {
  return useQuery({
    queryKey: productDescriptionKeys.all(),
    queryFn: () => Api.productDescriptions.list().then((res) => res.data),
    ...options,
  });
};
