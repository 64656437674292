import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { AuthorityResponse } from "@/lib/api/dto";

import { authorityKeys } from "./queryKeys";

interface UseFetchAuthorityQueryOptions<TSelectData = AuthorityResponse[]>
  extends UseQueryOptions<AuthorityResponse[], Error, TSelectData> {}

export const useFetchAuthorityQuery = (
  options?: UseFetchAuthorityQueryOptions
) => {
  return useQuery({
    queryKey: authorityKeys.all(),
    queryFn: () => Api.authorities.list().then((res) => res.data),
    ...options,
  });
};
