import { createMRTColumnHelper } from "mantine-react-table";

import { CompartmentStatusesResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<CompartmentStatusesResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.compartmentStatus.name",
  }),
  columnHelper.accessor("description", {
    header: "entity:administration.compartmentStatus.description",
  }),
];
