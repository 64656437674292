import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { ProductServiceResponse } from "@/lib/api/dto";

import { productServiceKeys } from "./queryKeys";

interface UseFetchProductServiceQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      ProductServiceResponse[],
      Error,
      T,
      ReturnType<typeof productServiceKeys.all>
    >,
    "queryKey" | "queryFn"
  > {}

export const useFetchProductServiceQuery = <T = ProductServiceResponse[]>(
  options: UseFetchProductServiceQueryOptions<T> = {}
) => {
  return useQuery({
    ...options,
    queryKey: productServiceKeys.all(),
    queryFn: () => Api.productService.list().then((res) => res.data),
  });
};
