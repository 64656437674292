import { Navigate } from "react-router-dom";

import { PermissionControl } from "@/components/PermissionControl";
import { Claim } from "@/lib/api/dto";
import { LicensesTable } from "@/widgets/LicensesTable/LicensesTable";

export const LicensesPage = () => {
  return (
    <PermissionControl
      permission={[
        Claim.SUPERADMIN,
        Claim.ORG_LICENCE_MANAGE,
        Claim.ORG_LICENCE_READ,
      ]}
      fallbackContent={<Navigate to="/entity" replace />}
    >
      <LicensesTable />
    </PermissionControl>
  );
};
