import { Navigate } from "react-router-dom";

import { PermissionControl } from "@/components/PermissionControl";
import { Claim } from "@/lib/api/dto";
import { StatesTable } from "@/widgets/StatesTable/StatesTable";

export const StatesPage = () => {
  return (
    <PermissionControl
      permission={Claim.SUPERADMIN}
      fallbackContent={<Navigate to="/entity" replace />}
    >
      <StatesTable />
    </PermissionControl>
  );
};
