import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { PolarDiagramParams, PolarDiagramResponse } from "@/lib/api/dto";

import { dashboardReportKeys } from "./queryKeys";

interface UseFetchPolarDiagramOptions<T>
  extends Omit<
    UseQueryOptions<
      PolarDiagramResponse[],
      Error,
      T,
      ReturnType<typeof dashboardReportKeys.polarDiagram>
    >,
    "queryKey" | "queryFn"
  > {
  params: Partial<PolarDiagramParams>;
}

export const useFetchPolarDiagramQuery = <T = PolarDiagramResponse[]>({
  params,
  ...options
}: UseFetchPolarDiagramOptions<T>) => {
  return useQuery({
    ...options,
    staleTime: 1000 * 60 * 5,
    queryKey: dashboardReportKeys.polarDiagram(params),
    queryFn: ({ queryKey: [_, keyParams] }) =>
      keyParams.subscriptionid && keyParams.year ?
        Api.dashboard
          .polarDiagram({
            subscriptionid: keyParams.subscriptionid,
            year: keyParams.year,
          } as PolarDiagramParams)
          .then((res) => res.data)
      : [],
  });
};
