import { TextInput, Button } from "@mantine/core";
import { zodResolver } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useCreateOrUpdateStatisticTypeMutation } from "@/hooks/api/useCreateOrUpdateStatisticTypeMutation";
import { useTForm } from "@/hooks/useTForm";
import { StatisticTypeResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";
interface StatisticTypeDetailsProps {
  statisticType?: StatisticTypeResponse | null;
  onSettled?: () => void;
  className?: string;
}
const statisticTypeSchema = z.object({
  name: z.string().min(1, "form:field.required"),
  description: z
    .string()
    .transform((value) => value || null)
    .nullable(),
});

type FormData = z.infer<typeof statisticTypeSchema>;

const defaultValues: FormData = {
  name: "",
  description: "",
};

const mapStatisticTypeToFormData = (
  statisticType?: StatisticTypeResponse | null,
): FormData => {
  if (!statisticType) return defaultValues;

  return {
    name: statisticType.name ?? defaultValues.name,
    description: statisticType.description ?? defaultValues.description,
  };
};

export const StatisticTypeDetails = ({
  statisticType,
  onSettled,
  className,
}: StatisticTypeDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);
  const { mutate, isPending } = useCreateOrUpdateStatisticTypeMutation({
    statisticTypeId: statisticType?.id,
  });

  const form = useTForm<FormData>({
    initialValues: mapStatisticTypeToFormData(statisticType),
    validate: zodResolver(statisticTypeSchema),
  });

  const onSubmit = (data: FormData) => {
    mutate(data, { onSettled });
  };

  return (
    <form
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("administration.statisticType.name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        label={t("administration.statisticType.description")}
        {...form.getInputProps("description")}
      />
      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" loading={isPending} disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </form>
  );
};
