import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CreateRegionPayload, RegionResponse } from "@/lib/api/dto";

import { regionKeys } from "./queryKeys";

interface UseCreateOrUpdateRegionMutationOptions
  extends UseMutationOptions<RegionResponse, Error, CreateRegionPayload> {
  regionId?: string;
}

export const useCreateOrUpdateRegionMutation = ({
  regionId,
  ...options
}: UseCreateOrUpdateRegionMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (data) => {
      if (regionId) {
        return Api.regions.update(regionId, data).then((res) => res.data);
      }

      return Api.regions.create(data).then((res) => res.data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: regionKeys.all() });
    },
  });
};
