import { Paper, Button, TextInput, Select } from "@mantine/core";
import { zodResolver } from "@mantine/form";
import { useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { toast } from "@/components/ui/use-toast";
import { authKeys, userKeys } from "@/hooks/api/queryKeys";
import { useFetchOrganizationsQuery } from "@/hooks/api/useFetchOrganizationsQuery";
import { useUpdateUserMutation } from "@/hooks/api/useUpdateUserMutation";
import { useTForm } from "@/hooks/useTForm";
import { Claim, UpdateUserPayload, UserResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface UserDetailsProps {
  user?: UserResponse;
  onSettled?: () => void;
  className?: string;
}

const userSchema = z
  .object({
    name: z.string().min(1, "form:field.required"),
    address: z.string(),
    organizationId: z
      .string()
      .nullable()
      .transform((value) => value ?? ""),
    authorities: z.array(z.nativeEnum(Claim)).optional(),
  })
  .refine(
    (schema) => {
      if (
        (schema.authorities?.includes(Claim.ORG_USER) ||
          schema.authorities?.includes(Claim.ORG_ADMIN) ||
          schema.authorities?.includes(Claim.OCI_SCHEDULER)) &&
        !schema.organizationId
      ) {
        return false;
      }

      return true;
    },
    {
      message: "form:field.required",
      path: ["organizationId"],
    },
  );

const mapUserToFormData = (
  user?: UserResponse,
): {
  name: string;
  address: string;
  organizationId: string;
  authorities?: Claim[];
} => ({
  name: user?.name ?? "",
  address: user?.address ?? "",
  organizationId: user?.organization?.id ?? "",
  authorities: user?.authorities,
});

type UserFormData = z.infer<typeof userSchema>;

export const UserDetails = ({
  user,
  onSettled,
  className,
}: UserDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const queryClient = useQueryClient();

  const { data: organizations } = useFetchOrganizationsQuery();

  const { mutate, isPending } = useUpdateUserMutation();

  const form = useTForm<UserFormData>({
    initialValues: mapUserToFormData(user),
    validate: zodResolver(userSchema),
  });

  const showOrganizationField =
    user?.authorities?.includes(Claim.ORG_USER) ||
    user?.authorities?.includes(Claim.ORG_ADMIN) ||
    user?.authorities?.includes(Claim.OCI_SCHEDULER);

  const onSubmit = (data: UserFormData) => {
    mutate(
      {
        name: data.name,
        address: data.address,
        organizationId: data.organizationId,
        id: user?.id,
      } as UpdateUserPayload,
      {
        onSuccess: (response) => {
          form.setInitialValues(mapUserToFormData(response));
          form.reset();
          toast({
            title: t("administration.user.userSaveSuccess"),
          });
          queryClient.invalidateQueries({
            queryKey: userKeys.all(),
          });
          queryClient.invalidateQueries({
            queryKey: authKeys.me(),
          });
        },
        onError: (error) => {
          toast({
            title:
              (isAxiosError(error) && error.response?.data?.message) ||
              t("administration.user.userSaveError"),
            variant: "destructive",
          });
        },
        onSettled,
      },
    );
  };

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("administration.common.personName")}
        {...form.getInputProps("name")}
      />
      <TextInput
        label={t("administration.common.address")}
        {...form.getInputProps("address")}
      />
      {showOrganizationField && (
        <Select
          withAsterisk
          label={t("administration.common.organization")}
          {...form.getInputProps("organizationId")}
          data={organizations?.map((organization) => ({
            value: organization.id,
            label: organization.name,
          }))}
        />
      )}

      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};

export default UserDetails;
