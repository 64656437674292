import { useMantineTheme, Text, Popover, ActionIcon } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useMemo } from "react";
import { Pie } from "react-chartjs-2";

import { ChartContainer } from "@/components/ui/chart-container";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchSecondDiagramQuery } from "@/hooks/api/useFetchSecondDiagramQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { TimeUnit } from "@/lib/api/dto";
import { formatNumberAsCurrency } from "@/lib/utils";

interface DashboardSecondDiagramReportProps {
  datefrom: string | number;
  dateto: string | number;
  timeUnit: TimeUnit;
  tenantocid: string;
}

export const DashboardSecondDiagramReport = ({
  datefrom,
  dateto,
  timeUnit,
  tenantocid,
}: DashboardSecondDiagramReportProps) => {
  const theme = useMantineTheme();
  const { tenant } = useTenantContext();
  const { data, isFetching, isLoading } = useFetchSecondDiagramQuery({
    params: {
      datefrom,
      dateto,
      timetype: timeUnit,
      tenantocid,
    },
  });

  const chartData = useMemo(
    () =>
      data && {
        labels: data.map((item) => item.serviceid),
        datasets: [
          {
            data: data.map((item) => item.mycost),
            borderColor: theme.other.chartColorsArray,
            backgroundColor: theme.other.transparentColorsArray,
            hoverBackgroundColor: theme.other.hoverColorsArray,
          },
        ],
      },
    [
      data,
      theme.other.chartColorsArray,
      theme.other.hoverColorsArray,
      theme.other.transparentColorsArray,
    ],
  );

  const currencyLocale = useCurrencyLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;

  return (
    <div className="relative grid w-max min-w-[16rem] flex-shrink-0 items-center justify-items-center gap-2 bg-white">
      <ChartContainer
        isDataEmpty={!data?.length}
        loading={isLoading}
        fetching={isFetching}
        chartContentClassName="flex justify-center items-center"
        className="items-center p-0"
      >
        <Pie
          width={180}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            spacing: 1,
            aspectRatio: 1,
            plugins: {
              tooltip: {
                intersect: true,
                titleFont: {
                  size: 12,
                },
                bodyFont: {
                  size: 12,
                },
                callbacks: {
                  label: (tooltipItem) =>
                    formatNumberAsCurrency({
                      value: tooltipItem.parsed,
                      currency,
                      options: { locale: currencyLocale },
                    }),
                },
              },
              legend: {
                display: false,
              },
            },
          }}
          data={
            chartData || {
              labels: [],
              datasets: [],
            }
          }
        />
      </ChartContainer>
      <Popover position="bottom" withArrow shadow="md" arrowOffset={1}>
        <Popover.Target>
          <ActionIcon
            c="black"
            bg="gray.2"
            className="absolute right-1 top-1 z-10 p-1"
            disabled={isFetching}
          >
            <IconInfoCircle />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown className="cursor-default">
          <SecondDiagramLegend
            labels={chartData?.labels}
            dataset={chartData?.datasets[0]}
            currency={currency}
            currencyLocale={currencyLocale}
          />
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

interface SecondDiagramLegendProps {
  labels?: string[];
  dataset?: {
    data: number[];
    borderColor: string[];
    backgroundColor: string[];
    hoverBackgroundColor: string[];
  };
  currency: CurrencyCode | null;
  currencyLocale: string;
}

const SecondDiagramLegend = ({
  labels,
  dataset,
  currency,
  currencyLocale,
}: SecondDiagramLegendProps) => {
  let colorIndex = 0;

  return (
    <>
      {labels?.map((label, i) => {
        const amount =
          formatNumberAsCurrency({
            value: dataset?.data[i],
            currency,
            options: { locale: currencyLocale },
          }) ?? 0;

        colorIndex =
          colorIndex === dataset?.backgroundColor?.length ? 0 : colorIndex;

        return (
          <div key={`${i}-${colorIndex}`} className="flex items-center gap-2">
            <span
              className="h-3 w-3 border"
              style={{
                backgroundColor: dataset?.backgroundColor[colorIndex],
                borderColor: dataset?.borderColor[colorIndex++],
              }}
            ></span>
            <Text component="span" className="text-xs">
              {`${label} - ${amount}`}
            </Text>
          </div>
        );
      })}
    </>
  );
};
