import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { ListMetricDto, ListMetricNameParams } from "@/lib/api/dto";

import { ociKeys } from "./queryKeys";

interface UseFetchListMetricNameQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      ListMetricDto[],
      Error,
      T,
      ReturnType<typeof ociKeys.listMetricName>
    >,
    "queryKey" | "queryFn"
  > {
  params?: ListMetricNameParams;
}

export const useFetchListMetricNameQuery = <T = ListMetricDto[]>({
  params,
  ...options
}: UseFetchListMetricNameQueryOptions<T> = {}) => {
  return useQuery({
    ...options,
    queryKey: ociKeys.listMetricName(params),
    queryFn: ({ queryKey }) =>
      queryKey[2] && queryKey[2].namespace
        ? Api.oci.listMetricName(queryKey[2]).then((res) => res.data)
        : [],
  });
};
