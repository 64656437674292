import { createMRTColumnHelper } from "mantine-react-table";

import { ProductDescriptionResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<ProductDescriptionResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.productDescription.name",
  }),
  columnHelper.accessor("description", {
    header: "entity:administration.productDescription.description",
  }),
  columnHelper.accessor("productServiceId.name", {
    header: "entity:administration.productDescription.productService.name",
  }),
  columnHelper.accessor("productServiceId.description", {
    header:
      "entity:administration.productDescription.productService.description",
  }),
];
