import { useMutation, useQueryClient } from "@tanstack/react-query";

import { LocalStorageKeys } from "@/constants";
import { Api } from "@/lib/api";

import { useAuthStore } from "../useAuth";
import { localStorageActions } from "../useLocalStorage";

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => Api.auth.logout(),
    onSuccess: () => {
      useAuthStore.getState().clearTokens();
      queryClient.clear();
      localStorageActions.removeItem(LocalStorageKeys.SelectedTenant);
      localStorageActions.removeItem(LocalStorageKeys.SelectedOrganization);
    },
  });
};
