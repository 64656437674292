import { MultiSelect, Select, useMantineTheme } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { useTranslation } from "react-i18next";

import { Toaster as Sonner } from "@/components/ui/sonner";
import { Toaster } from "@/components/ui/toaster";
import { Languages } from "@/constants";

import { OrganizationContextProvider } from "./context/OrganizationContext";
import { TenantContextProvider } from "./context/TenantContext";
import { queryClient, clientPersister } from "./lib/queryClient";
import Pages from "./Pages";

if (import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", "130.61.36.232:3000"],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    environment: import.meta.env.MODE,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
function App() {
  const { i18n, t } = useTranslation();
  const theme = useMantineTheme();

  theme.components = {
    ...theme?.components,
    Select: Select.extend({
      ...theme?.components?.Select,
      defaultProps: {
        ...theme?.components?.Select?.defaultProps,
        nothingFoundMessage: t("state.nothingFoundMessage"),
      },
    }),
    MultiSelect: MultiSelect.extend({
      ...theme?.components?.MultiSelect,
      defaultProps: {
        ...theme?.components?.MultiSelect?.defaultProps,
        nothingFoundMessage: t("state.nothingFoundMessage"),
      },
    }),
  };

  const dayjsLocaleMap: Record<Languages, string> = {
    cyril: "sr-cyrl",
    latin: "sr",
  };

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: clientPersister,
        buster: __BUILD_HASH__,
        maxAge: 7 * 24 * 60 * 60 * 1000,
        dehydrateOptions: {
          shouldDehydrateQuery(query) {
            return query.queryKey[0] === "resources";
          },
        },
      }}
    >
      <TenantContextProvider>
        <OrganizationContextProvider>
          <DatesProvider
            settings={{
              locale: dayjsLocaleMap[i18n.language as Languages],
            }}
          >
            <Pages />
          </DatesProvider>
        </OrganizationContextProvider>
      </TenantContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <Toaster />
      <Sonner />
    </PersistQueryClientProvider>
  );
}

export default App;
