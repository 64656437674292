import { Paper, TextInput, Button } from "@mantine/core";
import { isAxiosError } from "axios";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { z } from "zod";

import { PermissionControl } from "@/components/PermissionControl";
import { toast } from "@/components/ui/use-toast";
import { useCreateSuperAdminUserMutation } from "@/hooks/api/useCreateSuperAdminUserMutation";
import { useTForm } from "@/hooks/useTForm";
import { Claim } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface CreateSuperAdminUserPageProps {
  onSettled?: () => void;
  className?: string;
}

const superAdminUserSchema = z.object({
  email: z
    .string()
    .min(1, "form:field.required")
    .email("entity:administration.organization.emailValid"),
  password: z.string().min(1, "form:field.required"),
  name: z.string().min(1, "form:field.required"),
});

type SuperAdminUserFormValues = z.infer<typeof superAdminUserSchema>;

export const CreateSuperAdminUserPage = ({
  className,
  onSettled,
}: CreateSuperAdminUserPageProps) => {
  const { t } = useTranslation(["entity", "common"]);
  const form = useTForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
    },

    validate: zodResolver(superAdminUserSchema),
  });

  const { mutate, isPending } = useCreateSuperAdminUserMutation();

  const onSubmit = (values: SuperAdminUserFormValues) => {
    mutate(values, {
      onSuccess: () => {
        form.reset();
        toast({
          title: t("administration.organization.createUserSuccess"),
        });
      },
      onError: (error) => {
        if (isAxiosError(error) && error.response?.data?.message) {
          toast({
            title: "Greška",
            description: error.response.data.message,
            variant: "destructive",
          });
        } else {
          toast({
            title: t("administration.organization.createUserError"),
            variant: "destructive",
          });
        }
      },
      onSettled,
    });
  };

  return (
    <PermissionControl
      permission={[Claim.SUPERADMIN, Claim.ORG_USERS_WRITE]}
      fallbackContent={<Navigate to="/entity" replace />}
    >
      <Paper
        component="form"
        className={cn("flex flex-col gap-2", className)}
        onSubmit={form.onSubmit(onSubmit)}
      >
        <TextInput
          withAsterisk
          label={t("administration.common.email")}
          {...form.getInputProps("email")}
        />
        <TextInput
          withAsterisk
          label={t("administration.common.personName")}
          {...form.getInputProps("name")}
        />
        <TextInput
          withAsterisk
          label={t("administration.common.password")}
          type="password"
          {...form.getInputProps("password")}
        />
        <div className="mt-2 flex justify-between gap-2">
          <Button variant="light" disabled={isPending} onClick={onSettled}>
            {t("common:userActions.cancel")}
          </Button>
          <Button type="submit" disabled={isPending}>
            {t("common:userActions.save")}
          </Button>
        </div>
      </Paper>
    </PermissionControl>
  );
};
