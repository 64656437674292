import {
  TextInput,
  PasswordInput,
  Button,
  Divider,
  Title,
  Text,
} from "@mantine/core";
import { AxiosError } from "axios";
import { ParseKeys } from "i18next";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";

import logo from "@/assets/logo.svg";
import { LanguagePicker } from "@/components/ui/language-picker";
import { errorToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { useTForm } from "@/hooks/useTForm";

import sistemiLogo from "../assets/sistemi-logo.svg";

const loginSchema = z.object({
  email: z
    .string()
    .min(1, "form:field.required")
    .email("common:login.form.fields.email.errors.invalid"),
  password: z.string().min(1, "form:field.required"),
});

type LoginSchema = z.infer<typeof loginSchema>;

export const LoginPage = () => {
  const location = useLocation();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { login, isLoggedIn } = useAuth();
  const form = useTForm<LoginSchema>({
    initialValues: {
      email: "",
      password: "",
    },
    validate: zodResolver(loginSchema),
  });

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  const onSubmit = (data: LoginSchema) => {
    login.mutate(data, {
      onError: (error) => {
        errorToast({
          title: t([
            `login.form.errors.${(error as AxiosError).status}` as ParseKeys,
            "login.form.errors.generic",
          ]),
        });
      },
      onSuccess: () => {
        navigate(location.state?.from?.pathname || "/", { replace: true });
      },
    });
  };

  return (
    <div className="relative flex h-full flex-col items-center justify-center bg-primary p-4">
      <div className="absolute right-4 top-4">
        <LanguagePicker dropdownPosition="bottom-end" />
      </div>
      <div className="w-full max-w-96 p-6">
        <img src={sistemiLogo} alt="logo" className="w-full" />

        <Divider className="my-4" />

        <img src={logo} alt="logo" className="mx-auto" />
      </div>

      <form onSubmit={form.onSubmit(onSubmit)} className="w-full max-w-2xl">
        <div className="flex flex-col gap-6 rounded-xl bg-white p-4 shadow-md">
          <div>
            <Title component="h1" order={3}>
              {t("login.form.title")}
            </Title>
            <Text size="sm" className="text-muted-foreground">
              {t("login.form.subtitle")}
            </Text>
          </div>

          <Divider />

          <div className="flex flex-col gap-4">
            <TextInput placeholder="Email" {...form.getInputProps("email")} />

            <PasswordInput
              placeholder="Password"
              {...form.getInputProps("password")}
            />

            <Button
              type="submit"
              fullWidth
              className="mt-2 rounded-md bg-primary hover:bg-primary/90"
              loading={login.isPending}
            >
              {t("login.form.submit")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
