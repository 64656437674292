import { Navigate } from "react-router-dom";

import { PermissionControl } from "@/components/PermissionControl";
import { Claim } from "@/lib/api/dto";
import { OrganizationsTable } from "@/widgets/OrganizationsTable/OrganizationsTable";

export const OrganizationsPage = () => {
  return (
    <PermissionControl
      permission={Claim.SUPERADMIN}
      fallbackContent={<Navigate to="/entity" replace />}
    >
      <OrganizationsTable />
    </PermissionControl>
  );
};
