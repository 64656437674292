import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CompartmentResponse, CreateCompartmentPayload } from "@/lib/api/dto";

import { compartmentKeys } from "./queryKeys";

interface UseCreateOrUpdateCompartmentMutationOptions
  extends Omit<
    UseMutationOptions<CompartmentResponse, Error, CreateCompartmentPayload>,
    "mutationFn"
  > {
  id?: string;
}

export const useCreateOrUpdateCompartmentMutation = ({
  id,
  onSettled,
  ...rest
}: UseCreateOrUpdateCompartmentMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateCompartmentPayload) => {
      if (id) {
        return Api.compartments.update(id, data).then((res) => res.data);
      }

      return Api.compartments.create(data).then((res) => res.data);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({ queryKey: compartmentKeys.all() });
      onSettled?.(...args);
    },
    ...rest,
  });
};
