import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";
import { ResourceResponse, UpdateResourcePayload } from "@/lib/api/dto";

import { codebookResourceKeys } from "./queryKeys";

interface UseUpdateResourceMutationOptions
  extends Omit<
    UseMutationOptions<ResourceResponse, Error, UpdateResourcePayload>,
    "mutationFn"
  > {}

export const useUpdateResourceMutation = ({
  onSuccess,
  onError,
  ...options
}: UseUpdateResourceMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (payload) =>
      Api.codebookResources.update(payload).then((res) => res.data),
    onSuccess: (...args) => {
      successToast.update();

      queryClient.setQueryData<ResourceResponse[]>(
        codebookResourceKeys.all(),
        (oldData) => {
          if (!oldData) return;

          const newData = [...oldData];
          const index = newData.findIndex((item) => item.id === args[0].id);

          if (index !== -1) {
            newData?.splice(index, 1, args[0]);
          }

          return newData;
        },
      );

      onSuccess?.(...args);
    },
    onError: (...args) => {
      errorToast.update();
      onError?.(...args);
    },
  });
};
