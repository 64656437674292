import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { SCNotificationResponse } from "@/lib/api/dto";

import { scNotificationKeys } from "./queryKeys";

interface UseDeleteSCNotificationMutationOptions
  extends Omit<
    UseMutationOptions<SCNotificationResponse, Error, string>,
    "mutationFn" | "mutationKey"
  > {}

export const useDeleteSCNotificationMutation = ({
  onSettled,
  ...options
}: UseDeleteSCNotificationMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (id: string) =>
      Api.scNotifications.delete(id).then((res) => res.data),
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: scNotificationKeys.all(),
      });
      onSettled?.(...args);
    },
  });
};
