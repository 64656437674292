import { RangeSlider, Text, NumberInput } from "@mantine/core";
import { useDidUpdate } from "@mantine/hooks";
import { useState } from "react";

type Value = [number, number];

interface RangeSliderInputProps {
  max?: number;
  minRange?: number;
  description?: {
    firstInput: React.ReactNode;
    secondInput: React.ReactNode;
  };
  tooltip?: React.ReactNode | ((value: number) => React.ReactNode);
  step?: number;
  label?: React.ReactNode;
  disabled?: boolean;
  value?: Value;
  onChange?: (value: Value) => void;
}

const removeThousandSeparator = (value: string | number) => {
  if (typeof value === "number") return value;
  return value.replace(/,/g, "");
};

const RangeSliderInput = ({
  max = 100,
  minRange = 10,
  description,
  tooltip,
  step,
  label,
  disabled,
  value = [0, 0],
  onChange,
}: RangeSliderInputProps) => {
  const [tempValue, setTempValue] = useState<Value>(value);
  const [internalValue, setInternalValue] = useState<Value>(value);

  useDidUpdate(() => {
    onChange?.(internalValue);
  }, [internalValue[0], internalValue[1]]);

  return (
    <div className="flex flex-col gap-2">
      {typeof label === "string" ?
        <Text component="label" size="sm" className="font-medium">
          {label}
        </Text>
      : label}

      <RangeSlider
        disabled={disabled}
        value={tempValue}
        onChange={setTempValue}
        onChangeEnd={setInternalValue}
        minRange={minRange}
        max={max}
        step={step}
        label={tooltip}
      />

      <div className="mt-1 flex flex-col gap-4 md:flex-row">
        <NumberInput
          step={step}
          value={tempValue[0]}
          min={0}
          max={internalValue[1]}
          onChange={(value) =>
            setTempValue((prevState) => [
              +removeThousandSeparator(value),
              prevState[1],
            ])
          }
          onBlur={(e) =>
            setInternalValue((prevState) => [
              +removeThousandSeparator(e.target.value),
              prevState[1],
            ])
          }
          stepHoldDelay={500}
          stepHoldInterval={100}
          allowNegative={false}
          description={description?.firstInput}
          thousandSeparator={true}
          disabled={disabled}
        />
        <NumberInput
          step={step}
          value={tempValue[1]}
          min={internalValue[0]}
          max={max}
          onChange={(value) =>
            setTempValue((prevState) => [
              prevState[0],
              +removeThousandSeparator(value),
            ])
          }
          onBlur={(e) =>
            setInternalValue((prevState) => [
              prevState[0],
              +removeThousandSeparator(e.target.value),
            ])
          }
          stepHoldDelay={500}
          stepHoldInterval={100}
          allowNegative={false}
          description={description?.secondInput}
          thousandSeparator={true}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export { RangeSliderInput };
