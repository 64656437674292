import { UTCDate } from "@date-fns/utc";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import endOfYear from "date-fns/endOfYear";
import format from "date-fns/format";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";

export const yearMonthFormat = "yyyy-MM";
export const yearMoFormat = "yyyy-M";
export const yearFormat = "yyyy";
export const monthFormat = "MMM";
export const dayOfMonthYearFormat = "MM-yyyy";
export const monthYearFormat = "MMM yyyy";
export const dayMonthTimeFormat = "dd MMM HH:mm";

export const formatToDate = (
  date: Date | string | number,
  direction: "ytd" | "dty" = "dty",
) => {
  return format(
    new Date(date),
    direction === "ytd" ? "yyyy-MM-dd" : "dd.MM.yyyy",
  );
};

export const getUTCYearRange = (year: number) => {
  const start = new UTCDate(year, 0, 1);
  const end = new UTCDate(
    endOfYear(start).getTime() - new Date().getTimezoneOffset() * 60 * 1000,
  );

  return { start, end };
};

export const removeDateOffset = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const getYearAndMonthBetweenDates = (
  startDate: Date | null,
  endDate: Date | null,
) => {
  if (!startDate || !endDate) {
    return [];
  }
  const months = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  });

  const formattedMonths = months.map((date) => ({
    year: getYear(date),
    month: getMonth(date) + 1,
  }));

  return formattedMonths;
};

export function isCurrentMonth(year: number, month: number) {
  const now = new Date();
  const currentYear = getYear(now);
  const currentMonth = getMonth(now) + 1;

  return year === currentYear && month === currentMonth;
}
