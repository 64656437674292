import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CompartmentResponse } from "@/lib/api/dto";

import { compartmentKeys } from "./queryKeys";

interface UseFetchCompartmentsQueryOptions<T>
  extends Omit<
    UseQueryOptions<CompartmentResponse[], Error, T>,
    "queryKey" | "queryFn"
  > {}

export const useFetchCompartmentsQuery = <T = CompartmentResponse[]>(
  options?: UseFetchCompartmentsQueryOptions<T>,
) => {
  return useQuery({
    ...options,
    queryFn: () => Api.compartments.list().then((res) => res.data),
    select: (data) =>
      data.map((d) => ({
        ...d,
        compartmentStatus: {
          ...d.compartmentStatus,
          id: `${d.compartmentStatus.id}`,
        },
      })),
    queryKey: compartmentKeys.all(),
  });
};
