import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { ReportMetricParams, ReportMetricResponse } from "@/lib/api/dto";

import { reportKeys } from "./queryKeys";

interface UseFetchReportMetricQueryOptions<T>
  extends Omit<
      UseQueryOptions<
        ReportMetricResponse[],
        Error,
        T,
        ReturnType<typeof reportKeys.metric>
      >,
      "queryKey" | "queryFn"
    >,
    ReportMetricParams {}

export const useFetchReportMetricQuery = <T = ReportMetricResponse[]>(
  {
    datefrom,
    dateto,
    tenantocid,
    compartmentocid,
    statistictype,
    metricname,
    resource,
    ...options
  }: UseFetchReportMetricQueryOptions<T> = {} as UseFetchReportMetricQueryOptions<T>
) => {
  return useQuery({
    ...options,
    staleTime: options.staleTime ?? 1000 * 60 * 5,
    queryKey: reportKeys.metric({
      datefrom,
      dateto,
      tenantocid,
      compartmentocid,
      statistictype,
      metricname,
      resource,
    }),
    queryFn: ({ queryKey: [_, keyParams] }) =>
      keyParams.datefrom &&
      keyParams.dateto &&
      keyParams.tenantocid &&
      keyParams.compartmentocid &&
      keyParams.statistictype &&
      keyParams.resource
        ? Api.report.metric(keyParams).then((res) => res.data)
        : [],
  });
};
