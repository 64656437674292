import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { STALE_TIME } from "@/constants";
import { Api } from "@/lib/api";
import { ResourceResponse } from "@/lib/api/dto";

import { codebookResourceKeys } from "./queryKeys";

interface UseFetchCodebookResourcesQueryProps<T>
  extends Omit<
    UseQueryOptions<
      ResourceResponse[],
      Error,
      T,
      ReturnType<typeof codebookResourceKeys.all>
    >,
    "queryKey" | "queryFn"
  > {
  compartmentId?: string;
}

export const useFetchCodebookResourcesQuery = <T = ResourceResponse[]>({
  compartmentId,
  ...options
}: UseFetchCodebookResourcesQueryProps<T> = {}) => {
  return useQuery({
    queryKey: codebookResourceKeys.all(compartmentId),
    queryFn: ({ queryKey }) =>
      Api.codebookResources.list(queryKey[1]).then((res) => res.data),
    staleTime: STALE_TIME,
    ...options,
  });
};
