import { type EnumSelectData } from "@/components/ui/enum-select";

export enum Claim {
  VIEW_USERS = "VIEW_USERS",
  DELETE_USERS = "DELETE_USERS",
  EDIT_USERS = "EDIT_USERS",
  SUPERADMIN = "SUPERADMIN",
  SYS_USER = "SYS_USER",
  ORG_USER = "ORG_USER",
  SYS_ADMIN = "SYS_ADMIN",
  ORG_ADMIN = "ORG_ADMIN",
  ORG_USERS_WRITE = "ORG_USERS_WRITE",
  ORG_LICENCE_MANAGE = "ORG_LICENCE_MANAGE",
  ORG_LICENCE_READ = "ORG_LICENCE_READ",
  OCI_SCHEDULER = "OCI_SCHEDULER",
}

export enum SCNotificationType {
  Subscription = "subscription",
  Commitment = "commitment",
}

export enum SCNotificationValueType {
  Value = "value",
  Percent = "percent",
}

export enum UserType {
  SYSTEM = "SYSTEM",
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export enum SqlType {
  Hour = "hour",
  Day = "day",
  Month = "month",
}

export enum BudgetNotificationType {
  All = "ALL",
  Single = "SINGLE",
  Multiple = "MULTIPLE",
}

export enum NotificationOptions {
  Subscription = "subscription",
  Commitment = "commitment",
}

export enum CompartmentBudgetNotificationType {
  Month = "month",
  Day = "day",
}

export enum TimeUnit {
  Hour = "hour",
  Day = "day",
  Month = "month",
}

export enum CommitmentStatus {
  Active = "active",
  Expired = "expired",
  Inactive = "inactive",
}

export enum CommitmentPricingModel {
  Annual = "annual",
  PayAsYouGo = "payAsYouGo",
}

export interface LoginPayload {
  email: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export interface HeadquartersCity {
  id: number;
  name: string;
}

export interface OrganizationType {
  description: string | null;
  id: string;
  name: string;
}

export interface Organization {
  id: string;
  state: State;
  headquartersCity: HeadquartersCity;
  organizationType: OrganizationType;
  name: string;
  shortname: string;
  address: string;
  phone: string;
  email: string;
  www: string;
  license: {
    id: string;
    tenantsCount: number;
    expirationDate: string;
    valid: boolean;
  };
}

export type OrganizationLight = Pick<Organization, "id" | "name" | "shortname">;

export type CreateOrganizationPayload = Pick<
  Organization,
  "name" | "address" | "phone" | "email" | "www" | "shortname"
> & {
  headquartersCityId: string | null;
  organizationTypeId: string | null;
  stateCode: string | null;
};

export type CreateAndUpdateOrganizationResponse = Pick<
  Organization,
  | "address"
  | "email"
  | "headquartersCity"
  | "id"
  | "license"
  | "name"
  | "organizationType"
  | "phone"
  | "shortname"
  | "state"
  | "www"
>;

export type OrganizationResponse = Pick<
  Organization,
  | "address"
  | "email"
  | "headquartersCity"
  | "id"
  | "license"
  | "name"
  | "organizationType"
  | "shortname"
  | "state"
  | "www"
> & { phone: string[] };

export interface Tenant {
  id: string;
  ocid: string;
  name: string;
  regionId: string;
  region?: Region;
  csiNumber: string | null;
  organizationId: Organization["id"];
  organization: Organization;
  currentSubscription: Pick<
    Subscription,
    "id" | "subscriptionType" | "subscriptionID" | "currency"
  > | null;
  currentSubscriptionId: string;
}

export type CreateTenantPayload = Pick<
  Tenant,
  | "name"
  | "ocid"
  | "csiNumber"
  | "regionId"
  | "organizationId"
  | "currentSubscriptionId"
>;

export type TenantResponse = Pick<
  Tenant,
  | "id"
  | "name"
  | "ocid"
  | "region"
  | "csiNumber"
  | "currentSubscription"
  | "organization"
>;

export type TenantMinimal = Pick<Tenant, "name" | "ocid"> & {
  tenantId: Tenant["id"];
};

export interface ManageableResourceResponse {
  displayName: string;
  resourceType: string;
  lifecycleState: string;
  averageConsumptionDifference: number;
  currentAverageConsumption: number;
  pastAverageConsumption: number;
  percentageConsumptionDifference: number;
  identifier: string;
  compartmentId: string;
  availabilityDomain: string;
  timeCreated: Date;
  freeformTags: Record<string, string>;
}

export interface StopResourcePayload {
  resourceType: string;
  resourceOcid: string;
  tenantOcid: string;
}

export interface State {
  stateCode: string;
  name: string;
  countryCode: number;
}

export interface TenancyAccess {
  id: string;
  name: string;
  description: string;
}
export type TenancyAccessResponse = TenancyAccess;

export type StateResponse = State;

export interface GenericEntityFields {
  createdBy: string;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  version: number;
}

export interface CompartmentStatuses {
  version: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: string;
  updatedBy: string;
  id: string;
  name: string;
  description: string;
}

export type CompartmentStatusesResponse = CompartmentStatuses;

export interface ProductDescription {
  id: string;
  productDescriptionId: string;
  productServiceId: {
    id: string;
    productServiceId: string;
    name: string;
    description: string | null;
  };
  name: string;
  description: string | null;
}

export type ProductDescriptionResponse = ProductDescription;

export interface ResourceTypes {
  id: string;
  sku: string;
  name: string;
  description: string;
  unitReadable: string;
  unitDescription: string;
}

export type ResourceTypeResponse = ResourceTypes;

export interface StorageResultType {
  id: string;
  name: string;
  description: string;
}

export type StorageResultTypeResponse = StorageResultType;

export interface City {
  id: string;
  capital: boolean;
  state: State;
  name: string;
  postalCode: string;
}

export type CityResponse = City;

export type ExtendedCityResponse = CityResponse;

export type CreateCityPayload = Pick<
  City,
  "capital" | "name" | "postalCode"
> & {
  stateCode: City["state"]["stateCode"];
};

export interface Authority {
  id: string;
  name: string;
}
export type AuthorityResponse = Authority;

export interface Resource {
  id: string;
  ocid: string;
  name?: string;
  description?: string;
  organizationId: Organization["id"];
  organization: OrganizationLight;
  tenantId: Tenant["id"];
  tenant: TenantMinimal;
  compartment: CompartmentLight;
  ociQueriesCount: number;
}

export type ResourceResponse = Pick<
  Resource,
  | "id"
  | "ocid"
  | "name"
  | "description"
  | "organization"
  | "tenant"
  | "compartment"
  | "ociQueriesCount"
>;

export type CreateResourcePayload = Pick<
  Resource,
  "ocid" | "name" | "description" | "tenantId" | "organizationId"
>;

export type UpdateResourcePayload = Pick<
  Resource,
  "id" | "ocid" | "name" | "description" | "organizationId" | "tenantId"
>;

export interface CompartmentStatus {
  id: string;
  name: string;
  Description: string;
}

export interface Compartment {
  id: string;
  ocid: string;
  name: string;
  description: string;
  createdOn: string;
  tenantId: Tenant["id"];
  tenant: TenantMinimal;
  organizationId: Organization["id"];
  organization: OrganizationLight;
  compartmentStatusId: CompartmentStatus["id"];
  compartmentStatus: CompartmentStatus;
}

export type CompartmentResponse = Pick<
  Compartment,
  | "id"
  | "ocid"
  | "name"
  | "description"
  | "createdOn"
  | "tenant"
  | "organization"
  | "compartmentStatus"
>;

export type CreateCompartmentPayload = Pick<
  Compartment,
  | "ocid"
  | "name"
  | "description"
  | "tenantId"
  | "organizationId"
  | "compartmentStatusId"
>;

export type CompartmentLight = Pick<Compartment, "id" | "name" | "ocid">;

export interface MetricNamespace {
  metricNamespace: string;
  description: string;
  name: string;
}

export type MetricNamespaceResponse = Pick<
  MetricNamespace,
  "metricNamespace" | "description" | "name"
>;

export type CreateMetricNamespacePayload = Pick<
  MetricNamespace,
  "metricNamespace" | "description" | "name"
>;

export interface MetricMeasuringUnit extends GenericEntityFields {
  metricMeasuringUnit: string;
  name: string;
  description: string | null;
  regex: string | null;
}

export interface MetricName {
  metricName: string;
  description: string;
  name: string;
  metricNamespace: {
    metricNamespaceId: string;
    metricNamespace: string;
  };
  metricMeasuringUnit: {
    metricMeasuringUnitId: string;
    metricMeasuringUnit: string;
  };
}

export type MetricNameResponse = Pick<
  MetricName,
  | "metricName"
  | "description"
  | "name"
  | "metricMeasuringUnit"
  | "metricNamespace"
>;

export type CreateMetricNamePayload = Omit<
  MetricName,
  "metricNamespace" | "metricMeasuringUnit"
> & {
  metricNamespaceId: string | null;
  metricMeasuringUnitId: string | null;
};

export type MetricMeasuringUnitResponse = MetricMeasuringUnit;

export type CreateMetricMeasuringUnitPayload = Pick<
  MetricMeasuringUnit,
  "metricMeasuringUnit" | "name" | "regex" | "description"
>;
export interface FetchMetricMeasuringUnitParams {
  metricMeasuringUnitId?: string | null;
}

export interface StatisticType {
  id: number;
  name: string;
  description: string | null;
}

export type CreateStatisticTypePayload = Pick<
  StatisticType,
  "name" | "description"
>;

export type StatisticTypeResponse = Pick<
  StatisticType,
  "id" | "name" | "description"
>;

export interface UnitType {
  id: number;
  name: string;
  description: string;
}

export type UnitTypeResponse = UnitType;

export interface OCIQuery {
  statisticTypeId: number | null;
  metricNameId: string;
  metricNamespaceId: string | null;
  tenantId: string | null;
  unitTypeId: number | null;
  storageResultTypeId: number | null;
  compartmentId: string | null;
  ociResources: OCIResource[];
  id: string;
  resourcesIds: string[] | null;
  organizationId: string | null;
  statisticType: Pick<StatisticType, "id" | "name">;
  metricName: Pick<MetricName, "metricName" | "name">;
  metricNamespace: Pick<MetricNamespace, "metricNamespace" | "name">;
  unitType: Pick<UnitType, "id" | "name">;
  storageResultType: {
    id: number;
    name: string;
  };
  startTimeOffset: number;
  active: boolean;
}

export type CreateOCIQueryPayload = Pick<
  OCIQuery,
  | "statisticTypeId"
  | "metricNameId"
  | "metricNamespaceId"
  | "tenantId"
  | "unitTypeId"
  | "storageResultTypeId"
  | "compartmentId"
  | "resourcesIds"
  | "startTimeOffset"
> & {
  isActive: boolean;
};

export type UpdateOCIQueryPayload = Pick<
  OCIQuery,
  | "id"
  | "statisticTypeId"
  | "metricNameId"
  | "metricNamespaceId"
  | "tenantId"
  | "unitTypeId"
  | "storageResultTypeId"
  | "compartmentId"
  | "resourcesIds"
  | "startTimeOffset"
> & {
  isActive: boolean;
};

interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: { unsorted: boolean; sorted: boolean; empty: boolean };
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export type OCIQueryResponseContentItem = Pick<
  OCIQuery,
  | "id"
  | "statisticType"
  | "metricName"
  | "metricNamespace"
  | "tenantId"
  | "unitType"
  | "storageResultType"
  | "organizationId"
  | "compartmentId"
  | "ociResources"
  | "startTimeOffset"
  | "active"
> & {
  resourceOcid?: string;
  compartmentName?: string;
  activeLocalized?: string;
};

export type OCIQueryResponse = {
  content: OCIQueryResponseContentItem[];
  pageable: Pageable;
};

export type OCIResourceWithQueries = OCIResource & {
  queries: OCIQueryResponseContentItem[];
};

export interface FetchOCIQueryParams {
  page?: number;
  size?: number;
  sortBy?: string;
  sortDirection?: "ASC" | "DESC";
  tenantId?: string;
  resourceId?: string | null;
}

export interface License {
  id: string;
  organizationId: string;
  organizationName: string;
  tenantsCount: number;
  expirationDate: string;
  licenceBase64: string;
}

export type LicenseResponse = License;

export interface CreateLicensePayload {
  organizationId: string;
  file: File;
}

export interface MetricNotification {
  id: string;
  resource: Pick<Resource, "id" | "name">;
  ociQuery: Pick<
    OCIQuery,
    "id" | "statisticTypeId" | "metricNameId" | "metricNamespaceId"
  >;
  value: number | null;
  isShutdown: boolean;
  shutdown: boolean;
}

export type MetricNotificationResponse = Pick<
  MetricNotification,
  "id" | "resource" | "ociQuery" | "value" | "shutdown"
>;

export type CreateMetricNotificationPayload = Pick<
  MetricNotification,
  "value" | "isShutdown"
> & {
  resource: MetricNotification["resource"]["id"];
  ociQuery: MetricNotification["ociQuery"]["id"];
};

export type DeleteMetricNotificationPayload = Pick<MetricNotification, "id">;

export interface Commitment {
  id: string;
  subscription: {
    id: string;
    subscriptionType: string;
    subscriptionID: number;
    currency: string;
  };
  avaliableAmount: number;
  usedAmount: number;
  quantity: number;
  orderNumber: number;
  status: CommitmentStatus;
  pricingModel: CommitmentPricingModel;
  commitmentId: string;
  startDate: string;
  endDate: string;
}

export type CommitmentResponse = Commitment;

export interface Subscription {
  id: string;
  subscriptionTypeId: number;
  organizationId: string;
  subscriptionType: {
    id: number;
    name: string;
  };
  organization: {
    id: string;
    name: string;
    shortname: string;
  };
  subscriptionID: number;
  csiNumber: number;
  originalStartDate: string;
  startDate: string;
  endDate: string | null;
  currency: string | null;
}

export type SubscriptionResponse = Pick<
  Subscription,
  | "id"
  | "subscriptionType"
  | "organization"
  | "subscriptionID"
  | "csiNumber"
  | "originalStartDate"
  | "startDate"
  | "endDate"
  | "currency"
>;

export type CreateSubscriptionPayload = Pick<
  Subscription,
  | "subscriptionTypeId"
  | "subscriptionID"
  | "organizationId"
  | "originalStartDate"
  | "startDate"
  | "endDate"
  | "csiNumber"
  | "currency"
>;

export interface SCNotificationDto {
  id: string;
  subscription: Pick<
    Subscription,
    "id" | "subscriptionType" | "subscriptionID" | "currency"
  >;
  commitment: Pick<Commitment, "id">;
  valueType: SCNotificationValueType;
  sourceType: SCNotificationType;
  value: number;
  resources: Pick<Resource, "id" | "ocid" | "name">[];
  commitmentId: string;
  subscriptionId: string;
  resourceIds: string[];
}

export interface SubscriptionLightDto {
  id: string;
  subscriptionType: string;
  subscriptionID: number;
}

export interface CommitmentMinimalDto {
  id: string;
}

export interface ResourceLightDto {
  id: string;
  ocid: string;
  name: string;
}

export type SCNotificationResponse = Omit<
  SCNotificationDto,
  "commitmentId" | "subscriptionId" | "resourceIds"
>;

export type CreateSCNotificationPayload =
  | (Pick<
      SCNotificationDto,
      "valueType" | "value" | "resourceIds" | "commitmentId" | "subscriptionId"
    > & {
      sourceType: SCNotificationType.Commitment;
    })
  | (Pick<
      SCNotificationDto,
      "valueType" | "value" | "resourceIds" | "subscriptionId"
    > & {
      sourceType: SCNotificationType.Subscription;
    });

export interface ResetPasswordPayload {
  token: string;
  password: string;
  repeatPassword: string;
}

export interface User {
  id: string;
  email: string;
  name: string;
  userType: UserType;
  authorities: Claim[];
  address: string;
  password: string;
  disabled: boolean;
  organizationId: Organization["id"];
  organization: Pick<Organization, "id" | "name" | "shortname" | "license">;
  tenants: ({ tenantId: Tenant["id"] } & Pick<Tenant, "name" | "ocid">)[];
}

export type UserResponse = Pick<
  User,
  | "id"
  | "email"
  | "name"
  | "userType"
  | "authorities"
  | "address"
  | "organization"
  | "tenants"
  | "disabled"
>;

export type MeResponse = UserResponse;

export type CreateSuperUserPayload = Pick<User, "email" | "name" | "password">;

export type CreateSystemUserPayload = Pick<User, "email" | "name">;

export type CreateOrganizationAdminPayload = Pick<
  User,
  "email" | "name" | "password" | "organizationId"
>;

export type CreateOrganizationUserPayload = Pick<
  User,
  "email" | "password" | "name" | "organizationId"
>;

export type UpdateUserPayload = Pick<User, "id" | "name"> &
  Partial<
    Pick<
      User,
      "email" | "address" | "organizationId" | "authorities" | "disabled"
    >
  >;

export interface OCIResourceDTO {
  id: string;
  statisticTypeId: number;
  metricNameId: string;
  metricNamespaceId: string;
  tenantId: string;
  unitTypeId: number;
  storageResultTypeId: number;
  organizationId: string;
  compartmentId: string;
  ociResources: OCIResource[];
}

export interface OCIResource {
  id: string;
  ocid: string;
  name: string | null;
}

export type OCIResourcesResponse = {
  content: OCIResourceDTO[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  first: boolean;
  empty: boolean;
};

export interface QueriesGroupedByResource {
  resource: OCIResourceDTO;
  queries: OCIQuery[];
}

export interface FetchOCIResourcesParams {
  compartmentId?: string;
}

export interface ReportMetric {
  id: string;
  tenantOcid: string;
  compartmentOcid: string;
  resourceOcid: string;
  metricNamespace: string;
  metricName: string;
  statisticType: string;
  time: string;
  value: number;
  result: string;
  dateTimeValue: string | null;
}

export type ReportMetricResponse = Pick<
  ReportMetric,
  | "id"
  | "tenantOcid"
  | "compartmentOcid"
  | "resourceOcid"
  | "metricNamespace"
  | "metricName"
  | "statisticType"
  | "time"
  | "value"
  | "result"
  | "dateTimeValue"
>;

export type ReportMetricName =
  | "CpuUtilization"
  | "DiskBytesRead"
  | "DiskBytesWritten"
  | "MemoryUtilization"
  | "DiskIopsRead"
  | "DiskIopsWritten"
  | "NetworkBytesIn"
  | "NetworkBytesOut"
  | "MemoryAllocationStalls"
  | "LoadAverage";

/**
 * @example
 * {
 *  datefrom:2023-11-25
 *  dateto:2024-01-25
 *  tenantocid:ocid1.tenancy.oc1..aaaaaaaa7qrj2dchsxjbpk2hype4soufvofuplvi5nke7o6cmc3sad2qpiua
 *  compartmentocid:ocid1.compartment.oc1..aaaaaaaaq7ijswugyocyirsjywew3alzmhgtiau63fxu4ttgxuky2qhgye3q
 *  statistictype:mean()
 *  metricname:CpuUtilization
 *  resource:ocid1.instance.oc1.eu-frankfurt-1.antheljs6twpuqyc5rfcuun2fpydkdjrlaxemeyuiurtrchksqnjuxjbhnma
 * }
 */
export type ReportMetricParams = {
  datefrom?: string | number | null;
  dateto?: string | number | null;
  tenantocid?: string | null;
  compartmentocid?: string | null;
  statistictype?: string | null;
  resource?: string | null;
  metricname: ReportMetricName;
};

export interface ReportConsumptionByDays {
  day: string;
  service_id: string;
  resource_id: string;
  sum: string;
}

export type ReportConsumptionByDaysResponse = Pick<
  ReportConsumptionByDays,
  "day" | "service_id" | "resource_id" | "sum"
>;

export interface ReportConsumptionByDaysParams {
  /**
   * @example 1705931240
   */
  datefrom: number | string | null;
  /**
   * @example 1705931240
   */
  dateto: number | string | null;
  tenantocid: string | null;
  compartmentocid: string | null;
}

export interface ReportConsumptionByCompartments {
  name: string;
  compartmentid: string;
  billedquantity: string;
}

export type ReportConsumptionByCompartmentsResponse = Pick<
  ReportConsumptionByCompartments,
  "name" | "compartmentid" | "billedquantity"
>;

export interface ReportConsumptionByCompartmentsParams {
  datefrom: string | number | null;
  dateto: string | number | null;
  tenantocid: string | null;
  compartmentocid: string;
}

export interface ReportConsumptionForCommitments {
  csinumber: string;
  name: string;
  createdat: string;
  usedamount: string;
}

export type ReportConsumptionForCommitmentsResponse = Pick<
  ReportConsumptionForCommitments,
  "csinumber" | "name" | "createdat" | "usedamount"
>;

export interface ReportConsumptionForCommitmentsParams {
  datefrom: number | string | null;
  dateto: number | string | null;
  tenantocid: string | null;
  compartmentocid: string | null;
}

export interface ReportConsumptionForCredits {
  name: string;
  csinumber: string;
  startdate: string;
  enddate: string;
  avaliableamount: string;
  usedamount: string;
}

export type ReportConsumptionForCreditsResponse = Pick<
  ReportConsumptionForCredits,
  | "name"
  | "csinumber"
  | "startdate"
  | "enddate"
  | "avaliableamount"
  | "usedamount"
>;

export interface ReportConsumptionForCreditsParams {
  datefrom?: string | number | null;
  dateto?: string | number | null;
  tenantocid?: string | null;
  compartmentocid?: string | null;
}

export interface QueryResource {
  id: string;
  ocid: string;
  name: string | null;
  description: string | null;
  organization: {
    id: string;
    name: string;
    shortname: string;
  };
  tenant: {
    tenantId: string;
    name: string;
    ocid: string;
  };
  compartment: {
    id: string;
    ocid: string;
    name: string;
  };
  resourceType: {
    id: string;
    name: string | null;
  };
  ociQueries: {
    id: string;
    statisticType: {
      id: number;
      name: string;
    };
    metricName: {
      metricName: string;
      name: string;
    };
    metricNamespace: {
      metricNamespace: string;
      name: string;
    };
  }[];
  ociQueriesCount: number;
  createdAt: string;
  updatedAt: string | null;
  createdBy: string;
  updatedBy: string | null;
}

export type QueryResourceResponse = Pick<
  QueryResource,
  | "id"
  | "ocid"
  | "name"
  | "description"
  | "organization"
  | "tenant"
  | "compartment"
  | "resourceType"
  | "ociQueries"
  | "ociQueriesCount"
  | "createdAt"
  | "updatedAt"
  | "createdBy"
  | "updatedBy"
>;

export interface CompartmentStructure {
  parentId: string;
  compartmentOcid: string;
  compartmentId?: string;
  compartmentName: string;
  description: string;
  timeCreated: string;
  freeformTags: Record<string, string>;
  definedTags: Record<string, Record<string, unknown>>;
  isAccessible?: boolean;
  lifecycleState: string;
}

export type CompartmentStructureResponse = Pick<
  CompartmentStructure,
  | "parentId"
  | "compartmentOcid"
  | "compartmentId"
  | "compartmentName"
  | "description"
  | "timeCreated"
  | "freeformTags"
  | "definedTags"
  | "isAccessible"
  | "lifecycleState"
>;

export type CompartmentStructureWithChildren = CompartmentStructure & {
  children: CompartmentStructureWithChildren[];
};

export interface CompartmentStructureParams {
  tenantocid?: string;
}

export interface MetricDto {
  name: string;
  nameId?: string;
  namespace: string;
  namespaceID?: string;
  resourceGroup: string;
  compartmentOcid: string;
  dimensions: Record<string, string>;
}

export type ListMetricDto = Pick<
  MetricDto,
  | "name"
  | "nameId"
  | "namespace"
  | "namespaceID"
  | "resourceGroup"
  | "compartmentOcid"
  | "dimensions"
>;

export interface ListMetricNamespaceParams {
  tenantocid: string;
  compartmentocid: string;
}

export interface ListMetricResourceGroupParams {
  tenantocid: string;
  compartmentocid: string;
  namespace: string;
}

export interface ListMetricNameParams {
  tenantocid: string;
  compartmentocid: string;
  namespace: string;
}

export interface ListMetricsResourceParams {
  tenantocid: string;
  compartmentocid: string;
  namespace: string;
  name: string;
}

export interface FirstDiagramResponse {
  timeseries: string;
  fullsum: number;
}

export interface FirstDiagramParams {
  datefrom: string | number;
  dateto: string | number;
  tenantocid?: string;
  timetype: TimeUnit;
}

export interface FirstDiagramAvgResponse {
  avgsum: number;
  serviceid: string | null;
}

export interface SecondDiagramResponse {
  mycost: number;
  serviceid: string;
}

export interface SecondDiagramParams {
  datefrom: string | number;
  dateto: string | number;
  tenantocid: string;
  timetype: TimeUnit;
}

export interface ThirdDiagramParams {
  subscriptionid: string | number;
}

export interface ThirdDiagramResponse {
  name: string;
  csinumber: number;
  startdate: string;
  enddate: string;
  avaliableamount: number;
  used_amount: number;
  status: Commitment["status"];
  quantity: number;
  commitmentid: string;
  ordernumber: string;
  subscriptionid: number;
  pricingModel: Commitment["pricingModel"];
}

export interface FourthDiagramParams {
  subscriptionid: string | number;
}

export interface FourthDiagramResponse {
  name: string;
  ocid: string;
  ocidtime: string;
  fullsum: number;
}

export interface FifthDiagramParams {
  subscriptionid: string | number;
}

export interface FifthDiagramResponse {
  avaliableamount: string;
  quantity: string;
  usedamount: string;
  number: string;
  csinumber: string;
  currency: string;
  startdate: string;
  enddate: string;
}

export interface PolarDiagramParams {
  subscriptionid: string | number;
  year: number;
}

export interface PolarDiagramResponse {
  time: string;
  fullsum: number;
}

export interface ProductServiceResponse {
  id: string;
  productServiceId: string;
  name: string;
  description?: string;
}

export interface BillingCostReportResponse {
  year: string;
  month: string;
  tenatOcid: string;
  tenantName: string;
  productService: string;
  serviceName: string;
  resource: string;
  resourceName: string;
  cost: number;
}

export interface BillingCostReportParams {
  year: number;
  month: number;
  subscriptionid: string | number;
  tenantocid?: string;
  productservice?: string;
  resourceocid?: string;
  withzerocost?: boolean;
}

export interface BudgetNotificationValueDto {
  id: string;
  value: number;
  startDate: string;
  endDate: string;
  budgetNotificationId: string;
}

export interface BudgetNotificationEmailDto {
  email: string;
}

export interface BudgetNotificationResponse {
  id: string;
  notificationType: string;
  subscription: SubscriptionLightDto;
  tenant: TenantMinimal;
  emails: BudgetNotificationEmailDto[];
  tenancies: TenantMinimal[];
  values: BudgetNotificationValueDto[];
}

export interface CreateBudgetNotificationPayload {
  budgetNotificationType: BudgetNotificationType;
  subscription: string;
  tenant: string;
  tenancies?: string[];
  values: {
    value: number;
    /**
     * @example 01-2024
     */
    startDate: string;
    /**
     * @example 01-2024
     */
    endDate: string;
  }[];
  emails?: string[];
}

export interface BudgetCompartmentValueDto {
  id: string;
  value: number;
  startDate: string;
  endDate: string;
  budgetNotificationId: string;
}

export interface CompartmentBudgetNotificationResponse {
  id: string;
  budgetCompartmentNotificationType: CompartmentBudgetNotificationType;
  budgetCompartmentNotificationTypeLabel: string;
  subscription: SubscriptionLightDto;
  tenant: TenantMinimal;
  emails: BudgetNotificationEmailDto[];
  compartments: CompartmentLight[];
  values: BudgetCompartmentValueDto[];
}

export interface CreateCompartmentBudgetNotificationPayload {
  subscription: string;
  tenant: string;
  compartments: string[];
  values: {
    value: number;
    /**
     * @example 01-2024
     */
    startDate: string;
    /**
     * @example 01-2024
     */
    endDate: string;
  }[];
  emails?: string[];
  isShutdown: boolean;
  budgetCompartmentNotificationType: string;
}

export interface Region {
  id: string;
  name: string;
  identifier: string;
  location: string;
  regionKey: string;
  realmKey: string;
  availabilityDomains: number;
}

export type CreateRegionPayload = Pick<
  Region,
  | "name"
  | "identifier"
  | "location"
  | "regionKey"
  | "realmKey"
  | "availabilityDomains"
>;

export type RegionResponse = Pick<
  Region,
  | "id"
  | "name"
  | "identifier"
  | "location"
  | "regionKey"
  | "realmKey"
  | "availabilityDomains"
>;

export interface SubscriptionType {
  id: number;
  name: string;
  description: string;
}

export interface AccessConfigType {
  id: string;
  name: string;
  description: string;
  version: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: string;
  updatedBy: string;
}

export type SubscriptionTypeResponse = SubscriptionType;
export type AccessConfigTypeResponse = AccessConfigType;

export interface CommitmentConsumptionDto {
  subscriptionId: string;
  commitmentId: string;
  date: string;
  avaliableAmount: number;
  usedAmount: number;
}

export type CommitmentConsumptionResponse = CommitmentConsumptionDto;

export type CommitmentConsumptionParams = {
  subscriptionid: string;
  commitmentid?: string;
};

export type EnumResponse = EnumSelectData;
