import { createMRTColumnHelper } from "mantine-react-table";

import { OrganizationType } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<OrganizationType>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.organization.name",
  }),
  columnHelper.accessor("description", {
    header: "entity:administration.organization.description",
  }),
];
