import { Text, Skeleton, ScrollArea, Tabs } from "@mantine/core";
import { ParseKeys } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { EnumSelect } from "@/components/ui/enum-select";
import { useFetchCommitmentPricingModelTypesQuery } from "@/hooks/api/useFetchCommitmentPricingModelTypesQuery";
import { useFetchCommitmentStatusTypesQuery } from "@/hooks/api/useFetchCommitmentStatusTypesQuery";
import { useFetchThirdDiagramQuery } from "@/hooks/api/useFetchThirdDiagramQuery";
import {
  CommitmentStatus,
  CommitmentPricingModel,
  ThirdDiagramResponse,
} from "@/lib/api/dto";

import { CommitmentWidget } from "../CommitmentWidget";

interface DashboardThirdDiagramReportProps {
  subscriptionid?: string | number;
  onFilterChange?: (commitmentIds: string[]) => void;
}

export const DashboardThirdDiagramReport = ({
  subscriptionid,
  onFilterChange,
}: DashboardThirdDiagramReportProps) => {
  const { t } = useTranslation();
  const { data = [], isLoading } = useFetchThirdDiagramQuery({
    params: {
      subscriptionid,
    },
    select: (data) => {
      const reversed: ThirdDiagramResponse[] = [];

      data.forEach((item) => {
        reversed.unshift(item);
      });

      return reversed;
    },
  });

  const { data: commitmentStatusTypeEnum } =
    useFetchCommitmentStatusTypesQuery();

  const {
    data: commitmentPricingModelTypeEnum,
    isSuccess,
    isFetching,
  } = useFetchCommitmentPricingModelTypesQuery();

  const [commitmentStatus, setCommitmentStatus] =
    useState<CommitmentStatus | null>(null);

  const tabColor =
    commitmentStatus === CommitmentStatus.Active ? "green"
    : commitmentStatus === CommitmentStatus.Expired ? "red"
    : "gray";

  const [commitmentPricingModel, setCommitmentPricingModel] =
    useState<CommitmentPricingModel | null>(null);

  const filteredData = useMemo(() => {
    return data.filter(
      (data) =>
        (!commitmentStatus || data.status === commitmentStatus) &&
        data.pricingModel === commitmentPricingModel,
    );
  }, [commitmentPricingModel, commitmentStatus, data]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      const commitmentPricingModelType =
        commitmentPricingModelTypeEnum?.properties?.[0];
      if (commitmentPricingModelType?.selected) {
        setCommitmentPricingModel(
          commitmentPricingModelType.value as CommitmentPricingModel,
        );
      }
    }
  }, [isFetching, isSuccess, commitmentPricingModelTypeEnum]);

  useEffect(() => {
    onFilterChange?.(filteredData.map((item) => item.commitmentid));
  }, [filteredData, onFilterChange]);

  return (
    <div className="flex flex-1 flex-col gap-2 rounded-md border bg-white p-3 shadow-md md:min-h-[20rem]">
      <div className="flex justify-between gap-2">
        <Text size="xl">
          {t("dashboard:commitment.commitments" as ParseKeys)}
        </Text>
        <EnumSelect
          clearable
          allowDeselect
          placeholder={t("dashboard:commitment.status" as ParseKeys)}
          data={commitmentStatusTypeEnum}
          value={commitmentStatus}
          onChange={setCommitmentStatus}
        />
      </div>
      <Tabs
        value={commitmentPricingModel}
        onChange={(value) =>
          value && setCommitmentPricingModel(value as CommitmentPricingModel)
        }
      >
        <Tabs.List>
          {commitmentPricingModelTypeEnum?.basicEnums?.map(
            ({ value, label }) => (
              <Tabs.Tab key={value} value={value} color={tabColor} c="gray.8">
                {label}
              </Tabs.Tab>
            ),
          )}
        </Tabs.List>
        {commitmentPricingModelTypeEnum?.basicEnums?.map(({ value }) => (
          <Tabs.Panel
            key={value}
            value={value}
            className="flex h-auto pt-2 md:h-[13.5rem]"
          >
            <ScrollArea
              type="never"
              className="-m-2 flex-1"
              classNames={{
                viewport: "*:!block",
              }}
            >
              <div className="flex flex-col gap-2 p-2">
                {isLoading && (
                  <div className="flex flex-col gap-3 rounded-sm p-2 shadow-md">
                    <Skeleton height={16} />
                    <div className="flex gap-4">
                      <Skeleton height={16} width={"15%"} />
                      <Skeleton height={16} width={"70%"} />
                      <Skeleton height={16} width={"15%"} />
                    </div>
                  </div>
                )}
                {filteredData.map((item, index) => (
                  <CommitmentWidget
                    key={item.csinumber + index}
                    avaliableAmount={item.avaliableamount}
                    commitmentId={item.commitmentid}
                    csiNumber={item.csinumber}
                    endDate={item.enddate}
                    name={item.name}
                    orderNumber={item.ordernumber}
                    quantity={item.quantity}
                    startDate={item.startdate}
                    status={item.status}
                    subscriptionId={item.subscriptionid}
                    usedAmount={item.used_amount}
                    pricingModel={item.pricingModel}
                  />
                ))}
              </div>
            </ScrollArea>
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};
