import { Paper, TextInput, Button, Select } from "@mantine/core";
import { zodResolver } from "mantine-form-zod-resolver";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useCreateOrUpdateMetricNameMutation } from "@/hooks/api/useCreateOrUpdateMetricNameMutation";
import { useFetchMetricMeasuringUnitQuery } from "@/hooks/api/useFetchMetricMeasuringUnitQuery";
import { useFetchMetricNamespaceQuery } from "@/hooks/api/useFetchMetricNamespaceQuery";
import { useTForm } from "@/hooks/useTForm";
import { MetricNameResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface MetricNameDetailsProps {
  metricName?: MetricNameResponse | null;
  onSettled?: () => void;
  className?: string;
}

const metricNameSchema = z.object({
  name: z.string().min(1, "form:field.required"),
  description: z.string().min(1, "form:field.required"),
  metricNamespaceId: z
    .string()
    .nullable()
    .refine((value) => !!value, { message: "form:field.required" }),
  metricName: z.string().min(1, "form:field.required"),
  metricMeasuringUnitId: z
    .string()
    .nullable()
    .refine((value) => !!value, { message: "form:field.required" }),
});

type FormData = z.infer<typeof metricNameSchema>;

const defaultValues: FormData = {
  name: "",
  description: "",
  metricNamespaceId: null,
  metricName: "",
  metricMeasuringUnitId: null,
};

const mapMetricNameToFormData = (
  metricName?: MetricNameResponse | null,
): FormData => {
  if (!metricName) return defaultValues;

  return {
    name: metricName.name ?? defaultValues.name,
    description: metricName.description ?? defaultValues.description,
    metricNamespaceId:
      metricName.metricNamespace.metricNamespace ??
      defaultValues.metricNamespaceId,
    metricName: metricName.metricName ?? defaultValues.metricName,
    metricMeasuringUnitId:
      metricName.metricMeasuringUnit.metricMeasuringUnit ??
      defaultValues.metricMeasuringUnitId,
  };
};

export const MetricNameDetails = ({
  metricName,
  onSettled,
  className,
}: MetricNameDetailsProps) => {
  const { t } = useTranslation(["entity", "common", "form"]);

  const { mutate, isPending } = useCreateOrUpdateMetricNameMutation({
    metricName: metricName?.metricName,
  });

  const { data: metricNamespace = [] } = useFetchMetricNamespaceQuery();

  const { data: metricMeasuringUnit = [] } = useFetchMetricMeasuringUnitQuery();

  const form = useTForm<FormData>({
    initialValues: mapMetricNameToFormData(metricName),
    validate: zodResolver(metricNameSchema),
  });

  const onSubmit = (data: FormData) => {
    mutate(data, { onSettled });
  };

  const metricNamespaceOptions = useMemo(
    () =>
      metricNamespace.map((metricNamespace) => ({
        value: metricNamespace.metricNamespace,
        label: metricNamespace.name,
      })),
    [metricNamespace],
  );

  const metricMeasuringUnitOptions = useMemo(
    () =>
      metricMeasuringUnit.map((metricMeasuringUnit) => ({
        value: metricMeasuringUnit.metricMeasuringUnit,
        label: metricMeasuringUnit.name,
      })),
    [metricMeasuringUnit],
  );

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("administration.metricName.metricName")}
        {...form.getInputProps("metricName")}
      />
      <TextInput
        withAsterisk
        label={t("administration.metricName.name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        withAsterisk
        label={t("administration.metricName.description")}
        {...form.getInputProps("description")}
      />
      <Select
        withAsterisk
        label={t("administration.metricName.metricNamespace")}
        {...form.getInputProps("metricNamespaceId")}
        data={metricNamespaceOptions}
      />
      <Select
        withAsterisk
        label={t("administration.metricName.metricMeasuringUnit")}
        {...form.getInputProps("metricMeasuringUnitId")}
        data={metricMeasuringUnitOptions}
      />

      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" loading={isPending} disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};
