import { ActionIcon, Button, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { MantineReactTable } from "mantine-react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import AlertModal from "@/components/ui/alert-modal";
import { useDeleteMetricMeasuringUnitMutation } from "@/hooks/api/useDeleteMetricMeasuringUnitMutation";
import { useFetchMetricMeasuringUnitQuery } from "@/hooks/api/useFetchMetricMeasuringUnitQuery";
import { useMRT } from "@/hooks/useMRT";
import { MetricMeasuringUnitResponse } from "@/lib/api/dto";

import { columns } from "./columns";
import { MetricMeasuringUnitDetails } from "../MetricMeasuringUnitDetails/MetricMeasuringUnitDetails";

export const MetricMeasuringUnitTable = () => {
  const { mutate, isPending } = useDeleteMetricMeasuringUnitMutation();
  const { t } = useTranslation(["entity", "common"]);
  const { data, isLoading, isFetching } = useFetchMetricMeasuringUnitQuery();

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedMetricMeasuringUnit, setSelectedMetricMeasuringUnit] =
    useState<MetricMeasuringUnitResponse | null>(null);
  const [metricMeasuringUnitToDelete, setMetricMeasuringUnitToDelete] =
    useState<MetricMeasuringUnitResponse | null>(null);

  const showConfirmDeleteModal = (
    metricMeasuingUnit: MetricMeasuringUnitResponse,
  ) => {
    setMetricMeasuringUnitToDelete(metricMeasuingUnit);
  };

  const handleCloseDeleteMetricMeasuringUnitModal = () => {
    if (isPending) return;
    setMetricMeasuringUnitToDelete(null);
  };

  const handleDeleteMetricMeasuringUnit = () => {
    if (!metricMeasuringUnitToDelete) return;

    mutate(metricMeasuringUnitToDelete.metricMeasuringUnit, {
      onSuccess: () => {
        setMetricMeasuringUnitToDelete(null);
      },
    });
  };

  const handleSettled = () => {
    selectedMetricMeasuringUnit ?
      setSelectedMetricMeasuringUnit(null)
    : close();
  };

  const table = useMRT({
    data,
    columns,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <div className="flex gap-2">
        <ActinIconEdit
          onClick={() => setSelectedMetricMeasuringUnit(row.original)}
        />
        <ActionIcon
          color="red"
          onClick={() => showConfirmDeleteModal(row.original)}
        >
          <IconTrash size={18} />
        </ActionIcon>
      </div>
    ),
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={open}>
        {t("administration.metricMeasuringUnit.addMetricMeasuringUnit")}
      </Button>
    ),
  });

  return (
    <div className="flex flex-1 flex-col overflow-auto">
      <Title size="h2" className="mb-4">
        {t("administration.metricMeasuringUnit.metricMeasuringUnit")}
      </Title>

      <MantineReactTable table={table} />

      <Modal
        opened={!!selectedMetricMeasuringUnit || opened}
        onClose={handleSettled}
        title={
          selectedMetricMeasuringUnit ?
            t("administration.metricMeasuringUnit.editMetricMeasuringUnit")
          : t("administration.metricMeasuringUnit.addMetricMeasuringUnit")
        }
        size="lg"
      >
        <MetricMeasuringUnitDetails
          onSettled={handleSettled}
          metricMeasuringUnit={selectedMetricMeasuringUnit}
        />
      </Modal>

      <AlertModal
        opened={!!metricMeasuringUnitToDelete}
        onClose={handleCloseDeleteMetricMeasuringUnitModal}
        onConfirm={handleDeleteMetricMeasuringUnit}
        title={t("common:warning")}
        message={t("administration.metricNamespace.actions.delete", {
          value: metricMeasuringUnitToDelete?.metricMeasuringUnit,
        })}
        isPending={isPending}
      />
    </div>
  );
};
