import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { StateResponse } from "@/lib/api/dto";

import { stateKeys } from "./queryKeys";

interface UseFetchStatesQueryOptions<TSelectData = StateResponse[]>
  extends UseQueryOptions<StateResponse[], Error, TSelectData> {}

export const useFetchStatesQuery = (options?: UseFetchStatesQueryOptions) => {
  return useQuery({
    queryKey: stateKeys.all(),
    queryFn: () => Api.states.list().then((res) => res.data),
    ...options,
  });
};
