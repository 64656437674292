import { Paper, TextInput, Button, Select } from "@mantine/core";
import { isAxiosError } from "axios";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { toast } from "@/components/ui/use-toast";
import { useCreateOrganizationAdminUserMutation } from "@/hooks/api/useCreateOrganizationAdminUserMutation";
import { useFetchOrganizationsQuery } from "@/hooks/api/useFetchOrganizationsQuery";
import { useTForm } from "@/hooks/useTForm";
import { cn } from "@/lib/utils";

interface CreateOrganizationAdminUserPageProps {
  onSettled?: () => void;
  className?: string;
}

const organizationAdminUserSchema = z.object({
  email: z
    .string()
    .min(1, "form:field.required")
    .email("entity:administration.organization.emailValid"),
  password: z.string().min(1, "form:field.required"),
  name: z.string().min(1, "form:field.required"),
  organizationId: z
    .string()
    .nullable()
    .refine((value) => !!value, {
      message: "form:field.required",
    })
    .transform((value) => value ?? ""),
});

type OrganizationAdminUserSchema = z.infer<typeof organizationAdminUserSchema>;

export const CreateOrganizationAdminUserPage = ({
  className,
  onSettled,
}: CreateOrganizationAdminUserPageProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const { data: organizations } = useFetchOrganizationsQuery();
  const { mutate, isPending } = useCreateOrganizationAdminUserMutation();

  const form = useTForm<OrganizationAdminUserSchema>({
    initialValues: {
      email: "",
      password: "",
      name: "",
      organizationId: "",
    },
    validate: zodResolver(organizationAdminUserSchema),
  });

  const onSubmit = () => {
    mutate(form.values, {
      onSuccess: () => {
        form.reset();
        toast({ title: t("administration.organization.createUserSuccess") });
      },
      onError: (error) => {
        toast({
          title:
            (isAxiosError(error) && error.response?.data?.message) ||
            t("administration.organization.createUserError"),
          variant: "destructive",
        });
      },
      onSettled,
    });
  };

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("administration.common.email")}
        {...form.getInputProps("email")}
      />
      <TextInput
        withAsterisk
        label={t("administration.common.personName")}
        {...form.getInputProps("name")}
      />
      <TextInput
        withAsterisk
        label={t("administration.common.password")}
        type="password"
        {...form.getInputProps("password")}
      />
      <Select
        withAsterisk
        label={t("administration.common.organization")}
        {...form.getInputProps("organizationId")}
        data={organizations?.map((organization) => ({
          label: organization.name,
          value: organization.id,
        }))}
      />
      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};
