import {
  MantineProvider,
  Select,
  Slider,
  Text,
  createTheme,
} from "@mantine/core";
import {
  ArcElement,
  BarElement,
  TimeScale,
  TimeSeriesScale,
  CategoryScale,
  Chart,
  Colors,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Filler,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./i18n.ts";
import "dayjs/locale/sr";
import "dayjs/locale/sr-cyrl";

import App from "./App.tsx";

import "chartjs-adapter-date-fns";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "mantine-react-table/styles.css";
import "./index.css";

Chart.register(
  RadialLinearScale,
  TimeSeriesScale,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Filler,
  Colors,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  zoomPlugin,
);

const theme = createTheme({
  primaryColor: "brand",
  cursorType: "pointer",
  primaryShade: 5,
  colors: {
    brand: [
      "#ffebe7",
      "#ffd4d1",
      "#faa9a2",
      "#f57a6f",
      "#f05344",
      "#ee3a29",
      "#ee2b1a",
      "#d31d0f",
      "#be150b",
      "#a60705",
    ],
  },
  fontSizes: {
    xxs: "0.625rem",
  },
  components: {
    Select: Select.extend({
      defaultProps: {
        checkIconPosition: "right",
      },
    }),
    Loader: {
      defaultProps: {
        type: "bars",
      },
    },
    Text: Text.extend({
      defaultProps: {
        size: "xxs",
      },
    }),
    Slider: Slider.extend({
      styles: () => ({
        label: {
          left: 0,
        },
      }),
    }),
  },
  other: {
    chartColorsArray: [
      "#1C7ED6",
      "#66A80F",
      "#E03131",
      "#364FC7",
      "#F76707",
      "#15AABF",
      "#FCC419",
      "#12B886",
      "#845EF7",
      "#40C057",
      "#E64980",
      "#8B4513",
    ],
    transparentColorsArray: [
      "#1C7ED68e",
      "#66A80F8e",
      "#E031318e",
      "#364FC78e",
      "#F767078e",
      "#15AABF8e",
      "#FCC4198e",
      "#12B8868e",
      "#845EF78e",
      "#40C0578e",
      "#E649808e",
      "#8B45138e",
    ],
    hoverColorsArray: [
      "#1971C284",
      "#5C940D84",
      "#C92A2A84",
      "#3B5BDB84",
      "#E8590C84",
      "#1098AD84",
      "#FAB00584",
      "#0CA67884",
      "#7950F284",
      "#37B24D84",
      "#D6336C84",
      "#8b451384",
    ],
    chartColors: {
      green: "#66A80F",
      blue: "#1C7ED6",
      orange: "#F76707",
      red: "#E03131",
      indigo: "#364FC7",
      yellow: "#FCC419",
      teal: "#15AABF",
      cyan: "#12B886",
      lime: "#40C057",
      violet: "#845EF7",
      pink: "#E64980",
      greenTransparent: "#66A80F8e",
      blueTransparent: "#1C7ED68e",
      orangeTransparent: "#F767078e",
      redTransparent: "#E031318e",
      indigoTransparent: "#364FC78e",
      yellowTransparent: "#FCC4198e",
      tealTransparent: "#15AABF8e",
      cyanTransparent: "#12B8868e",
      limeTransparent: "#40C0578e",
      violetTransparent: "#845EF78e",
      pinkTransparent: "#E649808e",
    },
  },
});

Chart.defaults.responsive = true;
Chart.defaults.plugins.tooltip.mode = "index";
Chart.defaults.plugins.tooltip.intersect = false;

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MantineProvider>
  </StrictMode>,
);
