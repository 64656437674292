import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { ExtendedCityResponse } from "@/lib/api/dto";

import { cityKeys } from "./queryKeys";

interface UseFetchCitiesQueryOptions<T>
  extends Omit<
    UseQueryOptions<ExtendedCityResponse[], Error, T>,
    "queryFn" | "queryKey"
  > {}

export const useFetchCitiesQuery = <T = ExtendedCityResponse[]>({
  ...rest
}: UseFetchCitiesQueryOptions<T> = {}) => {
  return useQuery({
    queryFn: () => Api.cities.list().then((res) => res.data),
    queryKey: cityKeys.all(),
    ...rest,
  });
};
