import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";

import { ociKeys } from "./queryKeys";

interface UseDeleteOCIQueryMutationOptions
  extends Omit<UseMutationOptions<unknown, Error, string>, "mutationFn"> {
  tenantId?: string;
}

export const useDeleteOCIQueryMutation = ({
  tenantId,
  onSuccess,
  onError,
  onSettled,
  ...options
}: UseDeleteOCIQueryMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: Api.oci.deleteOCIQuery,
    onSuccess: (...args) => {
      successToast.delete();
      onSuccess?.(...args);
    },
    onError: (...args) => {
      errorToast.delete();
      onError?.(...args);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: ociKeys.all({ tenantId: tenantId }),
      });
      onSettled?.(...args);
    },
  });
};
