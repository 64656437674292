import {
  Modal,
  Stack,
  Button,
  Title,
  Select,
  Checkbox,
  NumberInput,
  ActionIcon,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconError404, IconLoader2, IconPlus } from "@tabler/icons-react";
import { TableMeta } from "@tanstack/table-core";
import { Trash2Icon } from "lucide-react";
import { zodResolver } from "mantine-form-zod-resolver";
import { createMRTColumnHelper, MantineReactTable } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import AlertModal from "@/components/ui/alert-modal";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useCreateMetricNotificationMutation } from "@/hooks/api/useCreateMetricNotificationMutation";
import { useDeleteMetricNotificationMutation } from "@/hooks/api/useDeleteMetricNotificationMutation";
import { useFetchCodebookResourcesQuery } from "@/hooks/api/useFetchCodebookResourcesQuery";
import { useFetchMetricNotificationsQuery } from "@/hooks/api/useFetchMetricNotificationsQuery";
import { useFetchOCIQueriesQuery } from "@/hooks/api/useFetchOCIQueriesQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useMRT } from "@/hooks/useMRT";
import { useTForm } from "@/hooks/useTForm";
import { MetricNotificationResponse } from "@/lib/api/dto";
import { formatNumberAsCurrency } from "@/lib/utils";
import { DashboardFifthDiagramReport } from "@/widgets/DashboardReports/DashboardFifthDiagramReport";
import { DashboardKPIReport } from "@/widgets/DashboardReports/DashboardKPIReport";
import { DashboardThirdDiagramReport } from "@/widgets/DashboardReports/DashboardThirdDiagramReport";

const nullableRequiredString = (message: string) =>
  z
    .string()
    .nullable()
    .transform((value, ctx) => {
      if (!value) {
        ctx.addIssue({
          type: "string",
          code: "too_small",
          message,
          minimum: 1,
          inclusive: true,
        });
      }
      return value || "";
    });
const notificationSchema = z.object({
  resource: nullableRequiredString("form:field.required"),
  ociQuery: nullableRequiredString("form:field.required"),
  value: z.coerce
    .number({
      invalid_type_error: "form:field.required",
    })
    .min(1, "notification:metricNotification.valueMin"),
  isShutdown: z.boolean().default(false),
});

type NotificationSchema = z.input<typeof notificationSchema>;
type NotificationSchemaOutput = z.output<typeof notificationSchema>;

const columnHelper = createMRTColumnHelper<MetricNotificationResponse>();
const columns = [
  columnHelper.accessor("resource", {
    header: "notification:metricNotification.resource",
    Cell: ({ cell, table }) => {
      const resource = cell.getValue();
      const { resources, t } =
        (table.options.meta as TableMeta<MetricNotificationResponse>) || {};
      if (!resources) {
        return null;
      }

      const resourceFromMeta = resources.find((r) => r.id === resource.id);

      return (
        resourceFromMeta?.name ||
        resourceFromMeta?.ocid || (
          <span className="flex items-center gap-1">
            <IconError404 className="inline" />
            {t("notification:metricNotification.resourceNotFound")}
          </span>
        )
      );
    },
  }),
  columnHelper.accessor("value", {
    header: "notification:metricNotification.value",
    Cell: ({ cell, table }) => {
      const value = cell.getValue();
      return formatNumberAsCurrency({
        value: value || 0,
        currency: (table.options.meta as TableMeta<MetricNotificationResponse>)
          ?.currency,
        options: {
          locale: (table.options.meta as TableMeta<MetricNotificationResponse>)
            ?.currencyLocale,
        },
      });
    },
  }),
  columnHelper.accessor("shutdown", {
    header: "notification:metricNotification.shutdown",
    Cell: ({ cell }) => <Checkbox readOnly checked={cell.getValue()} />,
  }),
];

export const MetricNotificationListPage = () => {
  const { t } = useTranslation(["notification"]);
  const { tenant } = useTenantContext();
  const currencyLocale = useCurrencyLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedResource, setSelectedResource] = useState<string | null>(null);
  const [alertOpened, setAlertOpened] = useState(false);
  const [pendingNotification, setPendingNotification] =
    useState<MetricNotificationResponse | null>(null);

  const form = useTForm<NotificationSchema, typeof notificationSchema.parse>({
    initialValues: {
      resource: null,
      ociQuery: null,
      value: "" as unknown as number,
      isShutdown: false,
    },
    validate: zodResolver(notificationSchema),
    enhanceGetInputProps({ field, form }) {
      if (field === "resource") {
        return {
          onChange: (value: string) => {
            form.setFieldValue("resource", value);
            form.setFieldValue("ociQuery", null);
          },
        };
      }
    },
  });

  const { mutate: deleteNotificationMutation } =
    useDeleteMetricNotificationMutation();

  const { mutate: createMetricNotification, isPending: isCreating } =
    useCreateMetricNotificationMutation({
      onSuccess: close,
    });

  const {
    data: resources,
    isLoading: areResourcesLoading,
    isFetching: areResourcesFetching,
  } = useFetchCodebookResourcesQuery({
    enabled: !!tenant,
    select: (resources) =>
      resources.filter(
        (r) => r.tenant.tenantId === tenant?.id && r.ociQueriesCount > 0,
      ),
  });

  const {
    data: metricNotifications,
    isLoading: areNotificationsLoading,
    isFetching: areNotificationsFetching,
  } = useFetchMetricNotificationsQuery({
    resourceId: selectedResource,
  });

  const { data: queries } = useFetchOCIQueriesQuery({
    params: {
      resourceId: form.values.resource,
    },
    enabled: !!form.values.resource,
  });

  const onSubmit = (data: NotificationSchemaOutput) => {
    createMetricNotification(data);
  };

  const queryOptions = queries?.map(({ id, metricName }) => ({
    value: id,
    label: metricName.metricName,
  }));
  const resourceOptions = resources?.map(
    ({ id, name, ocid, ociQueriesCount }) => ({
      value: id,
      label: `(${ociQueriesCount} upita) ${name || ocid}`,
    }),
  );

  const filteredNotifications = useMemo(() => {
    const notificationsForTenantResources = metricNotifications?.filter(
      (notification) =>
        resources?.some((resource) => resource.id === notification.resource.id),
    );

    return selectedResource ?
        notificationsForTenantResources?.filter(
          (notification) => notification.resource.id === selectedResource,
        )
      : notificationsForTenantResources;
  }, [metricNotifications, resources, selectedResource]);

  useEffect(() => {
    setSelectedResource(null);
  }, [tenant]);

  const table = useMRT<MetricNotificationResponse>({
    columns,
    data: filteredNotifications,
    meta: {
      resources,
      currency,
      currencyLocale,
    },
    state: {
      isLoading: areResourcesLoading || areNotificationsLoading,
      showProgressBars: areResourcesFetching || areNotificationsFetching,
    },
    initialState: {
      columnVisibility: {
        resource: true,
        value: true,
        isShutdown: true,
      },
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <>
        <ActionIcon
          color="red"
          onClick={() => {
            setPendingNotification(row.original);
            setAlertOpened(true);
          }}
        >
          <Trash2Icon className="size-5" />
        </ActionIcon>
        <AlertModal
          opened={alertOpened}
          onClose={() => setAlertOpened(false)}
          onConfirm={() => {
            if (pendingNotification) {
              deleteNotificationMutation({ id: pendingNotification.id });
              setAlertOpened(false);
            }
          }}
          title={t("metricNotification.deleteConfirmationTitle")}
          message={t("metricNotification.deleteConfirmation")}
          isPending={false}
        />
      </>
    ),
    renderTopToolbarCustomActions: () => (
      <div className="flex flex-1 justify-between">
        <Select
          placeholder={t("metricNotification.filterResource")}
          data={resourceOptions || []}
          value={selectedResource}
          rightSection={
            areResourcesFetching && <IconLoader2 className="animate-spin" />
          }
          disabled={areResourcesFetching}
          onChange={(value) => setSelectedResource(value)}
          clearable
        />
        <Button
          color="blue"
          size="compact-lg"
          leftSection={<IconPlus />}
          onClick={() => {
            form.reset();
            if (selectedResource) {
              form.setFieldValue("resource", selectedResource);
            }
            open();
          }}
        >
          {t("metricNotification.create")}
        </Button>
      </div>
    ),
  });

  return (
    <div className="flex h-full w-full overflow-auto">
      <div className="flex flex-1 flex-col gap-2 overflow-auto p-4">
        <Title className="mb-4" size="h2">
          {t("metricNotification.metricNotification")}
        </Title>

        <MantineReactTable table={table} />
      </div>

      <Stack className="basis-96 overflow-auto border-l p-4">
        <DashboardKPIReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />
        <DashboardFifthDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />
        <DashboardThirdDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID}
        />
      </Stack>

      <Modal
        opened={opened}
        onClose={close}
        title={t("metricNotification.createNotification")}
      >
        <form
          onSubmit={form.onSubmit(onSubmit)}
          className="flex flex-col gap-2"
        >
          <Select
            label={t("metricNotification.resource")}
            data={resourceOptions || []}
            disabled={areResourcesFetching}
            rightSection={
              areResourcesFetching && <IconLoader2 className="animate-spin" />
            }
            {...form.getInputProps("resource")}
          />
          <Select
            label={t("metricNotification.query")}
            disabled={!form.values.resource}
            {...form.getInputProps("ociQuery")}
            data={queryOptions || []}
          />
          <NumberInput
            label={t("metricNotification.value")}
            rightSectionProps={{ className: "hidden" }}
            {...form.getInputProps("value")}
          />
          <Checkbox
            label={t("metricNotification.shutdown")}
            {...form.getInputProps("isShutdown", {
              type: "checkbox",
            })}
          />

          <div className="mt-2 flex justify-between gap-2">
            <Button onClick={close} variant="default" disabled={isCreating}>
              {t("metricNotification.cancel")}
            </Button>

            <Button
              type="submit"
              color="blue"
              loading={isCreating || areResourcesFetching}
            >
              {t("metricNotification.confirm")}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
