import { ApiRoutes } from "@/constants/routes";

import { api } from "./axiosInstance";
import {
  MeResponse,
  CityResponse,
  LoginPayload,
  UserResponse,
  ListMetricDto,
  StateResponse,
  LoginResponse,
  TenantResponse,
  LicenseResponse,
  OCIQueryResponse,
  UnitTypeResponse,
  ResourceResponse,
  OrganizationType,
  UpdateUserPayload,
  CommitmentResponse,
  FifthDiagramParams,
  MetricNameResponse,
  PolarDiagramParams,
  ThirdDiagramParams,
  ReportMetricParams,
  FirstDiagramParams,
  CompartmentResponse,
  FetchOCIQueryParams,
  SecondDiagramParams,
  StopResourcePayload,
  FourthDiagramParams,
  FifthDiagramResponse,
  ListMetricNameParams,
  OCIResourcesResponse,
  OrganizationResponse,
  PolarDiagramResponse,
  ReportMetricResponse,
  ResetPasswordPayload,
  ThirdDiagramResponse,
  SubscriptionResponse,
  FirstDiagramResponse,
  CreateOCIQueryPayload,
  QueryResourceResponse,
  SecondDiagramResponse,
  UpdateResourcePayload,
  StatisticTypeResponse,
  FourthDiagramResponse,
  ProductServiceResponse,
  SCNotificationResponse,
  CreateSuperUserPayload,
  BillingCostReportParams,
  CreateSystemUserPayload,
  FetchOCIResourcesParams,
  FirstDiagramAvgResponse,
  MetricNamespaceResponse,
  BillingCostReportResponse,
  ListMetricNamespaceParams,
  ListMetricsResourceParams,
  CreateOrganizationPayload,
  ManageableResourceResponse,
  MetricNotificationResponse,
  CompartmentStructureParams,
  CreateSCNotificationPayload,
  CompartmentStructureResponse,
  CreateOrganizationUserPayload,
  ReportConsumptionByDaysParams,
  ListMetricResourceGroupParams,
  CreateOrganizationAdminPayload,
  CreateMetricNotificationPayload,
  ReportConsumptionByDaysResponse,
  DeleteMetricNotificationPayload,
  ReportConsumptionForCreditsParams,
  ReportConsumptionForCreditsResponse,
  ReportConsumptionByCompartmentsParams,
  ReportConsumptionForCommitmentsParams,
  ReportConsumptionByCompartmentsResponse,
  ReportConsumptionForCommitmentsResponse,
  BudgetNotificationResponse,
  CreateBudgetNotificationPayload,
  CompartmentBudgetNotificationResponse,
  CreateCompartmentBudgetNotificationPayload,
  RegionResponse,
  CreateAndUpdateOrganizationResponse,
  SubscriptionType,
  AccessConfigType,
  AuthorityResponse,
  CompartmentStatusesResponse,
  ProductDescriptionResponse,
  ResourceTypeResponse,
  StorageResultTypeResponse,
  TenancyAccessResponse,
  CreateMetricNamePayload,
  CreateStatisticTypePayload,
  CreateRegionPayload,
  CreateCityPayload,
  ExtendedCityResponse,
  CreateCompartmentPayload,
  MetricMeasuringUnitResponse,
  OCIQueryResponseContentItem,
  CreateMetricMeasuringUnitPayload,
  CreateSubscriptionPayload,
  CommitmentConsumptionParams,
  CommitmentConsumptionResponse,
  EnumResponse,
} from "./dto";

export const Api = {
  auth: {
    login: (data: LoginPayload) =>
      api.post<LoginResponse>(ApiRoutes.Auth.Login(), data),
    refreshToken: () => api.post<LoginResponse>(ApiRoutes.Auth.RefreshToken()),
    resetPassword: ({ token, ...data }: ResetPasswordPayload) =>
      api.post(ApiRoutes.Auth.ResetPassword(token), data),
    logout: () => api.get(ApiRoutes.Auth.Logout()),
    verifyEmail: (token: string) =>
      api.get(ApiRoutes.Auth.VerifyEmail(), { params: { token } }),
  },
  user: {
    me: () =>
      api.get<MeResponse>(ApiRoutes.User.Me()).then((res) => {
        return res;
      }),
    users: () => api.get(ApiRoutes.User.Users()),
  },
  tenants: {
    create: (data: Partial<TenantResponse>) =>
      api.post<TenantResponse>(ApiRoutes.Monitor.CreateTenant(), data),
    update: (tenantId: string, data: Partial<TenantResponse>) =>
      api.put<TenantResponse>(ApiRoutes.Monitor.UpdateTenant(tenantId), data),
  },
  organizations: {
    list: () => api.get<OrganizationResponse[]>(ApiRoutes.Organizations.List()),
    types: () => api.get<OrganizationType[]>(ApiRoutes.Organizations.Types()),
    create: (payload: CreateOrganizationPayload) =>
      api.post<CreateAndUpdateOrganizationResponse>(
        ApiRoutes.Organizations.List(),
        payload,
      ),
    update: (organizationId: string, payload: CreateOrganizationPayload) =>
      api.put<CreateAndUpdateOrganizationResponse>(
        ApiRoutes.Organizations.Update(organizationId),
        payload,
      ),
  },
  regions: {
    list: () => api.get<RegionResponse[]>(ApiRoutes.Regions.List()),
    create: (payload: CreateRegionPayload) =>
      api.post<RegionResponse>(ApiRoutes.Regions.List(), payload),
    update: (regionId: string, payload: CreateRegionPayload) =>
      api.put<RegionResponse>(ApiRoutes.Regions.Update(regionId), payload),
    delete: (regionId: string) =>
      api.delete(ApiRoutes.Regions.Delete(regionId)),
  },
  states: {
    list: () => api.get<StateResponse[]>(ApiRoutes.States.List()),
  },
  compartmentsStatuses: {
    list: () =>
      api.get<CompartmentStatusesResponse[]>(
        ApiRoutes.CompartmentStatuses.List(),
      ),
  },
  productDescriptions: {
    list: () =>
      api.get<ProductDescriptionResponse[]>(
        ApiRoutes.ProductDescriptions.List(),
      ),
  },
  resourceTypes: {
    list: () => api.get<ResourceTypeResponse[]>(ApiRoutes.ResourceTypes.List()),
  },
  storageResultTypes: {
    list: () =>
      api.get<StorageResultTypeResponse[]>(ApiRoutes.StorageResultTypes.List()),
  },
  tenancyAccess: {
    list: () =>
      api.get<TenancyAccessResponse[]>(ApiRoutes.TenancyAccess.List()),
  },
  cities: {
    list: () => api.get<ExtendedCityResponse[]>(ApiRoutes.Cities.List()),
    create: (payload: CreateCityPayload) =>
      api.post<CityResponse>(ApiRoutes.Cities.Create(), payload),
    update: (id: string, payload: CreateCityPayload) =>
      api.put<CityResponse>(ApiRoutes.Cities.Update(id), payload),
    delete: (id: string) => api.delete(ApiRoutes.Cities.Delete(id)),
  },
  subscriptionType: {
    list: () => api.get<SubscriptionType[]>(ApiRoutes.SubscriptionType.List()),
  },
  compartments: {
    list: () => api.get<CompartmentResponse[]>(ApiRoutes.Compartments.List()),
    create: (payload: CreateCompartmentPayload) =>
      api.post<CompartmentResponse>(ApiRoutes.Compartments.Create(), payload),
    update: (id: string, payload: CreateCompartmentPayload) =>
      api.put<CompartmentResponse>(ApiRoutes.Compartments.Update(id), payload),
    compartmentStructures: (params?: CompartmentStructureParams) =>
      api.get<CompartmentStructureResponse[]>(
        ApiRoutes.Compartments.CompartmentStructures(),
        {
          params,
        },
      ),
  },
  codebookResources: {
    list: (compartmentId?: string) =>
      api.get<ResourceResponse[]>(ApiRoutes.CodebookResources.List(), {
        params: {
          compartmentId,
        },
      }),
    update: (payload: UpdateResourcePayload) =>
      api.put<ResourceResponse>(
        ApiRoutes.CodebookResources.Update(payload.id),
        payload,
      ),
  },
  monitor: {
    tenantList: () => api.get<TenantResponse[]>(ApiRoutes.Monitor.TenantList()),
  },
  resource: {
    manageableResources: (tenantocid: string, resourcetypes?: string | null) =>
      api.get<ManageableResourceResponse[]>(
        ApiRoutes.Resource.ManageableResources(),
        { params: { tenantocid, resourcetypes } },
      ),
    manageableResourceTypes: () =>
      api.get<EnumResponse>(ApiRoutes.Resource.ResourceTypes()),
    stopResource: (data: StopResourcePayload) =>
      api.post(ApiRoutes.Resource.StopResource(), data),
    startResource: (data: StopResourcePayload) =>
      api.post(ApiRoutes.Resource.StartResource(), data),
    queryResources: (params: FetchOCIResourcesParams) =>
      api.get<QueryResourceResponse[]>(ApiRoutes.Resource.QueryResources(), {
        params,
      }),
  },
  metricNamespace: {
    list: (resourceId?: string | null) =>
      api.get<MetricNamespaceResponse[]>(ApiRoutes.MetricNamespace.List(), {
        params: { resourceId },
      }),
    create: (payload: MetricNamespaceResponse) =>
      api.post<MetricNamespaceResponse>(
        ApiRoutes.MetricNamespace.List(),
        payload,
      ),
    update: (metricNamespaceId: string, payload: MetricNamespaceResponse) =>
      api.put<MetricNamespaceResponse>(
        ApiRoutes.MetricNamespace.Update(metricNamespaceId),
        payload,
      ),
    delete: (id: string) => api.delete(ApiRoutes.MetricNamespace.Delete(id)),
  },
  metricMeasuringUnit: {
    list: (resourceId?: string | null) =>
      api.get<MetricMeasuringUnitResponse[]>(
        ApiRoutes.MetricMeasuringUnit.List(),
        {
          params: { resourceId },
        },
      ),
    create: (payload: CreateMetricMeasuringUnitPayload) =>
      api.post<MetricMeasuringUnitResponse>(
        ApiRoutes.MetricMeasuringUnit.List(),
        payload,
      ),
    update: (
      metricNamespaceId: string,
      payload: CreateMetricMeasuringUnitPayload,
    ) =>
      api.put<MetricMeasuringUnitResponse>(
        ApiRoutes.MetricMeasuringUnit.Update(metricNamespaceId),
        payload,
      ),
    delete: (id: string) =>
      api.delete(ApiRoutes.MetricMeasuringUnit.Delete(id)),
  },
  metricName: {
    list: (resourceId?: string | null) =>
      api.get<MetricNameResponse[]>(ApiRoutes.MetricName.List(), {
        params: { resourceId },
      }),
    create: (payload: CreateMetricNamePayload) =>
      api.post<MetricNameResponse>(ApiRoutes.MetricName.Create(), payload),
    update: (metricNameId: string, payload: CreateMetricNamePayload) =>
      api.put<MetricNameResponse>(
        ApiRoutes.MetricName.Update(metricNameId),
        payload,
      ),
  },
  statisticType: {
    list: () =>
      api.get<StatisticTypeResponse[]>(ApiRoutes.StatisticType.List()),
    create: (payload: CreateStatisticTypePayload) =>
      api.post<StatisticTypeResponse>(ApiRoutes.StatisticType.List(), payload),
    update: (statisticTypeId: number, payload: CreateStatisticTypePayload) =>
      api.put<StatisticTypeResponse>(
        ApiRoutes.StatisticType.Update(statisticTypeId),
        payload,
      ),
    delete: (statisticTypeId: number) =>
      api.delete(ApiRoutes.StatisticType.Delete(statisticTypeId)),
  },
  unitType: {
    list: (resourceId?: string | null) =>
      api.get<UnitTypeResponse[]>(ApiRoutes.UnitType.List(), {
        params: { resourceId },
      }),
  },
  oci: {
    all: (params?: FetchOCIQueryParams) =>
      api.get<OCIQueryResponseContentItem[]>(ApiRoutes.OCI.All(), { params }),
    list: (params?: FetchOCIQueryParams) =>
      api.get<OCIQueryResponse>(ApiRoutes.OCI.List(), { params }),
    create: (data: CreateOCIQueryPayload) =>
      api.post(ApiRoutes.OCI.CreateQuery(), data),
    update: (id: string, payload: CreateOCIQueryPayload) =>
      api.put(ApiRoutes.OCI.UpdateQuery(id), payload),
    resources: (params: FetchOCIResourcesParams) =>
      api.get<OCIResourcesResponse>(ApiRoutes.OCI.List(), { params }),
    deleteOCIQuery: (id: string) => api.delete(ApiRoutes.OCI.DeleteQuery(id)),
    listMetricNamespace: (params: ListMetricNamespaceParams) =>
      api.get<ListMetricDto[]>(ApiRoutes.OCI.ListMetricNamespace(), {
        params,
      }),
    listMetricResourceGroup: (params: ListMetricResourceGroupParams) =>
      api.get<ListMetricDto[]>(ApiRoutes.OCI.ListMetricResourceGroup(), {
        params,
      }),
    listMetricsResource: (params: ListMetricsResourceParams) =>
      api.get<ListMetricDto[]>(ApiRoutes.OCI.ListMetricsResource(), {
        params,
      }),
    listMetricName: (params: ListMetricNameParams) =>
      api.get<ListMetricDto[]>(ApiRoutes.OCI.ListMetricName(), {
        params,
      }),
  },
  licenses: {
    list: () => api.get<LicenseResponse[]>(ApiRoutes.Licenses.List()),
    upload: (organizationId: string, licenseFile: File) => {
      const payload = new FormData();
      payload.append("file", licenseFile);

      return api.post(ApiRoutes.Licenses.Upload(organizationId), payload);
    },
  },
  metricNotifications: {
    list: (resourceId?: string | null) =>
      api.get<MetricNotificationResponse[]>(
        ApiRoutes.MetricNotifications.List(),
        {
          params: { resourceId },
        },
      ),
    create: (data: CreateMetricNotificationPayload) =>
      api.post(ApiRoutes.MetricNotifications.Create(), data),
    delete: (data: DeleteMetricNotificationPayload) =>
      api.delete(ApiRoutes.MetricNotifications.Delete(data)),
  },
  commitments: {
    list: (subscriptionid?: number) =>
      api.get<CommitmentResponse[]>(ApiRoutes.Commitments.List(), {
        params: { subscriptionid },
      }),
  },
  subscriptions: {
    list: () => api.get<SubscriptionResponse[]>(ApiRoutes.Subscriptions.List()),
    create: (payload: CreateSubscriptionPayload) =>
      api.post<SubscriptionResponse>(ApiRoutes.Subscriptions.Create(), payload),
    update: (subscriptionId: string, payload: CreateSubscriptionPayload) =>
      api.put<SubscriptionResponse>(
        ApiRoutes.Subscriptions.Update(subscriptionId),
        payload,
      ),
  },
  scNotifications: {
    list: () =>
      api.get<SCNotificationResponse[]>(ApiRoutes.SCNotifications.List()),
    create: (data: CreateSCNotificationPayload) =>
      api.post<SCNotificationResponse>(
        ApiRoutes.SCNotifications.Create(),
        data,
      ),
    delete: (id: string) =>
      api.delete<SCNotificationResponse>(ApiRoutes.SCNotifications.Delete(id)),
    scNotificationTypes: () =>
      api.get<EnumResponse>(ApiRoutes.SCNotifications.SCNotificationType()),
    scNotificationValueTypes: () =>
      api.get<EnumResponse>(
        ApiRoutes.SCNotifications.SCNotificationValueTypes(),
      ),
  },
  users: {
    list: () => api.get<UserResponse[]>(ApiRoutes.Users.List()),
    createSuperUser: (data: CreateSuperUserPayload) =>
      api.post(ApiRoutes.Users.CreateSuperUser(), data),
    createSystemUser: (data: CreateSystemUserPayload) =>
      api.post(ApiRoutes.Users.CreateSystemUser(), data),
    createOrganizationAdminUser: (data: CreateOrganizationAdminPayload) =>
      api.post(ApiRoutes.Users.CreateOrganizationAdminUser(), data),
    createOrganizationUser: (data: CreateOrganizationUserPayload) =>
      api.post(ApiRoutes.Users.CreateOrganizationUser(), data),
    update: ({ id, ...data }: UpdateUserPayload) =>
      api.put<UserResponse>(ApiRoutes.Users.Update(id), data),
    requestPasswordReset: (id: string) =>
      api.post(ApiRoutes.Users.RequestPasswordReset(id)),
    delete: (id: string) =>
      api.delete<UserResponse>(ApiRoutes.Users.Delete(id)),
  },
  authorities: {
    all: () => api.get<string[]>(ApiRoutes.Authorities.All()),
    list: () => api.get<AuthorityResponse[]>(ApiRoutes.Authorities.List()),
    organization: () => api.get<string[]>(ApiRoutes.Authorities.Organization()),
  },
  report: {
    metric: (params: ReportMetricParams) =>
      api.get<ReportMetricResponse[]>(ApiRoutes.Report.Metric(), { params }),
    consumptionByDay: (params: ReportConsumptionByDaysParams) =>
      api.get<ReportConsumptionByDaysResponse[]>(
        ApiRoutes.Report.ConsumptionByDays(),
        { params },
      ),
    consumptionByCompartments: (
      params: ReportConsumptionByCompartmentsParams,
    ) =>
      api.get<ReportConsumptionByCompartmentsResponse[]>(
        ApiRoutes.Report.ConsumptionByCompartments(),
        { params },
      ),
    consumptionForCommitments: (
      params: ReportConsumptionForCommitmentsParams,
    ) =>
      api.get<ReportConsumptionForCommitmentsResponse[]>(
        ApiRoutes.Report.ConsumptionForCommitments(),
        { params },
      ),
    consumptionForCredits: (params: ReportConsumptionForCreditsParams) =>
      api.get<ReportConsumptionForCreditsResponse[]>(
        ApiRoutes.Report.ConsumptionForCredits(),
        { params },
      ),
  },
  dashboard: {
    firstDiagram: (params: FirstDiagramParams) =>
      api.get<FirstDiagramResponse[]>(ApiRoutes.Dashboard.FirstDiagram(), {
        params,
      }),
    firstDiagramAvg: (params: FirstDiagramParams) =>
      api.get<FirstDiagramAvgResponse[]>(
        ApiRoutes.Dashboard.FirstDiagramAvg(),
        {
          params,
        },
      ),
    secondDiagram: (params: SecondDiagramParams) =>
      api.get<SecondDiagramResponse[]>(ApiRoutes.Dashboard.SecondDiagram(), {
        params,
      }),
    thirdDiagram: (params: ThirdDiagramParams) =>
      api.get<ThirdDiagramResponse[]>(ApiRoutes.Dashboard.ThirdDiagram(), {
        params,
      }),
    fourthDiagram: (params: FourthDiagramParams) =>
      api.get<FourthDiagramResponse[]>(ApiRoutes.Dashboard.FourthDiagram(), {
        params,
      }),
    fifthDiagram: (params: FifthDiagramParams) =>
      api.get<FifthDiagramResponse[]>(ApiRoutes.Dashboard.FifthDiagram(), {
        params,
      }),
    polarDiagram: (params: PolarDiagramParams) =>
      api.get<PolarDiagramResponse[]>(ApiRoutes.Dashboard.PolarDiagram(), {
        params,
      }),
    timeTypes: () => api.get<EnumResponse>(ApiRoutes.Dashboard.TimeTypes()),
  },
  productService: {
    list: () =>
      api.get<ProductServiceResponse[]>(ApiRoutes.ProductService.List()),
  },
  billingReports: {
    costReport: (params: BillingCostReportParams) =>
      api.get<BillingCostReportResponse[]>(
        ApiRoutes.BillingReports.CostReport(),
        {
          params,
        },
      ),
  },
  budgetNotifications: {
    list: () =>
      api.get<BudgetNotificationResponse[]>(
        ApiRoutes.BudgetNotifications.List(),
      ),
    create: (data: CreateBudgetNotificationPayload) =>
      api.post<BudgetNotificationResponse>(
        ApiRoutes.BudgetNotifications.Create(),
        data,
      ),
    delete: (id: string) =>
      api.delete<BudgetNotificationResponse>(
        ApiRoutes.BudgetNotifications.Delete(id),
      ),
  },
  compartmentBudgetNotifications: {
    list: () =>
      api.get<CompartmentBudgetNotificationResponse[]>(
        ApiRoutes.CompartmentBudgetNotifications.List(),
      ),
    create: (data: CreateCompartmentBudgetNotificationPayload) =>
      api.post<CompartmentBudgetNotificationResponse>(
        ApiRoutes.CompartmentBudgetNotifications.Create(),
        data,
      ),
    delete: (id: string) =>
      api.delete<CompartmentBudgetNotificationResponse>(
        ApiRoutes.CompartmentBudgetNotifications.Delete(id),
      ),
    notificationTypes: () =>
      api.get<EnumResponse>(
        ApiRoutes.CompartmentBudgetNotifications.NotificationTypes(),
      ),
  },
  accessConfigType: {
    list: () => api.get<AccessConfigType[]>(ApiRoutes.AccessConfigType.List()),
  },
  consumption: {
    commitment: (params: CommitmentConsumptionParams) =>
      api.get<CommitmentConsumptionResponse[]>(
        ApiRoutes.Consumption.Commitment(),
        {
          params,
        },
      ),
    commitmentStatusTypes: () =>
      api.get<EnumResponse>(ApiRoutes.Consumption.CommitmentStatusTypes()),
    commitmentPricingModelTypes: () =>
      api.get<EnumResponse>(
        ApiRoutes.Consumption.CommitmentPricingModelTypes(),
      ),
  },
};
