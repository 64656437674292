import { Paper, Button, Select, NumberInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { format } from "date-fns";
import { orderBy, uniqBy } from "lodash";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { CURRENCIES } from "@/constants/currencies";
import { useCreateOrUpdateSubscriptionMutation } from "@/hooks/api/useCreateOrUpdateSubscriptionsMutation";
import { useFetchOrganizationsQuery } from "@/hooks/api/useFetchOrganizationsQuery";
import { useFetchSubscriptionTypeQuery } from "@/hooks/api/useFetchSubscriptionTypeQuery";
import { FormTransform, useTForm } from "@/hooks/useTForm";
import { SubscriptionResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface SubscriptionDetailsProps {
  subscription?: SubscriptionResponse | null;
  onSettled?: () => void;
  className?: string;
}

const subscriptionSchema = z.object({
  subscriptionTypeId: z.coerce
    .number()
    .nullable()
    .transform((value, ctx) => {
      if (!value || isNaN(value)) {
        ctx.addIssue({
          code: "custom",
          message: "form:field.required",
        });
      }

      return value ?? NaN;
    }),
  organizationId: z.string().min(1, "form:field.required"),
  subscriptionID: z.coerce
    .number()
    .nullable()
    .refine((value) => value !== null, {
      message: "form:field.required",
    }),
  csiNumber: z.coerce
    .number()
    .nullable()
    .refine((value) => value !== null, {
      message: "form:field.required",
    }),
  originalStartDate: z.coerce.date(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date().nullable(),
  currency: z
    .string()
    .nullable()
    .refine((value) => !!value, {
      message: "form:field.required",
    }),
});

type FormData = z.input<typeof subscriptionSchema>;
type FormOutput = z.output<typeof subscriptionSchema>;

const defaultValues: FormData = {
  subscriptionTypeId: null,
  organizationId: "",
  subscriptionID: null,
  csiNumber: null,
  originalStartDate: new Date(),
  startDate: new Date(),
  endDate: null,
  currency: null,
};

const currencyOptions = uniqBy(
  orderBy(CURRENCIES, "currencyCode", "asc"),
  "currencyCode",
).map((currency) => ({
  value: currency.currencyCode,
  label: `${currency.currencyCode} - ${currency.currencyName}`,
}));

const mapSubscriptionToFormData = (
  subscription?: SubscriptionResponse | null,
): FormData => {
  if (!subscription) return defaultValues;

  return {
    subscriptionTypeId:
      `${subscription.subscriptionType.id}` as unknown as number,
    organizationId: subscription.organization.id,
    subscriptionID: subscription.subscriptionID,
    csiNumber: subscription.csiNumber,
    originalStartDate: new Date(subscription.originalStartDate),
    startDate: new Date(subscription.startDate),
    endDate: subscription.endDate ? new Date(subscription.endDate) : null,
    currency: subscription.currency,
  };
};

export const SubscriptionsDetails = ({
  subscription,
  onSettled,
  className,
}: SubscriptionDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const { data: organizations } = useFetchOrganizationsQuery();
  const { data: subscriptionTypes } = useFetchSubscriptionTypeQuery();

  const { mutate, isPending } = useCreateOrUpdateSubscriptionMutation({
    subscriptionId: subscription?.id,
  });

  const form = useTForm<FormData, FormTransform<FormData, FormOutput>>({
    initialValues: mapSubscriptionToFormData(subscription),
    validate: zodResolver(subscriptionSchema),
  });

  const onSubmit = (data: FormOutput) => {
    mutate(
      {
        ...data,
        startDate: format(data.startDate, "dd/MM/yyyy"),
        originalStartDate: format(data.originalStartDate, "dd/MM/yyyy"),
        endDate: data.endDate ? format(data.endDate, "dd/MM/yyyy") : null,
      },
      {
        onSuccess: () => {
          form.reset();
          if (onSettled) {
            onSettled();
          }
        },
      },
    );
  };

  const mappedOrganizations = organizations?.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }));

  const mappedSubscriptionTypes = subscriptionTypes?.map((type) => ({
    value: `${type.id}`,
    label: type.name || "",
  }));

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <Select
        withAsterisk
        label={t("administration.subscription.subscriptionTypeId")}
        data={mappedSubscriptionTypes}
        {...form.getInputProps("subscriptionTypeId")}
      />
      <Select
        withAsterisk
        label={t("administration.subscription.organizationId")}
        data={mappedOrganizations}
        {...form.getInputProps("organizationId")}
      />
      <NumberInput
        withAsterisk
        rightSectionProps={{ className: "hidden" }}
        label={t("administration.subscription.subscriptionId")}
        {...form.getInputProps("subscriptionID")}
      />
      <NumberInput
        withAsterisk
        rightSectionProps={{ className: "hidden" }}
        label={t("administration.subscription.csiNumber")}
        {...form.getInputProps("csiNumber")}
      />
      <DatePickerInput
        withAsterisk
        label={t("administration.subscription.originalStartDate")}
        {...form.getInputProps("originalStartDate")}
      />
      <DatePickerInput
        withAsterisk
        label={t("administration.subscription.startDate")}
        {...form.getInputProps("startDate")}
      />
      <DatePickerInput
        clearable
        label={t("administration.subscription.endDate")}
        {...form.getInputProps("endDate")}
      />
      <Select
        withAsterisk
        label={t("administration.subscription.currency")}
        data={currencyOptions}
        searchable
        clearable
        {...form.getInputProps("currency")}
      />

      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" loading={isPending} disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};
