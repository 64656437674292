import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CreateTenantPayload, TenantResponse } from "@/lib/api/dto";

import { codebookKeys } from "./queryKeys";

interface UseCreateOrUpdateTenantMutationProps
  extends UseMutationOptions<TenantResponse, Error, CreateTenantPayload> {
  tenantId?: string;
}

export const useCreateOrUpdateTenantMutation = ({
  tenantId,
  ...mutationProps
}: UseCreateOrUpdateTenantMutationProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...mutationProps,
    mutationKey: codebookKeys.mutateTenant(tenantId),
    mutationFn: (data) => {
      if (tenantId) {
        return Api.tenants.update(tenantId, data).then((res) => res.data);
      }

      return Api.tenants.create(data).then((res) => res.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: codebookKeys.tenants() });
    },
  });
};
