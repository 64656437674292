import { createMRTColumnHelper } from "mantine-react-table";

import { SubscriptionType } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<SubscriptionType>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.subscriptionType.name",
  }),

  columnHelper.accessor("description", {
    header: "entity:administration.subscriptionType.description",
  }),
];
