import { TextInput, Button } from "@mantine/core";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useCreateOrUpdateMetricMeasuringUnitMutation } from "@/hooks/api/useCreateorUpdateMetricMeasuringUnitMutation";
import { useTForm } from "@/hooks/useTForm";
import { MetricMeasuringUnitResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface MetricMeasuringUnitDetailsProps {
  metricMeasuringUnit?: MetricMeasuringUnitResponse | null;
  onSettled?: () => void;
  className?: string;
}

const metricMeasuringUnitSchema = z.object({
  metricMeasuringUnit: z.string().min(1, "form:field.required"),
  name: z.string().min(1, "form:field.required"),
  regex: z.string(),
  description: z.string(),
});

type FormData = z.infer<typeof metricMeasuringUnitSchema>;

const defaultValues: FormData = {
  metricMeasuringUnit: "",
  name: "",
  regex: "",
  description: "",
};

const mapMetricMeasuringUnitToFormData = (
  metricMeasuringUnit?: MetricMeasuringUnitResponse | null,
): FormData => {
  if (!metricMeasuringUnit) return defaultValues;

  return {
    metricMeasuringUnit:
      metricMeasuringUnit.metricMeasuringUnit ??
      defaultValues.metricMeasuringUnit,
    name: metricMeasuringUnit.name ?? defaultValues.name,
    regex: metricMeasuringUnit.regex ?? defaultValues.regex,
    description: metricMeasuringUnit.description ?? defaultValues.description,
  };
};

export const MetricMeasuringUnitDetails = ({
  className,
  metricMeasuringUnit,
  onSettled,
}: MetricMeasuringUnitDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const { mutate, isPending } = useCreateOrUpdateMetricMeasuringUnitMutation({
    metricMeasurignUnitId: metricMeasuringUnit?.metricMeasuringUnit,
  });

  const form = useTForm<z.infer<typeof metricMeasuringUnitSchema>>({
    initialValues: mapMetricMeasuringUnitToFormData(metricMeasuringUnit),
    validate: zodResolver(metricMeasuringUnitSchema),
  });

  const onSubmit = (data: FormData) => {
    mutate(data, {
      onSuccess: (response) => {
        if (metricMeasuringUnit?.metricMeasuringUnit) {
          form.setInitialValues(mapMetricMeasuringUnitToFormData(response));
        }
        form.reset();
      },
      onSettled,
    });
  };

  return (
    <form
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("administration.metricMeasuringUnit.metricMeasuringUnit")}
        {...form.getInputProps("metricMeasuringUnit")}
      />

      <TextInput
        withAsterisk
        label={t("administration.metricMeasuringUnit.name")}
        {...form.getInputProps("name")}
      />

      <div className="mt-2 flex justify-between gap-2">
        <Button variant="default" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" loading={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </form>
  );
};
