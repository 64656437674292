import { createMRTColumnHelper } from "mantine-react-table";

import { TruncatedText } from "@/components/ui/truncated-text";
import { TenantResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<TenantResponse>();

export const columns = [
  columnHelper.accessor("organization.name", {
    header: "entity:administration.common.organization",
  }),
  columnHelper.accessor("name", {
    header: "entity:administration.tenant.tenant",
  }),
  columnHelper.accessor("ocid", {
    header: "entity:administration.common.ocid",
    Cell: ({ cell }) => (
      <TruncatedText size="sm" leadingCharacters={10} text={cell.getValue()} />
    ),
  }),
  columnHelper.accessor((row) => row.region?.name, {
    header: "entity:administration.tenant.region",
  }),
  columnHelper.accessor("csiNumber", {
    header: "entity:administration.tenant.csiNumber",
  }),
];
