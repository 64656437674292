import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Modal,
  NumberInput,
  ScrollArea,
  Stack,
  TagsInput,
  Text,
  TextInput,
} from "@mantine/core";
import { MonthPickerInput } from "@mantine/dates";
import { zodResolver } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconX } from "@tabler/icons-react";
import { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { EnumSelect } from "@/components/ui/enum-select";
import { MultiTreeSelect } from "@/components/ui/multi-tree-select";
import { useFetchCompartmentBudgetNotificationTypes } from "@/hooks/api/useFetchCompartmentBudgetNotificationTypes";
import {
  getTreeChildren,
  nestCompartmentStructure,
  useFetchCompartmentStructureQuery,
} from "@/hooks/api/useFetchCompartmentStructureQuery";
import { useTForm } from "@/hooks/useTForm";
import { TenantResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

const compartmentBudgetNotificationSchema = z.object({
  compartments: z.array(z.string()).min(1, "form:field.required"),
  values: z
    .array(
      z.object({
        datefrom: z.date({
          invalid_type_error: "notification:budget.invalidDate",
        }),
        dateto: z.date(),
        value: z.number(),
      }),
    )
    .refine((v) => {
      return v
        .sort(
          (a, b) =>
            new Date(a.datefrom).getTime() - new Date(b.datefrom).getTime(),
        )
        .every((value, index, self) => {
          const nextValue = self[index + 1];
          return !nextValue || value.dateto <= nextValue.datefrom;
        });
    }, "notification:budget.dateFromToError"),
  emails: z
    .array(z.string())
    .min(1, "form:field.required")
    .refine(
      (emails) =>
        emails.every((email) => z.string().email().safeParse(email).success),
      "notification:bugdet.invalidEmail",
    ),
  shutdown: z.boolean(),
  budgetCompartmentNotificationType: z
    .string({
      invalid_type_error: "form:field.required",
    })
    .min(1, "form:field.required"),
});

export type CompartmentBudgetNotificationFormValues = z.infer<
  typeof compartmentBudgetNotificationSchema
>;

interface CreateCompartmentBudgetNotificationFormProps {
  tenant: TenantResponse;
  subscription: string;
  onSubmit: (values: CompartmentBudgetNotificationFormValues) => void;
  onCancel?: () => void;
}

export const CreateCompartmentBudgetNotificationForm = ({
  tenant,
  subscription,
  onSubmit,
  onCancel,
}: CreateCompartmentBudgetNotificationFormProps) => {
  const [isConfirmShutdownModalOpen, confirmShutdownModal] = useDisclosure();
  const { data: compartments = [] } = useFetchCompartmentStructureQuery({
    params: {
      tenantocid: tenant.ocid,
    },
    select: nestCompartmentStructure,
  });

  const { data: compartmentBudgetNotificationTypeEnum } =
    useFetchCompartmentBudgetNotificationTypes();

  const form = useTForm<CompartmentBudgetNotificationFormValues>({
    initialValues: {
      compartments: [],
      values: [
        {
          datefrom: new Date(),
          dateto: new Date(),
          value: 0,
        },
      ],
      emails: [],
      shutdown: false,
      budgetCompartmentNotificationType: "",
    },
    validate: zodResolver(compartmentBudgetNotificationSchema),
  });

  const compartmentOptions = compartments.map((c) =>
    getTreeChildren(c, compartments, "id"),
  );

  const handleAddNewValue = () => {
    form.insertListItem("values", {
      datefrom: new Date(),
      dateto: new Date(),
      value: 0,
    });
  };
  const { t } = useTranslation(["notification", "common"]);

  return (
    <Group
      component="form"
      onSubmit={(e) =>
        form.onSubmit(onSubmit)(e as unknown as FormEvent<HTMLFormElement>)
      }
      className="flex-1"
      align="stretch"
    >
      <Stack className="flex-1">
        <TextInput
          readOnly
          label={t("compartment.tenant")}
          value={tenant.name}
        />
        <TextInput
          readOnly
          label={t("compartment.subscription")}
          value={subscription}
        />

        <MultiTreeSelect
          label={t("compartment.compartments")}
          isParentSelectable
          clearable
          closeOnSelect={false}
          data={[
            {
              value: "tenant",
              label: tenant?.name,
              selectable: false,
              children: compartmentOptions,
            },
          ]}
          value={form.values.compartments}
          onChange={(value) =>
            form.setFieldValue("compartments", value as unknown as string[])
          }
        />

        <EnumSelect
          data={compartmentBudgetNotificationTypeEnum}
          label={t("compartment.notificationTypes")}
          {...form.getInputProps("budgetCompartmentNotificationType")}
        />

        <TagsInput
          label={t("compartment.emails")}
          {...form.getInputProps("emails")}
        />

        <Stack gap="xs">
          <Checkbox
            label={t("compartment.automaticOff")}
            color="blue"
            labelPosition="left"
            {...form.getInputProps("shutdown", { type: "checkbox" })}
            onChange={(event) => {
              if (event.target.checked) {
                confirmShutdownModal.open();
              } else {
                form.setFieldValue("shutdown", false);
              }
            }}
          />
          <Text c="red">{t("compartment.note")}</Text>
        </Stack>

        <Group justify="end" mt="auto">
          {onCancel && (
            <Button variant="outline" color="gray" onClick={onCancel}>
              {t("compartment.cancel")}
            </Button>
          )}
          <Button bg="blue" type="submit">
            {t("compartment.save")}
          </Button>
        </Group>
      </Stack>
      <Stack className="flex-1 justify-start self-start">
        <Stack gap={6}>
          <ScrollArea
            h={420}
            className={cn("border border-transparent", {
              "rounded-sm border-red-500": form.errors["values"],
            })}
          >
            <Stack px="sm">
              {form.values.values.map((value, index) => (
                <Group key={index} align="end">
                  <MonthPickerInput
                    allowSingleDateInRange
                    type="range"
                    label={t("common:desiredPeriod")}
                    valueFormat="MMM YYYY"
                    className="flex-1"
                    value={[value.datefrom, value.dateto]}
                    minDate={new Date()}
                    onChange={([datefrom, dateto]) => {
                      if (!datefrom && !dateto) {
                        form.setFieldValue(
                          `values.${index}.datefrom`,
                          value.datefrom,
                        );
                        form.setFieldValue(
                          `values.${index}.dateto`,
                          value.datefrom,
                        );
                        return;
                      }
                      form.setFieldValue(`values.${index}.datefrom`, datefrom);
                      form.setFieldValue(`values.${index}.dateto`, dateto);
                    }}
                    error={form.errors[`values.${index}.datefrom`]}
                  />
                  <NumberInput
                    hideControls
                    label={t("compartment.value")}
                    maw={96}
                    value={value.value}
                    onChange={(value) =>
                      form.setFieldValue(`values.${index}.value`, value)
                    }
                  />
                  <ActionIcon
                    size="lg"
                    onClick={() => form.removeListItem("values", index)}
                    disabled={form.values.values.length <= 1}
                  >
                    <IconX />
                  </ActionIcon>
                </Group>
              ))}
            </Stack>
          </ScrollArea>
          {form.errors["values"] && (
            <Text c="red">{form.errors["values"]}</Text>
          )}
        </Stack>
        <Group>
          <Button color="blue" ml="auto" onClick={handleAddNewValue}>
            {t("compartment.addNewValue")}
          </Button>
        </Group>
      </Stack>

      <Modal
        opened={isConfirmShutdownModalOpen}
        onClose={confirmShutdownModal.close}
        title={t("budget.automaticOffConfirm")}
        centered
      >
        <Group>
          <Button
            color="blue"
            variant="outline"
            className="flex-1"
            onClick={confirmShutdownModal.close}
          >
            {t("budget.no")}
          </Button>
          <Button
            color="blue"
            className="flex-1"
            onClick={() => {
              form.setFieldValue("shutdown", true);
              confirmShutdownModal.close();
            }}
          >
            {t("budget.yes")}
          </Button>
        </Group>
      </Modal>
    </Group>
  );
};
