import { Stack, ComboboxItem, Group, Select } from "@mantine/core";
import { DatePickerInput, DatesRangeValue } from "@mantine/dates";
import subDays from "date-fns/subDays";
import { uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import filesSvg from "@/assets/files.svg";
import { TreeSelect } from "@/components/ui/tree-select";
import { useTenantContext } from "@/context/TenantContext";
import {
  findCompartment,
  getTreeChildren,
  nestCompartmentStructure,
  useFetchCompartmentStructureQuery,
} from "@/hooks/api/useFetchCompartmentStructureQuery";
import { useFetchQueryResourcesQuery } from "@/hooks/api/useFetchQueryResourcesQuery";
import { useFetchStatisticTypesQuery } from "@/hooks/api/useFetchStatisticTypesQuery";
import { MonitorCPUUtilizationReport } from "@/widgets/MonitorReports/MonitorCPUUtilizationReport";
import { MonitorDiskBytesReadReport } from "@/widgets/MonitorReports/MonitorDiskBytesReadReport";
import { MonitorDiskBytesWrittenReport } from "@/widgets/MonitorReports/MonitorDiskBytesWrittenReport";
import { MonitorDiskIopsReadReport } from "@/widgets/MonitorReports/MonitorDiskIopsReadReport";
import { MonitorDiskIopsWrittenReport } from "@/widgets/MonitorReports/MonitorDiskIopsWrittenReport";
import { MonitorLoadAverageReport } from "@/widgets/MonitorReports/MonitorLoadAverageReport";
import { MonitorMemoryAllocationStallsReport } from "@/widgets/MonitorReports/MonitorMemoryAllocationStallsReport";
import { MonitorMemoryUtilizationReport } from "@/widgets/MonitorReports/MonitorMemoryUtilizationReport";
import { MonitorNetworkBytesInReport } from "@/widgets/MonitorReports/MonitorNetworkBytesInReport";
import { MonitorNetworkBytesOutReport } from "@/widgets/MonitorReports/MonitorNetworkBytesOutReport";

export const MonitorPage = () => {
  const { t } = useTranslation(["dashboard", "common"]);
  const [selectedCompartmentId, setSelectedCompartmentId] = useState<
    string | null
  >(null);
  const [selectedResourceId, setSelectedResourceId] = useState<string | null>(
    null,
  );
  const [statisticType, setStatisticType] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<DatesRangeValue>([
    subDays(new Date(), 1),
    new Date(),
  ]);
  const [startDate, endDate] = dateRange;
  const { tenant } = useTenantContext();

  const { data: compartments } = useFetchCompartmentStructureQuery({
    params: {
      tenantocid: tenant?.ocid,
    },
    select: nestCompartmentStructure,
  });

  const selectedCompartment = findCompartment(
    selectedCompartmentId,
    compartments,
  );

  const { data: resourcesData } = useFetchQueryResourcesQuery({
    enabled: !!selectedCompartmentId,
    params: {
      compartmentId: selectedCompartment?.compartmentId,
    },
    select: (data) => uniqBy(data, "ocid"),
  });
  const { data: statisticTypes } = useFetchStatisticTypesQuery({
    enabled: !!selectedResourceId,
    resourceId: resourcesData?.find((r) => r.ocid === selectedResourceId)?.id,
  });

  const handleCompartmentChange = (value: string | null) => {
    setSelectedCompartmentId(value);
    setSelectedResourceId(null);
    setStatisticType(null);
  };

  const handleResourceChange = (value: string | null) => {
    setSelectedResourceId(value);
    setStatisticType(null);
  };

  const compartmentOptions = useMemo(
    () =>
      compartments?.map((c) => getTreeChildren(c, compartments, "ocid")) || [],
    [compartments],
  );
  const resourceOptions = useMemo(() => {
    return resourcesData?.map<ComboboxItem>((resource) => {
      return {
        value: resource.ocid,
        label:
          resource.name ? `${resource.name} (${resource.ocid})` : resource.ocid,
      };
    });
  }, [resourcesData]);
  const statisticTypeOptions = useMemo(() => {
    return statisticTypes?.map<ComboboxItem>((statisticType) => {
      return {
        value: statisticType.name,
        label: statisticType.name,
      };
    });
  }, [statisticTypes]);

  useEffect(() => {
    setSelectedCompartmentId(null);
    setSelectedResourceId(null);
    setStatisticType(null);
  }, [tenant]);

  useEffect(() => {
    if (statisticTypeOptions?.length === 1) {
      setStatisticType(statisticTypeOptions?.[0]?.value);
    }
  }, [statisticTypeOptions]);

  const areGrafsVisible =
    !!tenant &&
    !!selectedCompartmentId &&
    !!selectedResourceId &&
    !!statisticType;

  return (
    <Group className="h-full overflow-auto" align="start">
      <Stack className="h-full basis-96 gap-2 border-r p-4">
        <TreeSelect
          label={t("dashboardKPIReport.monitorPage.compartment")}
          isParentSelectable
          data={
            compartmentOptions.length ?
              [
                {
                  value: "tenant",
                  label: tenant?.name,
                  selectable: false,
                  children: compartmentOptions,
                },
              ]
            : []
          }
          value={selectedCompartmentId}
          onChange={handleCompartmentChange}
          clearable
        />
        <Select
          label={t("dashboardKPIReport.monitorPage.resource")}
          name="resource"
          data={resourceOptions}
          value={selectedResourceId}
          onChange={handleResourceChange}
          disabled={!selectedCompartmentId}
          maxDropdownHeight={200}
        />
        <Select
          label={t("dashboardKPIReport.monitorPage.statisticType")}
          name="statisticType"
          data={statisticTypeOptions}
          value={statisticType}
          onChange={setStatisticType}
          disabled={!selectedResourceId}
          maxDropdownHeight={200}
        />
        <DatePickerInput
          type="range"
          label={t("common:desiredPeriod")}
          miw={180}
          value={dateRange}
          onChange={setDateRange}
        />
      </Stack>
      <Stack className="h-full flex-1 overflow-auto p-8">
        <h3 className="text-3xl font-medium">
          {t("dashboardKPIReport.monitorPage.serverMetrics")}
        </h3>
        {!areGrafsVisible && (
          <div className="flex flex-1 flex-col items-center justify-center gap-y-4">
            <img src={filesSvg} alt="files" />
            <h3 className="text-center text-2xl font-medium">
              {t("dashboardKPIReport.monitorPage.formRequired")}
            </h3>
          </div>
        )}
        {areGrafsVisible && (
          <div className="flex w-full flex-col gap-4">
            <MonitorCPUUtilizationReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
            <MonitorDiskBytesReadReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
            <MonitorDiskBytesWrittenReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
            <MonitorMemoryUtilizationReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
            <MonitorDiskIopsReadReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
            <MonitorDiskIopsWrittenReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
            <MonitorNetworkBytesInReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
            <MonitorNetworkBytesOutReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
            <MonitorMemoryAllocationStallsReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
            <MonitorLoadAverageReport
              tenant={tenant}
              endDate={endDate}
              startDate={startDate}
              statisticType={statisticType}
              selectedResourceId={selectedResourceId}
              selectedCompartmentId={selectedCompartmentId}
            />
          </div>
        )}
      </Stack>
    </Group>
  );
};
