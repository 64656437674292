import { TextInput, Button } from "@mantine/core";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import logo from "@/assets/logo.svg";
import sistemiLogo from "@/assets/sistemi-logo.svg";
import { Separator } from "@/components/ui/separator";
import { errorToast } from "@/components/ui/use-toast";
import { useResetPasswordMutation } from "@/hooks/api/useResetPasswordMutation";
import { useAuth } from "@/hooks/useAuth";
import { useTForm } from "@/hooks/useTForm";
import { ResetPasswordPayload } from "@/lib/api/dto";

const resetPasswordSchema = z
  .object({
    password: z
      .string()
      .min(5, "entity:administration.user.actions.resetPasswordValidationMin")
      .max(
        255,
        "entity:administration.user.actions.resetPasswordValidationMax",
      ),
    repeatPassword: z.string(),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "entity:administration.user.actions.passwordDontMatch",
    path: ["repeatPassword"],
  });

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("entity");
  const { isLoggedIn } = useAuth();
  const [params] = useSearchParams();
  const { mutateAsync, isPending } = useResetPasswordMutation();
  const token = params.get("token");

  const form = useTForm<z.infer<typeof resetPasswordSchema>>({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validate: zodResolver(resetPasswordSchema),
  });

  const onSubmit = (
    data: Pick<ResetPasswordPayload, "password" | "repeatPassword">,
  ) => {
    if (!token) return;
    return mutateAsync({ ...data, token })
      .then(() => navigate("/"))
      .catch(() => {
        errorToast({
          description: "entity:administration.user.actions.errorResetPassword",
        });
      });
  };

  return (
    <div className="col-span-2 row-span-2 flex h-full flex-col items-center justify-center gap-3">
      {!token && (
        <>
          {!isLoggedIn && (
            <Link to="/" className="w-[400px] rounded-md bg-primary p-4">
              <img src={sistemiLogo} alt="logo" className="w-full" />
              <Separator className="my-4" />
              <img src={logo} className="mx-auto" />
            </Link>
          )}
          <h1 className="text-center text-3xl font-semibold">
            {t("administration.user.actions.resetPasswordLink")}
          </h1>
        </>
      )}
      {token && (
        <form
          className="flex w-full max-w-2xl flex-col gap-2"
          onSubmit={form.onSubmit(onSubmit)}
        >
          <TextInput
            required
            label={t("administration.user.actions.newPassword")}
            type="password"
            {...form.getInputProps("password")}
          />
          <TextInput
            required
            label={t("administration.user.actions.repeatPassword")}
            type="password"
            {...form.getInputProps("repeatPassword")}
          />
          <div className="mt-2 flex justify-between gap-2">
            <Button
              type="submit"
              loading={isPending}
              disabled={isPending}
              className="w-full"
            >
              {t("administration.user.actions.resetPasswordButton")}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
