import { Menu, Modal, Text, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconLogout } from "@tabler/icons-react";
import { IconUserEdit } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import avatar from "@/assets/avatar.svg";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { LanguagePicker } from "@/components/ui/language-picker";
import { useFetchMeQuery } from "@/hooks/api/useFetchMeQuery";
import { useAuth } from "@/hooks/useAuth";
import { UserDetails } from "@/widgets/UserDetails/UserDetails";

import { TenantSelect } from "../TenantSelect/TenantSelect";

const getInitials = (name: string) => {
  const [firstString, secondString] = name.split(" ");
  return `${firstString?.[0]}${secondString?.[0]}`;
};

const entityPagesPath = "/entity/*";
const ociQueryPage = "/entity/oci-queries";

export const AccountMenu = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { t } = useTranslation(["navbar", "entity"]);
  const entityMatch = useMatch(entityPagesPath);
  const ociQueryMatch = useMatch(ociQueryPage);
  const isOciQuery = !!ociQueryMatch;
  const isEntityPage = !!entityMatch;
  const isTenantSelect = !isEntityPage || isOciQuery;
  const { data: me } = useFetchMeQuery({});
  const { logout } = useAuth();

  function onLogout() {
    logout.mutate();
  }

  return (
    <div className="ml-auto flex items-center gap-2">
      {isTenantSelect && (
        <Label className="flex items-center gap-2">
          <span className="text-white">{t("userControl.tenant")}</span>
          <TenantSelect />
        </Label>
      )}
      <LanguagePicker />
      <Menu
        withinPortal
        position="bottom-start"
        transitionProps={{
          exitDuration: 300,
          transition: "pop-top-left",
          duration: 300,
        }}
      >
        <Menu.Target>
          <UnstyledButton className="flex items-center gap-2 rounded-sm text-white">
            <div className="flex items-center">
              <Avatar>
                <AvatarImage src={avatar} alt="avatar icon" />
                <AvatarFallback>
                  {me?.name ? getInitials(me.name) : ""}
                </AvatarFallback>
              </Avatar>
              <IconChevronDown className="size-4" stroke={1.5} />
            </div>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item leftSection={<IconUserEdit />} onClick={open}>
            <Text size="sm">{t("userControl.userControl")}</Text>
            <Text size="xs" c="dimmed">
              {t("userControl.userControlDescription")}
            </Text>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            leftSection={<IconLogout />}
            className="text-red-600"
            onClick={onLogout}
          >
            <Text size="sm">{t("logoutButton.logoutButton")}</Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={opened}
        onClose={close}
        title={t("entity:administration.user.actions.editUser")}
        size="lg"
      >
        <UserDetails onSettled={close} user={me} />
      </Modal>
    </div>
  );
};
