import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { RegionResponse } from "@/lib/api/dto";

export interface UseFetchRegionsQueryOptions<T>
  extends Omit<
    UseQueryOptions<RegionResponse[], Error, T>,
    "queryKey" | "queryFn"
  > {}

export const useFetchRegionsQuery = <T = RegionResponse[]>(
  options: UseFetchRegionsQueryOptions<T> = {}
) => {
  return useQuery<RegionResponse[], Error, T>({
    ...options,
    queryKey: ["regions"],
    queryFn: () =>
      Api.regions.list().then((res: { data: RegionResponse[] }) => res.data),
  });
};
