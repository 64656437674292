import { useAuth } from "@/hooks/useAuth";
import { Claim } from "@/lib/api/dto";
import { userHasPermission } from "@/lib/utils";

interface PermissionControlProps {
  /**
   * @default "any"
   */
  match?: "any" | "all";
  children: React.ReactNode;
  permission?: Claim | Claim[];
  fallbackContent?: React.ReactNode;
}

export const PermissionControl = ({
  match = "any",
  children,
  permission,
  fallbackContent = null,
}: PermissionControlProps) => {
  const {
    me: { data: me, isLoading },
  } = useAuth();

  if (!permission) {
    return children;
  }

  if (isLoading || !me) return null;

  return userHasPermission(me, permission, match) ? children : fallbackContent;
};
