import { TableMeta } from "@tanstack/table-core";
import { createMRTColumnHelper } from "mantine-react-table";

import { TruncatedText } from "@/components/ui/truncated-text";
import { CurrencyCode } from "@/constants/currencies";
import { SCNotificationResponse, SCNotificationValueType } from "@/lib/api/dto";
import { formatNumberAsCurrency, formatNumberAsPercentage } from "@/lib/utils";

const columnHelper = createMRTColumnHelper<SCNotificationResponse>();

export const columns = [
  columnHelper.accessor(({ subscription }) => subscription?.subscriptionID, {
    id: "subscription",
    header: "notification:sc:subscription.title",
  }),
  // TODO: ask BA to return commitment obj
  columnHelper.accessor(({ commitment }) => commitment?.id, {
    id: "commitment",
    header: "notification:sc:commitment.title",
    Cell: ({ cell }) => {
      const id = cell.getValue();

      return (
        !!id && <TruncatedText size="sm" leadingCharacters={10} text={id} />
      );
    },
  }),
  columnHelper.accessor("resources", {
    header: "notification:sc:resources",
    Cell: ({ cell }) => {
      const resources = cell.getValue();

      return (
        !!resources.length &&
        resources.map((resource) => {
          const text = resource.name ? resource.name : resource.ocid;
          return <TruncatedText size="sm" leadingCharacters={10} text={text} />;
        })
      );
    },
  }),
  columnHelper.accessor("value", {
    header: "notification:sc:value",
    Cell: ({ cell, row, table }) => {
      const value = cell.getValue();
      const {
        valueType,
        subscription: { currency },
      } = row.original;
      const { currencyLocale } = table.options
        .meta as TableMeta<SCNotificationResponse>;

      return formatValue({ valueType, currency, value, currencyLocale });
    },
  }),
];

export const formatValue = ({
  valueType,
  currency,
  value,
  currencyLocale,
}: {
  valueType: SCNotificationValueType;
  currency: string | null;
  value?: string | number;
  currencyLocale?: string;
}) => {
  if (!value) return;

  if (valueType === SCNotificationValueType.Percent) {
    return formatNumberAsPercentage(+value);
  }

  if (valueType === SCNotificationValueType.Value) {
    return formatNumberAsCurrency({
      value,
      currency: currency as CurrencyCode,
      options: { locale: currencyLocale },
    });
  }

  return formatNumberAsCurrency({
    value,
  });
};
