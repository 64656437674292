import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CreateSuperUserPayload } from "@/lib/api/dto";

interface UseCreateSuperAdminUserMutationOptions
  extends Omit<
    UseMutationOptions<unknown, Error, CreateSuperUserPayload>,
    "mutationFn"
  > {}

export const useCreateSuperAdminUserMutation = (
  options: UseCreateSuperAdminUserMutationOptions = {}
) => {
  return useMutation({ ...options, mutationFn: Api.users.createSuperUser });
};
