import { useMantineTheme } from "@mantine/core";
import { ChartData } from "chart.js";

import { useFetchReportMetricQuery } from "@/hooks/api/useFetchReportMetricQuery";
import { formatToDate } from "@/lib/dateUtils";
import { formatUnits } from "@/lib/utils";

import {
  MonitorMetricReportProps,
  MonitorMetricReportReport,
} from "./MonitorMetricReportReport";

export interface MonitorDiskBytesWrittenReportProps
  extends MonitorMetricReportProps {}

export const MonitorDiskBytesWrittenReport = ({
  tenant,
  endDate,
  startDate,
  statisticType,
  selectedResourceId,
  selectedCompartmentId,
}: MonitorDiskBytesWrittenReportProps) => {
  const theme = useMantineTheme();
  const {
    data = [],
    isFetching,
    isLoading,
  } = useFetchReportMetricQuery({
    datefrom: startDate && formatToDate(startDate, "ytd"),
    dateto: endDate && formatToDate(endDate, "ytd"),
    tenantocid: tenant! && tenant.ocid,
    compartmentocid: selectedCompartmentId,
    statistictype: statisticType,
    resource: selectedResourceId,
    metricname: "DiskBytesWritten",
  });

  const chartData: ChartData<"line"> = {
    labels: data?.map((data) => new Date(data.time)) || [],
    datasets: [
      {
        label: "Disk Bytes Written",
        data: data?.map((data) => data.value) || [],
        fill: false,
        borderColor: theme.other.chartColors.green,
        backgroundColor: theme.other.chartColors.green,
        borderWidth: 1
      },
    ],
  };

  const isDataEmpty =
    !data.length ||
    !tenant ||
    !startDate ||
    !endDate ||
    !selectedCompartmentId ||
    !selectedResourceId ||
    !statisticType;

  return (
    <MonitorMetricReportReport
      title="Disk Bytes Written"
      data={data}
      options={{
        scales: {
          y: {
            ticks: {
              callback: (value) => formatUnits(+value, { format: "bytes" }),
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) =>
                formatUnits(tooltipItem.parsed.y, { format: "bytes" }),
            },
          },
        },
      }}
      chartData={chartData}
      isLoading={isLoading}
      isFetching={isFetching}
      isDataEmpty={isDataEmpty}
    />
  );
};
