import { Button, Modal, Title, ActionIcon } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import { useDeleteStatisticTypeMutation } from "@/hooks/api/useDeleteStatisticTypeQueryMutation";
import { useFetchStatisticTypesQuery } from "@/hooks/api/useFetchStatisticTypesQuery";
import { useMRT } from "@/hooks/useMRT";
import { StatisticTypeResponse } from "@/lib/api/dto";

import { columns } from "./columns";
import { StatisticTypeDetails } from "../StatisticTypeDetails/StatisticTypeDetails";

export const StatisticTypeTable = () => {
  const { t } = useTranslation(["entity", "common"]);
  const { mutate, isPending } = useDeleteStatisticTypeMutation();
  const { data, isLoading, isFetching } = useFetchStatisticTypesQuery();

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedStatisticType, setSelectedStatisticType] =
    useState<StatisticTypeResponse | null>(null);
  const [statisticTypeToDelete, setStatisticTypeToDelete] =
    useState<StatisticTypeResponse | null>(null);

  const memoizedData = useMemo(() => data || [], [data]);

  const showConfirmDeleteModal = (query: StatisticTypeResponse) => {
    setStatisticTypeToDelete(query);
  };

  const handleCloseDeleteStatisticTypeQueryModal = () => {
    if (isPending) return;
    setStatisticTypeToDelete(null);
  };

  const handleDeleteStatisticTypeQuery = () => {
    if (!statisticTypeToDelete) return;

    mutate(statisticTypeToDelete.id, {
      onSuccess: () => {
        setStatisticTypeToDelete(null);
        handleCloseDeleteStatisticTypeQueryModal();
      },
    });
  };

  const handleSettled = () => {
    selectedStatisticType ? setSelectedStatisticType(null) : close();
  };

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <div className="flex gap-2">
        <ActinIconEdit onClick={() => setSelectedStatisticType(row.original)} />
        <ActionIcon
          color="red"
          onClick={() => showConfirmDeleteModal(row.original)}
        >
          <IconTrash size={18} />
        </ActionIcon>
      </div>
    ),
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={open}>
        {t("administration.statisticType.addStatisticType")}
      </Button>
    ),
  });

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <div className="mb-4 flex items-center justify-between">
        <Title size="h2">
          {t("administration.statisticType.statisticType")}
        </Title>
      </div>
      <MantineReactTable table={table} />

      <Modal
        opened={!!selectedStatisticType || opened}
        onClose={handleSettled}
        title={
          selectedStatisticType ?
            t("administration.statisticType.editStatisticType")
          : t("administration.statisticType.addNewStatisticType")
        }
        size="lg"
      >
        <StatisticTypeDetails
          onSettled={handleSettled}
          statisticType={selectedStatisticType}
        />
      </Modal>

      <Modal
        opened={!!statisticTypeToDelete}
        onClose={handleCloseDeleteStatisticTypeQueryModal}
        title={t("administration.statisticType.deleteStatisticTypeModal.title")}
        classNames={{ body: "flex justify-between gap-2" }}
      >
        <Button
          variant="outline"
          disabled={isPending}
          onClick={handleCloseDeleteStatisticTypeQueryModal}
        >
          {t("common:userActions.cancel")}
        </Button>
        <Button
          onClick={handleDeleteStatisticTypeQuery}
          loading={isPending}
          disabled={isPending}
        >
          {t("common:userActions.confirm")}
        </Button>
      </Modal>
    </div>
  );
};
