import { useMantineTheme } from "@mantine/core";
import { TableMeta } from "@tanstack/table-core";
import { ChartDataset } from "chart.js";
import { format } from "date-fns";
import { createMRTColumnHelper } from "mantine-react-table";
import { memo, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { ChartContainer } from "@/components/ui/chart-container";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchBillingCostReportQuery } from "@/hooks/api/useFetchBillingCostReportQuery";
import { useFetchProductServiceQuery } from "@/hooks/api/useFetchProductServiceQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useDateFnsLocale } from "@/hooks/useDateFnsLocale";
import { BillingCostReportResponse } from "@/lib/api/dto";
import { monthYearFormat } from "@/lib/dateUtils";
import { formatNumberAsCurrency, getColor } from "@/lib/utils";

export interface BillingCostReportProps {
  datefrom: Date | null;
  dateto: Date | null;
  tenantocid: string;
  services?: string[];
  subscriptionid?: string | number;
  costfrom?: number;
  costto?: number;
  withzerocost?: boolean;
}

const columnHelper = createMRTColumnHelper<BillingCostReportResponse>();

const columns = [
  columnHelper.display({
    id: "month",
    header: "billing:report.month",
    Cell: ({ row, table }) =>
      format(
        new Date(+row.original.year, +row.original.month - 1, 1),
        monthYearFormat,
        {
          locale: (table.options.meta as TableMeta<BillingCostReportResponse>)
            ?.dateFnsLocale,
        },
      ),
  }),
  columnHelper.accessor("serviceName", {
    header: "billing:report.servis",
    filterVariant: "select",
    mantineFilterSelectProps: ({ table }) => ({
      data: (table.options.meta as TableMeta<BillingCostReportResponse>)
        .selectOptions?.productServiceOptions,
    }),
  }),
  columnHelper.accessor("resource", { header: "billing:report.resource" }),
  columnHelper.accessor("cost", {
    header: "billing:report.cost",
    filterVariant: "range",
    Cell: ({ cell, table }) =>
      formatNumberAsCurrency({
        value: cell.getValue(),
        currency: (table.options.meta as TableMeta<BillingCostReportResponse>)
          ?.currency,
        options: {
          locale: (table.options.meta as TableMeta<BillingCostReportResponse>)
            ?.currencyLocale,
        },
      }),
  }),
];

const filterData = (
  data?: BillingCostReportResponse,
  costfrom?: number,
  costto?: number,
) =>
  data ?
    (!costfrom || costfrom <= data.cost) && (!costto || costto >= data.cost)
  : false;

export const BillingCostReport = memo(
  ({
    datefrom,
    dateto,
    tenantocid,
    services,
    subscriptionid,
    costfrom,
    costto,
    withzerocost,
  }: BillingCostReportProps) => {
    const theme = useMantineTheme();
    const { tenant } = useTenantContext();
    const dateFnsLocale = useDateFnsLocale();
    const currencyLocale = useCurrencyLocale();
    const currency = tenant?.currentSubscription
      ?.currency as CurrencyCode | null;

    const { t } = useTranslation("billing");

    const { dates, filteredQueries, groupedQueries, isFetching, isLoading } =
      useFetchBillingCostReportQuery({
        params: {
          datefrom,
          dateto,
          tenantocid,
          services,
          subscriptionid,
          withzerocost,
        },
      });

    const { data: productServices = [] } = useFetchProductServiceQuery();

    const chartData = useMemo(() => {
      const datasets = new Map<string, ChartDataset<"bar">>();

      let datasetCounter = 0;
      groupedQueries
        .filter((item) => filterData(item, costfrom, costto))
        .forEach((item) => {
          if (item) {
            const key = `${item.tenatOcid}-${item.productService}`;
            const dataIndex = dates.findIndex(
              (date) => date.month === +item.month && date.year === +item.year,
            );
            if (datasets.has(key)) {
              const dataset = datasets.get(key);
              dataset!.data![dataIndex] = item.cost;
            } else {
              const data = Array.from<number>({ length: dates.length }).fill(0);
              data[dataIndex] = item.cost;

              datasets.set(key, {
                label: item.serviceName,
                data,
                borderColor: getColor(
                  theme.other.chartColorsArray,
                  datasetCounter,
                ),
                backgroundColor: getColor(
                  theme.other.chartColorsArray,
                  datasetCounter++,
                  true,
                ),
              });
            }
          }
        });

      return {
        labels: dates.map(({ month, year }) =>
          format(new Date(year, month - 1, 1), monthYearFormat, {
            locale: dateFnsLocale,
          }),
        ),
        datasets: Array.from(datasets.values()),
      };
    }, [
      groupedQueries,
      dates,
      costfrom,
      costto,
      theme.other.chartColorsArray,
      dateFnsLocale,
    ]);

    const tableData = useMemo(() => {
      return filteredQueries?.filter((item) =>
        filterData(item, costfrom, costto),
      );
    }, [filteredQueries, costfrom, costto]);

    const productServiceOptions = useMemo(() => {
      return productServices
        .filter(
          (productService) =>
            !services?.length ||
            services.includes(productService.productServiceId),
        )
        .map((productService) => ({
          value: productService.name,
          label: productService.name,
        }));
    }, [productServices, services]);

    return (
      <ChartContainer
        enableGridDisplay
        title={`${t("report.consumptionReport")} - ${tenant?.name}`}
        loading={isLoading}
        fetching={isFetching}
        tableOptions={{
          columns,
          data: tableData as BillingCostReportResponse[],
          enableSorting: true,
          meta: {
            currency,
            dateFnsLocale,
            currencyLocale,
            selectOptions: {
              productServiceOptions,
            },
          },
          initialState: { density: "xs" },
        }}
      >
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            scales: {
              y: {
                ticks: {
                  min: 0,
                  max: costto,
                  callback: (tickValue) =>
                    formatNumberAsCurrency({
                      value: tickValue,
                      currency,
                      options: { locale: currencyLocale },
                    }),
                },
              },
            },
            plugins: {
              legend: {
                display: true,
              },
              tooltip: {
                mode: "point",
                callbacks: {
                  label: (tooltipItem) =>
                    `${tooltipItem.dataset.label}: ${formatNumberAsCurrency({
                      value: tooltipItem.parsed.y,
                      currency,
                      options: { locale: currencyLocale },
                    })}`,
                },
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: "xy",
                },
                zoom: {
                  wheel: {
                    modifierKey: "ctrl",
                    enabled: true,
                  },
                  mode: "xy",
                },
                limits: {
                  y: { min: 0 },
                },
              },
            },
          }}
        />
      </ChartContainer>
    );
  },
);

BillingCostReport.displayName = "BillingCostReport";
