import { createContext, useContext, useEffect, useState } from "react";

import { LocalStorageKeys } from "@/constants";
import { useFetchOrganizationsQuery } from "@/hooks/api/useFetchOrganizationsQuery";
import { useAuth } from "@/hooks/useAuth";
import { localStorageActions } from "@/hooks/useLocalStorage";
import { Organization } from "@/lib/api/dto";

interface OrganizationContextType {
  organization: Organization | null;
  organizations?: Organization[];
  setOrganization: (organization: Organization | Organization["id"]) => void;
}

export const OrganizationContext = createContext<OrganizationContextType>(
  {} as OrganizationContextType,
);

export const OrganizationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isLoggedIn } = useAuth();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const { data } = useFetchOrganizationsQuery({
    enabled: isLoggedIn,
  });

  const organizations = data?.map((organization) => ({
    ...organization,
    phone: organization.phone.join(", "),
  }));

  const handleSetOrganization = (
    organizationProp: Organization | Organization["id"],
  ) => {
    const organization =
      typeof organizationProp === "string" ?
        organizations?.find((t) => t.id === organizationProp)
      : organizationProp;
    if (organization) {
      setOrganization(organization);
      localStorageActions.setItem(
        LocalStorageKeys.SelectedOrganization,
        organization.id,
      );
    }
  };

  useEffect(() => {
    const selectedOrganizationId = localStorageActions.getItem(
      LocalStorageKeys.SelectedOrganization,
    );
    if (organizations && organizations.length > 0 && !organization) {
      setOrganization(
        organizations.find((t) => t.id === selectedOrganizationId) ||
          organizations[0],
      );
    }
  }, [organization, organizations]);

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        organizations,
        setOrganization: handleSetOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useOrganizationContext = () => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error(
      "useOrganizationContext must be used within a OrganizationContextProvider",
    );
  }
  return context;
};
