import axios, { isAxiosError } from "axios";
import memoize from "p-memoize";

import { LocalStorageKeys } from "@/constants";
import { ApiRoutes } from "@/constants/routes";
import { useAuthStore } from "@/hooks/useAuth";
import { localStorageActions } from "@/hooks/useLocalStorage";

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

const nonProtectedRoutes = [
  ApiRoutes.Auth.Login(),
  ApiRoutes.Auth.RefreshToken(),
];

const refreshTokenAction = memoize((refreshToken: string) =>
  api.post<{
    accessToken: string;
    refreshToken: string;
  }>(ApiRoutes.Auth.RefreshToken(), { refreshToken }),
);

api.interceptors.request.use((config) => {
  const token = localStorageActions.getItem(LocalStorageKeys.AccessToken);

  if (token && !nonProtectedRoutes.includes(config.url ?? "")) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    // persist tokens on login
    if (
      response.config.url === ApiRoutes.Auth.Login() &&
      response?.status === 200
    ) {
      const { accessToken, refreshToken } = response.data;

      useAuthStore.getState().setTokens({ accessToken, refreshToken });
    }

    return response;
  },
  async (error) => {
    if (isAxiosError(error)) {
      // users token is invalid
      if (error.response?.status === 401) {
        const rToken = localStorageActions.getItem(
          LocalStorageKeys.RefreshToken,
        );

        if (!rToken) {
          useAuthStore.getState().clearTokens();
          return Promise.reject(error);
        }

        try {
          const {
            data: { accessToken, refreshToken },
          } = await refreshTokenAction(rToken);

          useAuthStore.getState().setAccessToken(accessToken);
          useAuthStore.getState().setRefreshToken(refreshToken);

          if (!error.config) {
            return Promise.reject(error);
          }
          return api.request(error.config);
        } catch (e) {
          if (isAxiosError(e) && e.response?.status === 403) {
            useAuthStore.getState().clearTokens();
          }

          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(error);
  },
);
