import { UTCDate } from "@date-fns/utc";
import { useMantineTheme } from "@mantine/core";
import { TableMeta } from "@tanstack/table-core";
import {
  Chart,
  ChartData,
  ChartDataset,
  ChartOptions,
  LegendItem,
  TimeScaleOptions,
} from "chart.js";
import { AnnotationOptions } from "chartjs-plugin-annotation";
import format from "date-fns/format";
import { createMRTColumnHelper } from "mantine-react-table";
import { useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { ChartContainer } from "@/components/ui/chart-container";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchFourthDiagramQuery } from "@/hooks/api/useFetchFourthDiagramQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useDateFnsLocale } from "@/hooks/useDateFnsLocale";
import { FourthDiagramResponse } from "@/lib/api/dto";
import { monthYearFormat, yearMonthFormat } from "@/lib/dateUtils";
import { cn, formatNumberAsCurrency, getColor } from "@/lib/utils";

interface DashboardFourthDiagramReportProps {
  subscriptionid?: string | number;
  className?: string;
}

const columnHelper = createMRTColumnHelper<FourthDiagramResponse>();

const columns = [
  columnHelper.accessor("ocidtime", {
    header: "dashboard:common.month",
    Cell: ({ cell, table }) =>
      format(new Date(cell.getValue()), monthYearFormat, {
        locale: (table.options.meta as TableMeta<FourthDiagramResponse>)
          ?.dateFnsLocale,
      }),
  }),
  columnHelper.accessor("name", {
    header: "dashboard:common.tenant",
    enableGrouping: false,
  }),
  columnHelper.accessor("fullsum", {
    header: "dashboard:common.consumption",
    Cell: ({ cell, table }) =>
      formatNumberAsCurrency({
        value: cell.getValue(),
        currency: (table.options.meta as TableMeta<FourthDiagramResponse>)
          ?.currency,
        options: {
          locale: (table.options.meta as TableMeta<FourthDiagramResponse>)
            ?.currencyLocale,
        },
      }),
    enableGrouping: false,
  }),
];

export const DashboardFourthDiagramReport = ({
  subscriptionid,
  className,
}: DashboardFourthDiagramReportProps) => {
  const { t } = useTranslation("dashboard");
  const { tenant } = useTenantContext();
  const [filterDatasets, setFilterDatasets] = useState<string[]>([]);
  const [datasetsAreHidden, setDatasetsAreHidden] = useState(false);
  const chartRef = useRef<Chart<"bar"> | null>(null);
  const theme = useMantineTheme();
  const dateFnsLocale = useDateFnsLocale();
  const currencyLocale = useCurrencyLocale();
  const {
    data = [],
    isLoading,
    isFetching,
  } = useFetchFourthDiagramQuery({
    params: {
      subscriptionid,
    },
  });

  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;

  const monthsArray = useMemo(
    () =>
      data
        .reduce<string[]>((acc, curr) => {
          if (!acc.includes(curr.ocidtime)) acc.push(curr.ocidtime);
          return acc;
        }, [])
        .map((d) => new Date(d)),
    [data],
  );

  const { average, chartData } = useMemo(() => {
    if (!data) {
      return { average: null, chartData: { datasets: [] } as ChartData<"bar"> };
    }

    const labels = monthsArray.map((date) => format(date, yearMonthFormat));

    const datasets = new Map<string, ChartDataset<"bar">>();

    let datasetCounter = 0;
    let totalRecords = 0;

    data.forEach((item) => {
      const indexOfLabel = labels.indexOf(item.ocidtime);

      if (item.fullsum && !filterDatasets.includes(item.ocid)) totalRecords++;

      if (datasets.has(item.ocid)) {
        const dataset = datasets.get(item.ocid)!;

        dataset.data[indexOfLabel] = item.fullsum;

        return;
      }

      datasets.set(item.ocid, {
        label: item.name,
        data: labels.map((_, i) => (i === indexOfLabel ? item.fullsum : 0)),
        borderColor: getColor(theme.other.chartColorsArray, datasetCounter),
        backgroundColor: getColor(
          theme.other.chartColorsArray,
          datasetCounter++,
          true,
        ),
        stack: item.ocid,
        borderWidth: 1,
        hidden: filterDatasets.includes(item.ocid),
      });
    });

    const grandTotal = data
      .filter((item) => !filterDatasets.includes(item.ocid))
      .reduce((acc, curr) => acc + curr.fullsum, 0);

    return {
      average: grandTotal / totalRecords,
      chartData: {
        labels: labels.map((label) => new UTCDate(label).getTime()),
        datasets: Array.from(datasets.values()),
      } as ChartData<"bar">,
    };
  }, [data, filterDatasets, monthsArray, theme.other.chartColorsArray]);

  const baseOptions = useMemo<ChartOptions<"bar">>(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: { top: 0, right: 0, left: 0, bottom: 0 },
      },
      scales: {
        x: {
          type: "time",
          stacked: true,
          time: {
            tooltipFormat: monthYearFormat,
            unit: "month",
          },
          ticks: {
            callback(tickValue) {
              return format(+tickValue, monthYearFormat, {
                locale: dateFnsLocale,
              });
            },
          },
        },
        y: {
          suggestedMax: chartData.datasets.reduce(
            (acc, dataset) =>
              Math.max(acc, ...dataset.data.map((d) => (d as number) * 1.05)),
            0,
          ),
          ticks: {
            callback: (tickValue) =>
              formatNumberAsCurrency({
                value: tickValue,
                currency,
                options: { locale: currencyLocale },
              }),
          },
          stacked: false,
          beginAtZero: true,
          min: 0,
        },
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: { enabled: true, modifierKey: "ctrl" },
            pinch: { enabled: true },
            mode: "x",
          },
        },
      },
    }),
    [chartData.datasets, currency, currencyLocale, dateFnsLocale],
  );

  const handleDatasetsToggle = (hidden: boolean) => {
    if (!chartRef?.current) return;

    setFilterDatasets(
      hidden ?
        chartRef?.current.data.datasets.map((dataset) => dataset.stack!)
      : [],
    );
    setDatasetsAreHidden(hidden);
  };

  const handleLegendClick = (legendItem: LegendItem) => {
    const { datasetIndex, hidden } = legendItem;
    if (!chartRef?.current || datasetIndex === undefined) return;

    const dataset = chartRef?.current.data.datasets[datasetIndex];

    setFilterDatasets((prevState) => {
      if (!dataset.stack) return prevState;
      if (!hidden) return [...prevState, dataset.stack];
      return prevState.filter((stack) => stack !== dataset.stack);
    });
    setDatasetsAreHidden(
      chartRef?.current.data.datasets.every((dataset) => dataset.hidden),
    );
  };

  return (
    <ChartContainer
      className={cn(
        "flex-shrink-1 relative w-auto self-start shadow-md",
        className,
      )}
      title={t("consumptionPerTenant")}
      bordered
      enableGridDisplay
      tableOptions={{
        columns,
        data: data.filter((d) => !filterDatasets.includes(d.ocid)),
        enableSorting: true,
        enableGrouping: true,
        enableColumnDragging: false,
        paginateExpandedRows: false,
        enableColumnActions: true,
        initialState: {
          sorting: [{ id: "ocidtime", desc: false }],
          grouping: ["ocidtime"],
          expanded: true,
        },
        meta: { currency, dateFnsLocale, currencyLocale },
      }}
      loading={isLoading}
      fetching={isFetching}
      isDataEmpty={!data?.length}
      datasetsAreHidden={datasetsAreHidden}
      onDatasetsToggle={() => handleDatasetsToggle(!datasetsAreHidden)}
    >
      <Bar
        className="cursor-grab active:cursor-grabbing"
        data={chartData}
        ref={chartRef}
        options={{
          ...baseOptions,
          scales: {
            ...baseOptions.scales,
            x: {
              ...(baseOptions.scales!.x as TimeScaleOptions),
              clip: false,
              ticks: {
                ...baseOptions.scales!.x!.ticks,
                includeBounds: true,
              },
              bounds: "data",
            },
          },
          plugins: {
            ...baseOptions.plugins,
            legend: {
              onClick: (_e, legendItem) => handleLegendClick(legendItem),
            },
            tooltip: {
              itemSort: (a, b) => b.parsed.y - a.parsed.y,
              xAlign: "center",
              yAlign: "bottom",
              callbacks: {
                title: (items) =>
                  format(new Date(items[0].parsed.x), monthYearFormat, {
                    locale: dateFnsLocale,
                  }),
                label: (tooltipItem) =>
                  `${tooltipItem.dataset.label}: ${formatNumberAsCurrency({ value: tooltipItem.parsed.y, currency, options: { locale: currencyLocale } })}`,
              },
            },
            annotation: {
              annotations: {
                average:
                  average ?
                    ({
                      type: "line",
                      yMin: average,
                      yMax: average,
                      borderColor: theme.other.chartColors.red,
                      borderWidth: 1,
                      label: {
                        content: t("common.averageAmount", {
                          value: formatNumberAsCurrency({
                            value: average,
                            currency,
                            options: { locale: currencyLocale },
                          }),
                        }),
                        display: true,
                        position: "end",
                        backgroundColor: theme.other.chartColors.red,
                        font: {
                          size: 12,
                        },
                        yAdjust: -5,
                      },
                    } as AnnotationOptions)
                  : void 0,
              },
            },
          },
        }}
      />
    </ChartContainer>
  );
};
