import { Title, Modal, Button, TextInput, Paper } from "@mantine/core";
import { zodResolver } from "@mantine/form";
import { MantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import { useFetchCodebookResourcesQuery } from "@/hooks/api/useFetchCodebookResourcesQuery";
import { useUpdateResourceMutation } from "@/hooks/api/useUpdateResourceMutation";
import { useMRT } from "@/hooks/useMRT";
import { useTForm } from "@/hooks/useTForm";

import { columns } from "./columns";

const codebookResourceSchema = z.object({
  id: z.string(),
  ocid: z.string(),
  name: z.string().min(1, "form:field.required"),
  description: z.string(),
  organizationId: z.string(),
  tenantId: z.string(),
});

type CodebookResourceValues = z.infer<typeof codebookResourceSchema>;

export const CodebookResourcesTable = () => {
  const { t } = useTranslation("entity");

  const form = useTForm<CodebookResourceValues>({
    initialValues: {
      id: "",
      ocid: "",
      name: "",
      description: "",
      organizationId: "",
      tenantId: "",
    },
    validate: zodResolver(codebookResourceSchema),
  });

  const { mutate, isPending } = useUpdateResourceMutation();

  const { data, isLoading, isFetching } = useFetchCodebookResourcesQuery();
  const memoizedData = useMemo(() => data || [], [data]);

  const onSubmit = (data: CodebookResourceValues) => {
    if (isPending) return;
    mutate(data, {
      onSuccess: () => {
        form.reset();
      },
    });
  };

  const handleCloseModal = () => {
    if (isPending) return;
    form.reset();
  };

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <ActinIconEdit
        onClick={() =>
          form.setValues({
            id: row.original.id,
            ocid: row.original.ocid,
            name: row.original.name || "",
            description: row.original.description || "",
            organizationId: row.original.organization.id,
            tenantId: row.original.tenant.tenantId,
          })
        }
      />
    ),
  });

  return (
    <div className="flex flex-1 flex-col overflow-auto">
      <Title order={2} className="mb-4">
        {t("administration.resource.changeResource")}
      </Title>
      <MantineReactTable table={table} />
      <Modal
        opened={!!form.values.id}
        onClose={handleCloseModal}
        title={t("administration.resource.changeResource")}
        size="md"
      >
        <Paper
          component="form"
          className="flex flex-col gap-2"
          onSubmit={form.onSubmit(onSubmit)}
        >
          <TextInput
            withAsterisk
            label={t("administration.resource.name")}
            {...form.getInputProps("name")}
          />

          <TextInput
            label={t("administration.resource.description")}
            {...form.getInputProps("description")}
          />

          <div className="mt-2 flex justify-between gap-2">
            <Button
              onClick={handleCloseModal}
              variant="light"
              disabled={isPending}
            >
              {t("administration.resource.cancel")}
            </Button>
            <Button loading={isPending} disabled={isPending} type="submit">
              {t("administration.resource.save")}
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};
