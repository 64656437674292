import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";

import { compartmentBudgetNotificationKeys } from "./queryKeys";

interface UseDeleteCompartmentBudgetNotificationMutationOptions
  extends Omit<UseMutationOptions<unknown, Error, string>, "mutationFn"> {}

export const useDeleteCompartmentBudgetNotificationMutation = ({
  onSuccess,
  onError,
  onSettled,
  ...options
}: UseDeleteCompartmentBudgetNotificationMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (id: string) => Api.compartmentBudgetNotifications.delete(id),
    onSuccess: (...args) => {
      successToast.delete();
      onSuccess?.(...args);
    },
    onError: (...args) => {
      errorToast.delete();
      onError?.(...args);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: compartmentBudgetNotificationKeys.all(),
      });
      onSettled?.(...args);
    },
  });
};
