import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CreateSystemUserPayload } from "@/lib/api/dto";

interface UseCreateSystemUserMutationOptions
  extends Omit<
    UseMutationOptions<unknown, Error, CreateSystemUserPayload>,
    "mutationFn"
  > {}

export const useCreateSystemUserMutation = (
  options: UseCreateSystemUserMutationOptions = {}
) => {
  return useMutation({ ...options, mutationFn: Api.users.createSystemUser });
};
