import { Paper, TextInput, Button } from "@mantine/core";
import { isAxiosError } from "axios";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { toast } from "@/components/ui/use-toast";
import { useCreateOrUpdateRegionMutation } from "@/hooks/api/useCreateOrUpdateRegionMutation";
import { useTForm } from "@/hooks/useTForm";
import { RegionResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface RegionDetailsProps {
  region?: RegionResponse;
  onSettled?: () => void;
  className?: string;
}

const regionSchema = z.object({
  name: z.string().min(1, "form:field.required"),
  identifier: z.string(),
  location: z.string(),
  regionKey: z
    .string()
    .max(3, "entity:administration.region.regionKeyMaxLength"),
  realmKey: z.string().max(4, "entity:administration.region.realmKeyMaxLength"),
  availabilityDomains: z.coerce.number().default(0),
});

type FormData = z.infer<typeof regionSchema>;

const defaultValues: FormData = {
  name: "",
  identifier: "",
  location: "",
  regionKey: "",
  realmKey: "",
  availabilityDomains: 0,
};

const mapRegionToFormData = (region?: RegionResponse): FormData => {
  if (!region) return defaultValues;

  return {
    name: region.name ?? defaultValues.name,
    identifier: region.identifier ?? defaultValues.identifier,
    location: region.location ?? defaultValues.location,
    regionKey: region.regionKey ?? defaultValues.regionKey,
    realmKey: region.realmKey ?? defaultValues.realmKey,
    availabilityDomains:
      region.availabilityDomains ?? defaultValues.availabilityDomains,
  };
};

export const RegionDetails = ({
  region,
  onSettled,
  className,
}: RegionDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const { mutate, isPending } = useCreateOrUpdateRegionMutation({
    regionId: region?.id,
  });

  const form = useTForm<FormData>({
    initialValues: mapRegionToFormData(region),
    validate: zodResolver(regionSchema),
  });

  const onSubmit = (data: FormData) => {
    mutate(data, {
      onSuccess: (response) => {
        toast({
          title: t("administration.region.saveSuccess"),
        });
        if (region?.id) {
          form.setInitialValues(mapRegionToFormData(response));
        }
        form.reset();
      },
      onError: (error) => {
        toast({
          title: t("administration.common.error"),
          description:
            (isAxiosError(error) && error.response?.data?.message) ||
            t("administration.region.saveError"),
        });
      },
      onSettled,
    });
  };

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("administration.region.name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        label={t("administration.region.identifier")}
        {...form.getInputProps("identifier")}
      />
      <TextInput
        label={t("administration.region.location")}
        {...form.getInputProps("location")}
      />
      <TextInput
        label={t("administration.region.regionKey")}
        {...form.getInputProps("regionKey")}
      />
      <TextInput
        label={t("administration.region.realmKey")}
        {...form.getInputProps("realmKey")}
      />
      <TextInput
        label={t("administration.region.availabilityDomains")}
        {...form.getInputProps("availabilityDomains")}
        type="number"
      />
      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" loading={isPending} disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};
