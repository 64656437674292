import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CreateCityPayload, CityResponse } from "@/lib/api/dto";

import { cityKeys } from "./queryKeys";

interface UseCreateOrUpdateCityMutationOptions
  extends UseMutationOptions<CityResponse, Error, CreateCityPayload> {
  id?: string;
}

export const useCreateOrUpdateCityMutation = ({
  id,
  onSettled,
  ...options
}: UseCreateOrUpdateCityMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (data: CreateCityPayload) => {
      if (id) {
        return Api.cities.update(id, data).then((res) => res.data);
      }

      return Api.cities.create(data).then((res) => res.data);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({ queryKey: cityKeys.all() });
      onSettled?.(...args);
    },
  });
};
