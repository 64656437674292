import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Spinner } from "@/components/ui/spinner";
import { useVerifyEmailMutation } from "@/hooks/api/useVerifyEmailMutation";

export const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({ token: "" });
  const token = searchParams.get("token");
  const { mutate, isPending, isIdle, isSuccess, isError } =
    useVerifyEmailMutation();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (isIdle && token) {
      mutate(token, {
        onSuccess: () => {
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        },
      });
    }
  }, [token, mutate, isIdle, navigate]);

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      {isIdle && !token && (
        <>
          <p className="text-2xl">{t("invalidToken")}</p>
        </>
      )}
      {isPending ||
        (isIdle && !!token && (
          <>
            <Spinner size={128} />
            <p className="mt-4 text-2xl">{t("verifyingEmail")}</p>
          </>
        ))}
      {isSuccess && (
        <>
          <p className="text-2xl">{t("emailVerified")}</p>
        </>
      )}
      {isError && (
        <>
          <p className="text-2xl"> {t("emailVerifiedError")}</p>
        </>
      )}
    </div>
  );
};
