import { Button, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import { useFetchOrganizationsQuery } from "@/hooks/api/useFetchOrganizationsQuery";
import { useMRT } from "@/hooks/useMRT";
import { OrganizationResponse } from "@/lib/api/dto";
import { OrganizationDetails } from "@/widgets/OrganizationDetails/OrganizationDetails";

import { columns } from "./columns";

export const OrganizationsTable = () => {
  const { t } = useTranslation('entity')
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationResponse | undefined>()
  const { data, isLoading, isFetching } = useFetchOrganizationsQuery();

  const memoizedData = useMemo(() => data || [], [data]);

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <ActinIconEdit onClick={() => setSelectedOrganization(row.original)} />
    ),
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={open}>
        {t('administration.organization.addOrganization')}
      </Button>
    ),
  });

  const handleSettled = () => {
    selectedOrganization ? setSelectedOrganization(undefined) : close();
  }

  return (
    <div className="flex flex-col flex-1 col-span-2 overflow-auto lg:col-span-1">
      <div className="flex justify-between items-center mb-4">
        <Title size="h2">{t('administration.organization.organizations')}</Title>
      </div>
      <MantineReactTable table={table} />
      <Modal
        opened={!!selectedOrganization || opened}
        onClose={handleSettled}
        title={selectedOrganization ? t('administration.organization.editOrganization') : t('administration.organization.addOrganization')}
        size="lg"
      >
        <OrganizationDetails onSettled={handleSettled} organization={selectedOrganization} />
      </Modal>
    </div>
  );
};
