import { createMRTColumnHelper } from "mantine-react-table";

import { AuthorityResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<AuthorityResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:systemCodebook.authority.name",
  }),
];
