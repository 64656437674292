import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";
import {
  CreateSCNotificationPayload,
  SCNotificationResponse,
} from "@/lib/api/dto";

import { scNotificationKeys } from "./queryKeys";

interface UseCreateSCNotificationMutationOptions<T>
  extends Omit<
    UseMutationOptions<
      SCNotificationResponse,
      Error,
      CreateSCNotificationPayload,
      T
    >,
    "mutationKey" | "mutationFn"
  > {}

export const useCreateSCNotificationMutation = <T = SCNotificationResponse>({
  onSettled,
  ...options
}: UseCreateSCNotificationMutationOptions<T> = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (payload: CreateSCNotificationPayload) =>
      Api.scNotifications.create(payload).then((res) => res.data),
    onSettled(...args) {
      queryClient.invalidateQueries({ queryKey: scNotificationKeys.all() });
      onSettled?.(...args);
    },
  });
};
