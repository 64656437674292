import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";
import {
  CreateMetricNamespacePayload,
  MetricNamespaceResponse,
} from "@/lib/api/dto";

import { metricNamespaceKeys } from "./queryKeys";

interface UseCreateOrUpdateMetricNamespaceMutationOptions
  extends UseMutationOptions<
    MetricNamespaceResponse,
    Error,
    CreateMetricNamespacePayload
  > {
  metricNamespaceId?: string;
}

export const useCreateOrUpdateNamespaceMutation = ({
  metricNamespaceId,
  onError,
  onSuccess,
  onSettled,
  ...options
}: UseCreateOrUpdateMetricNamespaceMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (data) => {
      if (metricNamespaceId) {
        return Api.metricNamespace
          .update(metricNamespaceId, data)
          .then((res) => res.data);
      }

      return Api.metricNamespace.create(data).then((res) => res.data);
    },
    onSuccess: (...args) => {
      if (metricNamespaceId) {
        successToast.update();
      } else {
        successToast.create();
      }

      onSuccess?.(...args);
    },
    onError: (...args) => {
      if (metricNamespaceId) {
        errorToast.update();
      } else {
        errorToast.create();
      }

      onError?.(...args);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({ queryKey: metricNamespaceKeys.all() });
      onSettled?.(...args);
    },
  });
};
