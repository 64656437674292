import { Loader } from "@mantine/core";
import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "@/hooks/useAuth";

export const PrivatePage = ({ children }: { children: ReactNode }) => {
  const { accessToken, me: { isLoading: isMeLoading } = {} } = useAuth();
  const location = useLocation();

  if (isMeLoading) {
    return (
      <div className="flex items-center justify-center flex-1">
        <Loader size={64} />
      </div>
    );
  }

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
