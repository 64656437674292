import { useTranslation } from "react-i18next";

import { Languages } from "@/constants";

/**
 * Map of language to currency locale
 */
export const currencyLocaleMap: Record<Languages, string> = {
  cyril: "sr-Cyrl",
  latin: "sr-Latn",
};

/**
 * Returns the currency locale based on the current language
 *
 * @returns `string`
 */
export const useCurrencyLocale = () => {
  const { i18n } = useTranslation();

  return currencyLocaleMap[i18n.language as Languages];
};
