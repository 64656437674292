import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { ResourceTypes } from "@/lib/api/dto";

import { resourceTypeKeys } from "./queryKeys";

interface UseFetchStatesQueryOptions<TSelectData = ResourceTypes[]>
  extends UseQueryOptions<ResourceTypes[], Error, TSelectData> {}

export const useFetchResourceTypesQuery = (
  options?: UseFetchStatesQueryOptions
) => {
  return useQuery({
    queryKey: resourceTypeKeys.all(),
    queryFn: () => Api.resourceTypes.list().then((res) => res.data),
    ...options,
  });
};
