import { Button, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import { useFetchCompartmentsQuery } from "@/hooks/api/useFetchCompartmentsQuery";
import { useMRT } from "@/hooks/useMRT";
import { CompartmentResponse } from "@/lib/api/dto";
import { CompartmentDetails } from "@/widgets/CompartmentDetails/CompartmentDetails";

import { columns } from "./columns";

export const CompartmentsTable = () => {
  const { t } = useTranslation("entity");
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCompartment, setSelectedCompartment] =
    useState<CompartmentResponse | null>(null);
  const { data, isLoading, isFetching } = useFetchCompartmentsQuery();

  const memoizedData = useMemo(() => data || [], [data]);

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <ActinIconEdit onClick={() => setSelectedCompartment(row.original)} />
    ),
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={open}>
        {t("administration.compartment.addCompartment")}
      </Button>
    ),
  });

  const handleSettled = () => {
    selectedCompartment ? setSelectedCompartment(null) : close();
  };

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <div className="mb-4 flex items-center justify-between">
        <Title size="h2">{t("administration.compartment.compartments")}</Title>
      </div>
      <MantineReactTable table={table} />
      <Modal
        opened={!!selectedCompartment || opened}
        onClose={handleSettled}
        title={
          selectedCompartment ?
            t("administration.compartment.editCompartment")
          : t("administration.compartment.addCompartment")
        }
        size="lg"
      >
        <CompartmentDetails
          compartment={selectedCompartment}
          onSettled={handleSettled}
        />
      </Modal>
    </div>
  );
};
