import { Select, SelectProps } from "@mantine/core";
import { KeyboardEventHandler, ReactElement, useRef } from "react";

import { cn } from "@/lib/utils";

interface DetailedSelectProps
  extends Omit<SelectProps, "variant" | "withErrorStyles" | "content"> {
  content?: ReactElement;
}

const DetailedSelect = ({
  className,
  classNames,
  value,
  error,
  content,
  ...rest
}: DetailedSelectProps) => {
  const selectRef = useRef<HTMLInputElement>(null);

  const showContent = !!(value && content);

  const handleKeyUp: KeyboardEventHandler = ({ key }) => {
    // The space or spacebar key is encoded as " ".
    if (key === "Enter" || key === " ") {
      selectRef?.current?.dispatchEvent(
        new Event("click", { cancelable: true, bubbles: true }),
      );
    }
  };

  return (
    <div
      className={cn(
        "flex flex-col rounded-mantine border border-mantine-gray-4 outline-none focus:border-red-500",
        className,
        {
          "border-mantine-error": !!error,
        },
      )}
      tabIndex={0}
      onKeyUp={handleKeyUp}
    >
      <Select
        tabIndex={-1}
        ref={selectRef}
        classNames={{
          input: "placeholder-black ps-3 border-none",
          error: "p-3 pt-0",
          wrapper: cn({ "border-b border-mantine-gray-4": showContent }),
          ...classNames,
        }}
        withErrorStyles={false}
        variant="unstyled"
        value={value}
        error={error}
        {...rest}
      />

      {showContent && content}
    </div>
  );
};

export { DetailedSelect };
