import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import {
  ReportConsumptionByCompartmentsParams,
  ReportConsumptionByCompartmentsResponse,
} from "@/lib/api/dto";

import { reportKeys } from "./queryKeys";

interface UseFetchReportConsumptionByCompartmentsQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      ReportConsumptionByCompartmentsResponse[],
      Error,
      T,
      ReturnType<typeof reportKeys.consumptionByCompartments>
    >,
    "queryKey" | "queryFn"
  > {
  params: Partial<ReportConsumptionByCompartmentsParams>;
}

export const useFetchReportConsumptionByCompartmentsQuery = <
  T = ReportConsumptionByCompartmentsResponse[]
>({
  params,
  ...options
}: UseFetchReportConsumptionByCompartmentsQueryOptions<T>) =>
  useQuery({
    ...options,
    queryKey: reportKeys.consumptionByCompartments(params),
    queryFn: ({ queryKey: [_, keyParams] }) =>
      keyParams?.datefrom && keyParams.dateto && keyParams.tenantocid
        ? Api.report
            .consumptionByCompartments(
              keyParams as ReportConsumptionByCompartmentsParams
            )
            .then((res) => res.data)
        : [],
  });
