import {
  BillingCostReportParams,
  CommitmentConsumptionParams,
  CompartmentStructureParams,
  FetchOCIQueryParams,
  FetchOCIResourcesParams,
  FifthDiagramParams,
  FirstDiagramParams,
  FourthDiagramParams,
  ListMetricNameParams,
  ListMetricNamespaceParams,
  ListMetricResourceGroupParams,
  ListMetricsResourceParams,
  PolarDiagramParams,
  ReportConsumptionByCompartmentsParams,
  ReportConsumptionByDaysParams,
  ReportConsumptionForCommitmentsParams,
  ReportConsumptionForCreditsParams,
  ReportMetricParams,
  SecondDiagramParams,
  ThirdDiagramParams,
} from "@/lib/api/dto";

export const authKeys = {
  me: () => ["me"] as const,
  login: () => ["login"] as const,
  logout: () => ["logout"] as const,
  refreshToken: () => ["refreshToken"] as const,
};

export const codebookKeys = {
  tenants: () => ["tenants"] as const,
  mutateTenant: (tenantId?: string) => ["mutateTenant", tenantId] as const,
  organizations: () => ["organizations"] as const,
  organizationTypes: () => ["organizationTypes"] as const,
};

export const stateKeys = {
  all: () => ["states"] as const,
};

export const compartmentStatusesKeys = {
  all: () => ["compartmentStatuses"] as const,
};

export const productDescriptionKeys = {
  all: () => ["productDescriptions"] as const,
};

export const resourceTypeKeys = {
  all: () => ["resourceTypes"],
};

export const storageResultTypeKeys = {
  all: () => ["storageResultTypes"] as const,
};

export const tenancyAccessKeys = {
  all: () => ["tenancyAccess"] as const,
};

export const cityKeys = {
  all: () => ["cities"] as const,
};

export const compartmentKeys = {
  all: () => ["compartments"] as const,
  compartmentStructures: (params?: CompartmentStructureParams) =>
    ["compartmentStructures", params] as const,
};

export const codebookResourceKeys = {
  all: (compartmentId?: string) => ["resources", compartmentId] as const,
};

export const monitorKeys = {
  allTenants: () => ["tenants"] as const,
};

export const organizationKeys = {
  all: () => ["organizations"] as const,
  types: () => ["organizationTypes"] as const,
  create: () => ["organizations"] as const,
  update: (organizationId: string) =>
    ["organizations", organizationId] as const,
};

export const regionKeys = {
  all: () => ["regions"] as const,
  types: () => ["regionTypes"] as const,
};

export const resourceKeys = {
  manageableResourceTypes: () => ["manageableResources"] as const,
  manageableResources: (tenancyOCID: string, resourceType?: string | null) =>
    ["manageableResources", tenancyOCID, resourceType] as const,
  stopResource: () => ["stopResource"] as const,
  startResource: () => ["startResource"] as const,
  queryResources: (params: FetchOCIResourcesParams) =>
    ["queryResources", params] as const,
};

export const metricNamespaceKeys = {
  all: (resourceId?: string | null) =>
    ["metricNamespaces", resourceId] as const,
};

export const metricMeasuringUnitKeys = {
  all: (metricMeasuringUnitId?: string | null) =>
    ["metricMeasuringUnit", metricMeasuringUnitId] as const,
};

export const metricNamesKeys = {
  all: (resourceId?: string | null) => ["metricNames", resourceId] as const,
};

export const statisticTypeKeys = {
  all: (statisticTypeId?: number | null) =>
    ["statisticTypes", statisticTypeId] as const,
};

export const unitTypeKeys = {
  all: (resourceId?: string | null) => ["unitTypes", resourceId] as const,
};

export const licenseKeys = {
  all: () => ["licenses"] as const,
};

export const metricNotificationKeys = {
  all: () => ["metricNotifications"] as const,
  byResource: (resourceId?: string | null) =>
    [...metricNotificationKeys.all(), resourceId] as const,
};

export const ociKeys = {
  all: (params?: FetchOCIQueryParams) => ["ociQueries", params] as const,
  resources: (params: FetchOCIResourcesParams) =>
    ["ociQueries", "resources", params] as const,
  listMetricNamespace: (params?: ListMetricNamespaceParams) =>
    ["ociQueries", "metricNamespace", params] as const,
  listMetricResourceGroup: (params?: ListMetricResourceGroupParams) =>
    ["ociQueries", "metricResourceGroup", params] as const,
  listMetricName: (params?: ListMetricNameParams) =>
    ["ociQueries", "metricName", params] as const,
  listMetricsResource: (params?: ListMetricsResourceParams) =>
    ["ociQueries", "metricsResource", params] as const,
};

export const commitmentKeys = {
  all: (subscriptionid?: number) => ["commitments", subscriptionid] as const,
};

export const subscriptionKeys = {
  all: () => ["subscriptions"] as const,
};

export const scNotificationKeys = {
  all: () => ["scNotifications"] as const,
  scNotificationTypes: () => ["scNotificationTypes"] as const,
  scNotificationValueTypes: () => ["scNotificationValueTypes"] as const,
};

export const userKeys = {
  all: () => ["users"] as const,
};

export const authorityKeys = {
  all: () => ["authorities"] as const,
  organizationAuthorities: () => ["organizationAuthorities"] as const,
};

export const reportKeys = {
  metric: (params: ReportMetricParams) => ["reportMetric", params] as const,
  consumptionByDay: (params: Partial<ReportConsumptionByDaysParams>) =>
    ["reportConsumptionByDay", params] as const,
  consumptionByCompartments: (
    params: Partial<ReportConsumptionByCompartmentsParams>,
  ) => ["reportConsumptionByCompartments", params] as const,
  consumptionForCommitments: (
    params: Partial<ReportConsumptionForCommitmentsParams>,
  ) => ["reportConsumptionForCommitments", params] as const,
  consumptionForCredits: (params: Partial<ReportConsumptionForCreditsParams>) =>
    ["reportConsumptionForCredits", params] as const,
};

export const dashboardReportKeys = {
  firstDiagram: (params: Partial<FirstDiagramParams>) =>
    ["firstDiagram", params] as const,
  firstDiagramAvg: (params: Partial<FirstDiagramParams>) =>
    ["firstDiagramAvg", params] as const,
  secondDiagram: (params: Partial<SecondDiagramParams>) =>
    ["secondDiagram", params] as const,
  thirdDiagram: (params: Partial<ThirdDiagramParams>) =>
    ["thirdDiagram", params] as const,
  fourthDiagram: (params: Partial<FourthDiagramParams>) =>
    ["fourthDiagram", params] as const,
  fifthDiagram: (params: Partial<FifthDiagramParams>) =>
    ["fifthDiagram", params] as const,
  polarDiagram: (params: Partial<PolarDiagramParams>) =>
    ["polarDiagram", params] as const,
  timeTypes: () => ["timeTypes"] as const,
};

export const productServiceKeys = {
  all: () => ["productService"] as const,
};

export const billingReportKeys = {
  costReportBase: () => ["costReport"] as const,
  costReport: (params?: Partial<BillingCostReportParams>) =>
    [...billingReportKeys.costReportBase(), params] as const,
  costReportForMonth: ({
    month,
    subscriptionid,
    year,
  }: {
    year: string;
    month: string;
    subscriptionid: string | number;
  }) =>
    [
      "costReportForMonth",
      {
        month,
        subscriptionid,
        year,
      },
    ] as const,
};

export const budgetNotificationKeys = {
  all: () => ["budgetNotifications"] as const,
};

export const compartmentBudgetNotificationKeys = {
  all: () => ["compartmentBudgetNotifications"] as const,
  notificationTypes: () => ["notificationTypes"] as const,
};

export const subscriptionTypeKeys = {
  all: () => ["subscriptionType"] as const,
};
export const accessConfigTypeKeys = {
  all: () => ["accessConfigType"] as const,
};

export const consumptionKeys = {
  commitmentStatusTypes: () => ["commitmentStatusTypes"] as const,
  commitmentPricingModelTypes: () => ["commitmentPricingModelTypes"] as const,
  commitmentConsumption: (params: CommitmentConsumptionParams) =>
    ["commitmentConsumption", params] as const,
};
