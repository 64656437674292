import { QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";

import { toast } from "@/components/ui/use-toast";
import { createIDBPersister } from "@/lib/queryClient/idb-persister";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 24 * 60 * 60 * 1000,
      retry(failureCount, error) {
        if (isAxiosError(error) && error.response?.status === 401) {
          return false;
        }

        return failureCount < 3;
      },
      refetchOnWindowFocus: !import.meta.env.DEV,
      throwOnError(error, query) {
        if (import.meta.env.DEV) {
          console.error("error", error);
          console.log("query", query);
        }
        return false;
      },
    },
    mutations: {
      onError: (error) => {
        if (!!import.meta.env.DEV && isAxiosError(error)) {
          toast({
            title: "Network Error (DEV Only Message)",
            description: error.message,
            variant: "destructive",
          });
        }
      },
    },
  },
});

export const clientPersister = createIDBPersister("oci-query-data");
