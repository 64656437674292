import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { BudgetNotificationResponse } from "@/lib/api/dto";

import { budgetNotificationKeys } from "./queryKeys";

export interface UseFetchBudgetNotificationsQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      BudgetNotificationResponse[],
      Error,
      T,
      ReturnType<typeof budgetNotificationKeys.all>
    >,
    "queryKey" | "queryFn"
  > {}

export const useFetchBudgetNotificationsQuery = <
  T = BudgetNotificationResponse[]
>(
  options: UseFetchBudgetNotificationsQueryOptions<T> = {}
) => {
  return useQuery({
    ...options,
    queryKey: budgetNotificationKeys.all(),
    queryFn: () => Api.budgetNotifications.list().then((res) => res.data),
  });
};
