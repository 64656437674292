import { generatePath } from "react-router-dom";

import { DeleteMetricNotificationPayload } from "@/lib/api/dto";

export const ApiRoutes = {
  Auth: {
    Login: () => "/auth/login",
    RefreshToken: () => "/auth/refreshtoken",
    ResetPassword: (token: string) =>
      generatePath("/users/reset-password/:token", { token }),
    VerifyEmail: () => "/registration/confirm-token",

    Logout: () => "/logout",
  },
  User: {
    Me: () => "/me",
    Users: () => "/users",
  },
  Organizations: {
    List: () => "/codebook/organizations",
    Types: () => "/codebook/organization-types",
    Create: () => "/codebook/organizations",
    Update: (organizationId: string) =>
      generatePath("/codebook/organizations/:organizationId", {
        organizationId,
      }),
  },
  Regions: {
    List: () => "/codebook/regions",
    Create: () => "/codebook/regions",
    Update: (regionId: string) =>
      generatePath("/codebook/regions/:regionId", { regionId }),
    Delete: (regionId: string) =>
      generatePath("/codebook/regions/:regionId", { regionId }),
  },
  States: {
    List: () => "/codebook/states",
  },
  CompartmentStatuses: {
    List: () => "/codebook/compartmentstatuses",
  },
  ProductDescriptions: {
    List: () => "/cost/productdescription",
  },
  OrganizationTypes: {
    List: () => "/codebook/organization-types",
  },
  ResourceTypes: {
    List: () => "/cost/resourcetype",
  },
  StorageResultTypes: {
    List: () => "/metric/storage-result-types",
  },
  Cities: {
    List: () => "/codebook/cities",
    Create: () => "/codebook/cities",
    Update: (id: string) =>
      generatePath("/codebook/cities/:id", {
        id,
      }),
    Delete: (id: string) =>
      generatePath("/codebook/cities/:id", {
        id,
      }),
  },
  Compartments: {
    List: () => "/codebook/compartment",
    Create: () => "/codebook/compartment",
    Update: (id: string) =>
      generatePath("/codebook/compartment/:id", {
        id,
      }),
    CompartmentStructures: () => "/oci/query/compartment-structure",
  },
  CodebookResources: {
    List: () => "/metric/ociresources",
    Update: (id: string) => generatePath("/codebook/resources/:id", { id }),
  },
  Monitor: {
    CreateTenant: () => "/codebook/tenants",
    UpdateTenant: (tenantId: string) =>
      generatePath("/codebook/tenants/:tenantId", { tenantId }),
    TenantList: () => "/codebook/tenants",
  },
  Resource: {
    ManageableResources: () => "/oci/manageable-resources",
    OCIResources: () => "/metric/ociresources",
    ResourceTypes: () => "/oci/manageable-resources/resourcetypes",
    QueryResources: () => "/metric/ociresources/queryresources",
    StopResource: () => "/oci/manageable-resources/control/stop",
    StartResource: () => "/oci/manageable-resources/control/start",
  },
  MetricNamespace: {
    List: () => "/metric/metricnamespace",
    Create: () => "/metric/metricnamespace",
    Update: (metricNamespaceId: string) =>
      generatePath("/metric/metricnamespace/:metricNamespaceId", {
        metricNamespaceId,
      }),
    Delete: (id: string) =>
      generatePath("/metric/metricnamespace/:id", {
        id,
      }),
  },
  MetricMeasuringUnit: {
    List: () => "/codebook/metricmeasuringunit",
    Create: () => "/codebook/metricmeasuringunit",
    Update: (metricMeasuringUnitId: string) =>
      generatePath("/codebook/metricmeasuringunit/:metricMeasuringUnitId", {
        metricMeasuringUnitId,
      }),
    Delete: (id: string) =>
      generatePath("/codebook/metricmeasuringunit/:id", { id }),
  },
  MetricName: {
    List: () => "/metric/metricname",
    Create: () => "metric/metricname",
    Update: (metricNameId: string) =>
      generatePath("/metric/metricname/:metricNameId", { metricNameId }),
  },
  StatisticType: {
    List: () => "/metric/statistictype",
    Create: () => "/metric/statistictype",
    Update: (statisticTypeId: number) =>
      generatePath("/metric/statistictype/:statisticTypeId", {
        statisticTypeId: String(statisticTypeId),
      }),
    Delete: (statisticTypeId: number) =>
      generatePath("/metric/statistictype/:statisticTypeId", {
        statisticTypeId: String(statisticTypeId),
      }),
  },
  UnitType: {
    List: () => "/metric/unittype",
  },
  OCI: {
    All: () => "/codebook/ociqueries/all",
    List: () => "/codebook/ociqueries",
    CreateQuery: () => "/codebook/ociqueries",
    UpdateQuery: (id: string) =>
      generatePath("/codebook/ociqueries/:id", { id }),
    DeleteQuery: (id: string) =>
      generatePath("/codebook/ociqueries/:id", { id }),
    ListMetricNamespace: () => "/oci/query/list-metrics-namespace",
    ListMetricResourceGroup: () => "/oci/query/list-metrics-resource-group",
    ListMetricName: () => "/oci/query/list-metrics-name",
    ListMetricsResource: () => "/oci/query/list-metrics-resource",
  },
  Licenses: {
    List: () => "/licence",
    Upload: (organizationId: string) =>
      generatePath("/licence/upload-license/:organizationId", {
        organizationId,
      }),
  },
  MetricNotifications: {
    List: () => "/metric/metricnotification",
    Create: () => "/metric/metricnotification",
    Delete: ({ id }: DeleteMetricNotificationPayload) =>
      generatePath("/metric/metricnotification/:id", { id }),
  },
  Commitments: {
    List: () => "/cost/commitment",
  },
  Subscriptions: {
    List: () => "/codebook/subscriptions",
    Create: () => "codebook/subscriptions",
    Update: (subscriptionId: string) =>
      generatePath("/codebook/subscriptions/:subscriptionId", {
        subscriptionId,
      }),
  },
  SCNotifications: {
    List: () => "/oci/sc-notifications",
    Create: () => "/oci/sc-notifications",
    Delete: (id: string) => generatePath("/oci/sc-notifications/:id", { id }),
    SCNotificationType: () => "/oci/sc-notifications/notification-types",
    SCNotificationValueTypes: () => "/oci/sc-notifications/value-types",
  },
  Users: {
    List: () => "/users",
    Update: (userId: string) => generatePath("/users/:userId", { userId }),
    RequestPasswordReset: (id: string) =>
      generatePath("users/request-reset-password/:id", { id }),
    CreateSuperUser: () => "/registration/register-superuser",
    CreateSystemUser: () => "/registration/register-systemuser",
    CreateOrganizationAdminUser: () =>
      "/registration/register-organization-admin",
    CreateOrganizationUser: () => "/registration/register-organization-user",
    Delete: (id: string) => generatePath("/users/:id", { id }),
  },
  Authorities: {
    All: () => "/permissions/all",
    List: () => "codebook/authorities",
    Organization: () => "/permissions/organization",
  },
  Report: {
    Metric: () => "/reports/metric",
    ConsumptionByDays: () => "/reports/billing/consumptionbydays",
    ConsumptionByCompartments: () =>
      "/reports/billing/consumptionbycompartments",
    ConsumptionForCommitments: () =>
      "/reports/billing/consumptionforcommitment",
    ConsumptionForCredits: () => "/reports/billing/credits",
  },
  Dashboard: {
    FirstDiagram: () => "/dashboard/firstdiagram",
    FirstDiagramAvg: () => "/dashboard/firstdiagramavg",
    SecondDiagram: () => "/dashboard/seconddiagram",
    ThirdDiagram: () => "/dashboard/thirddiagram",
    FourthDiagram: () => "/dashboard/fourthdiagram",
    FifthDiagram: () => "/dashboard/fifthdiagram",
    PolarDiagram: () => "/dashboard/polarchartdiagram",
    TimeTypes: () => "/dashboard/timetype",
  },
  ProductService: {
    List: () => "/cost/productservice",
  },
  BillingReports: {
    CostReport: () => "/reports/billing/costreport",
  },
  BudgetNotifications: {
    List: () => "/notification/budget",
    Create: () => "/notification/budget",
    Delete: (id: string) => generatePath("/notification/budget/:id", { id }),
  },
  CompartmentBudgetNotifications: {
    List: () => "/notification/budgetcompartment",
    Create: () => "/notification/budgetcompartment",
    Delete: (id: string) =>
      generatePath("/notification/budgetcompartment/:id", { id }),
    NotificationTypes: () =>
      "/notification/budgetcompartment/notification-types",
  },
  SubscriptionType: {
    List: () => "/codebook/subscription-types",
  },
  AccessConfigType: {
    List: () => "/codebook/accessconfigtypes",
  },
  TenancyAccess: {
    List: () => "/codebook/tenancy-access-types",
  },
  Consumption: {
    Commitment: () => "/consumption/commitment",
    CommitmentStatusTypes: () => "/consumption/commitment/status-type",
    CommitmentPricingModelTypes: () =>
      "/consumption/commitment/pricing-model-type",
  },
};
