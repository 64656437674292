import { Button, Paper, Select, Text } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconFileUpload, IconUpload, IconX } from "@tabler/icons-react";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import { PermissionControl } from "@/components/PermissionControl";
import { errorToast, successToast } from "@/components/ui/use-toast";
import { useFetchOrganizationsQuery } from "@/hooks/api/useFetchOrganizationsQuery";
import { useUploadLicenceMutation } from "@/hooks/api/useUploadLicenceMutation";
import { useAuth } from "@/hooks/useAuth";
import { Claim } from "@/lib/api/dto";
import { userHasPermission } from "@/lib/utils";

const MAX_FILE_SIZE = 5 * 1024 ** 2;

export const UploadLicensePage = () => {
  const {
    me: { data: me },
  } = useAuth();
  const { t } = useTranslation("entity");

  const isSuperAdmin = userHasPermission(me!, Claim.SUPERADMIN);

  const [selectedOrganization, setSelectedOrganization] = useState<
    string | null
  >(isSuperAdmin ? null : (me?.organization.id ?? null));
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { data: organizations } = useFetchOrganizationsQuery();
  const { mutate, isPending } = useUploadLicenceMutation();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isPending) {
      return;
    }

    if (selectedFile && selectedOrganization) {
      mutate(
        { file: selectedFile, organizationId: selectedOrganization },
        {
          onSuccess: () => {
            successToast();
          },
          onError: () => {
            errorToast();
          },
        },
      );
    }
  };

  const handleDrop = (files: File[]) => {
    setSelectedFile(files[0]);
  };

  const organizationOptions =
    organizations?.map((organization) => ({
      value: organization.id,
      label: organization.name,
    })) || [];

  return (
    <PermissionControl
      permission={[Claim.SUPERADMIN, Claim.ORG_LICENCE_MANAGE]}
      fallbackContent={<Navigate to="/entity/licenses" replace />}
    >
      <Paper
        component="form"
        onSubmit={onSubmit}
        className="mx-auto flex w-full max-w-3xl flex-col gap-y-3 overflow-auto px-4 py-8"
      >
        <Select
          label={t("administration.license.upload.organization")}
          disabled={isPending}
          value={selectedOrganization || ""}
          onChange={(value) => setSelectedOrganization(value)}
          data={organizationOptions}
        />

        <Dropzone
          onDrop={handleDrop}
          maxSize={MAX_FILE_SIZE}
          accept={IMAGE_MIME_TYPE}
          disabled={isPending}
          maxFiles={1}
        >
          <div className="flex min-h-56 flex-col justify-center gap-4">
            <Dropzone.Accept>
              <IconUpload className="w-13 h-13 stroke-1.5 text-blue-600" />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX className="w-13 h-13 stroke-1.5 text-red-600" />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconFileUpload className="w-13 h-13 text-dimmed stroke-1.5" />
            </Dropzone.Idle>

            <Text size="xl" inline>
              {t("administration.license.upload.file")}
            </Text>
            <Text size="sm" c="dimmed" inline mt={7}>
              {t("administration.license.upload.fileSize")}
            </Text>
          </div>
        </Dropzone>

        <Button className="mt-2" type="submit" disabled={isPending}>
          {t("administration.license.upload.confirm")}
        </Button>
      </Paper>
    </PermissionControl>
  );
};
