import { Outlet } from "react-router-dom";

import { Footer } from "@/widgets/Footer";
import { Navbar } from "@/widgets/Navbar/Navbar";

import { useAuth } from "./hooks/useAuth";

function Layout() {
  const { isLoggedIn, me } = useAuth();

  return (
    <div className="flex flex-col h-full">
      {isLoggedIn && !me.isLoading && <Navbar />}

      <Outlet />

      {isLoggedIn && !me.isLoading && <Footer />}
    </div>
  );
}

export default Layout;
