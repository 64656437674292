import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";
import {
  CreateMetricNotificationPayload,
  MetricNotificationResponse,
} from "@/lib/api/dto";

import { metricNotificationKeys } from "./queryKeys";

interface UseCreateMetricNotificationMutationOptions
  extends UseMutationOptions<
    MetricNotificationResponse,
    unknown,
    CreateMetricNotificationPayload
  > {}

export const useCreateMetricNotificationMutation = ({
  onSuccess,
  onError,
  onSettled,
  ...options
}: UseCreateMetricNotificationMutationOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: async (data: CreateMetricNotificationPayload) =>
      Api.metricNotifications.create(data).then((res) => res.data),
    onSuccess: (...args) => {
      successToast.create();
      onSuccess?.(...args);
    },
    onError: (...args) => {
      errorToast.create();
      onError?.(...args);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: metricNotificationKeys.all(),
      });
      onSettled?.(...args);
    },
  });
};
