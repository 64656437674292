import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ParseKeys } from "i18next";
import { useTranslation } from "react-i18next";

import { Api } from "@/lib/api";
import { EnumResponse } from "@/lib/api/dto";

import { consumptionKeys } from "./queryKeys";

export interface UseFetchCommitmentStatusTypesQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      EnumResponse,
      Error,
      T,
      ReturnType<typeof consumptionKeys.commitmentStatusTypes>
    >,
    "queryKey" | "queryFn"
  > {}

export const useFetchCommitmentStatusTypesQuery = <T = EnumResponse>(
  options: UseFetchCommitmentStatusTypesQueryOptions<T> = {},
) => {
  const { t } = useTranslation("enum");

  return useQuery({
    ...options,
    queryKey: consumptionKeys.commitmentStatusTypes(),
    queryFn: () =>
      Api.consumption.commitmentStatusTypes().then((res) => res.data),
    select: (data) => {
      if (options.select) return options.select(data);

      return {
        ...data,
        basicEnums: data.basicEnums?.map((item) => ({
          ...item,
          label: t(`commitment-status.${item.value}` as ParseKeys<"enum">, {
            defaultValue: item.label,
          }),
        })),
        extendedEnums: data.extendedEnums?.map((item) => ({
          ...item,
          label: t(`extended-enum-type.${item.value}` as ParseKeys<"enum">, {
            defaultValue: item.label,
          }),
        })),
      };
    },
  });
};
