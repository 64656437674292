import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";
import {
  CreateMetricMeasuringUnitPayload,
  MetricMeasuringUnitResponse,
} from "@/lib/api/dto";

import { metricMeasuringUnitKeys } from "./queryKeys";

interface UseCreateOrUpdateMetricMeasurignUnitMutationOptions
  extends UseMutationOptions<
    MetricMeasuringUnitResponse,
    Error,
    CreateMetricMeasuringUnitPayload
  > {
  metricMeasurignUnitId?: string;
}

export const useCreateOrUpdateMetricMeasuringUnitMutation = ({
  metricMeasurignUnitId,
  onError,
  onSuccess,
  onSettled,
  ...options
}: UseCreateOrUpdateMetricMeasurignUnitMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (data: CreateMetricMeasuringUnitPayload) => {
      if (metricMeasurignUnitId) {
        return Api.metricMeasuringUnit
          .update(metricMeasurignUnitId, data)
          .then((res) => res.data);
      }

      return Api.metricMeasuringUnit.create(data).then((res) => res.data);
    },
    onSuccess: (...args) => {
      metricMeasurignUnitId ? successToast.update() : successToast.create();
      onSuccess?.(...args);
    },
    onError: (...args) => {
      metricMeasurignUnitId ? errorToast.update() : errorToast.create();
      onError?.(...args);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: metricMeasuringUnitKeys.all(),
      });
      onSettled?.(...args);
    },
  });
};
