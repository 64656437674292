import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import {
  FetchOCIQueryParams,
  OCIQueryResponseContentItem,
  OCIResourceWithQueries,
} from "@/lib/api/dto";

import { ociKeys } from "./queryKeys";

interface UseFetchOCIQueriesQueryOoptions<T>
  extends Omit<
    UseQueryOptions<
      OCIQueryResponseContentItem[],
      Error,
      T,
      ReturnType<typeof ociKeys.all>
    >,
    "queryKey" | "queryFn"
  > {
  params?: FetchOCIQueryParams;
}

export const useFetchOCIQueriesQuery = <T = OCIQueryResponseContentItem[]>({
  params,
  ...options
}: UseFetchOCIQueriesQueryOoptions<T> = {}) =>
  useQuery({
    ...options,
    queryKey: ociKeys.all(params),
    queryFn: ({ queryKey }) => Api.oci.all(queryKey[1]).then((res) => res.data),
  });

export const selectResourcesWithQueries = (
  queries: OCIQueryResponseContentItem[],
): OCIResourceWithQueries[] => {
  return queries.reduce((acc, query) => {
    query.ociResources.forEach((resource) => {
      const existingResource = acc.find((r) => r.ocid === resource.ocid);

      if (existingResource) {
        existingResource.queries.push(query);
      } else {
        acc.push({
          ...resource,
          queries: [query],
        });
      }
    });

    return acc;
  }, [] as OCIResourceWithQueries[]);
};
