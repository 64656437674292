import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { SubscriptionType } from "@/lib/api/dto";

import { subscriptionTypeKeys } from "./queryKeys";

interface UseFetchSubscriptionTypeQueryOptions<T>
  extends Omit<
    UseQueryOptions<SubscriptionType[], Error, T>,
    "queryKey" | "queryFn"
  > {}

export const useFetchSubscriptionTypeQuery = <T = SubscriptionType[]>(
  options?: UseFetchSubscriptionTypeQueryOptions<T>,
) => {
  return useQuery({
    ...options,
    queryFn: () => Api.subscriptionType.list().then((res) => res.data),
    queryKey: subscriptionTypeKeys.all(),
  });
};
