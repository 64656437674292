import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";
import {
  BudgetNotificationResponse,
  CreateBudgetNotificationPayload,
} from "@/lib/api/dto";

import { budgetNotificationKeys } from "./queryKeys";

export const useCreateBudgetNotificationMutation = ({
  onSuccess,
  ...options
}: UseMutationOptions<
  BudgetNotificationResponse,
  Error,
  CreateBudgetNotificationPayload
> = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (request: CreateBudgetNotificationPayload) =>
      Api.budgetNotifications.create(request).then((res) => res.data),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: budgetNotificationKeys.all(),
      });
      onSuccess?.(...args);
    },
  });
};
