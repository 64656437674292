import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CompartmentStatusesResponse } from "@/lib/api/dto";

import { compartmentStatusesKeys } from "./queryKeys";

interface UseFetchCompartmentStatusesQueryOptions<
  TSelectData = CompartmentStatusesResponse[],
> extends UseQueryOptions<CompartmentStatusesResponse[], Error, TSelectData> {}

export const useFetchCompartmentsStatusesQuery = (
  options?: UseFetchCompartmentStatusesQueryOptions,
) => {
  return useQuery({
    queryKey: compartmentStatusesKeys.all(),
    queryFn: () => Api.compartmentsStatuses.list().then((res) => res.data),
    select: (data) => data.map((d) => ({ ...d, id: `${d.id}` })),
    ...options,
  });
};
