import { deepMerge } from "@mantine/core";
import { ParseKeys } from "i18next";
import {
  useMantineReactTable,
  MRT_TableOptions,
  MRT_RowData,
  MRT_Localization,
} from "mantine-react-table";
import { MRT_Localization_SR_CYRL_RS } from "mantine-react-table/locales/sr-Cyrl-RS/index.esm.mjs";
import { MRT_Localization_SR_LATN_RS } from "mantine-react-table/locales/sr-Latn-RS/index.esm.mjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SetOptional } from "type-fest";

import { Languages } from "@/constants";
import { cn, parseFromValuesOrFunc } from "@/lib/utils";

const emptyArray: unknown[] = [];

export const mrtLocalization: Record<Languages, MRT_Localization> = {
  [Languages.Cyril]: MRT_Localization_SR_CYRL_RS,
  [Languages.Latin]: MRT_Localization_SR_LATN_RS,
};

const defaultTableOptions: Partial<MRT_TableOptions<MRT_RowData>> = {
  mantineTableHeadCellProps: {
    style: {
      whiteSpace: "nowrap",
    },
  },
  mantineHighlightProps: {
    size: "sm",
  },
  displayColumnDefOptions: {
    "mrt-row-actions": {
      header: undefined,
    },
  },
  //@ts-expect-error: Property 'value' is missing in type '{ style: { display: "none" | "block"; }; }' but required in type 'ProgressProps'.ts(2322)
  mantineProgressProps: ({ isTopToolbar }) => ({
    style: { display: !isTopToolbar ? "block" : "none" },
  }),
};

/**
 * Custom hook that wraps `useMantineReactTable` hook
 *
 * We use this custom hook so we can define `default props` on MRT
 *
 * @param tableOptions
 * @returns the MRT table instance
 */
export const useMRT = <TData extends MRT_RowData>(
  tableOptions: SetOptional<MRT_TableOptions<TData>, "data">,
) => {
  const { t, i18n } = useTranslation();

  const memoizedColumns = useMemo(
    () =>
      tableOptions.columns.map((c) => ({
        ...c,
        header: t(c.header as ParseKeys),
      })),
    [t, tableOptions],
  );

  const memoizedLocalization = useMemo(
    () => mrtLocalization[i18n.language as Languages],
    [i18n.language],
  );

  const memoizedData = useMemo(
    () => tableOptions.data || (emptyArray as TData[]),
    [tableOptions.data],
  );

  const memoizedMeta = useMemo(
    () => ({ t, ...tableOptions.meta }),
    [t, tableOptions.meta],
  );

  return useMantineReactTable(
    deepMerge(defaultTableOptions as MRT_TableOptions<TData>, {
      ...tableOptions,
      mantineTableHeadProps: (({ table }) => {
        const mantineTableHeadProps = parseFromValuesOrFunc(
          tableOptions.mantineTableHeadProps,
          {
            table,
          },
        );
        const isSticky = table.options.enableStickyHeader;

        // const mantineTableHeadStyle = mantineTableHeadProps?.style;

        // let style: MantineStyleProp;
        // if (!isSticky) {
        //   style = mantineTableHeadStyle;
        // } else {
        //   if (mantineTableHeadStyle) {
        //     if (typeof mantineTableHeadStyle === "function") {
        //       style = (theme) => ({
        //         position: "sticky",
        //         ...mantineTableHeadStyle(theme),
        //       });
        //     } else if (Array.isArray(mantineTableHeadStyle)) {
        //       style = [{ position: "sticky" }, ...mantineTableHeadStyle];
        //     } else {
        //       style = {
        //         position: "sticky",
        //         ...mantineTableHeadStyle,
        //       };
        //     }
        //   } else {
        //     style = { position: "sticky" };
        //   }
        // }

        return {
          ...mantineTableHeadProps,
          className: cn(
            isSticky && "!sticky",
            mantineTableHeadProps?.className,
          ),
        };
      }) as MRT_TableOptions<TData>["mantineTableHeadProps"],
      meta: memoizedMeta,
      data: memoizedData,
      columns: memoizedColumns,
      localization: memoizedLocalization,
    }),
  );
};
