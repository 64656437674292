import { createMRTColumnHelper } from "mantine-react-table";

import { MetricNamespaceResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<MetricNamespaceResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.metricNamespace.name",
  }),
  columnHelper.accessor("description", {
    header: "entity:administration.metricNamespace.description",
  }),
];
