import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { StatisticTypeResponse } from "@/lib/api/dto";

import { statisticTypeKeys } from "./queryKeys";

export interface UseStatisticTypesQueryParams<T>
  extends Omit<
    UseQueryOptions<
      StatisticTypeResponse[],
      Error,
      T,
      ReturnType<typeof statisticTypeKeys.all>
    >,
    "queryKey" | "queryFn"
  > {
  resourceId?: string | null;
}

export const useFetchStatisticTypesQuery = <T = StatisticTypeResponse[]>({
  resourceId,
  ...options
}: UseStatisticTypesQueryParams<T> = {}) => {
  return useQuery({
    ...options,
    queryKey: statisticTypeKeys.all(resourceId),
    queryFn: ({ queryKey }) =>
      Api.statisticType.list(queryKey[1]).then((res) => res.data),
  });
};
