import { Paper, TextInput, Button, Checkbox, Select } from "@mantine/core";
import { zodResolver } from "mantine-form-zod-resolver";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { useCreateOrUpdateCityMutation } from "@/hooks/api/useCreateOrUpdateCityMutation";
import { useFetchStatesQuery } from "@/hooks/api/useFetchStatesQuery";
import { useTForm } from "@/hooks/useTForm";
import { CityResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface CityDetailsProps {
  city: CityResponse | null;
  onSettled?: () => void;
  className?: string;
}

const citySchema = z.object({
  capital: z.boolean(),
  name: z.string().min(1, { message: "form:field.required" }),
  postalCode: z.string().min(1, { message: "form:field.required" }),
  stateCode: z
    .string()
    .nullable()
    .refine((value) => !!value, { message: "form:field.required" })
    .transform((value) => value ?? ""),
});

type FormData = z.infer<typeof citySchema>;

const defaultValues: FormData = {
  capital: false,
  name: "",
  postalCode: "",
  stateCode: "",
};

const mapCityToFormData = (city: CityResponse | null): FormData => {
  if (!city) return defaultValues;

  return {
    capital: city.capital ?? defaultValues.capital,
    name: city.name ?? defaultValues.name,
    postalCode: city.postalCode ?? defaultValues.postalCode,
    stateCode: city.state.stateCode ?? defaultValues.stateCode,
  };
};

export const CityDetails = ({
  city,
  onSettled,
  className,
}: CityDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const { mutate, isPending } = useCreateOrUpdateCityMutation({
    id: city?.id,
  });

  const { data: states = [] } = useFetchStatesQuery();

  const form = useTForm<FormData>({
    initialValues: mapCityToFormData(city),
    validate: zodResolver(citySchema),
  });

  const onSubmit = (data: FormData) => {
    mutate(data, {
      onSuccess: () =>
        city?.id ? successToast.update() : successToast.create(),
      onError: () => (city?.id ? errorToast.update() : errorToast.create()),
      onSettled: onSettled,
    });
  };

  const stateOptions = useMemo(
    () =>
      states.map((state) => ({ value: state.stateCode, label: state.name })),
    [states],
  );

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("systemCodebook.common.name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        withAsterisk
        label={t("systemCodebook.cities.postalCode")}
        {...form.getInputProps("postalCode")}
      />
      <Select
        withAsterisk
        label={t("systemCodebook.cities.state")}
        data={stateOptions}
        {...form.getInputProps("stateCode")}
      />
      <Checkbox
        className="mt-2"
        label={t("systemCodebook.cities.capitalCity")}
        {...form.getInputProps("capital", { type: "checkbox" })}
      />
      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};
