import { FloatingPosition, Menu, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { RS } from "country-flag-icons/react/3x2";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { Languages, LocalStorageKeys } from "@/constants";

const languageMap = {
  [Languages.Cyril]: "Српски (Ћирилица)",
  [Languages.Latin]: "Srpski (Latinica)",
};

const flagMap = {
  [Languages.Cyril]: RS,
  [Languages.Latin]: RS,
};

interface LanguagePickerProps {
  dropdownPosition?: FloatingPosition;
}

export const LanguagePicker = ({
  dropdownPosition = "bottom-start",
}: LanguagePickerProps) => {
  const { i18n } = useTranslation();
  const [opened, { open, close }] = useDisclosure();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    i18n.changeLanguage(e.currentTarget.value, () => {
      localStorage.setItem(LocalStorageKeys.Language, e.currentTarget.value);
    });
  };

  const options = Array.from(i18n.languages)
    .sort()
    .map((language) => {
      const FlagComponent = flagMap[language as keyof typeof flagMap];

      return (
        <Menu.Item
          key={language}
          value={language}
          onClick={handleClick}
          leftSection={<FlagComponent className="h-4" />}
          // have important flags as there is a flicker for some reason when hovering
          className="!bg-slate-500 font-medium text-white hover:!bg-slate-400"
        >
          {languageMap[language as keyof typeof languageMap]}
        </Menu.Item>
      );
    });

  const FlagComponent = flagMap[i18n.language as keyof typeof flagMap];

  return (
    <Menu
      onOpen={open}
      position={dropdownPosition}
      returnFocus
      onClose={close}
      radius="md"
      classNames={{
        dropdown:
          "border shadow-lg border-slate-400/40 bg-slate-500 shadow-black/25",
      }}
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className="group flex items-center justify-between gap-2 rounded px-2 py-2 transition-colors duration-150 hover:bg-slate-400 focus-visible:bg-slate-600 data-[expanded=true]:bg-slate-400"
          data-expanded={opened}
        >
          <FlagComponent className="h-4" />
          <IconChevronDown
            className="size-4 rotate-0 text-white transition-transform group-data-[expanded=true]:rotate-180"
            stroke={1.5}
          />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{options}</Menu.Dropdown>
    </Menu>
  );
};
