import { useQueries } from "@tanstack/react-query";
import { useMemo } from "react";

import { STALE_TIME } from "@/constants";
import { Api } from "@/lib/api";
import {
  BillingCostReportParams,
  BillingCostReportResponse,
} from "@/lib/api/dto";
import { getYearAndMonthBetweenDates, isCurrentMonth } from "@/lib/dateUtils";

import { billingReportKeys } from "./queryKeys";

interface UseFetchBillingCostReportQueryOptions {
  params: Omit<
    BillingCostReportParams,
    "year" | "month" | "subscriptionid" | "productservice"
  > & {
    datefrom: Date | null;
    dateto: Date | null;
    subscriptionid?: string | number;
    services?: string[];
  };
}

const fetchBillingCost = ({
  year,
  month,
  subscriptionid,
  tenantocid,
  withzerocost,
}: Partial<BillingCostReportParams>) => {
  return !year || !month || !subscriptionid || !tenantocid ?
      Promise.resolve([])
    : Api.billingReports
        .costReport({
          year,
          month,
          subscriptionid,
          tenantocid,
          withzerocost,
        })
        .then((res) => res.data);
};

export const useFetchBillingCostReportQuery = ({
  params,
}: UseFetchBillingCostReportQueryOptions) => {
  const dates = getYearAndMonthBetweenDates(params.datefrom, params?.dateto);

  const fullDataQuery = useQueries({
    queries: dates.map(({ year, month }) => ({
      staleTime: isCurrentMonth(year, month) ? STALE_TIME : Infinity,
      queryKey: billingReportKeys.costReport({
        year,
        month,
        subscriptionid: params!.subscriptionid!,
        tenantocid: params.tenantocid,
        withzerocost: params.withzerocost,
      }),
      queryFn: ({
        queryKey,
      }: {
        queryKey: ReturnType<typeof billingReportKeys.costReport>;
      }) =>
        fetchBillingCost({
          month: queryKey[1]?.month,
          year: queryKey[1]?.year,
          subscriptionid: queryKey[1]?.subscriptionid,
          tenantocid: queryKey[1]?.tenantocid,
          withzerocost: queryKey[1]?.withzerocost,
        }),
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).flat(),
        isFetching: !results.every((result) => !result.isFetching),
        isLoading: !results.some((result) => !result.isLoading),
      };
    },
  });

  const filteredQueries = useMemo(() => {
    return fullDataQuery.data.filter(
      (queryData) =>
        !params.services?.length ||
        params.services.includes(queryData?.productService || ""),
    );
  }, [fullDataQuery.data, params.services]);

  const groupedQueries = useMemo(
    () =>
      Object.values(
        filteredQueries.reduce<Record<string, BillingCostReportResponse>>(
          (acc, item) => {
            if (!item) return acc;

            const key = `${item.year}-${item.month}-${item.tenatOcid}-${item.productService}`;

            if (!acc[key]) {
              acc[key] = {
                year: item.year,
                month: item.month,
                tenatOcid: item.tenatOcid,
                tenantName: item.tenantName,
                productService: item.productService,
                serviceName: item.serviceName,
                resource: "",
                resourceName: "",
                cost: 0,
              };
            }

            acc[key].cost += item.cost;

            return acc;
          },
          {},
        ),
      ),
    [filteredQueries],
  );

  return {
    dates,
    filteredQueries,
    groupedQueries,
    isFetching: fullDataQuery.isFetching,
    isLoading: fullDataQuery.isLoading,
  };
};
