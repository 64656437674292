import { NavLink } from "react-router-dom";

import logo from "@/assets/logo.svg";

import { AccountMenu } from "./AccountMenu";
import { NavMenu } from "./NavMenu";

export const Navbar = () => {
  return (
    <div className="col-span-2 flex flex-shrink-0 flex-grow-0 basis-auto items-center gap-x-2 bg-primary p-3">
      <NavLink to="/" className="flex-shrink-0">
        <img src={logo} alt="monitor logo" />
      </NavLink>

      <NavMenu />

      <AccountMenu />
    </div>
  );
};
