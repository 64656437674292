import { Navigate } from "react-router-dom";

import { PermissionControl } from "@/components/PermissionControl";
import { Claim } from "@/lib/api/dto";
import { CitiesTable } from "@/widgets/CitiesTable/CitiesTable";

export const CitiesPage = () => {
  return (
    <PermissionControl
      permission={Claim.SUPERADMIN}
      fallbackContent={<Navigate to="/entity" replace />}
    >
      <CitiesTable />
    </PermissionControl>
  );
};
