import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { TenancyAccessResponse } from "@/lib/api/dto";

import { tenancyAccessKeys } from "./queryKeys";

interface UseFetchTenancyAccessQueryOptions<
  TSelectData = TenancyAccessResponse[]
> extends UseQueryOptions<TenancyAccessResponse[], Error, TSelectData> {}

export const useFetchTenancyAccess = (
  options?: UseFetchTenancyAccessQueryOptions
) => {
  return useQuery({
    queryKey: tenancyAccessKeys.all(),
    queryFn: () => Api.tenancyAccess.list().then((res) => res.data),
    ...options,
  });
};
