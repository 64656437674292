import { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import Layout from "./Layout";
import { AccessConfigPage } from "./pages/AccessConfigPage";
import { AuthorityPage } from "./pages/AuthorityPage";
import { BillingPage } from "./pages/BillingPage";
import { BillingReportPage } from "./pages/BillingReportPage";
import { BudgetNotificationsPage } from "./pages/BudgetNotificationsPage";
import { CitiesPage } from "./pages/CitiesPage";
import { CodebookResourcesPage } from "./pages/CodebookResourcesPage";
import { CompartmentBudgetNotificationsPage } from "./pages/CompartmentNotificationsPage";
import { CompartmentsPage } from "./pages/CompartmentsPage";
import { CompartmentStatusesPage } from "./pages/CompartmentStatusesPage";
import { DashboardPage } from "./pages/DashboardPage";
import { EntitiesPage } from "./pages/EntitiesPage";
import { LicensesPage } from "./pages/LicensesPage";
import { LoginPage } from "./pages/LoginPage";
import { MetricMeasuringUnitPage } from "./pages/MetricMeasuringUnitPage";
import { MetricNamePage } from "./pages/MetricNamePage";
import { MetricNamespacePage } from "./pages/MetricNamespacePage";
import { MetricNotificationListPage } from "./pages/MetricNotificationListPage";
import { MonitorPage } from "./pages/MonitorPage";
import { OCIQueryListPage } from "./pages/OCIQueryListPage";
import { OrganizationTypesPage } from "./pages/OgranizationTypesPage";
import { OrganizationsPage } from "./pages/OrganizationsPage";
import { PrivatePage } from "./pages/PrivatePage";
import { ProductDescriptionPage } from "./pages/ProductDescriptionPage";
import { ProductServicePage } from "./pages/ProductServicePage";
import { ProfilePage } from "./pages/ProfilePage";
import { RegionsPage } from "./pages/RegionsPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { ResourcesPage } from "./pages/ResourcesPage";
import { ResourceTypesPage } from "./pages/ResourceTypesPage";
import { SCNotificationPage } from "./pages/SCNotificationPage";
import { StatesPage } from "./pages/StatesPage";
import { StatisticTypePage } from "./pages/StatisticTypePage";
import { StorageResultPage } from "./pages/StorageResultPage";
import { SubscriptionPage } from "./pages/SubscriptionPage";
import { SubscriptionsTypePage } from "./pages/SubscriptionTypePage";
import { TenancyAccessPage } from "./pages/TenancyAccessPage";
import { TenantsPage } from "./pages/TenantsPage";
import { UploadLicensePage } from "./pages/UploadLicensePage";
import { UsersListPage } from "./pages/UsersListPage";
import { VerifyEmailPage } from "./pages/VerifyEmailPage";

function Pages() {
  return (
    <Suspense fallback={<div className="flex-1">Loading...</div>}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <PrivatePage>
                <DashboardPage />
              </PrivatePage>
            }
          />
          <Route
            path="monitor"
            element={
              <PrivatePage>
                <MonitorPage />
              </PrivatePage>
            }
          />
          <Route
            path="notifications/"
            element={
              <PrivatePage>
                <Outlet />
              </PrivatePage>
            }
          >
            <Route index element={<Navigate to="sc" replace />} />
            <Route path="sc" element={<SCNotificationPage />} />
            <Route path="budget" element={<BudgetNotificationsPage />} />
            <Route
              path="compartment"
              element={<CompartmentBudgetNotificationsPage />}
            />
            <Route path="metric" element={<MetricNotificationListPage />} />
          </Route>
          <Route
            path="billing"
            element={
              <PrivatePage>
                <Outlet />
              </PrivatePage>
            }
          >
            <Route index element={<BillingPage />} />
            <Route path="report" element={<BillingReportPage />} />
          </Route>
          <Route
            path="account"
            element={
              <PrivatePage>
                <ProfilePage />
              </PrivatePage>
            }
          />
          <Route
            path="entity/"
            element={
              <PrivatePage>
                <EntitiesPage />
              </PrivatePage>
            }
          >
            <Route index element={<Navigate to="tenants" replace />} />
            <Route path="tenants" element={<TenantsPage />} />
            <Route path="organizations" element={<OrganizationsPage />} />
            <Route path="metricName" element={<MetricNamePage />} />
            <Route path="statistictype" element={<StatisticTypePage />} />
            <Route path="metricnamespace" element={<MetricNamespacePage />} />
            <Route path="subscription" element={<SubscriptionPage />} />
            <Route
              path="metricmeasuringunit"
              element={<MetricMeasuringUnitPage />}
            />
            <Route path="regions" element={<RegionsPage />} />
            <Route path="states" element={<StatesPage />} />
            <Route
              path="subscription-types"
              element={<SubscriptionsTypePage />}
            />
            <Route path="authorities" element={<AuthorityPage />} />
            <Route path="productService" element={<ProductServicePage />} />
            <Route
              path="tenancy-access-types"
              element={<TenancyAccessPage />}
            />
            <Route path="cities" element={<CitiesPage />} />
            <Route
              path="compartmentstatuses"
              element={<CompartmentStatusesPage />}
            />
            <Route
              path="productDescription"
              element={<ProductDescriptionPage />}
            />
            <Route path="resourcetype" element={<ResourceTypesPage />} />
            <Route
              path="storage-result-types"
              element={<StorageResultPage />}
            />
            <Route
              path="organization-types"
              element={<OrganizationTypesPage />}
            />
            <Route path="compartments" element={<CompartmentsPage />} />
            <Route path="resources" element={<CodebookResourcesPage />} />
            <Route path="oci-queries" element={<OCIQueryListPage />} />
            <Route path="licenses" element={<LicensesPage />} />
            <Route path="licenses/upload" element={<UploadLicensePage />} />
            <Route path="users" element={<UsersListPage />} />
            <Route path="accessconfigtypes" element={<AccessConfigPage />} />
            {/* <Route path="alarms" element={<>Alarmi</>} /> */}
            {/* <Route path="employees" element={<>Zaposleni</>} /> */}
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route
            path="registration/confirm-token"
            element={<VerifyEmailPage />}
          />
          <Route
            path="resources"
            element={
              <PrivatePage>
                <ResourcesPage />
              </PrivatePage>
            }
          />
        </Route>
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </Suspense>
  );
}
export default Pages;
