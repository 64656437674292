import { Button, Title, Modal, Menu, Text, Box } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { ParseKeys } from "i18next";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDeleteOCIQueryMutation } from "@/hooks/api/useDeleteOCIQueryMutation";
import { useFetchCompartmentsQuery } from "@/hooks/api/useFetchCompartmentsQuery";
import { useFetchOCIQueriesQuery } from "@/hooks/api/useFetchOCIQueriesQuery";
import { useMRT } from "@/hooks/useMRT";
import { OCIQueryResponseContentItem, TenantResponse } from "@/lib/api/dto";

import { columns } from "./columns";
import { OCIQueryDetails } from "../OCIQueryDetails/OCIQueryDetails";

interface OCIQueryTableProps {
  tenant?: TenantResponse | null;
}

type OCIQueryActionType = "create" | "edit" | "delete";

export const OCIQueryTable = ({ tenant }: OCIQueryTableProps) => {
  const { t } = useTranslation(["common", "entity"]);
  const { mutate: deleteOCIQuery, isPending: isDeletePending } =
    useDeleteOCIQueryMutation({ tenantId: tenant?.id });

  const [selectedRow, setSelectedRow] = useState<{
    ociQuery?: OCIQueryResponseContentItem;
    action: OCIQueryActionType;
  } | null>(null);

  const { data: compartments, isFetching: areCompartmentsFetching } =
    useFetchCompartmentsQuery();

  const memoizedCompartments = useMemo(() => {
    return compartments?.filter((c) => c.tenant.tenantId === tenant?.id) || [];
  }, [compartments, tenant?.id]);

  const { data, isLoading, isFetching } = useFetchOCIQueriesQuery({
    enabled: !areCompartmentsFetching,
    params: {
      tenantId: tenant?.id,
    },
    select: (data) =>
      data
        .map((ociQuery) => {
          const compartmentName = memoizedCompartments.find(
            (compartment) => compartment.id === ociQuery.compartmentId,
          )?.name;

          return ociQuery.ociResources?.map((resource) => ({
            ...ociQuery,
            resourceOcid: resource.ocid,
            compartmentName,
            activeLocalized: t(
              `common:boolean.${ociQuery.active}` as ParseKeys,
            ),
          }));
        })
        .flat(),
  });

  const memoizedData = useMemo(() => {
    return data || [];
  }, [data]);

  const titleConfig: Record<OCIQueryActionType, string> = {
    delete: t("entity:ociQuery.deleteOciQuery"),
    create: t("entity:ociQuery.addOciQuery"),
    edit: t("entity:ociQuery.editOciQuery"),
  };

  const title = selectedRow ? titleConfig[selectedRow.action] : null;

  const handleSettled = () => setSelectedRow(null);

  const handleDeleteOCIQuery = () => {
    if (!selectedRow?.ociQuery) return;

    deleteOCIQuery(selectedRow.ociQuery.id, {
      onSettled: handleSettled,
    });
  };

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableGrouping: true,
    state: {
      showProgressBars: isFetching || areCompartmentsFetching,
      isLoading,
    },
    initialState: {
      showColumnFilters: true,
      grouping: ["compartmentName", "resourceOcid"],
    },
    mantineToolbarAlertBannerProps: {
      display: "none",
    },
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Menu.Item
          onClick={() => {
            setSelectedRow({
              ociQuery: row.original,
              action: "edit",
            });
          }}
        >
          {t("common:userActions.edit")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            setSelectedRow({
              ociQuery: row.original,
              action: "delete",
            })
          }
        >
          {t("common:userActions.delete")}
        </Menu.Item>
      </>
    ),
    renderTopToolbarCustomActions: () => (
      <Button
        className="ml-auto"
        onClick={() => setSelectedRow({ action: "create" })}
      >
        {t("entity:ociQuery.addOciQuery")}
      </Button>
    ),
  });

  return (
    <div className="flex flex-1 flex-col overflow-auto">
      <Title order={2} className="mb-4">
        {t("entity:ociQuery.ociQuery")}
      </Title>

      <MantineReactTable table={table} />

      <Modal
        opened={
          selectedRow?.action === "create" || selectedRow?.action == "edit"
        }
        onClose={handleSettled}
        title={title}
        size="xl"
      >
        <OCIQueryDetails
          ociQuery={selectedRow?.ociQuery}
          onSettled={handleSettled}
        />
      </Modal>

      <Modal
        opened={selectedRow?.action === "delete"}
        onClose={handleSettled}
        title={
          <Box c="red.8" className="flex items-center gap-2">
            <IconAlertTriangle />
            <span className="font-semibold">{t("common:warning")}</span>
          </Box>
        }
      >
        <Text className="mb-4" variant="text" size="sm">
          {title}
        </Text>
        <div className="flex justify-between gap-2">
          <Button
            variant="outline"
            disabled={isDeletePending}
            onClick={handleSettled}
          >
            {t("common:userActions.cancel")}
          </Button>
          <Button
            onClick={handleDeleteOCIQuery}
            loading={isDeletePending}
            disabled={isDeletePending}
          >
            {t("common:userActions.confirm")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
