import { Paper, TextInput, Button } from "@mantine/core";
import { zodResolver } from "@mantine/form";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { z } from "zod";

import { PermissionControl } from "@/components/PermissionControl";
import { toast } from "@/components/ui/use-toast";
import { useCreateSystemUserMutation } from "@/hooks/api/useCreateSystemUserMutation";
import { useTForm } from "@/hooks/useTForm";
import { Claim } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface CreateSystemUserPageProps {
  onSettled?: () => void;
  className?: string;
}

const systemUserSchema = z.object({
  email: z
    .string()
    .min(1, "form:field.required")
    .email("entity:administration.organization.emailValid"),
  name: z.string().min(1, "form:field.required"),
});

type SystemUserSchema = z.infer<typeof systemUserSchema>;

export const CreateSystemUserPage = ({
  className,
  onSettled,
}: CreateSystemUserPageProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const form = useTForm<SystemUserSchema>({
    initialValues: {
      email: "",
      name: "",
    },
    validate: zodResolver(systemUserSchema),
  });
  const { mutate, isPending } = useCreateSystemUserMutation();

  const onSubmit = (data: SystemUserSchema) => {
    mutate(data, {
      onSuccess: () => {
        form.reset();
        toast({
          title: t("administration.organization.createUserSuccess"),
        });
      },
      onError: (error) => {
        if (isAxiosError(error) && error.response?.data?.message) {
          toast({
            title: error.response.data.message,
            variant: "destructive",
          });
        } else {
          toast({
            title: t("administration.organization.createUserError"),
            variant: "destructive",
          });
        }
      },
      onSettled,
    });
  };

  return (
    <PermissionControl
      permission={Claim.SUPERADMIN}
      fallbackContent={<Navigate to="/entity/users" replace />}
    >
      <Paper
        component="form"
        className={cn("flex flex-col gap-2", className)}
        onSubmit={form.onSubmit(onSubmit)}
      >
        <TextInput
          withAsterisk
          label={t("administration.common.email")}
          {...form.getInputProps("email")}
        />
        <TextInput
          withAsterisk
          label={t("administration.common.personName")}
          {...form.getInputProps("name")}
        />
        <div className="mt-2 flex justify-between gap-2">
          <Button variant="light" disabled={isPending} onClick={onSettled}>
            {t("common:userActions.cancel")}
          </Button>
          <Button type="submit" disabled={isPending}>
            {t("common:userActions.save")}
          </Button>
        </div>
      </Paper>
    </PermissionControl>
  );
};
