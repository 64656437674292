import { useMutation } from "@tanstack/react-query";

import { Api } from "@/lib/api";

import { resourceKeys } from "./queryKeys";

export const useStartManageableResourceMutation = () => {
  return useMutation({
    mutationKey: resourceKeys.startResource(),
    mutationFn: Api.resource.startResource,
  });
};
