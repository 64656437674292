import {
  Button,
  Checkbox,
  LoadingOverlay,
  NumberInput,
  ScrollArea,
  Select,
} from "@mantine/core";
import { zodResolver } from "mantine-form-zod-resolver";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { TreeSelect } from "@/components/ui/tree-select";
import { useTenantContext } from "@/context/TenantContext";
import { useCreateOrUpdateOCIQueryMutation } from "@/hooks/api/useCreateOrUpdateOCIQueryMutation";
import {
  getTreeChildren,
  nestCompartmentStructure,
  useFetchCompartmentStructureQuery,
} from "@/hooks/api/useFetchCompartmentStructureQuery";
import { useFetchListMetricNameQuery } from "@/hooks/api/useFetchListMetricNameQuery";
import { useFetchListMetricNamespaceQuery } from "@/hooks/api/useFetchListMetricNamespaceQuery";
import { useFetchListMetricsResourceQuery } from "@/hooks/api/useFetchListMetricsResourceQuery";
import { useFetchStatisticTypesQuery } from "@/hooks/api/useFetchStatisticTypesQuery";
import { useFetchUnitTypesQuery } from "@/hooks/api/useFetchUnitTypesQuery";
import { useTForm } from "@/hooks/useTForm";
import { OCIQueryResponseContentItem } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

const requiredNullableString = z
  .string()
  .nullable()
  .refine((value) => !!value, { message: "form:field.required" });

const schema = z.object({
  compartmentId: requiredNullableString,
  metricNamespaceId: requiredNullableString,
  metricNameId: requiredNullableString,
  unitTypeId: requiredNullableString,
  statisticTypeId: requiredNullableString,
  storageResultTypeId: requiredNullableString,
  dimensionName: requiredNullableString,
  dimensionValue: requiredNullableString,
  startTimeOffset: z.coerce
    .number()
    .refine((value) => value > 0, { message: "form:field.required" }),
  isActive: z.boolean(),
});

type FormData = z.infer<typeof schema>;

const allowedDimensionKeys = ["resourceId", "resourceDisplayName"];

const defaultValues: FormData = {
  compartmentId: "",
  metricNameId: "",
  metricNamespaceId: "",
  statisticTypeId: "",
  storageResultTypeId: "",
  unitTypeId: "",
  dimensionName: "",
  dimensionValue: "",
  isActive: true,
  startTimeOffset: 1,
};

const mapOCIQueryToFormData = (
  ociQuery?: OCIQueryResponseContentItem,
): FormData => {
  if (!ociQuery) return defaultValues;

  return {
    compartmentId: ociQuery?.compartmentId ?? defaultValues?.compartmentId,
    metricNameId:
      ociQuery?.metricName?.metricName ?? defaultValues?.metricNameId,
    metricNamespaceId:
      ociQuery?.metricNamespace?.metricNamespace ??
      defaultValues?.metricNamespaceId,
    statisticTypeId:
      `${ociQuery?.statisticType?.id}` ?? defaultValues.statisticTypeId,
    storageResultTypeId:
      `${ociQuery?.storageResultType?.id}` ?? defaultValues.storageResultTypeId,
    unitTypeId: `${ociQuery?.unitType.id}` ?? defaultValues.unitTypeId,
    dimensionName: allowedDimensionKeys[0],
    dimensionValue: ociQuery?.resourceOcid ?? defaultValues.dimensionValue,
    isActive: ociQuery.active ?? defaultValues.isActive,
    startTimeOffset: ociQuery.startTimeOffset ?? defaultValues.startTimeOffset,
  };
};

const buildQueryString = ({
  metricNameId,
  unitType,
  dimensionName,
  dimensionValue,
  statisticType,
}: {
  metricNameId: string;
  unitType: string;
  dimensionName: string | null;
  dimensionValue: string | null;
  statisticType: string;
}) => {
  return `${metricNameId}[${unitType}]${
    dimensionName && dimensionValue ?
      `{${dimensionName} = "${dimensionValue}"}`
    : ""
  }.${statisticType}`;
};

interface OCIQueryDetailsProps {
  ociQuery?: OCIQueryResponseContentItem;
  onSettled?: () => void;
  className?: string;
}

export const OCIQueryDetails = ({
  className,
  ociQuery,
  onSettled,
}: OCIQueryDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);
  const { tenant } = useTenantContext();
  const { mutate, isPending } = useCreateOrUpdateOCIQueryMutation({
    id: ociQuery?.id,
  });

  const form = useTForm<FormData>({
    initialValues: mapOCIQueryToFormData(ociQuery),
    validate: zodResolver(schema),
  });

  const {
    compartmentId,
    metricNamespaceId,
    metricNameId,
    unitTypeId,
    statisticTypeId,
    dimensionName,
    dimensionValue,
  } = form.getValues();

  const { data: compartments } = useFetchCompartmentStructureQuery({
    params: { tenantocid: tenant?.ocid },
    select: (data) => nestCompartmentStructure(data),
  });

  const compartmentOcid = useMemo(
    () =>
      compartments?.find((c) =>
        compartmentId ? c.compartmentId === compartmentId : false,
      )?.compartmentOcid,
    [compartments, compartmentId],
  );

  const { data: metricNamespaces, isFetching: areMetricNamespacesFetching } =
    useFetchListMetricNamespaceQuery({
      enabled: !!(tenant?.ocid && compartmentOcid),
      params:
        compartmentOcid && tenant?.ocid ?
          {
            tenantocid: tenant.ocid,
            compartmentocid: compartmentOcid,
          }
        : void 0,
    });

  const { data: metricNames, isFetching: areMetricNamesFetching } =
    useFetchListMetricNameQuery({
      enabled: !!(tenant?.ocid && compartmentOcid && metricNamespaceId),
      params:
        tenant && compartmentOcid && metricNamespaceId ?
          {
            tenantocid: tenant?.ocid,
            compartmentocid: compartmentOcid,
            namespace: metricNamespaceId,
          }
        : void 0,
    });

  const { data: unitTypes, isFetching: areUnitTypesFetching } =
    useFetchUnitTypesQuery();

  const { data: statisticTypes, isFetching: areStatisticTypesFetching } =
    useFetchStatisticTypesQuery();

  const { data: dimensions, isFetching: areDimensionsFetching } =
    useFetchListMetricsResourceQuery({
      enabled: !!(
        tenant?.ocid &&
        compartmentOcid &&
        metricNamespaceId &&
        metricNameId
      ),
      params:
        tenant && compartmentOcid && metricNamespaceId && metricNameId ?
          {
            tenantocid: tenant.ocid,
            compartmentocid: compartmentOcid,
            namespace: metricNamespaceId,
            name: metricNameId,
          }
        : void 0,
    });

  const metricNamespacesOptions = useMemo(
    () =>
      metricNamespaces?.map((mn) => ({
        label: mn.namespaceID || mn.namespace,
        value: mn.namespace,
        disabled: !mn.namespaceID,
      })) || [],
    [metricNamespaces],
  );

  const metricNameOptions = useMemo(
    () =>
      metricNames?.map((mn) => ({
        label: mn.nameId || mn.name,
        value: mn.nameId || mn.name,
        disabled: !mn.nameId,
      })) || [],
    [metricNames],
  );

  const unitTypeOptions = useMemo(
    () =>
      unitTypes?.map((unitType) => ({
        label: unitType.name,
        value: `${unitType.id}`,
      })) || [],
    [unitTypes],
  );

  const statisticTypeOptions = useMemo(
    () =>
      statisticTypes?.map((statisticType) => ({
        label: statisticType.name,
        value: `${statisticType.id}`,
      })) || [],
    [statisticTypes],
  );

  const storageResultTypeOptions = useMemo(
    () => [
      { label: "Relational", value: "1" },
      { label: "NoSQL", value: "2" },
    ],
    [],
  );

  const { dimensionKeys, dimensionValues } = useMemo(() => {
    return (
      dimensions?.reduce<{
        dimensionKeys: string[];
        dimensionValues: Record<string, string[]>;
      }>(
        (acc, dimension) => {
          Object.entries(dimension.dimensions).forEach(([key, value]) => {
            if (allowedDimensionKeys.includes(key)) {
              if (!acc.dimensionKeys.includes(key)) {
                acc.dimensionKeys.push(key);
              }

              acc.dimensionValues[key] ||= [];
              if (!acc.dimensionValues[key].includes(value)) {
                acc.dimensionValues[key].push(value);
              }
            }
          });

          return acc;
        },
        {
          dimensionKeys: [],
          dimensionValues: {},
        },
      ) || { dimensionKeys: [], dimensionValues: {} }
    );
  }, [dimensions]);

  const dimensionValueOptions = useMemo(
    () =>
      dimensionName ?
        dimensionValues[dimensionName]?.map((value) => ({
          label: value,
          value,
        }))
      : [],
    [dimensionName, dimensionValues],
  );

  const statisticType = statisticTypes?.find(
    (st) => `${st.id}` === statisticTypeId,
  )?.name;

  const unitType = unitTypes?.find((ut) => `${ut.id}` === unitTypeId)?.name;

  const loadingOverlayIsVisible =
    areMetricNamespacesFetching ||
    areMetricNamesFetching ||
    areUnitTypesFetching ||
    areStatisticTypesFetching ||
    areDimensionsFetching;

  const onSubmit = (data: FormData) => {
    const {
      compartmentId,
      metricNamespaceId,
      metricNameId,
      unitTypeId,
      statisticTypeId,
      storageResultTypeId,
      dimensionName,
      dimensionValue,
      startTimeOffset,
      isActive,
    } = data;

    const resource =
      dimensionName === allowedDimensionKeys[0] ?
        dimensionValue
      : dimensions?.find((d) => d.dimensions[dimensionName!] === dimensionValue)
          ?.dimensions.resourceSystemId;

    if (
      tenant?.id &&
      compartmentId &&
      metricNamespaceId &&
      metricNameId &&
      unitTypeId &&
      statisticTypeId &&
      storageResultTypeId
    ) {
      mutate(
        {
          compartmentId,
          metricNamespaceId,
          metricNameId,
          tenantId: tenant.id,
          resourcesIds: [resource!],
          unitTypeId: +unitTypeId,
          statisticTypeId: +statisticTypeId,
          storageResultTypeId: +storageResultTypeId,
          startTimeOffset,
          isActive,
        },
        { onSettled },
      );
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <LoadingOverlay visible={loadingOverlayIsVisible} zIndex={20} />
      <div className={cn("grid gap-4 md:grid-cols-2", className)}>
        <TreeSelect
          isParentSelectable
          clearable={!ociQuery?.id}
          label={t("ociQuery.compartment")}
          {...form.getInputProps("compartmentId")}
          onChange={(value) => {
            form.setFieldValue("compartmentId", value as string);
            form.setFieldValue("metricNamespaceId", null);
            form.setFieldValue("metricNameId", null);
            form.setFieldValue("dimensionName", null);
            form.setFieldValue("dimensionValue", null);
          }}
          data={[
            {
              value: "tenant",
              label: tenant?.name,
              selectable: false,
              children: compartments?.map((c) =>
                getTreeChildren(c, compartments, "id"),
              ),
            },
          ]}
          disabled={!!ociQuery?.id}
        />
        <Select
          clearable
          label={t("entity:ociQuery.metricNamespace")}
          {...form.getInputProps("metricNamespaceId")}
          onChange={(value) => {
            form.setFieldValue("metricNamespaceId", value);
            form.setFieldValue("metricNameId", null);
            form.setFieldValue("dimensionName", null);
            form.setFieldValue("dimensionValue", null);
          }}
          data={metricNamespacesOptions}
          disabled={areMetricNamespacesFetching || !compartmentOcid}
        />
        <Select
          clearable
          label={t("entity:ociQuery.metricName")}
          {...form.getInputProps("metricNameId")}
          onChange={(value) => {
            form.setFieldValue("metricNameId", value);
            form.setFieldValue("dimensionName", null);
            form.setFieldValue("dimensionValue", null);
          }}
          data={metricNameOptions}
          disabled={
            areMetricNamesFetching ||
            areMetricNamespacesFetching ||
            !metricNamespaceId
          }
        />
        <Select
          clearable
          label={t("entity:ociQuery.unitType")}
          {...form.getInputProps("unitTypeId")}
          data={unitTypeOptions}
          disabled={areUnitTypesFetching}
        />
        <Select
          clearable
          label={t("entity:ociQuery.statisticType")}
          {...form.getInputProps("statisticTypeId")}
          data={statisticTypeOptions}
          disabled={areStatisticTypesFetching}
        />
        <Select
          clearable
          label={t("entity:ociQuery.storageResultType")}
          {...form.getInputProps("storageResultTypeId")}
          data={storageResultTypeOptions}
        />
        <Select
          clearable
          label={t("entity:ociQuery.dimensionName")}
          {...form.getInputProps("dimensionName")}
          onChange={(value) => {
            form.setFieldValue("dimensionName", value);
            form.setFieldValue("dimensionValue", null);
          }}
          data={dimensionKeys.map((value) => ({ value: value, label: value }))}
          disabled={!metricNameId}
        />
        <Select
          clearable
          label={t("entity:ociQuery.dimensionValue")}
          {...form.getInputProps("dimensionValue")}
          data={dimensionValueOptions}
          disabled={!dimensionName}
        />
        <NumberInput
          label={t("entity:ociQuery.startTimeOffset")}
          rightSectionProps={{ className: "hidden" }}
          {...form.getInputProps("startTimeOffset")}
        />
        <Checkbox
          className="md:mt-6"
          label={t("entity:ociQuery.active")}
          {...form.getInputProps("isActive", {
            type: "checkbox",
          })}
        />
      </div>
      <ScrollArea className="mt-4">
        <pre className="text-wrap rounded-md bg-gray-100 px-4 py-2 text-sm">
          {metricNameId && unitType && statisticType ?
            buildQueryString({
              metricNameId,
              unitType,
              dimensionName,
              dimensionValue,
              statisticType,
            })
          : t("entity:ociQuery.ociQuery")}
        </pre>
      </ScrollArea>
      <div className="mt-4 flex justify-end gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </form>
  );
};
