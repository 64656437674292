import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { OrganizationResponse } from "@/lib/api/dto";
import { parseArrayFromString } from "@/lib/utils";

import { codebookKeys } from "./queryKeys";

interface UseFetchOrganizationsQueryProps<TSelectData>
  extends Omit<
    UseQueryOptions<OrganizationResponse[], Error, TSelectData>,
    "queryKey"
  > { }

export const useFetchOrganizationsQuery = <T = OrganizationResponse[]>(
  props?: UseFetchOrganizationsQueryProps<T>
) => {
  return useQuery({
    queryFn: () => Api.organizations.list().then((res) => res.data),
    ...props,
    select: data => {
      const result = data.map(d => ({ ...d, phone: parseArrayFromString(d.phone) }));
      if (props?.select) {
        return props.select(data);
      }
      return result
    },
    queryKey: codebookKeys.organizations(),
  });
};
