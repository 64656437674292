import { createMRTColumnHelper } from "mantine-react-table";

import { MetricNameResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<MetricNameResponse>();

export const columns = [
  columnHelper.accessor("metricName", {
    header: "entity:administration.metricName.metricName",
  }),
  columnHelper.accessor("name", {
    header: "entity:administration.metricName.name",
  }),
  columnHelper.accessor("description", {
    header: "entity:administration.metricName.description",
  }),
  columnHelper.accessor("metricNamespace.metricNamespace", {
    header: "entity:administration.metricName.metricNamespace",
  }),
  columnHelper.accessor("metricMeasuringUnit.metricMeasuringUnit", {
    header: "entity:administration.metricName.metricMeasuringUnit",
  }),
];
