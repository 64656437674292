import {
  Stack,
  Group,
  Select,
  Button,
  Title,
  Modal,
  Text,
  Box,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconAlertTriangle, IconPlus } from "@tabler/icons-react";
import { TableMeta } from "@tanstack/table-core";
import { endOfMonth } from "date-fns";
import format from "date-fns/format";
import { MantineReactTable, createMRTColumnHelper } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconDelete } from "@/components/ui/action-icon-delete";
import { toast } from "@/components/ui/use-toast";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useCreateBudgetNotificationMutation } from "@/hooks/api/useCreateBudgetNotificationMutation";
import { useDeleteBudgetNotificationMutation } from "@/hooks/api/useDeleteBudgetNotificationMutation";
import { useFetchBudgetNotificationsQuery } from "@/hooks/api/useFetchBudgetNotificationsQuery";
import { useFetchSubscriptionsQuery } from "@/hooks/api/useFetchSubscriptionsQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useMRT } from "@/hooks/useMRT";
import {
  BudgetNotificationResponse,
  BudgetNotificationType,
  BudgetNotificationValueDto,
  CreateBudgetNotificationPayload,
} from "@/lib/api/dto";
import { formatToDate } from "@/lib/dateUtils";
import { formatNumberAsCurrency } from "@/lib/utils";
import {
  BudgetNotificationFormValues,
  CreateBudgetNotificationForm,
} from "@/widgets/CreateBudgetNotificationForm";
import { DashboardFifthDiagramReport } from "@/widgets/DashboardReports/DashboardFifthDiagramReport";
import { DashboardKPIReport } from "@/widgets/DashboardReports/DashboardKPIReport";
import { DashboardThirdDiagramReport } from "@/widgets/DashboardReports/DashboardThirdDiagramReport";

const notificationOptions = [
  { value: BudgetNotificationType.All, label: "ALL" },
  { value: BudgetNotificationType.Multiple, label: "MULTIPLE" },
  { value: BudgetNotificationType.Single, label: "SINGLE" },
];

const columnHelper = createMRTColumnHelper<
  BudgetNotificationResponse &
    BudgetNotificationValueDto & { subRows?: BudgetNotificationResponse[] }
>();

const columns = [
  columnHelper.accessor("subscription.subscriptionID", {
    header: "notification:budget.subscription",
    editVariant: "select",
    enableGrouping: false,
  }),
  columnHelper.accessor("notificationType", {
    header: "notification:budget.notificationType",
    editVariant: "select",
    enableGrouping: false,
  }),
  columnHelper.accessor("tenancies", {
    header: "notification:budget.tenants",
    Cell: ({ cell }) =>
      cell
        .getValue()
        ?.map((tenancy) => tenancy.name)
        .join(", "),
  }),
  columnHelper.accessor("startDate", {
    header: "notification:budget.beginning",
    Cell: ({ cell }) => formatToDate(cell.getValue()),
  }),
  columnHelper.accessor("endDate", {
    header: "notification:budget.end",
    Cell: ({ cell }) => formatToDate(cell.getValue()),
  }),
  columnHelper.accessor("value", {
    header: "notification:budget.value",
    Cell: ({ cell, row, table }) => {
      if (!row.depth) return;
      return formatNumberAsCurrency({
        value: cell.getValue(),
        currency: (table.options.meta as TableMeta<BudgetNotificationValueDto>)
          ?.currency,
        options: {
          locale: (table.options.meta as TableMeta<BudgetNotificationValueDto>)
            ?.currencyLocale,
        },
      });
    },
  }),
  columnHelper.display({
    id: "emails",
    header: "notification:budget.emails",
    Cell: ({ row }) =>
      row.original.emails?.map(({ email }) => email).join(", "),
  }),
];

export const BudgetNotificationsPage = () => {
  const { t } = useTranslation(["notification", "common"]);

  const { tenant } = useTenantContext();
  const currencyLocale = useCurrencyLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;
  const [isCreateModalOpen, createModal] = useDisclosure(false);
  const [selectedNotificationType, setSelectedNotificationType] =
    useState<BudgetNotificationType | null>(null);
  const { data: subscriptions = [] } = useFetchSubscriptionsQuery();

  const createBudgetNotificationMutation =
    useCreateBudgetNotificationMutation();
  const subscriptionOptions = subscriptions.map((subscription) => ({
    value: subscription.id,
    label: subscription.subscriptionID.toString(),
  }));

  const [selectedBudgetNotification, setSelectedBudgetNotification] =
    useState<BudgetNotificationResponse | null>();

  const { mutate: deleteBudgetNotification, isPending: isDeleting } =
    useDeleteBudgetNotificationMutation();

  const { data: budgetNotifications = [] } = useFetchBudgetNotificationsQuery();

  const tableData = useMemo(
    () =>
      budgetNotifications
        ?.filter(
          (bn) =>
            (selectedNotificationType ?
              bn.notificationType === selectedNotificationType
            : true) && tenant?.id === bn.tenant.tenantId,
        )
        .map((notification) => ({
          ...notification,
          startDate: notification.values.reduce(
            (acc, cv) => (acc < cv.startDate ? acc : cv.startDate),
            notification.values[0].startDate,
          ),
          endDate: notification.values.reduce(
            (acc, cv) => (acc > cv.endDate ? acc : cv.endDate),
            notification.values[0].endDate,
          ),
          subRows: notification.values.map((value) => value),
        }))
        .flat(),
    [budgetNotifications, selectedNotificationType, tenant?.id],
  );

  const table = useMRT({
    enableTopToolbar: false,
    enableStickyFooter: true,
    enableStickyHeader: true,
    enableColumnDragging: false,
    paginateExpandedRows: false,
    mantineExpandButtonProps: {
      c: "gray.7",
    },
    mantineExpandAllButtonProps: {
      c: "gray.7",
    },
    mantineTableBodyRowProps: ({ row }) => ({
      style: {
        "--mrt-base-background-color": `var(--mantine-color-gray-${
          (row.originalSubRows?.length || 0) > 0 ? 3 : 1
        })`,
        "--mrt-row-hover-background-color": "var(--mantine-color-gray-2)",
      },
    }),
    getRowCanExpand(row) {
      return row.subRows?.length > 0;
    },
    enableExpanding: true,
    enableRowActions: true,
    renderRowActions: ({ row }) =>
      !row.depth &&
      !isDeleting && (
        <ActinIconDelete
          onClick={() => setSelectedBudgetNotification(row.original)}
        />
      ),
    meta: {
      subscriptions: subscriptionOptions,
      notificationType: selectedNotificationType,
      currency,
      currencyLocale,
    },
    mantinePaperProps: {
      className: "flex flex-col flex-1",
    },
    mantineTableContainerProps: {
      className: "flex-1",
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: columns as any[],
    data: tableData,
    renderBottomToolbarCustomActions: () => (
      <Button
        color="blue"
        size="compact-lg"
        leftSection={<IconPlus />}
        onClick={createModal.open}
      >
        {t("budget.create")}
      </Button>
    ),
  });

  const onCreateBudgetNotification = async (
    values: BudgetNotificationFormValues,
  ) => {
    if (!tenant) {
      return;
    }

    const payload: CreateBudgetNotificationPayload = {
      budgetNotificationType: values.notificationType,
      subscription: tenant.currentSubscription!.id!,
      tenant: tenant.id,
      values: values.values.map((value) => ({
        startDate: format(new Date(value.datefrom), "MM-yyyy"),
        endDate: format(endOfMonth(new Date(value.dateto)), "MM-yyyy"),
        value: value.value,
      })),
      emails: values.emails,
    };

    if (values.tenants?.length) {
      payload["tenancies"] = values.tenants;
    }

    await createBudgetNotificationMutation.mutateAsync(payload, {
      onSuccess: () => {
        createModal.close();
        toast({
          title: "Uspešno kreirana notifikacija",
          description: "Notifikacija je uspešno kreirana",
        });
      },
    });
  };

  const handleDeleteCompartmentBudgetNotification = () => {
    if (selectedBudgetNotification?.id) {
      deleteBudgetNotification(selectedBudgetNotification?.id, {
        onSettled: () => handleCloseConfirmModal(),
      });
    }
  };

  const handleCloseConfirmModal = () => {
    if (!isDeleting) setSelectedBudgetNotification(null);
  };

  return (
    <Group className="flex-1 items-stretch gap-0 overflow-auto" wrap="nowrap">
      <Stack className="flex-shrink-0 basis-64 border-r p-4">
        <Select
          clearable
          data={notificationOptions}
          value={selectedNotificationType}
          label={t("budget.notificationType")}
          onChange={(value) =>
            setSelectedNotificationType(value as BudgetNotificationType)
          }
        />
      </Stack>

      <div className="flex flex-1 flex-col gap-2 overflow-auto p-4">
        <Title className="mb-4" size="h2">
          {t("budget.budgetNotification")}
        </Title>

        <MantineReactTable table={table} />
      </div>

      <Stack className="basis-96 overflow-auto border-l p-4">
        <DashboardKPIReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />

        <DashboardFifthDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />

        <DashboardThirdDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID}
        />
      </Stack>

      <Modal
        centered
        size={960}
        title={t("budget.budgetNotificationCreate")}
        opened={isCreateModalOpen}
        onClose={createModal.close}
      >
        <CreateBudgetNotificationForm
          tenant={tenant?.name || ""}
          subscription={
            tenant?.currentSubscription?.subscriptionID.toString() || ""
          }
          onCancel={createModal.close}
          onSubmit={onCreateBudgetNotification}
        />
      </Modal>
      <Modal
        opened={!!selectedBudgetNotification}
        onClose={handleCloseConfirmModal}
        title={
          <Box c="red.8" className="flex items-center gap-2">
            <IconAlertTriangle />
            <span className="font-semibold">{t("common:warning")}</span>
          </Box>
        }
      >
        <Text className="mb-4" variant="text" size="sm">
          {t("budget.confirmDelete")}
        </Text>
        <div className="flex justify-between gap-2">
          <Button variant="light" onClick={handleCloseConfirmModal}>
            {t("common:userActions.cancel")}
          </Button>
          <Button
            onClick={handleDeleteCompartmentBudgetNotification}
            loading={isDeleting}
            disabled={isDeleting}
          >
            {t("common:userActions.confirm")}
          </Button>
        </div>
      </Modal>
    </Group>
  );
};
