import { type SelectProps, Select } from "@mantine/core";
import { orderBy } from "lodash";
import { useEffect, useMemo } from "react";

import { cn } from "@/lib/utils";

enum ExtendedEnumType {
  All = "__all__",
}

type EnumDto = {
  value: string;
  label: string;
  code: string | null;
};

type EnumPropertyDto = {
  value: string;
  selected: boolean;
  visible: boolean;
  disabled: boolean;
};

export type EnumSelectData = {
  multiselect: boolean;
  order: "asc" | "desc" | null;
  basicEnums?: EnumDto[] | null;
  extendedEnums?: EnumDto[] | null;
  properties: EnumPropertyDto[] | null;
};

export interface EnumSelectProps<TValue extends string>
  extends Omit<SelectProps, "value" | "data" | "allowDeselect" | "onChange"> {
  value?: TValue | null;
  data?: EnumSelectData | null;
  /** Determines whether it should be possible to deselect value by clicking on the selected option, `false` by default */
  allowDeselect?: boolean;
  /** Separator for multiple values, comma `,` by default */
  valueSeparator?: string;
  onChange?: (value: TValue | null) => void;
}

export const EnumSelect = <TValue extends string>({
  value,
  data,
  allowDeselect = false,
  valueSeparator = ",",
  classNames,
  onChange,
  ...rest
}: EnumSelectProps<TValue>) => {
  const { basicEnums, extendedEnums, properties, order } = data || {};

  const basicVisibleOptions = useMemo(() => {
    const basicOptions =
      basicEnums
        ?.map(({ value, label }) => {
          return createOption(value, label, properties);
        })
        .filter((option) => option.visible) || [];

    return order ? orderBy(basicOptions, "label", order) : basicOptions;
  }, [basicEnums, order, properties]);

  const allBasicVisibleNonDisabledOptionValues = useMemo(
    () =>
      basicVisibleOptions
        .filter((option) => !option.disabled)
        .map((option) => option.value)
        .join(valueSeparator),
    [basicVisibleOptions, valueSeparator],
  );

  const extendedVisibleOptions = useMemo(
    () =>
      extendedEnums
        ?.map(({ value, label }) => {
          const option = createOption(value, label, properties);

          if (value === ExtendedEnumType.All) {
            return {
              ...option,
              value: allBasicVisibleNonDisabledOptionValues,
            };
          }

          return option;
        })
        .filter((option) => option.visible) || [],
    [allBasicVisibleNonDisabledOptionValues, extendedEnums, properties],
  );

  const initiallySelectedOptions = useMemo(() => {
    const isAllInitiallySelected = properties?.some(
      (property) =>
        property.value === ExtendedEnumType.All &&
        property.selected &&
        property.visible,
    );

    if (isAllInitiallySelected) {
      return allBasicVisibleNonDisabledOptionValues;
    }

    return (
      properties
        ?.filter((property) => property.selected && property.visible)
        .map((property) => property.value)
        .join(valueSeparator) ?? null
    );
  }, [properties, allBasicVisibleNonDisabledOptionValues, valueSeparator]);

  const options = useMemo(
    () => [
      {
        group: "",
        items: extendedVisibleOptions,
      },
      ...basicVisibleOptions,
    ],
    [extendedVisibleOptions, basicVisibleOptions],
  );

  const handleChange = (value: string | null) => {
    onChange?.(value as TValue | null);
  };

  /*
    Pass the initially selected options if exists, 
    which are typically predefined by data from the backend obtained via an API call,
    to the onChange function if no value is provided initially.
  */
  useEffect(() => {
    if (initiallySelectedOptions && !value) {
      onChange?.(initiallySelectedOptions as TValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiallySelectedOptions]);

  return (
    <Select
      value={value}
      data={options}
      allowDeselect={allowDeselect}
      classNames={{
        ...classNames,
        group: cn(classNames && "group" in classNames && classNames.group, {
          "border-b": !!extendedVisibleOptions.length,
        }),
      }}
      onChange={handleChange}
      {...rest}
    />
  );
};

const createOption = (
  value: string,
  label: string,
  properties?: EnumPropertyDto[] | null,
) => {
  const property = properties?.find((property) => property.value === value);

  return {
    value,
    label,
    disabled: property?.disabled ?? false,
    visible: property?.visible ?? true,
  };
};
