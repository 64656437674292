import { TreeNodeData } from "@mantine/core";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { type CurrencyCode } from "@/constants/currencies";

import { Claim, UserResponse } from "./api/dto";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const userHasPermission = (
  user: UserResponse,
  permission: Claim | Claim[],
  mode?: "all" | "any",
): boolean => {
  return (
    Array.isArray(permission) ?
      mode === "all" ?
        permission.every((p) => user.authorities.includes(p))
      : permission.some((p) => user.authorities.includes(p))
    : user.authorities.includes(permission)
  );
};

export const enumToArray = (e: Record<string, string | number>) => {
  return Object.keys(e).map((key) => e[key]);
};

export const parseArrayFromString = (value: unknown) => {
  if (Array.isArray(value)) return value;
  if (typeof value === "string") {
    let retVal: string[];
    try {
      const parsedValue = JSON.parse(value);
      retVal = Array.isArray(parsedValue) ? parsedValue : [`${parsedValue}`];
    } catch {
      retVal = [value];
    }
    return retVal;
  }
  return [];
};

export const breakString = (inputString: string, maxLength = 15) => {
  if (inputString.length <= maxLength) {
    return [inputString];
  } else {
    const words = inputString.split(" ");
    const optimalStrings = [];
    let currentString = "";

    for (let i = 0; i < words.length; i++) {
      if ((currentString + words[i]).length <= maxLength) {
        currentString += words[i] + " ";
      } else {
        optimalStrings.push(currentString.trim());
        currentString = words[i] + " ";
      }
    }

    if (currentString.trim().length > 0) {
      optimalStrings.push(currentString.trim());
    }

    return optimalStrings;
  }
};

export const getColor = (
  colors: string[],
  index: number,
  transparent = false,
) => {
  return transparent ?
      `${colors[index % colors.length]}8e`
    : colors[index % colors.length];
};

export const numberFormat = new Intl.NumberFormat("en-US");

const byteUnits = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
const genericUnits = ["", "K", "M", "B", "T", "P", "E", "Z", "Y"];
const unitMap = {
  bytes: byteUnits,
  generic: genericUnits,
};

export const formatUnits = (
  value: number,
  {
    decimals = 2,
    format,
  }: {
    decimals?: number;
    format?: keyof typeof unitMap;
  },
) => {
  const sizes = unitMap[format || "generic"] || genericUnits;
  if (!+value) return `0 ${sizes[0]}`;

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(value) / Math.log(k));

  return `${parseFloat((value / Math.pow(k, i)).toFixed(dm))} ${
    sizes[i] || ""
  }`.trim();
};

/**
 * Format provided value `e.g. 55.25 -> 55.25%`
 *
 * @param value number to format with `%` sign
 * @param fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
 * @returns `value` with `%` sign
 */
export const formatNumberAsPercentage = (
  value: number,
  fractionDigits: number = 2,
) => {
  if (value === 100 || value === 0) return `${value}%`;
  return `${value.toFixed(fractionDigits)}%`;
};

export type FormatNumberAsCurrencyOptions = Omit<
  Intl.NumberFormatOptions,
  "style" | "currency"
> & {
  /**
   * The locale or locales to use
   *
   * See more about locales on [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument)
   * | [iana.org](https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry)
   */
  locale?: Intl.LocalesArgument;
};

export type FormatNumberAsCurrencyParams = {
  value?: number | string;
  /**
   * The currency to use
   *
   * See more about Currency codes on [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#currency_2)
   * | [Wikipedia](https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes)
   * | [ISO 4214](https://www.iso.org/iso-4217-currency-codes.html)
   * | [XML](https://www.six-group.com/dam/download/financial-information/data-center/iso-currrency/lists/list-one.xml)
   */
  currency?: CurrencyCode | null;
  options?: FormatNumberAsCurrencyOptions;
};

/**
 * The Function takes value and currency, it returns formatted string as currency based on provided options.
 *
 * @param value Number to format
 * @param currency Currency to display
 * @param options See more on [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options)
 * @dafault `options { locale: 'sr-Latn', notation: 'compact', minimumFractionDigits: 2 }`
 * @returns `string | number | undefined`
 */
export const formatNumberAsCurrency = ({
  value,
  currency,
  options,
}: FormatNumberAsCurrencyParams) => {
  if (value === undefined || isNaN(+value)) return;
  if (!currency) return new Intl.NumberFormat("sr-Latn").format(+value);

  return new Intl.NumberFormat(options?.locale || "sr-Latn", {
    currency,
    notation: "compact",
    minimumFractionDigits: 2,
    ...options,
    style: "currency",
  }).format(+value);
};

export type FormatNumberParams = {
  value?: number | string;
  options?: {
    locale?: Intl.LocalesArgument;
    minimumFractionDigits?: number;
  };
};

export const formatNumber = ({ value, options }: FormatNumberParams) => {
  if (value === undefined || isNaN(+value)) return;

  return new Intl.NumberFormat(options?.locale || "sr-Latn", {
    minimumFractionDigits: 2,
    ...options,
  }).format(+value);
};

export const parseFromValuesOrFunc = <T, U>(
  fn: ((arg: U) => T) | T | undefined,
  arg: U,
): T | undefined => (fn instanceof Function ? fn(arg) : fn);

type Value = string | null;

export const findTreeNode = (
  tree: TreeNodeData[],
  value?: Value,
): TreeNodeData | null => {
  if (!value) return null;

  for (const node of tree) {
    if (node.value === value) {
      return node;
    }

    if (node.children) {
      const result = findTreeNode(node.children, value);
      if (result) {
        return result;
      }
    }
  }

  return null;
};
