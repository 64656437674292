import { Group, Stack } from "@mantine/core";
import { DatesRangeValue, MonthPickerInput } from "@mantine/dates";
import { startOfMonth, endOfMonth } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import filesSvg from "@/assets/files.svg";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useTenantContext } from "@/context/TenantContext";
import {
  BillingByCommitmentReport,
  BillingByCompartmentReport,
  //BillingByDayReport,
  //BillingCreditsReport,
} from "@/widgets/BillingReports";
import { DashboardFifthDiagramReport } from "@/widgets/DashboardReports/DashboardFifthDiagramReport";
import { DashboardKPIReport } from "@/widgets/DashboardReports/DashboardKPIReport";
import { DashboardThirdDiagramReport } from "@/widgets/DashboardReports/DashboardThirdDiagramReport";

export const BillingPage = () => {
  const { t } = useTranslation(["dashboard", "common"]);

  const { tenant } = useTenantContext();
  const [dateRange, setDateRange] = useState<DatesRangeValue>(() => {
    const now = new Date();
    return [startOfMonth(now), endOfMonth(now)];
  });

  const [commitmentIds, setCommitmentIds] = useState<string[]>([]);

  const handleDateRangeChange = (dates: DatesRangeValue) => {
    // if one date is selected and user blurs the input, then select the whole month
    if (!dates[0]) {
      setDateRange((prevState) => [prevState[0], endOfMonth(prevState[0]!)]);
      return;
    }

    // parse date from
    dates[0] = startOfMonth(dates[0]);

    // parse date to
    if (dates[1]) {
      dates[1] = endOfMonth(dates[1]!);
    }

    setDateRange(dates);
  };

  const areGrafsVisible = dateRange[0] && dateRange[1];

  return (
    <Group className="flex-1 items-stretch gap-0 overflow-auto" wrap="nowrap">
      <Stack className="h-full basis-96 gap-2 border-r p-4">
        <MonthPickerInput
          allowSingleDateInRange
          type="range"
          label={t("common:desiredPeriod")}
          valueFormat="DD.MM.YYYY"
          value={dateRange}
          onChange={handleDateRangeChange}
        />
      </Stack>
      <ScrollArea className="flex h-full flex-1 flex-col p-8">
        <div className="grid auto-rows-[20rem] gap-4">
          <>
            {!areGrafsVisible && (
              <div className="flex flex-1 flex-col items-center justify-center gap-y-4">
                <img src={filesSvg} alt="files" />
                <h3 className="text-center text-2xl font-medium">
                  {t("dashboardKPIReport.billing.formRequired")}
                </h3>
              </div>
            )}
            {areGrafsVisible && (
              <BillingByCompartmentReport
                datefrom={dateRange[0]!}
                dateto={dateRange[1]!}
                tenantocid={tenant?.ocid}
              />
              /* TODO: check data fetching */
              /* <BillingByDayReport
              datefrom={startDate}
              dateto={endDate}
              tenantocid={tenant?.ocid}
              tenantId={tenant?.id}
            /> */
              /* <BillingCreditsReport
              datefrom={startDate}
              dateto={endDate}
              tenantocid={tenant?.ocid}
            /> */
            )}
            <BillingByCommitmentReport
              subscriptionId={tenant?.currentSubscription?.subscriptionID}
              commitmentIds={commitmentIds}
            />
          </>
        </div>
      </ScrollArea>
      <Stack className="basis-96 overflow-auto border-l p-4">
        <DashboardKPIReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />

        <DashboardFifthDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />

        <DashboardThirdDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID}
          onFilterChange={setCommitmentIds}
        />
      </Stack>
    </Group>
  );
};
