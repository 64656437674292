import { createMRTColumnHelper } from "mantine-react-table";

import { StatisticType } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<StatisticType>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.statisticType.name",
  }),
  columnHelper.accessor("description", {
    header: "entity:administration.statisticType.description",
  }),
];
