import { Text, Skeleton } from "@mantine/core";
import { ReactNode } from "react";

import { Separator } from "@/components/ui/separator";

export interface DashboardKPIProps {
  title: ReactNode;
  value: ReactNode;
  isLoading?: boolean;
}

export const DashboardKPI = ({
  title,
  value,
  isLoading,
}: DashboardKPIProps) => {
  if (isLoading) {
    return (
      <div className="flex h-28 flex-col justify-end gap-1 rounded-md border bg-white p-4 shadow-md">
        <Skeleton height={20} />
        <Separator className="mb-1" />
        <Skeleton height={34} />
      </div>
    );
  }

  const titleContent =
    typeof title === "string" ?
      <Text size="xl" fw="bold">
        {title}
      </Text>
    : title;
  const valueContent =
    typeof value === "string" ?
      <Text lh="1" fz="sm" py="xs" fw="bolder">
        {value}
      </Text>
    : value;

  return (
    <div className="flex h-28 flex-col justify-end gap-1 rounded-md border bg-white p-4 shadow-md">
      {titleContent}
      <Separator className="mb-1" />
      {valueContent}
    </div>
  );
};
