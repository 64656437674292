import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { StorageResultTypeResponse } from "@/lib/api/dto";

import { storageResultTypeKeys } from "./queryKeys";

interface useFetchStorageResultQuery<TSelectData = StorageResultTypeResponse[]>
  extends UseQueryOptions<StorageResultTypeResponse[], Error, TSelectData> {}

export const UseFetchStorageResultQuery = (
  options?: useFetchStorageResultQuery
) => {
  return useQuery({
    queryKey: storageResultTypeKeys.all(),
    queryFn: () => Api.storageResultTypes.list().then((res) => res.data),
    ...options,
  });
};
