import { createMRTColumnHelper } from "mantine-react-table";

import { ResourceTypeResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<ResourceTypeResponse>();

export const columns = [
  columnHelper.accessor("sku", {
    header: "entity:administration.resources.sku",
  }),
  columnHelper.accessor("name", {
    header: "entity:administration.resources.name",
  }),
  columnHelper.accessor("description", {
    header: "entity:administration.resources.description",
  }),
  columnHelper.accessor("unitReadable", {
    header: "entity:administration.resources.unitReadable",
  }),
  columnHelper.accessor("unitDescription", {
    header: "entity:administration.resources.unitDescription",
  }),
];
