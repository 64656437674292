import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";
import { CreateSubscriptionPayload, SubscriptionResponse } from "@/lib/api/dto";

import { subscriptionKeys } from "./queryKeys";

interface UseCreateOrUpdateSubscriptionMutationOptions
  extends UseMutationOptions<
    AxiosResponse<SubscriptionResponse>,
    Error,
    CreateSubscriptionPayload
  > {
  subscriptionId?: string;
}

export const useCreateOrUpdateSubscriptionMutation = ({
  subscriptionId,
  ...options
}: UseCreateOrUpdateSubscriptionMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (data: CreateSubscriptionPayload) => {
      if (subscriptionId) {
        return Api.subscriptions.update(subscriptionId, data);
      }

      return Api.subscriptions.create(data);
    },
    onSuccess: (...args) => {
      subscriptionId ? successToast.update() : successToast.create();
      queryClient.invalidateQueries({ queryKey: subscriptionKeys.all() });

      options.onSuccess?.(...args);
    },
    onError: (error, variables, context) => {
      subscriptionId ? errorToast.update() : errorToast.create();

      options.onError?.(error, variables, context);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({ queryKey: subscriptionKeys.all() });

      options.onSettled?.(...args);
    },
  });
};
