import { useTranslation } from "react-i18next";

import { useFetchMeQuery } from "@/hooks/api/useFetchMeQuery";
import { UsersTable } from "@/widgets/UsersTable";

const requiredAuthorities = ["SUPERADMIN", "ORG_USERS_WRITE"];

export const UsersListPage = () => {
  const { data: me } = useFetchMeQuery({
    enabled: false,
  });
  const { t } = useTranslation("common");

  if (
    !me?.authorities?.some((authority) =>
      requiredAuthorities.includes(authority),
    )
  )
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <h1 className="text-3xl font-bold">403</h1>
        <p className="text-xl text-gray-600">{t("noAccessPage")}</p>
      </div>
    );

  return <UsersTable />;
};
