/**
 * The currency to use
 *
 * See more about Currency codes on [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#currency_2)
 * | [Wikipedia](https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes)
 * | [ISO 4214](https://www.iso.org/iso-4217-currency-codes.html)
 * | [XML](https://www.six-group.com/dam/download/financial-information/data-center/iso-currrency/lists/list-one.xml)
 */
export const CURRENCIES = [
  {
    countryName: "AFGHANISTAN",
    currencyName: "Afghani",
    currencyCode: "AFN",
  },
  {
    countryName: "ÅLAND ISLANDS",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "ALBANIA",
    currencyName: "Lek",
    currencyCode: "ALL",
  },
  {
    countryName: "ALGERIA",
    currencyName: "Algerian Dinar",
    currencyCode: "DZD",
  },
  {
    countryName: "AMERICAN SAMOA",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "ANDORRA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "ANGOLA",
    currencyName: "Kwanza",
    currencyCode: "AOA",
  },
  {
    countryName: "ANGUILLA",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },

  {
    countryName: "ANTIGUA AND BARBUDA",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    countryName: "ARGENTINA",
    currencyName: "Argentine Peso",
    currencyCode: "ARS",
  },
  {
    countryName: "ARMENIA",
    currencyName: "Armenian Dram",
    currencyCode: "AMD",
  },
  {
    countryName: "ARUBA",
    currencyName: "Aruban Florin",
    currencyCode: "AWG",
  },
  {
    countryName: "AUSTRALIA",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    countryName: "AUSTRIA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "AZERBAIJAN",
    currencyName: "Azerbaijan Manat",
    currencyCode: "AZN",
  },
  {
    countryName: "BAHAMAS (THE)",
    currencyName: "Bahamian Dollar",
    currencyCode: "BSD",
  },
  {
    countryName: "BAHRAIN",
    currencyName: "Bahraini Dinar",
    currencyCode: "BHD",
  },
  {
    countryName: "BANGLADESH",
    currencyName: "Taka",
    currencyCode: "BDT",
  },
  {
    countryName: "BARBADOS",
    currencyName: "Barbados Dollar",
    currencyCode: "BBD",
  },
  {
    countryName: "BELARUS",
    currencyName: "Belarusian Ruble",
    currencyCode: "BYN",
  },
  {
    countryName: "BELGIUM",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "BELIZE",
    currencyName: "Belize Dollar",
    currencyCode: "BZD",
  },
  {
    countryName: "BENIN",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    countryName: "BERMUDA",
    currencyName: "Bermudian Dollar",
    currencyCode: "BMD",
  },
  {
    countryName: "BHUTAN",
    currencyName: "Indian Rupee",
    currencyCode: "INR",
  },
  {
    countryName: "BHUTAN",
    currencyName: "Ngultrum",
    currencyCode: "BTN",
  },
  {
    countryName: "BOLIVIA (PLURINATIONAL STATE OF)",
    currencyName: "Boliviano",
    currencyCode: "BOB",
  },
  {
    countryName: "BOLIVIA (PLURINATIONAL STATE OF)",
    currencyName: "Mvdol",
    currencyCode: "BOV",
  },
  {
    countryName: "BONAIRE, SINT EUSTATIUS AND SABA",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "BOSNIA AND HERZEGOVINA",
    currencyName: "Convertible Mark",
    currencyCode: "BAM",
  },
  {
    countryName: "BOTSWANA",
    currencyName: "Pula",
    currencyCode: "BWP",
  },
  {
    countryName: "BOUVET ISLAND",
    currencyName: "Norwegian Krone",
    currencyCode: "NOK",
  },
  {
    countryName: "BRAZIL",
    currencyName: "Brazilian Real",
    currencyCode: "BRL",
  },
  {
    countryName: "BRITISH INDIAN OCEAN TERRITORY (THE)",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "BRUNEI DARUSSALAM",
    currencyName: "Brunei Dollar",
    currencyCode: "BND",
  },
  {
    countryName: "BULGARIA",
    currencyName: "Bulgarian Lev",
    currencyCode: "BGN",
  },
  {
    countryName: "BURKINA FASO",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    countryName: "BURUNDI",
    currencyName: "Burundi Franc",
    currencyCode: "BIF",
  },
  {
    countryName: "CABO VERDE",
    currencyName: "Cabo Verde Escudo",
    currencyCode: "CVE",
  },
  {
    countryName: "CAMBODIA",
    currencyName: "Riel",
    currencyCode: "KHR",
  },
  {
    countryName: "CAMEROON",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    countryName: "CANADA",
    currencyName: "Canadian Dollar",
    currencyCode: "CAD",
  },
  {
    countryName: "CAYMAN ISLANDS (THE)",
    currencyName: "Cayman Islands Dollar",
    currencyCode: "KYD",
  },
  {
    countryName: "CENTRAL AFRICAN REPUBLIC (THE)",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    countryName: "CHAD",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    countryName: "CHILE",
    currencyName: "Chilean Peso",
    currencyCode: "CLP",
  },
  {
    countryName: "CHILE",
    currencyName: "Unidad de Fomento",
    currencyCode: "CLF",
  },
  {
    countryName: "CHINA",
    currencyName: "Yuan Renminbi",
    currencyCode: "CNY",
  },
  {
    countryName: "CHRISTMAS ISLAND",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    countryName: "COCOS (KEELING) ISLANDS (THE)",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    countryName: "COLOMBIA",
    currencyName: "Colombian Peso",
    currencyCode: "COP",
  },
  {
    countryName: "COLOMBIA",
    currencyName: "Unidad de Valor Real",
    currencyCode: "COU",
  },
  {
    countryName: "COMOROS (THE)",
    currencyName: "Comorian Franc",
    currencyCode: "KMF",
  },
  {
    countryName: "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
    currencyName: "Congolese Franc",
    currencyCode: "CDF",
  },
  {
    countryName: "CONGO (THE)",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    countryName: "COOK ISLANDS (THE)",
    currencyName: "New Zealand Dollar",
    currencyCode: "NZD",
  },
  {
    countryName: "COSTA RICA",
    currencyName: "Costa Rican Colon",
    currencyCode: "CRC",
  },
  {
    countryName: "CÔTE D'IVOIRE",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    countryName: "CROATIA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "CUBA",
    currencyName: "Cuban Peso",
    currencyCode: "CUP",
  },
  {
    countryName: "CUBA",
    currencyName: "Peso Convertible",
    currencyCode: "CUC",
  },
  {
    countryName: "CURAÇAO",
    currencyName: "Netherlands Antillean Guilder",
    currencyCode: "ANG",
  },
  {
    countryName: "CYPRUS",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "CZECHIA",
    currencyName: "Czech Koruna",
    currencyCode: "CZK",
  },
  {
    countryName: "DENMARK",
    currencyName: "Danish Krone",
    currencyCode: "DKK",
  },
  {
    countryName: "DJIBOUTI",
    currencyName: "Djibouti Franc",
    currencyCode: "DJF",
  },
  {
    countryName: "DOMINICA",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    countryName: "DOMINICAN REPUBLIC (THE)",
    currencyName: "Dominican Peso",
    currencyCode: "DOP",
  },
  {
    countryName: "ECUADOR",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "EGYPT",
    currencyName: "Egyptian Pound",
    currencyCode: "EGP",
  },
  {
    countryName: "EL SALVADOR",
    currencyName: "El Salvador Colon",
    currencyCode: "SVC",
  },
  {
    countryName: "EL SALVADOR",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "EQUATORIAL GUINEA",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    countryName: "ERITREA",
    currencyName: "Nakfa",
    currencyCode: "ERN",
  },
  {
    countryName: "ESTONIA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "ESWATINI",
    currencyName: "Lilangeni",
    currencyCode: "SZL",
  },
  {
    countryName: "ETHIOPIA",
    currencyName: "Ethiopian Birr",
    currencyCode: "ETB",
  },
  {
    countryName: "EUROPEAN UNION",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "FALKLAND ISLANDS (THE) [MALVINAS]",
    currencyName: "Falkland Islands Pound",
    currencyCode: "FKP",
  },
  {
    countryName: "FAROE ISLANDS (THE)",
    currencyName: "Danish Krone",
    currencyCode: "DKK",
  },
  {
    countryName: "FIJI",
    currencyName: "Fiji Dollar",
    currencyCode: "FJD",
  },
  {
    countryName: "FINLAND",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "FRANCE",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "FRENCH GUIANA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "FRENCH POLYNESIA",
    currencyName: "CFP Franc",
    currencyCode: "XPF",
  },
  {
    countryName: "FRENCH SOUTHERN TERRITORIES (THE)",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "GABON",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    countryName: "GAMBIA (THE)",
    currencyName: "Dalasi",
    currencyCode: "GMD",
  },
  {
    countryName: "GEORGIA",
    currencyName: "Lari",
    currencyCode: "GEL",
  },
  {
    countryName: "GERMANY",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "GHANA",
    currencyName: "Ghana Cedi",
    currencyCode: "GHS",
  },
  {
    countryName: "GIBRALTAR",
    currencyName: "Gibraltar Pound",
    currencyCode: "GIP",
  },
  {
    countryName: "GREECE",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "GREENLAND",
    currencyName: "Danish Krone",
    currencyCode: "DKK",
  },
  {
    countryName: "GRENADA",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    countryName: "GUADELOUPE",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "GUAM",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "GUATEMALA",
    currencyName: "Quetzal",
    currencyCode: "GTQ",
  },
  {
    countryName: "GUERNSEY",
    currencyName: "Pound Sterling",
    currencyCode: "GBP",
  },
  {
    countryName: "GUINEA",
    currencyName: "Guinean Franc",
    currencyCode: "GNF",
  },
  {
    countryName: "GUINEA-BISSAU",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    countryName: "GUYANA",
    currencyName: "Guyana Dollar",
    currencyCode: "GYD",
  },
  {
    countryName: "HAITI",
    currencyName: "Gourde",
    currencyCode: "HTG",
  },
  {
    countryName: "HAITI",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "HEARD ISLAND AND McDONALD ISLANDS",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    countryName: "HOLY SEE (THE)",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "HONDURAS",
    currencyName: "Lempira",
    currencyCode: "HNL",
  },
  {
    countryName: "HONG KONG",
    currencyName: "Hong Kong Dollar",
    currencyCode: "HKD",
  },
  {
    countryName: "HUNGARY",
    currencyName: "Forint",
    currencyCode: "HUF",
  },
  {
    countryName: "ICELAND",
    currencyName: "Iceland Krona",
    currencyCode: "ISK",
  },
  {
    countryName: "INDIA",
    currencyName: "Indian Rupee",
    currencyCode: "INR",
  },
  {
    countryName: "INDONESIA",
    currencyName: "Rupiah",
    currencyCode: "IDR",
  },
  {
    countryName: "INTERNATIONAL MONETARY FUND (IMF)",
    currencyName: "SDR (Special Drawing Right)",
    currencyCode: "XDR",
  },
  {
    countryName: "IRAN (ISLAMIC REPUBLIC OF)",
    currencyName: "Iranian Rial",
    currencyCode: "IRR",
  },
  {
    countryName: "IRAQ",
    currencyName: "Iraqi Dinar",
    currencyCode: "IQD",
  },
  {
    countryName: "IRELAND",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "ISLE OF MAN",
    currencyName: "Pound Sterling",
    currencyCode: "GBP",
  },
  {
    countryName: "ISRAEL",
    currencyName: "New Israeli Sheqel",
    currencyCode: "ILS",
  },
  {
    countryName: "ITALY",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "JAMAICA",
    currencyName: "Jamaican Dollar",
    currencyCode: "JMD",
  },
  {
    countryName: "JAPAN",
    currencyName: "Yen",
    currencyCode: "JPY",
  },
  {
    countryName: "JERSEY",
    currencyName: "Pound Sterling",
    currencyCode: "GBP",
  },
  {
    countryName: "JORDAN",
    currencyName: "Jordanian Dinar",
    currencyCode: "JOD",
  },
  {
    countryName: "KAZAKHSTAN",
    currencyName: "Tenge",
    currencyCode: "KZT",
  },
  {
    countryName: "KENYA",
    currencyName: "Kenyan Shilling",
    currencyCode: "KES",
  },
  {
    countryName: "KIRIBATI",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    countryName: "KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)",
    currencyName: "North Korean Won",
    currencyCode: "KPW",
  },
  {
    countryName: "KOREA (THE REPUBLIC OF)",
    currencyName: "Won",
    currencyCode: "KRW",
  },
  {
    countryName: "KUWAIT",
    currencyName: "Kuwaiti Dinar",
    currencyCode: "KWD",
  },
  {
    countryName: "KYRGYZSTAN",
    currencyName: "Som",
    currencyCode: "KGS",
  },
  {
    countryName: "LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)",
    currencyName: "Lao Kip",
    currencyCode: "LAK",
  },
  {
    countryName: "LATVIA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "LEBANON",
    currencyName: "Lebanese Pound",
    currencyCode: "LBP",
  },
  {
    countryName: "LESOTHO",
    currencyName: "Loti",
    currencyCode: "LSL",
  },
  {
    countryName: "LESOTHO",
    currencyName: "Rand",
    currencyCode: "ZAR",
  },
  {
    countryName: "LIBERIA",
    currencyName: "Liberian Dollar",
    currencyCode: "LRD",
  },
  {
    countryName: "LIBYA",
    currencyName: "Libyan Dinar",
    currencyCode: "LYD",
  },
  {
    countryName: "LIECHTENSTEIN",
    currencyName: "Swiss Franc",
    currencyCode: "CHF",
  },
  {
    countryName: "LITHUANIA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "LUXEMBOURG",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "MACAO",
    currencyName: "Pataca",
    currencyCode: "MOP",
  },
  {
    countryName: "NORTH MACEDONIA",
    currencyName: "Denar",
    currencyCode: "MKD",
  },
  {
    countryName: "MADAGASCAR",
    currencyName: "Malagasy Ariary",
    currencyCode: "MGA",
  },
  {
    countryName: "MALAWI",
    currencyName: "Malawi Kwacha",
    currencyCode: "MWK",
  },
  {
    countryName: "MALAYSIA",
    currencyName: "Malaysian Ringgit",
    currencyCode: "MYR",
  },
  {
    countryName: "MALDIVES",
    currencyName: "Rufiyaa",
    currencyCode: "MVR",
  },
  {
    countryName: "MALI",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    countryName: "MALTA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "MARSHALL ISLANDS (THE)",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "MARTINIQUE",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "MAURITANIA",
    currencyName: "Ouguiya",
    currencyCode: "MRU",
  },
  {
    countryName: "MAURITIUS",
    currencyName: "Mauritius Rupee",
    currencyCode: "MUR",
  },
  {
    countryName: "MAYOTTE",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP",
    currencyName: "ADB Unit of Account",
    currencyCode: "XUA",
  },
  {
    countryName: "MEXICO",
    currencyName: "Mexican Peso",
    currencyCode: "MXN",
  },
  {
    countryName: "MEXICO",
    currencyName: "Mexican Unidad de Inversion (UDI)",
    currencyCode: "MXV",
  },
  {
    countryName: "MICRONESIA (FEDERATED STATES OF)",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "MOLDOVA (THE REPUBLIC OF)",
    currencyName: "Moldovan Leu",
    currencyCode: "MDL",
  },
  {
    countryName: "MONACO",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "MONGOLIA",
    currencyName: "Tugrik",
    currencyCode: "MNT",
  },
  {
    countryName: "MONTENEGRO",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "MONTSERRAT",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    countryName: "MOROCCO",
    currencyName: "Moroccan Dirham",
    currencyCode: "MAD",
  },
  {
    countryName: "MOZAMBIQUE",
    currencyName: "Mozambique Metical",
    currencyCode: "MZN",
  },
  {
    countryName: "MYANMAR",
    currencyName: "Kyat",
    currencyCode: "MMK",
  },
  {
    countryName: "NAMIBIA",
    currencyName: "Namibia Dollar",
    currencyCode: "NAD",
  },
  {
    countryName: "NAMIBIA",
    currencyName: "Rand",
    currencyCode: "ZAR",
  },
  {
    countryName: "NAURU",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    countryName: "NEPAL",
    currencyName: "Nepalese Rupee",
    currencyCode: "NPR",
  },
  {
    countryName: "NETHERLANDS (THE)",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "NEW CALEDONIA",
    currencyName: "CFP Franc",
    currencyCode: "XPF",
  },
  {
    countryName: "NEW ZEALAND",
    currencyName: "New Zealand Dollar",
    currencyCode: "NZD",
  },
  {
    countryName: "NICARAGUA",
    currencyName: "Cordoba Oro",
    currencyCode: "NIO",
  },
  {
    countryName: "NIGER (THE)",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    countryName: "NIGERIA",
    currencyName: "Naira",
    currencyCode: "NGN",
  },
  {
    countryName: "NIUE",
    currencyName: "New Zealand Dollar",
    currencyCode: "NZD",
  },
  {
    countryName: "NORFOLK ISLAND",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    countryName: "NORTHERN MARIANA ISLANDS (THE)",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "NORWAY",
    currencyName: "Norwegian Krone",
    currencyCode: "NOK",
  },
  {
    countryName: "OMAN",
    currencyName: "Rial Omani",
    currencyCode: "OMR",
  },
  {
    countryName: "PAKISTAN",
    currencyName: "Pakistan Rupee",
    currencyCode: "PKR",
  },
  {
    countryName: "PALAU",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },

  {
    countryName: "PANAMA",
    currencyName: "Balboa",
    currencyCode: "PAB",
  },
  {
    countryName: "PANAMA",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "PAPUA NEW GUINEA",
    currencyName: "Kina",
    currencyCode: "PGK",
  },
  {
    countryName: "PARAGUAY",
    currencyName: "Guarani",
    currencyCode: "PYG",
  },
  {
    countryName: "PERU",
    currencyName: "Sol",
    currencyCode: "PEN",
  },
  {
    countryName: "PHILIPPINES (THE)",
    currencyName: "Philippine Peso",
    currencyCode: "PHP",
  },
  {
    countryName: "PITCAIRN",
    currencyName: "New Zealand Dollar",
    currencyCode: "NZD",
  },
  {
    countryName: "POLAND",
    currencyName: "Zloty",
    currencyCode: "PLN",
  },
  {
    countryName: "PORTUGAL",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "PUERTO RICO",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "QATAR",
    currencyName: "Qatari Rial",
    currencyCode: "QAR",
  },
  {
    countryName: "RÉUNION",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "ROMANIA",
    currencyName: "Romanian Leu",
    currencyCode: "RON",
  },
  {
    countryName: "RUSSIAN FEDERATION (THE)",
    currencyName: "Russian Ruble",
    currencyCode: "RUB",
  },
  {
    countryName: "RWANDA",
    currencyName: "Rwanda Franc",
    currencyCode: "RWF",
  },
  {
    countryName: "SAINT BARTHÉLEMY",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
    currencyName: "Saint Helena Pound",
    currencyCode: "SHP",
  },
  {
    countryName: "SAINT KITTS AND NEVIS",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    countryName: "SAINT LUCIA",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    countryName: "SAINT MARTIN (FRENCH PART)",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "SAINT PIERRE AND MIQUELON",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "SAINT VINCENT AND THE GRENADINES",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    countryName: "SAMOA",
    currencyName: "Tala",
    currencyCode: "WST",
  },
  {
    countryName: "SAN MARINO",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "SAO TOME AND PRINCIPE",
    currencyName: "Dobra",
    currencyCode: "STN",
  },
  {
    countryName: "SAUDI ARABIA",
    currencyName: "Saudi Riyal",
    currencyCode: "SAR",
  },
  {
    countryName: "SENEGAL",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    countryName: "SERBIA",
    currencyName: "Serbian Dinar",
    currencyCode: "RSD",
  },
  {
    countryName: "SEYCHELLES",
    currencyName: "Seychelles Rupee",
    currencyCode: "SCR",
  },
  {
    countryName: "SIERRA LEONE",
    currencyName: "Leone",
    currencyCode: "SLE",
  },
  {
    countryName: "SINGAPORE",
    currencyName: "Singapore Dollar",
    currencyCode: "SGD",
  },
  {
    countryName: "SINT MAARTEN (DUTCH PART)",
    currencyName: "Netherlands Antillean Guilder",
    currencyCode: "ANG",
  },
  {
    countryName: 'SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS "SUCRE"',
    currencyName: "Sucre",
    currencyCode: "XSU",
  },
  {
    countryName: "SLOVAKIA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "SLOVENIA",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "SOLOMON ISLANDS",
    currencyName: "Solomon Islands Dollar",
    currencyCode: "SBD",
  },
  {
    countryName: "SOMALIA",
    currencyName: "Somali Shilling",
    currencyCode: "SOS",
  },
  {
    countryName: "SOUTH AFRICA",
    currencyName: "Rand",
    currencyCode: "ZAR",
  },

  {
    countryName: "SOUTH SUDAN",
    currencyName: "South Sudanese Pound",
    currencyCode: "SSP",
  },
  {
    countryName: "SPAIN",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    countryName: "SRI LANKA",
    currencyName: "Sri Lanka Rupee",
    currencyCode: "LKR",
  },
  {
    countryName: "SUDAN (THE)",
    currencyName: "Sudanese Pound",
    currencyCode: "SDG",
  },
  {
    countryName: "SURINAME",
    currencyName: "Surinam Dollar",
    currencyCode: "SRD",
  },
  {
    countryName: "SVALBARD AND JAN MAYEN",
    currencyName: "Norwegian Krone",
    currencyCode: "NOK",
  },
  {
    countryName: "SWEDEN",
    currencyName: "Swedish Krona",
    currencyCode: "SEK",
  },
  {
    countryName: "SWITZERLAND",
    currencyName: "Swiss Franc",
    currencyCode: "CHF",
  },
  {
    countryName: "SWITZERLAND",
    currencyName: "WIR Euro",
    currencyCode: "CHE",
  },
  {
    countryName: "SWITZERLAND",
    currencyName: "WIR Franc",
    currencyCode: "CHW",
  },
  {
    countryName: "SYRIAN ARAB REPUBLIC",
    currencyName: "Syrian Pound",
    currencyCode: "SYP",
  },
  {
    countryName: "TAIWAN (PROVINCE OF CHINA)",
    currencyName: "New Taiwan Dollar",
    currencyCode: "TWD",
  },
  {
    countryName: "TAJIKISTAN",
    currencyName: "Somoni",
    currencyCode: "TJS",
  },
  {
    countryName: "TANZANIA, UNITED REPUBLIC OF",
    currencyName: "Tanzanian Shilling",
    currencyCode: "TZS",
  },
  {
    countryName: "THAILAND",
    currencyName: "Baht",
    currencyCode: "THB",
  },
  {
    countryName: "TIMOR-LESTE",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "TOGO",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    countryName: "TOKELAU",
    currencyName: "New Zealand Dollar",
    currencyCode: "NZD",
  },
  {
    countryName: "TONGA",
    currencyName: "Pa’anga",
    currencyCode: "TOP",
  },
  {
    countryName: "TRINIDAD AND TOBAGO",
    currencyName: "Trinidad and Tobago Dollar",
    currencyCode: "TTD",
  },
  {
    countryName: "TUNISIA",
    currencyName: "Tunisian Dinar",
    currencyCode: "TND",
  },
  {
    countryName: "TÜRKİYE",
    currencyName: "Turkish Lira",
    currencyCode: "TRY",
  },
  {
    countryName: "TURKMENISTAN",
    currencyName: "Turkmenistan New Manat",
    currencyCode: "TMT",
  },
  {
    countryName: "TURKS AND CAICOS ISLANDS (THE)",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "TUVALU",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    countryName: "UGANDA",
    currencyName: "Uganda Shilling",
    currencyCode: "UGX",
  },
  {
    countryName: "UKRAINE",
    currencyName: "Hryvnia",
    currencyCode: "UAH",
  },
  {
    countryName: "UNITED ARAB EMIRATES (THE)",
    currencyName: "UAE Dirham",
    currencyCode: "AED",
  },
  {
    countryName: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)",
    currencyName: "Pound Sterling",
    currencyCode: "GBP",
  },
  {
    countryName: "UNITED STATES MINOR OUTLYING ISLANDS (THE)",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "UNITED STATES OF AMERICA (THE)",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "UNITED STATES OF AMERICA (THE)",
    currencyName: "US Dollar (Next day)",
    currencyCode: "USN",
  },
  {
    countryName: "URUGUAY",
    currencyName: "Peso Uruguayo",
    currencyCode: "UYU",
  },
  {
    countryName: "URUGUAY",
    currencyName: "Uruguay Peso en Unidades Indexadas (UI)",
    currencyCode: "UYI",
  },
  {
    countryName: "URUGUAY",
    currencyName: "Unidad Previsional",
    currencyCode: "UYW",
  },
  {
    countryName: "UZBEKISTAN",
    currencyName: "Uzbekistan Sum",
    currencyCode: "UZS",
  },
  {
    countryName: "VANUATU",
    currencyName: "Vatu",
    currencyCode: "VUV",
  },
  {
    countryName: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    currencyName: "Bolívar Soberano",
    currencyCode: "VES",
  },
  {
    countryName: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    currencyName: "Bolívar Soberano",
    currencyCode: "VED",
  },
  {
    countryName: "VIET NAM",
    currencyName: "Dong",
    currencyCode: "VND",
  },
  {
    countryName: "VIRGIN ISLANDS (BRITISH)",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "VIRGIN ISLANDS (U.S.)",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    countryName: "WALLIS AND FUTUNA",
    currencyName: "CFP Franc",
    currencyCode: "XPF",
  },
  {
    countryName: "WESTERN SAHARA",
    currencyName: "Moroccan Dirham",
    currencyCode: "MAD",
  },
  {
    countryName: "YEMEN",
    currencyName: "Yemeni Rial",
    currencyCode: "YER",
  },
  {
    countryName: "ZAMBIA",
    currencyName: "Zambian Kwacha",
    currencyCode: "ZMW",
  },
  {
    countryName: "ZIMBABWE",
    currencyName: "Zimbabwe Dollar",
    currencyCode: "ZWL",
  },
] as const;

export const CURRENCY_CODE = CURRENCIES.map(
  (CURRENCY) => CURRENCY.currencyCode,
);

export type CurrencyCode = (typeof CURRENCY_CODE)[number];
