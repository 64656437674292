import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";
import {
  CompartmentBudgetNotificationResponse,
  CreateCompartmentBudgetNotificationPayload,
} from "@/lib/api/dto";

import { compartmentBudgetNotificationKeys } from "./queryKeys";

export const useCreateCompartmentBudgetNotification = ({
  onSuccess,
  ...options
}: UseMutationOptions<
  CompartmentBudgetNotificationResponse,
  Error,
  CreateCompartmentBudgetNotificationPayload
> = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (request: CreateCompartmentBudgetNotificationPayload) =>
      Api.compartmentBudgetNotifications
        .create(request)
        .then((res) => res.data),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: compartmentBudgetNotificationKeys.all(),
      });
      onSuccess?.(...args);
    },
  });
};
