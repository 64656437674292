import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Api } from "@/lib/api";

import { resourceKeys } from "./queryKeys";

export const useStopManageableResourceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: resourceKeys.stopResource(),
    mutationFn: Api.resource.stopResource,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: resourceKeys.stopResource(),
      });
    },
  });
};
