import { Paper, TextInput, Button } from "@mantine/core";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useCreateOrUpdateNamespaceMutation } from "@/hooks/api/useCreateOrUpdateMetricnamespaceMutation";
import { useTForm } from "@/hooks/useTForm";
import { MetricNamespaceResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface MetricNamespaceDetailsProps {
  metricNamespace?: MetricNamespaceResponse | null;
  onSettled?: () => void;
  className?: string;
}

const metricNamespaceSchema = z.object({
  metricNamespace: z.string().min(1, "form:field.required"),
  description: z.string(),
  name: z.string().min(1, "form:field.required"),
});

type FormData = z.infer<typeof metricNamespaceSchema>;

const defaultValues: FormData = {
  metricNamespace: "",
  description: "",
  name: "",
};

const mapMetricNamespaceToFormData = (
  metricNamespace?: MetricNamespaceResponse | null,
): FormData => {
  if (!metricNamespace) return defaultValues;

  return {
    metricNamespace:
      metricNamespace.metricNamespace ?? defaultValues.metricNamespace,
    description: metricNamespace.description ?? defaultValues.description,
    name: metricNamespace.name ?? defaultValues.name,
  };
};

export const MetricNamespaceDetails = ({
  className,
  metricNamespace,
  onSettled,
}: MetricNamespaceDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const { mutate, isPending } = useCreateOrUpdateNamespaceMutation({
    metricNamespaceId: metricNamespace?.metricNamespace,
  });

  const form = useTForm<FormData>({
    initialValues: mapMetricNamespaceToFormData(metricNamespace),
    validate: zodResolver(metricNamespaceSchema),
  });

  const onSubmit = (data: FormData) => {
    mutate(data, {
      onSuccess: (response) => {
        if (metricNamespace?.metricNamespace) {
          form.setInitialValues(mapMetricNamespaceToFormData(response));
        }
        form.reset();
      },
      onSettled,
    });
  };

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("administration.metricNamespace.metricNamespace")}
        {...form.getInputProps("metricNamespace")}
      />
      <TextInput
        withAsterisk
        label={t("administration.metricNamespace.name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        label={t("administration.metricNamespace.description")}
        {...form.getInputProps("description")}
      />

      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};
