// import axios from 'axios';
// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { Languages, LocalStorageKeys, isDev } from "@/constants";

const loggedKeys = new Set<string>();

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    backend: {
      // Backend API
      // loadPath: '/locales/{{lng}}/{{ns}}',
      // request: (_options, url, _payload, callback) => {
      //   axios.get(url, { baseURL: 'http://localhost:8000/' }).then((response) => {
      //     callback(null, response)
      //   })
      // }
      // Static files
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    fallbackLng: [Languages.Latin, Languages.Cyril],
    lng: localStorage.getItem(LocalStorageKeys.Language) ?? Languages.Latin,
    saveMissing: true,
    missingKeyHandler: (
      lngs,
      ns,
      key,
      _fallbackValue,
      _updateMissing,
      _options,
    ) => {
      if (!isDev) {
        return;
      }
      const missingKey = `${lngs.join(",")}:${ns}:${key}`;
      if (loggedKeys.has(missingKey)) {
        return;
      }
      loggedKeys.add(missingKey);

      console.error("Missing key", missingKey);
      console.log("Latest key set", Array.from(loggedKeys.values()));
    },
    ns: [
      "common",
      "dashboard",
      "entity",
      "notification",
      "toast",
      "form",
      "billing",
      "navbar",
      "control",
      "enum",
    ],
    defaultNS: ["common", "enum"],
  });

export default i18n;
