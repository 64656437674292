import { ScrollArea } from "@/components/ui/scroll-area";
import { useAuth } from "@/hooks/useAuth";
import { UserDetails } from "@/widgets/UserDetails/UserDetails";

export const ProfilePage = () => {
  const {
    me: { data: me },
  } = useAuth();

  return (
    <div className="flex items-center flex-1 w-full max-w-3xl p-4 mx-auto">
      <ScrollArea className="flex-1">
        <UserDetails
          className="p-3"
          user={me!}
        />
      </ScrollArea>
    </div>
  );
};
