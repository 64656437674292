import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { Api } from "@/lib/api";
import { UserResponse } from "@/lib/api/dto";

import { userKeys } from "./queryKeys";

interface UseFetchUsersOptions<T>
  extends Omit<
    UseQueryOptions<
      UserResponse[],
      AxiosError,
      T,
      ReturnType<typeof userKeys.all>
    >,
    "queryKey" | "queryFn"
  > {}

export const useFetchUsersQuery = <T = UserResponse[]>(
  options: UseFetchUsersOptions<T> = {}
) => {
  return useQuery({
    ...options,
    queryKey: userKeys.all(),
    queryFn: () => Api.users.list().then((res) => res.data),
  });
};
