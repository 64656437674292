import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { FetchOCIResourcesParams, QueryResourceResponse } from "@/lib/api/dto";

import { resourceKeys } from "./queryKeys";

export interface UseFetchQueryResourcesQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      QueryResourceResponse[],
      Error,
      T,
      ReturnType<typeof resourceKeys.queryResources>
    >,
    "queryKey" | "queryFn"
  > {
  params: FetchOCIResourcesParams;
}

export const useFetchQueryResourcesQuery = <T = QueryResourceResponse[]>({
  params,
  ...options
}: UseFetchQueryResourcesQueryOptions<T>) => {
  return useQuery({
    ...options,
    queryKey: resourceKeys.queryResources(params),
    queryFn: ({ queryKey }) =>
      Api.resource.queryResources(queryKey[1]).then((res) => res.data),
  });
};
