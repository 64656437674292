import { ActionIcon, Flex, Tooltip, Checkbox, Title } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { isAxiosError } from "axios";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { EnumSelect } from "@/components/ui/enum-select";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { REFETCH_INTERVAL } from "@/constants";
import { CurrencyCode } from "@/constants/currencies";
import { useFetchManageableResourcesQuery } from "@/hooks/api/useFetchManageableResourcesQuery";
import { useFetchManageableResourceTypesQuery } from "@/hooks/api/useFetchManageableResourceTypes";
import { useStartManageableResourceMutation } from "@/hooks/api/useStartManageableResourceMutation";
import { useStopManageableResourceMutation } from "@/hooks/api/useStopManageableResource";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useMRT } from "@/hooks/useMRT";
import {
  ManageableResourceResponse,
  StopResourcePayload,
  TenantResponse,
} from "@/lib/api/dto";
import { formatToDate } from "@/lib/dateUtils";

import { columns } from "./columns";

interface ResourcesTableProps {
  tenant: TenantResponse;
  compartment?: string | null;
}

export const ResourcesTable = ({
  tenant,
  compartment,
}: ResourcesTableProps) => {
  const [resourceType, setResourceType] = useState<string>("");
  const [selectedResource, setSelectedResource] =
    useState<ManageableResourceResponse | null>(null);

  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const { toast } = useToast();
  const { t } = useTranslation(["navbar", "common"]);
  const currencyLocale = useCurrencyLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;
  const { mutate: stopResource } = useStopManageableResourceMutation();
  const { mutate: startResource } = useStartManageableResourceMutation();
  const { data: resourceTypeEnum } = useFetchManageableResourceTypesQuery();
  const {
    data: resourcesData,
    isLoading,
    isFetching,
    refetch,
  } = useFetchManageableResourcesQuery({
    resourceType,
    tenancyOCID: tenant.ocid,
    refetchInterval,
  });

  const resources = useMemo(() => {
    if (!resourcesData) return [];

    if (compartment) {
      return resourcesData.filter((r) => r.compartmentId === compartment);
    }

    return resourcesData;
  }, [compartment, resourcesData]);

  function handleRefetchInterval(checked: boolean) {
    const interval = checked ? REFETCH_INTERVAL : false;
    setRefetchInterval(interval);
  }

  const handleStartResource = (
    data: Omit<StopResourcePayload, "tenantOcid">,
  ) => {
    startResource(
      { ...data, tenantOcid: tenant.ocid },
      {
        onError: (error) => {
          if (isAxiosError(error)) {
            toast({
              title: "Error",
              description:
                error.response?.status === 500 ?
                  "Unexpected error occurred"
                : error.response?.data.message || "Unknown error occurred",
              variant: "destructive",
            });
          }
        },
      },
    );
  };

  function handleStopResource(data: Omit<StopResourcePayload, "tenantOcid">) {
    stopResource(
      { ...data, tenantOcid: tenant.ocid },
      {
        onError: (error) => {
          if (isAxiosError(error)) {
            toast({
              title: "Error",
              description:
                error.response?.status === 500 ?
                  "Unexpected error occurred"
                : error.response?.data.message || "Unknown error occurred",
              variant: "destructive",
            });
          }
        },
      },
    );
  }

  const table = useMRT({
    data: resources,
    columns: columns,
    enableStickyHeader: true,
    renderTopToolbarCustomActions: () => (
      <Flex gap="md">
        <Flex
          component={EnumSelect}
          data={resourceTypeEnum}
          value={resourceType}
          disabled={isFetching}
          gap="xs"
          label={t("resources.resourceType")}
          align="center"
          onChange={(value) => {
            if (value) {
              setResourceType(value as unknown as string);
              setSelectedResource(null);
            }
          }}
        />

        <Tooltip label={t("resources.refresh")}>
          <ActionIcon
            color="gray"
            variant="subtle"
            size="input-sm"
            onClick={() => refetch()}
          >
            <IconRefresh />
          </ActionIcon>
        </Tooltip>

        <div className="flex items-center gap-1">
          <Checkbox
            id="refetch"
            checked={!!refetchInterval}
            onChange={(event) =>
              handleRefetchInterval(event.currentTarget.checked)
            }
          />
          <label
            htmlFor="refetch"
            className="cursor-pointer text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {t("resources.automaticRefresh")}
          </label>
        </div>
      </Flex>
    ),
    meta: {
      startHandler: handleStartResource,
      stopHandler: handleStopResource,
      currency,
      currencyLocale,
    },
    state: {
      isLoading,
      showProgressBars: isFetching,
    },
    enableColumnActions: false,
    mantineTableBodyRowProps: (props) => ({
      onClick: () =>
        setSelectedResource((prevState) => {
          return prevState?.identifier === props.row.original?.identifier ?
              null
            : props.row.original;
        }),
      bg:
        selectedResource?.identifier === props.row.original.identifier ?
          "gray.1"
        : "",
      className: "cursor-pointer",
    }),
  });

  return (
    <>
      <Title className="mb-4" size="h2">
        {t("resources.resource")}
      </Title>

      <MantineReactTable table={table} />

      {selectedResource && (
        <div className="mt-6 grid grid-cols-2 gap-4 border-t p-6">
          <div className="flex flex-col gap-2">
            <span className="font-medium">{t("resources.compartmentId")}</span>
            <Input value={selectedResource?.compartmentId ?? ""} readOnly />
          </div>

          <div className="flex flex-col gap-2">
            <span className="font-medium">
              {t("resources.availabilityDomain")}
            </span>
            <Input
              value={selectedResource?.availabilityDomain ?? ""}
              readOnly
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="font-medium">{t("resources.identifier")}</span>
            <Input value={selectedResource?.identifier ?? ""} readOnly />
          </div>

          <div className="flex flex-col gap-2">
            <span className="font-medium"> {t("resources.timeCreated")}</span>
            <Input
              value={
                selectedResource ?
                  formatToDate(new Date(selectedResource.timeCreated))
                : ""
              }
              readOnly
            />
          </div>
        </div>
      )}
    </>
  );
};
