import {
  ActionIcon,
  LoadingOverlay,
  Modal,
  Text,
  alpha,
  deepMerge,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { cva } from "class-variance-authority";
import { CircleOffIcon, FullscreenIcon } from "lucide-react";
import {
  MRT_TableOptions,
  MRT_RowData,
  MantineReactTable,
} from "mantine-react-table";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useMRT } from "@/hooks/useMRT";
import { cn } from "@/lib/utils";

import { Separator } from "./separator";

type ChartContainerTableOptions<T extends MRT_RowData> =
  | { enableGridDisplay: true; tableOptions: MRT_TableOptions<T> }
  | { enableGridDisplay?: false; tableOptions?: never };

type TitleRenderParams = {
  isFullscreen: boolean;
};

type TitleOrRenderProp = ReactNode | ((params: TitleRenderParams) => ReactNode);

type BaseChartContainerProps = {
  title?: TitleOrRenderProp;
  loading?: boolean;
  fetching?: boolean;
  bordered?: boolean;
  children?: ReactNode;
  className?: string;
  isDataEmpty?: boolean;
  emptyDataContent?: ReactNode;
  chartContentClassName?: string;
  datasetsAreHidden?: boolean;
  onDatasetsToggle?: () => void;
};

type ChartContainerProps<T extends MRT_RowData> = BaseChartContainerProps &
  ChartContainerTableOptions<T>;

const chartContainerVariants = cva(
  "flex p-1 flex-col bg-white w-full h-full gap-y-2 min-h-[10rem] relative",
  {
    variants: {
      bordered: {
        true: "border rounded-md p-3",
        false: "",
      },
      variant: {
        bordered: "border border-border",
      },
    },
    defaultVariants: {
      bordered: false,
    },
  },
);

const getTitle = (isFullscreen: boolean, title?: TitleOrRenderProp) => {
  switch (typeof title) {
    case "function":
      return title({ isFullscreen });
    case "string":
      return (
        <Text className="text-2xl" component="h2">
          {title}
        </Text>
      );
    default:
      return title;
  }
};

const baseTableOptions: Omit<MRT_TableOptions<object>, "columns" | "data"> = {
  enableStickyHeader: true,
  enableTopToolbar: true,
  enableColumnActions: false,
  enableSorting: false,
  enablePagination: true,
  enableFullScreenToggle: false,
  mantinePaperProps: {
    className: "flex-1 flex flex-col overflow-hidden",
  },
  mantineTableContainerProps: {
    className: "flex-1",
  },
};

export const ChartContainer = <T extends MRT_RowData>({
  title,
  loading,
  fetching,
  bordered,
  children,
  className,
  isDataEmpty,
  emptyDataContent,
  enableGridDisplay,
  chartContentClassName,
  tableOptions = { columns: [], data: [] },
  datasetsAreHidden,
  onDatasetsToggle,
}: ChartContainerProps<T>) => {
  const [gridModalOpen, { open, close }] = useDisclosure();
  const { t } = useTranslation("common");
  const typedBaseTableOptions = baseTableOptions as MRT_TableOptions<T>;
  const table = useMRT(
    deepMerge(typedBaseTableOptions, {
      state: { isLoading: loading },
      ...tableOptions,
    }),
  );
  const titleContent = getTitle(gridModalOpen, title);

  return (
    <div className={cn(chartContainerVariants({ bordered }), className)}>
      {titleContent}

      <LoadingOverlay
        zIndex={20}
        visible={!loading && !isDataEmpty && fetching}
        overlayProps={{ bg: alpha("#fff", 0.4) }}
      />

      {!!titleContent && <Separator />}

      {(!isDataEmpty || loading) && (
        <div className={cn("relative flex-1", chartContentClassName)}>
          {onDatasetsToggle && (
            <ActionIcon
              c="black"
              bg="gray.2"
              className="absolute left-1 top-1 z-10 p-1"
              onClick={onDatasetsToggle}
            >
              {datasetsAreHidden ?
                <IconEye />
              : <IconEyeOff />}
            </ActionIcon>
          )}
          {children}
          {enableGridDisplay && (
            <ActionIcon
              c="black"
              bg="gray.2"
              className="absolute right-1 top-1 z-10 p-1"
              onClick={open}
            >
              <FullscreenIcon />
            </ActionIcon>
          )}
          <LoadingOverlay
            zIndex={20}
            overlayProps={{ bg: alpha("#fff", 0.4) }}
            visible={loading}
          />
        </div>
      )}

      {!loading && !fetching && isDataEmpty && (
        <div className="flex flex-1 flex-col items-center justify-center">
          <CircleOffIcon size={48} />
          {emptyDataContent || <p className="text-lg">{t("state.noData")}</p>}
        </div>
      )}

      <Modal
        fullScreen
        opened={gridModalOpen}
        onClose={close}
        title={typeof title === "string" ? title : titleContent}
        classNames={{
          title: "text-2xl",
          content: "flex flex-col",
          body: "flex-1 flex flex-col overflow-auto",
          header: "items-start",
        }}
      >
        <MantineReactTable table={table} />
      </Modal>
    </div>
  );
};
