import { Menu, Button, Title, Modal, Text } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { PermissionControl } from "@/components/PermissionControl";
import { toast } from "@/components/ui/use-toast";
import { useDeleteUserMutation } from "@/hooks/api/useDeleteUserMutation";
import { useFetchUsersQuery } from "@/hooks/api/useFetchUsersQuery";
import { useRequestPasswordResetMutation } from "@/hooks/api/useRequestPasswordResetMutation";
import { useMRT } from "@/hooks/useMRT";
import { Claim, UserResponse } from "@/lib/api/dto";
import { CreateOrganizationAdminUserPage } from "@/pages/CreateOrganizationAdminUserPage";
import { CreateOrganizationUserPage } from "@/pages/CreateOrganizationUserPage";
import { CreateSuperAdminUserPage } from "@/pages/CreateSuperAdminUserPage";
import { CreateSystemUserPage } from "@/pages/CreateSystemUserPage";
import { UserDetails } from "@/widgets/UserDetails/UserDetails";

import { columns } from "./columns";

type UserActionType = "edit" | "delete" | "resetPassword";
type ConfirmModalConfigKey = Exclude<UserActionType, "edit">;
type ConfirmModalConfigValue = { message: string; confirmAction: () => void };

type ClaimModalConfigKey =
  | Claim.SYS_USER
  | Claim.SUPERADMIN
  | Claim.ORG_ADMIN
  | Claim.ORG_USER;

type ClaimModalConfigValue = {
  title: string;
  component: (props: { onSettled: () => void }) => JSX.Element;
};

export const UsersTable = () => {
  const { t } = useTranslation(["entity", "common"]);

  const [selectedRow, setSelectedRow] = useState<{
    user: UserResponse;
    action: UserActionType;
  } | null>(null);

  const [claim, setClaim] = useState<Claim | null>(null);

  const { data, isLoading, isFetching } = useFetchUsersQuery();

  const { mutate: deleteUser, isPending: isDeleting } = useDeleteUserMutation();
  const { mutate: requestPasswordReset, isPending: isResetingPassword } =
    useRequestPasswordResetMutation();

  const memoizedData = useMemo(() => data || [], [data]);

  const claimModalConfig: Record<ClaimModalConfigKey, ClaimModalConfigValue> = {
    ORG_ADMIN: {
      title: t("administration.user.actions.addOrgAdmin"),
      component: CreateOrganizationAdminUserPage,
    },
    ORG_USER: {
      title: t("administration.user.actions.addOrgUser"),
      component: CreateOrganizationUserPage,
    },
    SUPERADMIN: {
      title: t("administration.user.actions.addSuperAdmin"),
      component: CreateSuperAdminUserPage,
    },
    SYS_USER: {
      title: t("administration.user.actions.addSystemUser"),
      component: CreateSystemUserPage,
    },
  };

  const confirmModalConfig: Record<
    ConfirmModalConfigKey,
    ConfirmModalConfigValue
  > = {
    delete: {
      message: t("administration.user.actions.deleteUser", {
        value: selectedRow?.user?.name,
      }),
      confirmAction: () => handleDeleteUser(selectedRow?.user),
    },
    resetPassword: {
      message: t("administration.user.actions.resetPassword", {
        value: selectedRow?.user?.name,
      }),
      confirmAction: () => handleResetPassword(selectedRow?.user),
    },
  };

  const claimModal =
    claim ? claimModalConfig[claim as ClaimModalConfigKey] : null;

  const confirmModal =
    selectedRow ?
      confirmModalConfig[selectedRow.action as ConfirmModalConfigKey]
    : null;

  const renderCustomActions = () => (
    <Menu shadow="md">
      <Menu.Target>
        <Button className="ml-auto">
          {t("administration.common.actions")}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <PermissionControl permission={Claim.SUPERADMIN}>
          <Menu.Item onClick={() => setClaim(Claim.SYS_USER)}>
            {t("administration.user.actions.addSystemUser")}
          </Menu.Item>
          <Menu.Item onClick={() => setClaim(Claim.SUPERADMIN)}>
            {t("administration.user.actions.addSuperAdmin")}
          </Menu.Item>
          <Menu.Item onClick={() => setClaim(Claim.ORG_ADMIN)}>
            {t("administration.user.actions.addOrgAdmin")}
          </Menu.Item>
        </PermissionControl>
        <PermissionControl
          permission={[Claim.SUPERADMIN, Claim.ORG_USERS_WRITE]}
        >
          <Menu.Item onClick={() => setClaim(Claim.ORG_USER)}>
            {t("administration.user.actions.addOrgUser")}
          </Menu.Item>
        </PermissionControl>
      </Menu.Dropdown>
    </Menu>
  );

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    state: {
      showProgressBars: isFetching,
      isLoading,
    },
    initialState: { showColumnFilters: true },
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Menu.Item
          onClick={() => {
            setSelectedRow({
              user: row.original,
              action: "edit",
            });
          }}
        >
          {t("common:userActions.edit")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            setSelectedRow({
              user: row.original,
              action: "delete",
            })
          }
        >
          {t("common:userActions.delete")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            setSelectedRow({
              user: row.original,
              action: "resetPassword",
            })
          }
        >
          {t("common:userActions.resetPassword")}
        </Menu.Item>
      </>
    ),
    renderTopToolbarCustomActions: renderCustomActions,
  });

  const handleDeleteUser = (user?: UserResponse) => {
    if (!user || isDeleting) return;
    deleteUser(user.id, {
      onSuccess: () => {
        toast({
          title: t("administration.user.actions.deleteUserSuccess"),
        });
      },
      onError: () => {
        toast({
          title: t("administration.user.actions.deleteUserError"),
          variant: "destructive",
        });
      },
      onSettled: () => setSelectedRow(null),
    });
  };

  const handleResetPassword = (user?: UserResponse) => {
    if (!user) return;
    requestPasswordReset(user.id, {
      onSuccess: () => {
        toast({
          title: t("administration.user.actions.resetPasswordSuccess"),
        });
      },
      onError: () => {
        toast({
          title: t("administration.user.actions.resetPasswordError"),
        });
      },
      onSettled: () => setSelectedRow(null),
    });
  };

  const handleCloseConfirmModal = () => {
    if (!isDeleting || !isResetingPassword) setSelectedRow(null);
  };

  const handleCloseClaimModal = () => setClaim(null);

  const Component =
    claim ? claimModalConfig[claim as ClaimModalConfigKey].component : null;

  return (
    <div className="flex flex-1 flex-col overflow-auto">
      <Title className="mb-4" size="h2">
        {t("administration.user.users")}
      </Title>
      <MantineReactTable table={table} />
      <Modal
        opened={selectedRow?.action === "edit"}
        onClose={() => setSelectedRow(null)}
        title={t("administration.user.actions.editUser")}
        size="lg"
      >
        <UserDetails
          user={selectedRow?.user}
          onSettled={() => setSelectedRow(null)}
        />
      </Modal>
      <Modal
        opened={!!claimModal}
        onClose={handleCloseClaimModal}
        title={claimModal?.title}
        size="lg"
      >
        {Component && <Component onSettled={handleCloseClaimModal} />}
      </Modal>
      <Modal
        opened={!!confirmModal}
        onClose={handleCloseConfirmModal}
        title={
          <div className="flex items-center gap-2">
            <IconAlertTriangle /> {t("common:warning")}
          </div>
        }
      >
        <Text className="mb-4" variant="text" size="sm">
          {confirmModal?.message}
        </Text>
        <div className="flex justify-between gap-2">
          <Button variant="light" onClick={handleCloseConfirmModal}>
            {t("common:userActions.cancel")}
          </Button>
          <Button
            onClick={confirmModal?.confirmAction}
            loading={isDeleting || isResetingPassword}
            disabled={isDeleting || isResetingPassword}
          >
            {t("common:userActions.confirm")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
