import { createMRTColumnHelper } from "mantine-react-table";

import { StateResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<StateResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:systemCodebook.common.name",
  }),
  columnHelper.accessor("stateCode", {
    header: "entity:systemCodebook.common.stateCode",
  }),
  columnHelper.accessor("countryCode", {
    header: "entity:systemCodebook.states.countryCode",
  }),
];
