import { createMRTColumnHelper } from "mantine-react-table";

import { OrganizationResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<OrganizationResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.common.organization",
  }),
  columnHelper.accessor("shortname", {
    header: "entity:administration.organization.shortname",
  }),
  columnHelper.accessor("organizationType.name", {
    header: "entity:administration.organization.organizationType",
  }),
  columnHelper.accessor("state.name", {
    header: "entity:administration.organization.state",
  }),
  columnHelper.accessor("headquartersCity.name", {
    header: "entity:administration.organization.headquartersCity",
  }),
  columnHelper.accessor("address", {
    header: "entity:administration.common.address",
  }),
  columnHelper.accessor("phone", {
    header: "entity:administration.organization.phone",
    Cell: ({ cell }) => cell.getValue().join(", "),
  }),
  columnHelper.accessor("email", {
    header: "entity:administration.common.email",
  }),
  columnHelper.accessor("www", {
    header: "entity:administration.organization.www",
  }),
];
