import isEqual from "lodash/isEqual";
import { useMemo, useSyncExternalStore } from "react";

import { LocalStorageKeys } from "@/constants";

/**
 * This is a map of listeners for localStorage changes. The key is the localStorage key
 */
const listeners = new Map<string, Set<() => void>>();

/**
 * This is a map of cached localStorage entries. The key is a stringified
 */
const cache = new Map<string, Partial<LocalStorageData>>();

/**
 * The shape of the data stored in localStorage
 */
export interface LocalStorageData {
  [LocalStorageKeys.AccessToken]: string;
  [LocalStorageKeys.RefreshToken]: string;
  [LocalStorageKeys.SelectedTenant]: string;
  [LocalStorageKeys.SelectedOrganization]: string;
  [key: string]: unknown;
}

type LocalStorageSnapshot<T extends string[]> = {
  [Key in T[number]]: LocalStorageData[Key];
};

/**
 * API for interacting with localStorage
 */
export const localStorageActions = {
  subscribe: (keys: string[]) => (ping: () => void) => () => {
    keys.forEach((key) => {
      let listenersForKey = listeners.get(key);

      if (listenersForKey) {
        // if there are already listeners for this key, add the new one
        listenersForKey.add(ping);
      } else {
        // if there are no listeners for this key, create a new set
        listenersForKey = listeners.set(key, new Set([ping])).get(key)!;
      }

      // return a function that removes the listener for cleanup
      return () => listenersForKey?.delete(ping);
    });
  },
  getItem: <T extends LocalStorageKeys | string>(key: T) => {
    const localStorageItem = localStorage.getItem(key);

    try {
      return JSON.parse(localStorageItem || "null") as
        | LocalStorageData[T]
        | null;
    } catch {
      return localStorageItem || null;
    }
  },
  removeItem: <T extends LocalStorageKeys | string>(key: T) => {
    localStorage.removeItem(key);
    const listenersForKey = listeners.get(key);

    if (listenersForKey) {
      listenersForKey.forEach((ping) => ping());
    }
  },
  setItem: <T extends LocalStorageKeys | string>(
    key: T,
    value: LocalStorageData[T] | null
  ) => {
    if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
    const listenersForKey = listeners.get(key);

    if (listenersForKey) {
      listenersForKey.forEach((ping) => ping());
    }
  },
};

/**
 * This function is used to get a snapshot of the localStorage data. It will
 * return the cached value if it exists and is equal to the current value.
 * Otherwise, it will return the current value and update the cache.
 */
const getSnapshot = <T extends string[]>(keys: T) => {
  const cacheKey = keys.join(".");
  const snapshot = keys.reduce(
    (acc, key) => ({ ...acc, [key]: localStorageActions.getItem(key) }),
    {}
  );

  const cacheEntry = cache.get(cacheKey);

  if (cacheEntry && isEqual(cacheEntry, snapshot)) {
    return cacheEntry;
  }

  cache.set(cacheKey, snapshot);

  return snapshot as LocalStorageSnapshot<T>;
};

window.addEventListener("storage", (event) => {
  const { key, newValue } = event;

  if (key && newValue) {
    const listenersForKey = listeners.get(key);

    if (listenersForKey) {
      listenersForKey.forEach((ping) => ping());
    }
  }
});

/**
 * This hook is used to subscribe to localStorage changes. It will return the
 * current value and update the value when localStorage changes.
 * @deprecated react doesn't "react" to when snapshots change, so this hook is
 * not very useful
 */
export const useLocalStorage = <T extends string[]>(keys: T) => {
  const store = useSyncExternalStore(
    useMemo(() => localStorageActions.subscribe(keys), [keys]),
    () => getSnapshot<T>(keys)
  );

  return {
    store,
    setItem: localStorageActions.setItem,
  };
};
