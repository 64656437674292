import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { MeResponse } from "@/lib/api/dto";

import { authKeys } from "./queryKeys";

interface UseFetchMeQueryProps
  extends Omit<UseQueryOptions<MeResponse>, "queryKey"> {}

export const useFetchMeQuery = (queryProps?: UseFetchMeQueryProps) => {
  return useQuery({
    staleTime: 1000 * 60 * 60,
    ...queryProps,
    queryFn: () => Api.user.me().then((res) => res.data),
    queryKey: authKeys.me(),
  });
};
