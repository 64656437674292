import {
  CloseButton,
  Combobox,
  InputBase,
  ScrollArea,
  useCombobox,
  TreeNodeData,
  Tree,
  Group,
  useTree,
  UseTreeInput,
} from "@mantine/core";
import { IconChevronRight, IconChevronDown } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/lib/utils";
import { findTreeNode } from "@/lib/utils";

type Value = string | null;

type DisplayValueOrRenderFn =
  | keyof Pick<TreeNodeData, "value" | "label">
  | ((node: TreeNodeData) => React.ReactNode);

interface TreeSelectProps {
  className?: string;
  value?: Value;
  data?: TreeNodeData[];
  placeholder?: React.ReactNode;
  label?: React.ReactNode;
  error?: React.ReactNode;
  closeOnSelect?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  required?: boolean;
  treeLine?: boolean;
  isParentSelectable?: boolean;
  displayValue?: DisplayValueOrRenderFn;
  treeHookProps?: UseTreeInput;
  nothingFoundMessage?: string;
  onChange?: (value: Value) => void;
}

const TreeSelect = ({
  className,
  value,
  data = [],
  placeholder,
  label,
  error,
  displayValue = "label",
  closeOnSelect = true,
  clearable,
  disabled,
  required,
  treeLine = true,
  isParentSelectable,
  onChange,
  treeHookProps,
  nothingFoundMessage,
  ...rest
}: TreeSelectProps) => {
  const tree = useTree(treeHookProps);
  const combobox = useCombobox();
  const { t } = useTranslation("common");

  const memoizedNode = useMemo(() => findTreeNode(data, value), [data, value]);
  const memoizedData = useMemo(
    () =>
      data.length > 0 ?
        data
      : [
          {
            value: "",
            label: nothingFoundMessage || t("state.nothingFoundMessage"),
            disabled: true,
            nodeProps: {
              className: "hover:bg-transparent text-sm text-center py-2 px-1",
            },
          },
        ],
    [data, nothingFoundMessage, t],
  );

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    node: TreeNodeData,
    hasChildren: boolean,
    callback: (event: React.MouseEvent<Element, MouseEvent>) => void,
  ) => {
    if (node.disabled) {
      if (hasChildren) {
        callback(event);
      }
      return;
    }

    const isSelectable = node.selectable !== false;

    // value parsing
    const newValue: Value = node.value;

    // leaf node handling
    if (!hasChildren) {
      if (isSelectable) {
        onChange?.(newValue);
      }

      if (closeOnSelect && isSelectable) {
        combobox.closeDropdown();
      }
      callback(event);
      return;
    }

    // parent node handling
    if (isParentSelectable && isSelectable) {
      onChange?.(newValue);
    }

    if (isParentSelectable && isSelectable && closeOnSelect) {
      combobox.closeDropdown();
    }

    if (!isParentSelectable || !isSelectable) {
      callback(event);
    }
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onChange?.(null);
  };

  return (
    <Combobox store={combobox}>
      <Combobox.Target>
        <InputBase
          className={className}
          component="button"
          type="button"
          label={label}
          error={error}
          disabled={disabled}
          required={required}
          pointer
          rightSectionPointerEvents={
            value?.length && clearable ? "all" : "none"
          }
          rightSection={
            value?.length && clearable ?
              <CloseButton size="sm" onClick={handleClear} />
            : <Combobox.Chevron />
          }
          onClick={() => combobox.toggleDropdown()}
        >
          {memoizedNode ?
            <div className="truncate">
              {typeof displayValue === "string" ?
                memoizedNode[displayValue]
              : displayValue(memoizedNode)}
            </div>
          : <span>{placeholder}</span>}
        </InputBase>
      </Combobox.Target>
      <Combobox.Dropdown>
        <ScrollArea.Autosize type="auto" className="max-h-[200px]">
          <Tree
            tree={tree}
            levelOffset={8}
            selectOnClick
            expandOnClick
            data={memoizedData}
            renderNode={({
              node,
              level,
              expanded,
              hasChildren,
              elementProps: { className, onClick },
              ...rest
            }) => {
              const isSelected = node.value && node.value === value;
              const shouldRenderTreeLine =
                !hasChildren && level > 1 && treeLine;

              return (
                <Group
                  gap={4}
                  aria-disabled={node.disabled}
                  className={cn(
                    "flex-nowrap hover:bg-gray-200 [&.selected]:bg-brand-100 [&.selected]:hover:bg-brand-200",
                    isSelected && "selected bg-brand-100",
                    node.disabled && "cursor-default",
                    node.nodeProps?.className,
                    className,
                  )}
                  onClick={(e) => handleClick(e, node, hasChildren, onClick)}
                  {...rest}
                >
                  {hasChildren && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick(e);
                      }}
                      className="cursor-pointer rounded hover:bg-gray-300"
                    >
                      {expanded ?
                        <IconChevronDown size="16" />
                      : <IconChevronRight size="16" />}
                    </span>
                  )}

                  <div
                    className={cn(
                      "relative grow rounded px-1",
                      node.disabled && "opacity-50",
                      shouldRenderTreeLine &&
                        "ml-3 before:absolute before:bottom-0 before:left-[-13px] before:top-0 before:border-l before:border-gray-300 before:content-['']",
                    )}
                  >
                    {node.label}
                  </div>
                </Group>
              );
            }}
            {...rest}
          />
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export { TreeSelect, type TreeSelectProps };
