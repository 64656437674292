import { useMantineTheme } from "@mantine/core";
import { TableMeta } from "@tanstack/table-core";
import { ChartDataset } from "chart.js";
import { createMRTColumnHelper } from "mantine-react-table";
import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { ChartContainer } from "@/components/ui/chart-container";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchReportConsumptionByCompartmentsQuery } from "@/hooks/api/useFetchReportConsumptionByCompartmentsQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { ReportConsumptionByCompartmentsResponse } from "@/lib/api/dto";
import { formatToDate, removeDateOffset } from "@/lib/dateUtils";
import { formatNumberAsCurrency } from "@/lib/utils";

const prepareByCompartmentChartData =
  (borderColor: string, backgroundColor: string) =>
  (data: ReportConsumptionByCompartmentsResponse[]) => {
    if (!Array.isArray(data)) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const labels = data?.map((item) => item.name);
    const datasets: ChartDataset<"bar">[] = [
      {
        data: data?.map((item) => +item.billedquantity),
        backgroundColor,
        borderColor,
        stack: "0",
        borderWidth: 1,
      },
    ];

    return {
      labels,
      datasets,
    };
  };

const columnHelper =
  createMRTColumnHelper<ReportConsumptionByCompartmentsResponse>();

const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.compartment.compartment",
  }),
  columnHelper.accessor("billedquantity", {
    header: "billing:page.consumption",
    Cell: ({ cell, table }) =>
      formatNumberAsCurrency({
        value: cell.getValue(),
        currency: (
          table.options
            .meta as TableMeta<ReportConsumptionByCompartmentsResponse>
        )?.currency,
        options: {
          locale: (
            table.options
              .meta as TableMeta<ReportConsumptionByCompartmentsResponse>
          )?.currencyLocale,
        },
      }),
  }),
];

interface BillingByCompartmentReportProps {
  tenantocid?: string | null;
  datefrom?: string | Date | null;
  dateto?: string | Date | null;
}

export const BillingByCompartmentReport = ({
  tenantocid,
  datefrom,
  dateto,
}: BillingByCompartmentReportProps) => {
  const theme = useMantineTheme();
  const { tenant } = useTenantContext();
  const { t } = useTranslation(["dashboard", "billing"]);

  const {
    data = [],
    isLoading,
    isFetching,
  } = useFetchReportConsumptionByCompartmentsQuery({
    params: {
      tenantocid,
      datefrom: datefrom && removeDateOffset(new Date(datefrom)).getTime(),
      dateto: dateto && removeDateOffset(new Date(dateto)).getTime(),
    },
  });

  const memoData = useMemo(
    () =>
      prepareByCompartmentChartData(
        theme.other.chartColors.green,
        theme.other.chartColors.greenTransparent,
      )(data),
    [
      data,
      theme.other.chartColors.green,
      theme.other.chartColors.greenTransparent,
    ],
  );

  const currencyLocale = useCurrencyLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;

  const formatedDateRange = `(${datefrom && formatToDate(datefrom)} - ${dateto && formatToDate(dateto)})`;

  return (
    <ChartContainer
      title={`${t("billing:page.consumptionPerCompartment")} ${formatedDateRange}`}
      enableGridDisplay
      tableOptions={{
        columns,
        data,
        meta: {
          currency,
          currencyLocale,
        },
      }}
      fetching={isFetching}
      loading={isLoading}
      isDataEmpty={!memoData?.datasets[0]?.data.length}
    >
      {memoData && (
        <Bar
          options={{
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              y: {
                ticks: {
                  callback: (tickValue) =>
                    formatNumberAsCurrency({
                      value: tickValue,
                      currency,
                      options: { locale: currencyLocale },
                    }),
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) =>
                    `${t("dashboard:commitment.usedAmount")}: ${formatNumberAsCurrency({ value: tooltipItem.parsed.y, currency, options: { locale: currencyLocale } })}`,
                },
              },
            },
          }}
          data={memoData}
        />
      )}
    </ChartContainer>
  );
};
