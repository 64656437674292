import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";

import { metricMeasuringUnitKeys } from "./queryKeys";

interface UseDeleteMetricNamespaceMutationOptions
  extends Omit<UseMutationOptions<unknown, Error, string>, "mutationFn"> {}

export const useDeleteMetricMeasuringUnitMutation = ({
  onError,
  onSuccess,
  onSettled,
  ...options
}: UseDeleteMetricNamespaceMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: Api.metricMeasuringUnit.delete,
    onSuccess: (...args) => {
      successToast.delete();
      onSuccess?.(...args);
    },
    onError: (...args) => {
      errorToast.delete();
      onError?.(...args);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: metricMeasuringUnitKeys.all(),
      });
      onSettled?.(...args);
    },
  });
};
