import { ScrollArea } from "@mantine/core";
import { Outlet } from "react-router-dom";

import { Claim } from "@/lib/api/dto";
import { Link, LinkGroup, Sidebar } from "@/widgets/Sidebar";

const entityLinks: (Link | LinkGroup)[] = [
  {
    label: "sidebar.systemCodebook.systemCodebook",
    links: [
      {
        label: "sidebar.systemCodebook.accessConfigType",
        path: "/entity/accessconfigtypes",
        // requiredAuthorities: [Claim.SUPERADMIN],
      },
      // {
      //   label: "Tip rola ili autorizacija",
      //   path: "",
      // },
      // {
      //   label: "Tip proizvoda",
      //   path: "",
      // },
      // {
      //   label: "Tip konfiguracije pristupa",
      //   path: "",
      //   requiredAuthorities: [Claim.SUPERADMIN],
      // },
      {
        label: "sidebar.systemCodebook.authority",
        path: "/entity/authorities",
      },
      {
        label: "sidebar.systemCodebook.productDescription",
        path: "/entity/productdescription",
      },
      // {
      //   label: "Tip grupe proizvoda",
      //   path: "",
      // },
      {
        label: "sidebar.systemCodebook.resources",
        path: "/entity/resourcetype",
      },
      // {
      //   label: "Tip skladišta",
      //   path: "",
      // },
      {
        label: "sidebar.systemCodebook.subscriptionType",
        path: "/entity/subscription-types",
      },
      // {
      //   label: "Tip pristupa tenanta",
      //   path: "",
      // },
      // {
      //   label: "Status kompartmenta",
      {
        label: "sidebar.systemCodebook.productService",
        path: "/entity/productservice",
      },
      // {
      //   label: "Tip resursa",
      //   path: "",
      // },
      {
        label: "sidebar.systemCodebook.storage",
        path: "/entity/storage-result-types",
      },
      // {
      //   label: "Tip ugovora",
      //   path: "",
      // },
      {
        label: "sidebar.systemCodebook.tenancyAccess",
        path: "/entity/tenancy-access-types",
      },
      {
        label: "sidebar.systemCodebook.organizationType",
        path: "/entity/organization-types",
      },
      {
        label: "sidebar.systemCodebook.compartmentStatus",
        path: "/entity/compartmentstatuses",
      },
      {
        label: "sidebar.systemCodebook.states",
        path: "/entity/states",
        requiredAuthorities: [Claim.SUPERADMIN],
      },
      {
        label: "sidebar.systemCodebook.cities",
        path: "/entity/cities",
        requiredAuthorities: [Claim.SUPERADMIN],
      },
    ],
    // {
    //   label: "Alarms",
    //   path: "/entity/alarms",
    // },
    // {
    //   label: "Employees",
    //   path: "/entity/employees",
    // },
  },
  {
    label: "sidebar.administration.administration",
    links: [
      {
        label: "sidebar.administration.tenants",
        path: "/entity/tenants",
      },
      {
        label: "sidebar.administration.organization",
        path: "/entity/organizations",
        requiredAuthorities: [Claim.SUPERADMIN],
      },
      {
        label: "sidebar.administration.compartment",
        path: "/entity/compartments",
      },
      {
        label: "sidebar.administration.resources",
        path: "/entity/resources",
      },
      {
        label: "sidebar.administration.license",
        path: "/entity/licenses",
        requiredAuthorities: [
          Claim.SUPERADMIN,
          Claim.ORG_LICENCE_MANAGE,
          Claim.ORG_LICENCE_READ,
        ],
      },
      {
        label: "sidebar.administration.users",
        path: "/entity/users",
        requiredAuthorities: [Claim.SUPERADMIN, Claim.ORG_USERS_WRITE],
      },
      {
        label: "sidebar.administration.subscriptions",
        path: "/entity/subscription",
      },
    ],
  },
  {
    label: "sidebar.userCodebook.userCodebook",
    links: [
      {
        label: "sidebar.userCodebook.metricMeasuringUnit",
        path: "/entity/metricmeasuringunit",
      },
      {
        label: "sidebar.userCodebook.metricName",
        path: "/entity/metricname",
      },
      {
        label: "sidebar.userCodebook.metricNamespace",
        path: "/entity/metricnamespace",
      },
      {
        label: "sidebar.userCodebook.statisticType",
        path: "/entity/statistictype",
      },
      {
        label: "sidebar.userCodebook.region",
        path: "/entity/regions",
      },
    ],
  },
  {
    label: "sidebar.oci",
    path: "/entity/oci-queries",
  },
];

export const EntitiesPage = () => {
  return (
    <div className="flex flex-1 flex-col overflow-hidden lg:flex-row">
      <ScrollArea scrollbars="y" className="border-r">
        <Sidebar links={entityLinks} />
      </ScrollArea>
      <div className="flex flex-1 overflow-hidden p-4">
        <Outlet />
      </div>
    </div>
  );
};
