import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { parse, isValid } from "date-fns";

import { Api } from "@/lib/api";
import { FourthDiagramParams, FourthDiagramResponse } from "@/lib/api/dto";
import { yearMonthFormat } from "@/lib/dateUtils";

import { dashboardReportKeys } from "./queryKeys";

interface UseFetchFourthDiagramOptions<T>
  extends Omit<
    UseQueryOptions<
      FourthDiagramResponse[],
      Error,
      T,
      ReturnType<typeof dashboardReportKeys.fourthDiagram>
    >,
    "queryKey"
  > {
  params: Partial<FourthDiagramParams>;
}

/**
 * If `ocidtime` isn't a valid date, e.g. `-----`,
 * then data represents average data.
 *
 * @param ocidtime
 * @returns boolean
 */
const isDataAverage = (ocidtime: string) => {
  return !isValid(parse(ocidtime, yearMonthFormat, new Date()));
};

export const useFetchFourthDiagramQuery = <T = FourthDiagramResponse[]>({
  params,
  ...options
}: UseFetchFourthDiagramOptions<T>) => {
  return useQuery({
    ...options,
    staleTime: 1000 * 60 * 5,
    queryKey: dashboardReportKeys.fourthDiagram(params),
    queryFn: ({ queryKey: [_, keyParams] }) =>
      keyParams.subscriptionid ?
        Api.dashboard
          .fourthDiagram(params as FourthDiagramParams)
          .then((res) => res.data.filter((d) => !isDataAverage(d.ocidtime)))
      : [],
  });
};
