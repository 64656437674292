import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";

import { statisticTypeKeys } from "./queryKeys";

interface UseDeleteStatisticTypeMutationOptions
  extends Omit<UseMutationOptions<unknown, Error, number>, "mutationFn"> {}

export const useDeleteStatisticTypeMutation = ({
  onSuccess,
  onError,
  onSettled,
  ...options
}: UseDeleteStatisticTypeMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (id: number) => Api.statisticType.delete(id),
    onSuccess: (...args) => {
      successToast.delete();
      onSuccess?.(...args);
    },
    onError: (...args) => {
      errorToast.delete();
      onError?.(...args);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: statisticTypeKeys.all(),
      });
      onSettled?.(...args);
    },
  });
};
