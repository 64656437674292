import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { UnitTypeResponse } from "@/lib/api/dto";

import { unitTypeKeys } from "./queryKeys";

export interface UseUnitTypesQueryParams<T>
  extends Omit<
    UseQueryOptions<
      UnitTypeResponse[],
      Error,
      T,
      ReturnType<typeof unitTypeKeys.all>
    >,
    "queryKey" | "queryFn"
  > {
  resourceId?: string | null;
}

export const useFetchUnitTypesQuery = <T = UnitTypeResponse[]>({
  resourceId,
  ...options
}: UseUnitTypesQueryParams<T> = {}) => {
  return useQuery({
    ...options,
    queryKey: unitTypeKeys.all(resourceId),
    queryFn: ({ queryKey }) =>
      Api.unitType.list(queryKey[1]).then((res) => res.data),
  });
};
