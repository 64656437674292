import { ActionIcon, type ActionIconProps, type PolymorphicComponentProps } from "@mantine/core";
import { IconEdit, type TablerIconsProps } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

type ActinIconEditProps<C = 'button'> = PolymorphicComponentProps<C, ActionIconProps> & { tablerIconsProps?: TablerIconsProps };

const ActinIconEdit = ({ color = 'blue', tablerIconsProps, ...rest }: Omit<ActinIconEditProps, 'children'>) => {
    const { t } = useTranslation();

    return (
        <ActionIcon title={t('userActions.edit')} color={color} {...rest}>
            <IconEdit size={20} {...tablerIconsProps} />
        </ActionIcon>
    )
}

export { ActinIconEdit, type ActinIconEditProps };