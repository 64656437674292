import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";
import { CreateMetricNamePayload, MetricNameResponse } from "@/lib/api/dto";

import { metricNamesKeys } from "./queryKeys";

interface UseCreateOrUpdateMetricNameMutationOptions
  extends UseMutationOptions<
    MetricNameResponse,
    Error,
    CreateMetricNamePayload
  > {
  metricName?: string;
}

export const useCreateOrUpdateMetricNameMutation = ({
  metricName,
  ...options
}: UseCreateOrUpdateMetricNameMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (data: CreateMetricNamePayload) => {
      if (metricName) {
        return Api.metricName.update(metricName, data).then((res) => res.data);
      }
      return Api.metricName.create(data).then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      metricName ? successToast.update() : successToast.create();
      queryClient.invalidateQueries({ queryKey: metricNamesKeys.all() });

      options.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      metricName ? errorToast.update() : errorToast.create();

      options.onError?.(error, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({ queryKey: metricNamesKeys.all() });
      if (options.onSettled) {
        options.onSettled(data, error, variables, context);
      }
    },
  });
};
