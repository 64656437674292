import { Paper, TagsInput, TextInput, Button, Select } from "@mantine/core";
import { isAxiosError } from "axios";
import { zodResolver } from "mantine-form-zod-resolver";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { toast } from "@/components/ui/use-toast";
import { useCreateOrUpdateOrganizationMutation } from "@/hooks/api/useCreateOrUpdateOrganizationMutation";
import { useFetchCitiesQuery } from "@/hooks/api/useFetchCitiesQuery";
import { useFetchOrganizationTypesQuery } from "@/hooks/api/useFetchOrganizationTypesQuery";
import { useFetchStatesQuery } from "@/hooks/api/useFetchStatesQuery";
import { useTForm } from "@/hooks/useTForm";
import {
  CreateAndUpdateOrganizationResponse,
  OrganizationResponse,
} from "@/lib/api/dto";
import { parseArrayFromString, cn } from "@/lib/utils";

interface OrganizationDetailsProps {
  organization?: OrganizationResponse;
  onSettled?: () => void;
  className?: string;
}
const organizationSchema = z.object({
  name: z.string().min(1, {
    message: "form:field.required",
  }),
  shortname: z.string().min(1, "form:field.required"),
  address: z.string(),
  phone: z.string().array(),
  email: z.union([
    z.literal(""),
    z.string().email("entity:administration.organization.emailValid"),
  ]),
  stateCode: z
    .string()
    .nullable()
    .refine((value) => !!value, { message: "form:field.required" }),
  headquartersCityId: z.string().nullable(),
  organizationTypeId: z
    .string()
    .nullable()
    .refine((value) => !!value, { message: "form:field.required" }),
  www: z.string(),
});

type FormData = z.infer<typeof organizationSchema>;

const defaultValues: FormData = {
  name: "",
  shortname: "",
  email: "",
  phone: [],
  address: "",
  stateCode: null,
  headquartersCityId: null,
  organizationTypeId: null,
  www: "",
};

const mapOrganizationToFormData = (
  organization?: OrganizationResponse | CreateAndUpdateOrganizationResponse,
): FormData => {
  if (!organization) return defaultValues;

  return {
    name: organization.name ?? defaultValues.name,
    shortname: organization.shortname ?? defaultValues.shortname,
    email: organization.email ?? defaultValues.email,
    phone: parseArrayFromString(organization.phone) ?? defaultValues.phone,
    address: organization.address ?? defaultValues.address,
    stateCode: organization.state?.stateCode ?? defaultValues.stateCode,
    headquartersCityId:
      organization.headquartersCity?.id?.toString() ??
      defaultValues.headquartersCityId,
    organizationTypeId:
      organization.organizationType.id ?? defaultValues.organizationTypeId,
    www: organization.www ?? defaultValues.www,
  };
};

export const OrganizationDetails = ({
  organization,
  onSettled,
  className,
}: OrganizationDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);
  const { data: organizationTypes } = useFetchOrganizationTypesQuery();

  const { data: states = [] } = useFetchStatesQuery();

  const { data: cities = [] } = useFetchCitiesQuery();

  const { mutate, isPending } = useCreateOrUpdateOrganizationMutation({
    organizationId: organization?.id,
  });

  const form = useTForm<FormData>({
    initialValues: mapOrganizationToFormData(organization),
    validate: zodResolver(organizationSchema),
  });

  const onSubmit = (data: FormData) => {
    mutate(
      {
        ...data,
        phone: JSON.stringify(data.phone),
      },
      {
        onSuccess: (response) => {
          toast({
            title: t("administration.organization.organizationSaveSuccess"),
          });
          if (organization?.id) {
            form.setInitialValues(mapOrganizationToFormData(response));
          }
          form.reset();
        },
        onError: (error) => {
          toast({
            title:
              (isAxiosError(error) && error.response?.data?.message) ||
              t("administration.organization.organizationSaveError"),
          });
        },
        onSettled,
      },
    );
  };

  const stateOptions = useMemo(
    () =>
      states.map((state) => ({ value: state.stateCode, label: state.name })),
    [states],
  );

  const cityOptions = useMemo(
    () =>
      form.values.stateCode ?
        cities
          .filter((city) => city.state.stateCode === form.values.stateCode)
          .map((city) => ({
            value: `${city.id}`,
            label: city.name,
          }))
      : [],
    [cities, form.values.stateCode],
  );

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("administration.common.name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        withAsterisk
        label={t("administration.organization.shortname")}
        {...form.getInputProps("shortname")}
      />
      <Select
        withAsterisk
        label={t("administration.organization.organizationType")}
        {...form.getInputProps("organizationTypeId")}
        data={organizationTypes?.map((organizationType) => ({
          value: organizationType.id,
          label: organizationType.name,
        }))}
      />
      <Select
        withAsterisk
        label={t("administration.organization.state")}
        {...form.getInputProps("stateCode")}
        onChange={(value) => {
          form.setFieldValue("stateCode", value);
          form.setFieldValue("headquartersCityId", null);
        }}
        clearable
        data={stateOptions}
      />
      <Select
        label={t("administration.organization.headquartersCity")}
        {...form.getInputProps("headquartersCityId")}
        clearable
        data={cityOptions}
      />
      <TextInput
        label={t("administration.common.address")}
        {...form.getInputProps("address")}
      />
      <TagsInput
        splitChars={[" ", ","]}
        classNames={{
          input: "max-h-[4.125rem] overflow-auto",
        }}
        label={t("administration.organization.phone")}
        {...form.getInputProps("phone")}
      />
      <TextInput
        label={t("administration.common.email")}
        {...form.getInputProps("email")}
      />
      <TextInput
        label={t("administration.organization.www")}
        {...form.getInputProps("www")}
      />
      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};
