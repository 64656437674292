import { createMRTColumnHelper } from "mantine-react-table";

import { ProductServiceResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<ProductServiceResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.productService.name",
  }),
];
