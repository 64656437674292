import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { isToday, parseISO } from "date-fns";

import { Api } from "@/lib/api";
import {
  CommitmentConsumptionParams,
  CommitmentConsumptionResponse,
} from "@/lib/api/dto";

import { consumptionKeys } from "./queryKeys";

interface UseFetchCommitmentConsumptionQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      CommitmentConsumptionResponse[],
      Error,
      T,
      ReturnType<typeof consumptionKeys.commitmentConsumption>
    >,
    "queryKey" | "queryFn"
  > {
  params: CommitmentConsumptionParams;
}

export const useFetchCommitmentConsumptionQuery = <
  T = CommitmentConsumptionResponse[],
>({
  params,
  ...options
}: UseFetchCommitmentConsumptionQueryOptions<T>) => {
  return useQuery({
    ...options,
    queryKey: consumptionKeys.commitmentConsumption(params),
    queryFn: ({ queryKey: [_, keyParams] }) =>
      keyParams.subscriptionid ?
        Api.consumption
          .commitment({
            subscriptionid: keyParams.subscriptionid,
            commitmentid: keyParams.commitmentid,
          })
          .then((res) => res.data)
      : [],
    select: (data) => {
      return data
        .filter((item) => !isToday(parseISO(item.date)))
        .map((item, index, array) => {
          const previousItem = index > 0 ? array[index - 1] : null;

          if (!(previousItem?.commitmentId === item.commitmentId)) return item;

          return {
            ...item,
            // transform the usedAmount to the difference between the previous and current item
            // in api response, usedAmoint is the total amount accumulated till the current date
            usedAmount: item.usedAmount - (previousItem?.usedAmount || 0),
          };
        });
    },
  });
};
