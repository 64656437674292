import { Menu, UnstyledButton, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { ParseKeys } from "i18next";
import { Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { Claim } from "@/lib/api/dto";

type NavbarTranslationKeys = ParseKeys<"navbar">;

interface NavigationItemLink {
  label: NavbarTranslationKeys;
  description?: NavbarTranslationKeys;
  tooltip?: NavbarTranslationKeys;
  href: string;
  requiredAuthorities?: Claim[];
}

interface NavigationItemGroup {
  label: NavbarTranslationKeys;
  list: NavigationItemLink[];
  requiredAuthorities?: Claim[];
}

type NavigationItem = NavigationItemLink | NavigationItemGroup;

const navigationItems: NavigationItem[] = [
  {
    label: "monitoring.monitoring",
    href: "/monitor",
  },
  {
    label: "billing.billing",
    list: [
      {
        label: "billing.creditUsage",
        description: "billing.creditUsageDescription",
        href: "/billing",
      },
      {
        label: "billing.usageReport",
        description: "billing.usageReportDescription",
        href: "/billing/report",
      },
    ],
  },
  {
    label: "control.control",
    href: "/resources",
    requiredAuthorities: [Claim.SUPERADMIN, Claim.ORG_ADMIN, Claim.SYS_ADMIN],
  },
  {
    label: "notification.notification",
    requiredAuthorities: [Claim.SUPERADMIN, Claim.ORG_ADMIN, Claim.SYS_ADMIN],
    list: [
      {
        label: "notification.reviewMetricNotification",
        description: "notification.reviewMetricNotificationDescription",
        href: "/notifications/metric",
      },
      {
        label: "notification.scNotification",
        description: "notification.scNotificationDescription",
        href: "/notifications/sc",
      },
      {
        label: "notification.budgetNotification",
        description: "notification.budgetNotificationDescription",
        href: "/notifications/budget",
      },
      {
        label: "notification.compartmentNotification",
        description: "notification.compartmentNotificationDescription",
        href: "/notifications/compartment",
      },
    ],
  },
  {
    label: "admin.admin",
    requiredAuthorities: [Claim.SUPERADMIN, Claim.ORG_ADMIN, Claim.SYS_ADMIN],
    href: "/entity",
  },
];

export const NavMenu = () => {
  const { t } = useTranslation(["navbar"]);

  return (
    <nav className="flex items-center gap-1">
      {navigationItems.map((item) => {
        if (!("list" in item)) {
          return (
            <UnstyledButton
              key={item.label}
              to={item.href}
              component={NavLink}
              className="block rounded px-3 py-2 font-medium text-white hover:bg-gray-800 dark:text-gray-100 dark:hover:bg-gray-600"
            >
              {t(item.label)}
            </UnstyledButton>
          );
        }

        return (
          <Menu
            key={item.label}
            trigger="click-hover"
            transitionProps={{
              exitDuration: 300,
              transition: "pop-top-left",
              duration: 300,
            }}
            withinPortal
            position="bottom-start"
          >
            <Menu.Target>
              <UnstyledButton className="rounded px-3 py-2 font-medium text-white hover:bg-gray-800 dark:text-gray-100 dark:hover:bg-gray-900">
                <div className="flex items-center">
                  <span className="mr-1">{t(item.label)}</span>
                  <IconChevronDown className="size-4" stroke={1.5} />
                </div>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown className="max-w-60 border-none bg-gray-100 shadow-lg dark:bg-gray-800">
              {item.list.map((subItem, index) => (
                <Fragment key={subItem.href}>
                  <Menu.Item
                    key={subItem.href}
                    to={subItem.href}
                    component={NavLink}
                    className="w-full rounded px-3 py-2 text-left hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    <Text
                      size="sm"
                      className="text-gray-800 dark:text-gray-200"
                    >
                      {t(subItem.label)}
                    </Text>
                    {subItem.description && (
                      <Text size="xs" className="text-gray-400">
                        {t(subItem.description)}
                      </Text>
                    )}
                  </Menu.Item>
                  {index !== item.list.length - 1 && (
                    <Menu.Divider className="border-gray-200" />
                  )}
                </Fragment>
              ))}
            </Menu.Dropdown>
          </Menu>
        );
      })}
    </nav>
  );
};
