import { createMRTColumnHelper } from "mantine-react-table";
import { useTranslation } from "react-i18next";

import { LicenseResponse } from "@/lib/api/dto";
import { formatToDate } from "@/lib/dateUtils";

const columnHelper = createMRTColumnHelper<LicenseResponse>();

const isPermanent = (expirationDate: string) => {
  const expiration = new Date(expirationDate);
  const thirtyYearsFromNow = new Date();
  thirtyYearsFromNow.setFullYear(thirtyYearsFromNow.getFullYear() + 30);
  return expiration > thirtyYearsFromNow;
};

export const columns = [
  columnHelper.accessor("organizationName", {
    header: "entity:administration.common.organization",
  }),

  columnHelper.accessor("tenantsCount", {
    header: "entity:administration.tenant.tenant",
  }),

  columnHelper.accessor("expirationDate", {
    header: "entity:administration.license.expirationDate",
    Cell: ({ row }) => {
      const { t } = useTranslation("entity");
      const expirationDate = row.original.expirationDate;
      return isPermanent(expirationDate) ?
          t("administration.license.permanent")
        : formatToDate(new Date(expirationDate));
    },
  }),
];
