import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { LocalStorageKeys } from "@/constants";
import { useFetchTenantsQuery } from "@/hooks/api/useFetchTenantsQuery";
import { useAuth } from "@/hooks/useAuth";
import { localStorageActions } from "@/hooks/useLocalStorage";
import { TenantResponse } from "@/lib/api/dto";

type TenantContextProps = {
  tenants?: TenantResponse[];
  tenant: TenantResponse | null;
  setTenant: (tenantId: TenantResponse["id"]) => void;
  isLoading: boolean;
  isError: boolean;
};

const TenantContext = createContext({} as TenantContextProps);

export const TenantContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isLoggedIn } = useAuth();
  const {
    data: tenants,
    isLoading,
    isError,
  } = useFetchTenantsQuery({
    enabled: isLoggedIn,
  });

  const [tenant, setTenant] = useState<TenantResponse | null>(null);

  useEffect(() => {
    if (!!tenants?.length && !tenant) {
      setTenant(
        tenants.find(
          (t) =>
            t.ocid ===
            localStorageActions.getItem(LocalStorageKeys.SelectedTenant),
        ) || tenants[0],
      );
    }
  }, [tenant, tenants]);

  const handleSetTenant = (
    tenantOcidOrId: TenantResponse["ocid"] | TenantResponse["id"],
  ) => {
    const tenant = tenants?.find(
      (t) => t.ocid === tenantOcidOrId || t.id === tenantOcidOrId,
    );
    if (tenant) {
      setTenant(tenant);
      localStorageActions.setItem(
        LocalStorageKeys.SelectedTenant,
        tenantOcidOrId,
      );
    }
  };

  return (
    <TenantContext.Provider
      value={{
        tenants,
        tenant,
        setTenant: handleSetTenant,
        isLoading,
        isError,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useTenantContext = () => {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error("useTenantContext must be used within a TenantContext");
  }
  return context;
};
