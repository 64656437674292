import { useMantineTheme } from "@mantine/core";
import { ChartDataset } from "chart.js";
import { useMemo } from "react";
import { ChartProps, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { ChartContainer } from "@/components/ui/chart-container";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchCommitmentConsumptionQuery } from "@/hooks/api/useFetchCommitmentConsumptionQuery";
import { useFetchThirdDiagramQuery } from "@/hooks/api/useFetchThirdDiagramQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { CommitmentStatus } from "@/lib/api/dto";
import { formatNumberAsCurrency } from "@/lib/utils";

interface BillingByCommitmentReportProps {
  subscriptionId?: number;
  commitmentIds?: string[];
}

export const BillingByCommitmentReport = ({
  subscriptionId,
  commitmentIds,
}: BillingByCommitmentReportProps) => {
  const { t } = useTranslation("billing");
  const { tenant } = useTenantContext();
  const theme = useMantineTheme();
  const currencyLocale = useCurrencyLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;

  const { data, isLoading, isFetching } = useFetchCommitmentConsumptionQuery({
    params: {
      subscriptionid: `${subscriptionId}`,
    },
  });

  const { data: thirdDiagram } = useFetchThirdDiagramQuery({
    params: {
      subscriptionid: subscriptionId,
    },
  });

  const filteredData = useMemo(() => {
    return data?.filter((item) => commitmentIds?.includes(item.commitmentId));
  }, [commitmentIds, data]);

  const uniqueDates = useMemo(() => {
    return Array.from(
      new Set(
        filteredData
          ?.map((item) => item.date)
          ?.sort((a, b) => new Date(a).getTime() - new Date(b).getTime()),
      ),
    );
  }, [filteredData]);

  const chartDatasets = useMemo(() => {
    const datasets = new Map<string, ChartDataset<"line">>();

    uniqueDates?.forEach((date, index) => {
      const items = filteredData?.filter((item) => item.date === date);

      if (items?.length) {
        const uniqueCommitmentIds = Array.from(
          new Set(items.map((item) => item.commitmentId)),
        );

        uniqueCommitmentIds.forEach((commitmentId) => {
          if (datasets.has(commitmentId)) {
            const dataset = datasets.get(commitmentId);
            dataset!.data![index] =
              items.find((item) => item.commitmentId === commitmentId)
                ?.usedAmount || null;
          } else {
            const datasetData = Array.from<number | null>({
              length: uniqueDates.length,
            }).fill(null);

            const commitment = thirdDiagram?.find(
              (item) => item.commitmentid === commitmentId,
            );
            const label = commitment ? `#${commitment.ordernumber}` : void 0;
            const isActiveCommitmentStatus =
              commitment?.status === CommitmentStatus.Active;

            datasetData[index] =
              items.find((item) => item.commitmentId === commitmentId)
                ?.usedAmount || null;

            theme.other.chartColors.green,
              theme.other.chartColors.greenTransparent,
              datasets.set(commitmentId, {
                label: label,
                data: datasetData,
                fill: "origin",
                borderWidth: 1,
                borderColor:
                  isActiveCommitmentStatus ?
                    theme.other.chartColors.green
                  : theme.other.chartColors.red,
                backgroundColor:
                  isActiveCommitmentStatus ?
                    theme.other.chartColors.greenTransparent
                  : theme.other.chartColors.redTransparent,
              });
          }
        });
      }
    });

    return datasets;
  }, [
    filteredData,
    theme.other.chartColors.green,
    theme.other.chartColors.greenTransparent,
    theme.other.chartColors.red,
    theme.other.chartColors.redTransparent,
    thirdDiagram,
    uniqueDates,
  ]);

  const chartOptions: ChartProps<"line">["options"] = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    elements: {
      point: {
        radius: 0,
        hoverRadius: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItem) =>
            `${tooltipItem[0].dataset.label} - ${tooltipItem[0].label}`,
          label: (tooltipItem) =>
            formatNumberAsCurrency({
              value: tooltipItem.parsed.y,
              currency: currency,
              options: { locale: currencyLocale },
            }),
        },
      },
    },
    scales: {
      y: {
        min: 0,
        ticks: {
          callback: (tickValue) =>
            formatNumberAsCurrency({
              value: tickValue,
              currency,
              options: { locale: currencyLocale },
            }),
        },
      },
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "dd.MM.yyyy",
          displayFormats: {
            day: "dd.MM.yyyy",
          },
          round: "day",
        },
        ticks: {
          stepSize: 15,
        },
      },
    },
  };

  const chartData: ChartProps<"line">["data"] = {
    labels: uniqueDates,
    datasets: Array.from(chartDatasets.values()),
  };

  return (
    <ChartContainer
      title={t("page.consumptionPerCommitment")}
      fetching={isFetching}
      loading={isLoading}
      isDataEmpty={!filteredData?.length}
    >
      <Line options={chartOptions} data={chartData} />
    </ChartContainer>
  );
};
