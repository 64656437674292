import { Paper, TextInput, Button, Select } from "@mantine/core";
import { zodResolver } from "mantine-form-zod-resolver";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { useCreateOrUpdateCompartmentMutation } from "@/hooks/api/useCreateOrUpdateCompartmentMutation";
import { useFetchCompartmentsStatusesQuery } from "@/hooks/api/useFetchCompartmentsStatusQuery";
import { useFetchTenantsQuery } from "@/hooks/api/useFetchTenantsQuery";
import { useTForm } from "@/hooks/useTForm";
import { CompartmentResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface CompartmentDetailsProps {
  compartment: CompartmentResponse | null;
  onSettled?: () => void;
  className?: string;
}

const compartmentSchema = z.object({
  ocid: z.string().min(1, "form:field.required"),
  name: z.string().min(1, "form:field.required"),
  description: z.string(),
  tenantId: z
    .string()
    .nullable()
    .refine((value) => !!value, {
      message: "form:field.required",
    })
    .transform((value) => value ?? ""),
  organizationId: z.string().min(1, "form:field.required"),
  compartmentStatusId: z
    .string()
    .nullable()
    .refine((value) => !!value, {
      message: "form:field.required",
    })
    .transform((value) => value ?? ""),
});

type FormData = z.infer<typeof compartmentSchema>;

const UNDEFINED_COMPARTMENT_STATUS = "1";

const defaultValues: FormData = {
  ocid: "",
  name: "",
  description: "",
  tenantId: "",
  organizationId: "",
  compartmentStatusId: UNDEFINED_COMPARTMENT_STATUS,
};

const mapCompartmentToFormData = (
  compartment: CompartmentResponse | null,
): FormData => {
  if (!compartment) return defaultValues;

  return {
    ocid: compartment.ocid ?? defaultValues.ocid,
    name: compartment.name ?? defaultValues.name,
    description: compartment.description ?? defaultValues.description,
    tenantId: compartment.tenant.tenantId ?? defaultValues.tenantId,
    organizationId: compartment.organization.id ?? defaultValues.organizationId,
    compartmentStatusId:
      compartment.compartmentStatus.id ?? defaultValues.compartmentStatusId,
  };
};

export const CompartmentDetails = ({
  compartment,
  onSettled,
  className,
}: CompartmentDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const { data: tenants = [], isFetching: areTenantsFetching } =
    useFetchTenantsQuery();
  const { data: compartmentStatuses = [], isFetching: areCompartmentFetching } =
    useFetchCompartmentsStatusesQuery();
  const { mutate, isPending } = useCreateOrUpdateCompartmentMutation({
    id: compartment?.id,
  });

  const form = useTForm<FormData>({
    initialValues: mapCompartmentToFormData(compartment),
    validate: zodResolver(compartmentSchema),
  });

  const tenantOptions = useMemo(
    () =>
      tenants.map((tenant) => ({
        value: tenant.id,
        label: tenant.name,
      })),
    [tenants],
  );

  const compartmentStatusOptions = useMemo(
    () =>
      compartmentStatuses.map((compartmentStatus) => ({
        value: compartmentStatus.id,
        label: compartmentStatus.name,
      })),
    [compartmentStatuses],
  );

  const onSubmit = (data: FormData) => {
    mutate(data, {
      onSuccess: () =>
        compartment?.id ? successToast.update() : successToast.create(),
      onError: () =>
        compartment?.id ? errorToast.update() : errorToast.create(),
      onSettled: onSettled,
    });
  };

  const handleTenantChange = (value: string | null) => {
    const tenant = tenants.find((tenant) => tenant.id === value);
    form.setFieldValue("tenantId", value ?? (null as unknown as string));
    form.setFieldValue("ocid", tenant?.ocid ?? "");
    form.setFieldValue("organizationId", tenant?.organization?.id ?? "");
  };

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        withAsterisk
        label={t("administration.common.name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        label={t("administration.common.description")}
        {...form.getInputProps("description")}
      />
      <Select
        withAsterisk
        label={t("administration.tenant.tenant")}
        {...form.getInputProps("tenantId")}
        onChange={handleTenantChange}
        data={tenantOptions}
        disabled={areTenantsFetching}
      />
      <Select
        withAsterisk
        label={t("administration.compartmentStatus.compartmentStatus")}
        {...form.getInputProps("compartmentStatusId")}
        data={compartmentStatusOptions}
        disabled={areCompartmentFetching}
      />
      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};
