import { Title } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFetchAccessConfigType } from "@/hooks/api/useFetchAccessConfigType";
import { useMRT } from "@/hooks/useMRT";

import { columns } from "./columns";

export const AccessConfigTypeTable = () => {
  const { t } = useTranslation("entity");
  const { data, isLoading, isFetching } = useFetchAccessConfigType();

  const memoizedData = useMemo(() => data || [], [data]);

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
  });

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <div className="mb-4 flex items-center justify-between">
        <Title size="h2">
          {t("administration.accessConfigType.accessConfigType")}
        </Title>
      </div>
      <MantineReactTable table={table} />
    </div>
  );
};
