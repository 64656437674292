import { Group, useMantineTheme } from "@mantine/core";
import { YearPickerInput } from "@mantine/dates";
import { TableMeta } from "@tanstack/table-core";
import { Chart, LegendItem, LegendElement, ChartData } from "chart.js";
import format from "date-fns/format";
import { sortBy } from "lodash";
import { createMRTColumnHelper } from "mantine-react-table";
import { useMemo, useRef, useState } from "react";
import { PolarArea } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { ChartContainer } from "@/components/ui/chart-container";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchPolarDiagramQuery } from "@/hooks/api/useFetchPolarDiagramQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useDateFnsLocale } from "@/hooks/useDateFnsLocale";
import { PolarDiagramResponse } from "@/lib/api/dto";
import { monthFormat } from "@/lib/dateUtils";
import { cn, formatNumberAsCurrency } from "@/lib/utils";

interface DashboardPolarReportProps {
  subscriptionid?: string | number;
  className?: string;
}

const columnHelper = createMRTColumnHelper<PolarDiagramResponse>();

const columns = [
  columnHelper.accessor("time", {
    header: "dashboard:common.month",
    Cell: ({ cell, table }) =>
      format(new Date(cell.getValue()), monthFormat, {
        locale: (table.options.meta as TableMeta<PolarDiagramResponse>)
          ?.dateFnsLocale,
      }),
  }),
  columnHelper.accessor("fullsum", {
    header: "dashboard:common.consumption",
    Cell: ({ cell, table }) =>
      formatNumberAsCurrency({
        value: cell.getValue(),
        currency: (table.options.meta as TableMeta<PolarDiagramResponse>)
          ?.currency,
        options: {
          locale: (table.options.meta as TableMeta<PolarDiagramResponse>)
            ?.currencyLocale,
        },
      }),
  }),
];

export const DashboardPolarReport = ({
  subscriptionid,
  className,
}: DashboardPolarReportProps) => {
  const { t } = useTranslation("dashboard");
  const { tenant } = useTenantContext();
  const [year, setYear] = useState(new Date());
  const [filterData, setFilterData] = useState<number[]>([]);
  const [dataIsHidden, setDataIsHidden] = useState(false);
  const chartRef = useRef<Chart<"polarArea"> | null>(null);
  const theme = useMantineTheme();
  const dateFnsLocale = useDateFnsLocale();
  const currencyLocale = useCurrencyLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;

  const {
    data = [],
    isFetching,
    isLoading,
  } = useFetchPolarDiagramQuery({
    params: {
      subscriptionid,
      year: year.getFullYear(),
    },
  });

  const chartData = useMemo((): ChartData<"polarArea"> => {
    const sortedData = sortBy(data, "time");
    const { monthValues, monthLabels } = sortedData.reduce<{
      monthValues: number[];
      monthLabels: string[];
    }>(
      (acc, item) => {
        const { time, fullsum } = item;

        acc.monthValues.push(fullsum);
        acc.monthLabels.push(
          format(new Date(time).getTime(), monthFormat, {
            locale: dateFnsLocale,
          }),
        );

        return acc;
      },
      { monthValues: [], monthLabels: [] },
    );

    return {
      labels: monthLabels,
      datasets: [
        {
          data: monthValues,
          borderColor: theme.other.chartColorsArray,
          backgroundColor: theme.other.transparentColorsArray,
          hoverBackgroundColor: theme.other.hoverColorsArray,
          parsing: {
            xAxisKey: "x",
            yAxisKey: "y",
          },
        },
      ],
    };
  }, [data, theme, dateFnsLocale]);

  const handleDataToggle = (hidden: boolean) => {
    if (!chartRef?.current) return;

    const meta = chartRef.current?.getDatasetMeta(0);

    meta?.data.forEach((_, i) => {
      const visible = chartRef.current?.getDataVisibility(i);
      if (visible && hidden) chartRef.current?.toggleDataVisibility(i);
      if (!visible && !hidden) chartRef.current?.toggleDataVisibility(i);
    });

    chartRef.current?.update();

    setFilterData(hidden ? meta?.data.map((_, index) => index) : []);
    setDataIsHidden(hidden);
  };

  const handleLegendClick = (
    legendItem: LegendItem,
    legend: LegendElement<"polarArea">,
  ) => {
    const { index } = legendItem;
    if (!chartRef?.current || index === undefined) return;

    chartRef.current?.toggleDataVisibility(index);
    chartRef.current?.update();

    const visible = chartRef.current?.getDataVisibility(index);

    setFilterData((prevState) => {
      if (!visible) return [...prevState, index];
      return prevState.filter((i) => i !== index);
    });

    setDataIsHidden(
      legend.legendItems?.every((legendItem) => legendItem.hidden) ?? false,
    );
  };

  return (
    <ChartContainer
      bordered
      enableGridDisplay
      tableOptions={{
        columns,
        data: data.filter((_, i) => !filterData.includes(i)),
        meta: { currency, dateFnsLocale, currencyLocale },
      }}
      className={cn("flex-shrink-1 h-auto w-auto shadow-md", className)}
      title={
        <Group justify="space-between" wrap="nowrap">
          <h2 className="text-2xl">{t("consumptionPerMonths")}</h2>
          <YearPickerInput
            value={year}
            onChange={(date) => setYear((prev) => date || prev)}
            size="xs"
          />
        </Group>
      }
      loading={isLoading}
      fetching={isFetching}
      isDataEmpty={!data}
      datasetsAreHidden={dataIsHidden}
      onDatasetsToggle={() => handleDataToggle(!dataIsHidden)}
    >
      {data && (
        <PolarArea
          ref={chartRef}
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              r: {
                pointLabels: {
                  display: true,
                  centerPointLabels: true,
                  font: {
                    size: 18,
                  },
                },
                ticks: {
                  callback: (value) =>
                    formatNumberAsCurrency({
                      value,
                      currency,
                      options: { locale: currencyLocale },
                    }),
                },
              },
            },
            plugins: {
              legend: {
                position: "bottom",
                onClick: (_e, legendItem, legend) =>
                  handleLegendClick(legendItem, legend),
              },
              tooltip: {
                intersect: false,
                mode: "point",
                callbacks: {
                  title: (items) => items[0].label,
                  label: (tooltipItem) =>
                    formatNumberAsCurrency({
                      value: tooltipItem.parsed.r,
                      currency,
                      options: { locale: currencyLocale },
                    }),
                },
              },
            },
          }}
        />
      )}
    </ChartContainer>
  );
};
