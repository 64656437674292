import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { ThirdDiagramParams, ThirdDiagramResponse } from "@/lib/api/dto";

import { dashboardReportKeys } from "./queryKeys";

interface UseFetchThirdDiagramQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      ThirdDiagramResponse[],
      Error,
      T,
      ReturnType<typeof dashboardReportKeys.thirdDiagram>
    >,
    "queryKey" | "queryFn"
  > {
  params: Partial<ThirdDiagramParams>;
}

export const useFetchThirdDiagramQuery = <T = ThirdDiagramResponse[]>({
  params,
  ...options
}: UseFetchThirdDiagramQueryOptions<T>) =>
  useQuery({
    ...options,
    staleTime: 1000 * 60 * 5,
    queryKey: dashboardReportKeys.thirdDiagram(params),
    queryFn: ({ queryKey: [_, keyParams] }) =>
      keyParams.subscriptionid
        ? Api.dashboard
            .thirdDiagram(keyParams as ThirdDiagramParams)
            .then((res) => res.data)
        : [],
  });
