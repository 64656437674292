import { createMRTColumnHelper } from "mantine-react-table";

import { RegionResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<RegionResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.region.name",
  }),

  columnHelper.accessor("identifier", {
    header: "entity:administration.region.identifier",
  }),

  columnHelper.accessor("location", {
    header: "entity:administration.region.location",
  }),

  columnHelper.accessor("regionKey", {
    header: "entity:administration.region.regionKey",
  }),

  columnHelper.accessor("realmKey", {
    header: "entity:administration.region.realmKey",
  }),

  columnHelper.accessor("availabilityDomains", {
    header: "entity:administration.region.availabilityDomains",
  }),
];
