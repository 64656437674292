import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Api } from "@/lib/api";

import { authKeys } from "./queryKeys";

export const useLoginMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: authKeys.login(),
    mutationFn: Api.auth.login,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: authKeys.me(),
      });
    },
  });
};
