export enum LocalStorageKeys {
  AccessToken = "accessToken",
  RefreshToken = "refreshToken",
  SelectedTenant = "selectedTenant",
  SelectedOrganization = "selectedOrganization",
  Language = "language",
}

export const REFETCH_INTERVAL = 5000;

export enum Languages {
  Cyril = "cyril",
  Latin = "latin",
}
/**
 * Five minute stale time.
 */
export const STALE_TIME = 60 * 1000 * 5;
export const isDev = import.meta.env.DEV;
