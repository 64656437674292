import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CreateOrganizationUserPayload } from "@/lib/api/dto";

interface UseCreateOrganizationUserMutationOptions
  extends Omit<
    UseMutationOptions<unknown, Error, CreateOrganizationUserPayload, unknown>,
    "mutationFn"
  > {}

export const useCreateOrganizationUserMutation = (
  options: UseCreateOrganizationUserMutationOptions = {}
) => {
  return useMutation({
    ...options,
    mutationFn: Api.users.createOrganizationUser,
  });
};
