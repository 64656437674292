import { TreeNodeData } from "@mantine/core";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import {
  CompartmentStructureParams,
  CompartmentStructureResponse,
  CompartmentStructureWithChildren,
} from "@/lib/api/dto";

import { compartmentKeys } from "./queryKeys";

interface UseFetchCompartmentStructureQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      CompartmentStructureResponse[],
      Error,
      T,
      ReturnType<typeof compartmentKeys.compartmentStructures>
    >,
    "queryKey" | "queryFn"
  > {
  params?: CompartmentStructureParams;
}

export const useFetchCompartmentStructureQuery = <
  T = CompartmentStructureResponse[],
>({ params, ...options }: UseFetchCompartmentStructureQueryOptions<T> = {}) => {
  return useQuery({
    ...options,
    queryKey: compartmentKeys.compartmentStructures(params),
    queryFn: ({ queryKey }) =>
      queryKey[1]?.tenantocid ?
        Api.compartments
          .compartmentStructures(queryKey[1])
          .then((res) => res.data)
      : [],
  });
};

export const getChildrenForCompartment = (
  compartment: CompartmentStructureResponse,
  compartmentList: CompartmentStructureResponse[],
): CompartmentStructureWithChildren[] => {
  const children = compartmentList.filter(
    (c) => c.parentId === compartment.compartmentOcid,
  );

  return children.map((child) => ({
    ...child,
    children: getChildrenForCompartment(child, compartmentList),
  }));
};

export const nestCompartmentStructure = (
  compartments: CompartmentStructureResponse[],
): CompartmentStructureWithChildren[] => {
  const compartmentOcids = compartments.map((c) => c.compartmentOcid);
  const parentIds = compartments.map((c) => c.parentId);
  const distinctParentIds = parentIds.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  const rootOcids = distinctParentIds.filter(
    (id) => !compartmentOcids.includes(id),
  );

  const rootCompartments = compartments.filter((c) =>
    rootOcids.includes(c.parentId),
  );

  return rootCompartments.map((compartment) => ({
    ...compartment,
    children: getChildrenForCompartment(compartment, compartments),
  }));
};

export const getTreeChildren = (
  compartment: CompartmentStructureWithChildren,
  compartmentList: CompartmentStructureWithChildren[],
  value: "id" | "ocid",
): TreeNodeData => {
  return {
    value:
      value === "id" ?
        compartment.compartmentId || ""
      : compartment.compartmentOcid,
    label: compartment.compartmentName,
    disabled: !compartment.compartmentId,
    children: compartment.children?.map((c) =>
      getTreeChildren(c, compartmentList, value),
    ),
  };
};

export const findCompartment = (
  compartmentId: string | null | undefined,
  compartments: CompartmentStructureWithChildren[] | null | undefined,
): CompartmentStructureResponse | null => {
  if (!compartmentId || !compartments) return null;

  const compartment = compartments.find(
    (c) =>
      c.compartmentId === compartmentId || c.compartmentOcid === compartmentId,
  );

  if (compartment) return compartment;

  for (const c of compartments) {
    const child = findCompartment(compartmentId, c.children || []);

    if (child) return child;
  }

  return null;
};
