import { createMRTColumnHelper } from "mantine-react-table";

import { AccessConfigType } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<AccessConfigType>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.accessConfigType.name",
  }),

  columnHelper.accessor("description", {
    header: "entity:administration.accessConfigType.description",
  }),
];
