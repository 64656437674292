import {
  ActionIcon,
  type ActionIconProps,
  type PolymorphicComponentProps,
} from "@mantine/core";
import { IconTrash, type TablerIconsProps } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

type ActinIconDeleteProps<C = "button"> = PolymorphicComponentProps<
  C,
  ActionIconProps
> & { tablerIconsProps?: TablerIconsProps };

const ActinIconDelete = ({
  color = "red",
  tablerIconsProps,
  ...rest
}: Omit<ActinIconDeleteProps, "children">) => {
  const { t } = useTranslation();

  return (
    <ActionIcon title={t("userActions.delete")} color={color} {...rest}>
      <IconTrash size={20} {...tablerIconsProps} />
    </ActionIcon>
  );
};

export { ActinIconDelete, type ActinIconDeleteProps };
