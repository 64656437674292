import { useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";

import { organizationKeys } from "./queryKeys";

export const useFetchOrganizationTypesQuery = () => {
  return useQuery({
    queryKey: organizationKeys.types(),
    queryFn: () => Api.organizations.types().then((res) => res.data),
  });
};
