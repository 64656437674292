import { UTCDate } from "@date-fns/utc";
import { Text, Tooltip } from "@mantine/core";
import { startOfMonth, format, getDaysInMonth } from "date-fns";
import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import getDate from "date-fns/getDate";
import subMonths from "date-fns/subMonths";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { type CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchFifthDiagramQuery } from "@/hooks/api/useFetchFifthDiagramQuery";
import { useFetchFourthDiagramQuery } from "@/hooks/api/useFetchFourthDiagramQuery";
import { useFetchThirdDiagramQuery } from "@/hooks/api/useFetchThirdDiagramQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useDateFnsLocale } from "@/hooks/useDateFnsLocale";
import {
  CommitmentStatus,
  ThirdDiagramResponse,
  TimeUnit,
} from "@/lib/api/dto";
import { cn, formatNumberAsCurrency } from "@/lib/utils";

import { DashboardKPI } from "../DashboardKPI";

interface DashboardKPIReportProps {
  className?: string;
  subscriptionid?: string;
}

const NOW = new UTCDate();
const FISRT_DAY_OF_MONTH = 1;
const PREVIOUS_MONTH = subMonths(NOW, 1);
const MAX_YEARS = 5;
const MAX_DAYS = MAX_YEARS * 365;
const AVERAGE_DAYS_IN_MONTH = 30.44;

export const DashboardKPIReport = ({
  className,
  subscriptionid,
}: DashboardKPIReportProps) => {
  const { tenant } = useTenantContext();
  const { t } = useTranslation("dashboard");
  const dateFnsLocale = useDateFnsLocale();
  const currencyLocale = useCurrencyLocale();

  const { data: subscriptionData, isLoading: isFifthDiagramLoading } =
    useFetchFifthDiagramQuery({
      params: { subscriptionid },
    });

  const { data: fourthDiagramQuery, isLoading: isFourthDiagramLoading } =
    useFetchFourthDiagramQuery({
      params: {
        subscriptionid,
      },
    });

  const { data: commitmentData, isLoading: isCommitmentDataLoading } =
    useFetchThirdDiagramQuery({
      params: {
        subscriptionid,
      },
    });

  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;
  const isLoading =
    isFifthDiagramLoading || isFourthDiagramLoading || isCommitmentDataLoading;

  const currentSubscription = subscriptionData?.[0];

  const lastCommitment =
    currentSubscription ?
      commitmentData?.reduce((prev: ThirdDiagramResponse | null, current) => {
        if (!prev) {
          return current;
        }

        return new Date(prev.enddate) > new Date(current.enddate) ?
            prev
          : current;
      }, null)
    : null;

  const remainingAmountLeft = useMemo(
    () =>
      commitmentData && lastCommitment?.enddate ?
        commitmentData
          .filter((c) => c.status === CommitmentStatus.Active)
          .reduce((acc, current) => acc + current.avaliableamount, 0)
      : 0,
    [commitmentData, lastCommitment?.enddate],
  );

  const averageRemainingAmountLeftPerMonth = useMemo(() => {
    const remainingDaysUntilEndOfSubscription =
      lastCommitment?.enddate ?
        differenceInDays(new Date(lastCommitment.enddate), NOW)
      : 0;

    const remainingMonthsUntilEndOfSubscription =
      remainingDaysUntilEndOfSubscription / AVERAGE_DAYS_IN_MONTH;

    return remainingAmountLeft && lastCommitment?.enddate ?
        remainingAmountLeft / remainingMonthsUntilEndOfSubscription
      : 0;
  }, [lastCommitment?.enddate, remainingAmountLeft]);

  const previousMonthUsage = useMemo(
    () =>
      fourthDiagramQuery ?
        fourthDiagramQuery
          ?.filter(
            (query) => query.ocidtime === format(PREVIOUS_MONTH, "yyyy-MM"),
          )
          .reduce((acc, current) => acc + current.fullsum, 0)
      : 0,
    [fourthDiagramQuery],
  );

  const currentMonthUsage = useMemo(
    () =>
      fourthDiagramQuery ?
        fourthDiagramQuery
          ?.filter((query) => query.ocidtime === format(NOW, "yyyy-MM"))
          .reduce((acc, current) => acc + current.fullsum, 0)
      : 0,
    [fourthDiagramQuery],
  );

  const averageConsumptionPerDay = useMemo(() => {
    if (getDate(NOW) === FISRT_DAY_OF_MONTH) {
      // If the current day is the first day of the month,
      // we need to calculate the average consumption per day based on the previous month's data
      // because data for the current month is not available yet, and we do not have data for the whole day
      return previousMonthUsage / getDaysInMonth(PREVIOUS_MONTH);
    }

    return currentMonthUsage / differenceInDays(NOW, startOfMonth(NOW));
  }, [currentMonthUsage, previousMonthUsage]);

  const remainingDaysAtCurrentUsage =
    remainingAmountLeft / averageConsumptionPerDay || 0;

  const subscriptionDepletionDate = addDays(NOW, remainingDaysAtCurrentUsage);

  const remainingTimeAtCurrentUsage =
    remainingDaysAtCurrentUsage > MAX_DAYS ?
      t("dashboardKPIReport.moreThanNYears", {
        value: MAX_YEARS,
      })
    : formatDistanceStrict(subscriptionDepletionDate, NOW, {
        addSuffix: false,
        unit: TimeUnit.Day,
        locale: dateFnsLocale,
      });

  const remainingTimeForSubscription = formatDistanceStrict(
    new Date(lastCommitment?.enddate || 0),
    NOW,
    {
      addSuffix: false,
      unit: TimeUnit.Day,
      locale: dateFnsLocale,
    },
  );

  return (
    <div className={cn("grid grid-cols-2 gap-2", className)}>
      <DashboardKPI
        isLoading={isLoading}
        title={
          <Tooltip
            label={t("dashboardKPIReport.remainingTimeForSubscription.tooltip")}
          >
            <Text>
              {t("dashboardKPIReport.remainingTimeForSubscription.text")}
            </Text>
          </Tooltip>
        }
        value={remainingTimeForSubscription}
      />
      <DashboardKPI
        isLoading={isLoading}
        title={
          <Tooltip
            label={t("dashboardKPIReport.averageRemainingAmountLeft.tooltip")}
          >
            <Text>
              {t("dashboardKPIReport.averageRemainingAmountLeft.text")}
            </Text>
          </Tooltip>
        }
        value={formatNumberAsCurrency({
          value: averageRemainingAmountLeftPerMonth,
          currency,
          options: { locale: currencyLocale },
        })}
      />
      <DashboardKPI
        isLoading={isLoading}
        title={
          <Tooltip label={t("dashboardKPIReport.lastMonthUsage.tooltip")}>
            <Text>{t("dashboardKPIReport.lastMonthUsage.text")}</Text>
          </Tooltip>
        }
        value={formatNumberAsCurrency({
          value: previousMonthUsage || 0,
          currency,
          options: { locale: currencyLocale },
        })}
      />
      <DashboardKPI
        isLoading={isLoading}
        title={
          <Tooltip
            label={t("dashboardKPIReport.remainingTimeAtCurrentUsage.tooltip")}
          >
            <Text>
              {t("dashboardKPIReport.remainingTimeAtCurrentUsage.text")}
            </Text>
          </Tooltip>
        }
        value={remainingTimeAtCurrentUsage}
      />
    </div>
  );
};
