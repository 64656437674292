import { ActionIcon, Box, Button, Modal, Title, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconAlertTriangle, IconTrash } from "@tabler/icons-react";
import { MantineReactTable } from "mantine-react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import { useDeleteCityMutation } from "@/hooks/api/useDeleteCityMutation";
import { useFetchCitiesQuery } from "@/hooks/api/useFetchCitiesQuery";
import { useMRT } from "@/hooks/useMRT";
import { CityResponse } from "@/lib/api/dto";
import { CityDetails } from "@/widgets/CityDetails/CityDetails";

import { columns } from "./columns";

export const CitiesTable = () => {
  const { t } = useTranslation(["entity", "common"]);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCity, setSelectedCity] = useState<CityResponse | null>(null);
  const [cityToDelete, setCityToDelete] = useState<CityResponse | null>(null);
  const { data, isLoading, isFetching } = useFetchCitiesQuery();

  const { mutate, isPending } = useDeleteCityMutation();

  const showConfirmDeleteModal = (query: CityResponse) => {
    setCityToDelete(query);
  };

  const table = useMRT({
    columns,
    data: data || [],
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <div className="flex gap-2">
        <ActinIconEdit onClick={() => setSelectedCity(row.original)} />
        <ActionIcon
          color="red"
          onClick={() => showConfirmDeleteModal(row.original)}
        >
          <IconTrash size={18} />
        </ActionIcon>
      </div>
    ),
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={open}>
        {t("systemCodebook.cities.addCity")}
      </Button>
    ),
  });

  const handleSettled = () => {
    selectedCity ? setSelectedCity(null) : close();
  };

  const handleCloseDeleteModal = () => {
    setCityToDelete(null);
  };

  const handleDeleteCity = () => {
    if (!cityToDelete) return;

    mutate(cityToDelete.id, {
      onSettled: handleCloseDeleteModal,
    });
  };

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <div className="mb-4 flex items-center justify-between">
        <Title size="h2">{t("systemCodebook.cities.cities")}</Title>
      </div>
      <MantineReactTable table={table} />
      <Modal
        opened={!!selectedCity || opened}
        onClose={handleSettled}
        title={
          selectedCity ?
            t("systemCodebook.cities.editCity")
          : t("systemCodebook.cities.addCity")
        }
        size="lg"
      >
        <CityDetails city={selectedCity} onSettled={handleSettled} />
      </Modal>
      <Modal
        opened={!!cityToDelete}
        onClose={handleCloseDeleteModal}
        title={
          <Box c="red.8" className="flex items-center gap-2">
            <IconAlertTriangle />
            <span className="font-semibold">{t("common:warning")}</span>
          </Box>
        }
        classNames={{
          body: "flex flex-col gap-4",
        }}
      >
        <Text variant="text" size="sm">
          {t("systemCodebook.cities.actions.delete", {
            value: cityToDelete?.name,
          })}
        </Text>

        <div className="flex justify-between gap-2">
          <Button
            variant="outline"
            disabled={isPending}
            onClick={handleCloseDeleteModal}
          >
            {t("common:userActions.cancel")}
          </Button>
          <Button
            onClick={handleDeleteCity}
            loading={isPending}
            disabled={isPending}
          >
            {t("common:userActions.confirm")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
