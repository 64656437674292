import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ParseKeys } from "i18next";
import { useTranslation } from "react-i18next";

import { Api } from "@/lib/api";
import { EnumResponse } from "@/lib/api/dto";

import { scNotificationKeys } from "./queryKeys";

export interface UseFetchSCNotificationsValueTypesQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      EnumResponse,
      Error,
      T,
      ReturnType<typeof scNotificationKeys.scNotificationValueTypes>
    >,
    "queryKey" | "queryFn"
  > {}

export const useFetchSCNotificationsValueTypesQuery = <T = EnumResponse>(
  options: UseFetchSCNotificationsValueTypesQueryOptions<T> = {},
) => {
  const { t } = useTranslation("enum");

  return useQuery({
    ...options,
    queryKey: scNotificationKeys.scNotificationValueTypes(),
    queryFn: () =>
      Api.scNotifications.scNotificationValueTypes().then((res) => res.data),
    select: (data) => {
      if (options.select) return options.select(data);

      return {
        ...data,
        basicEnums: data.basicEnums?.map((item) => ({
          ...item,
          label: t(
            `sc-notification-value-type.${item.value}` as ParseKeys<"enum">,
            {
              defaultValue: item.label,
            },
          ),
        })),
        extendedEnums: data.extendedEnums?.map((item) => ({
          ...item,
          label: t(`extended-enum-type.${item.value}` as ParseKeys<"enum">, {
            defaultValue: item.label,
          }),
        })),
      };
    },
  });
};
