import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { Api } from "@/lib/api";

interface UseRequestPasswordResetMutationOptions
  extends Omit<UseMutationOptions<unknown, Error, string>, "mutationFn"> {}

export const useRequestPasswordResetMutation = (
  options: UseRequestPasswordResetMutationOptions = {}
) => {
  return useMutation({
    ...options,
    mutationFn: Api.users.requestPasswordReset,
  });
};
