import {
  Group,
  Modal,
  Stack,
  Title,
  Text,
  Box,
  Divider,
  Popover,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";
import { zodResolver } from "mantine-form-zod-resolver";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { DetailedSelect } from "@/components/ui/detailed-select";
import { EnumSelect } from "@/components/ui/enum-select";
import type { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchCommitmentsQuery } from "@/hooks/api/useFetchCommitmentsQuery";
import { useFetchSCNotificationsTypesQuery } from "@/hooks/api/useFetchSCNotificationsTypesQuery";
import { useFetchSubscriptionsQuery } from "@/hooks/api/useFetchSubscriptionsQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useTForm } from "@/hooks/useTForm";
import {
  CommitmentStatus,
  CommitmentPricingModel,
  SCNotificationType,
} from "@/lib/api/dto";
import { formatToDate } from "@/lib/dateUtils";
import { formatNumberAsCurrency, formatNumberAsPercentage } from "@/lib/utils";
import { DashboardFifthDiagramReport } from "@/widgets/DashboardReports/DashboardFifthDiagramReport";
import { DashboardKPIReport } from "@/widgets/DashboardReports/DashboardKPIReport";
import { DashboardThirdDiagramReport } from "@/widgets/DashboardReports/DashboardThirdDiagramReport";
import { SCNotificationForm } from "@/widgets/SCNotificationForm/SCNotificationForm";
import {
  type FilterFormValues,
  filterFormSchema,
} from "@/widgets/SCNotificationForm/validation-schema";
import { SCNotificationTable } from "@/widgets/SCNotificationTable/SCNotificationTable";

export const SCNotificationPage = () => {
  const { t } = useTranslation(["common", "notification"]);
  const { tenant } = useTenantContext();
  const filterFormRef = useRef<HTMLFormElement>(null);

  const [isSCNotificationModalOpen, scNotificationModal] = useDisclosure(false);

  const filterForm = useTForm<FilterFormValues>({
    initialValues: {
      notificationType: SCNotificationType.Subscription as SCNotificationType,
      subscriptionId: null,
      commitmentId: null,
    },
    validate: zodResolver(filterFormSchema),
    enhanceGetInputProps: ({ field, form }) => {
      if (field === "subscriptionId")
        return {
          onChange: (value: string) => {
            form.setFieldValue("subscriptionId", value);
            form.setFieldValue("commitmentId", null);
          },
        };
    },
  });

  const currencyLocale = useCurrencyLocale();
  const notificationType = filterForm.values.notificationType;
  const subscriptionId = filterForm.values.subscriptionId;
  const commitmentId =
    filterForm.values.notificationType === SCNotificationType.Commitment ?
      filterForm.values.commitmentId
    : null;

  const {
    data: notificationTypeEnum,
    isFetching: areNotificationTypesFetching,
  } = useFetchSCNotificationsTypesQuery();

  const { data: subscriptions = [], isFetching: areSubscriptionsFetching } =
    useFetchSubscriptionsQuery();

  const subscription = useMemo(
    () => subscriptions.find((s) => s.id === subscriptionId),
    [subscriptions, subscriptionId],
  );

  const { data: commitments = [], isFetching: areCommitmentsFetching } =
    useFetchCommitmentsQuery({ subscriptionid: subscription?.subscriptionID });

  const commitment = useMemo(
    () => commitments.find((c) => c.id === commitmentId),
    [commitments, commitmentId],
  );

  const commitmentCurrency = commitment?.subscription
    ?.currency as CurrencyCode | null;
  const usedPercentage =
    commitment ? (commitment.usedAmount / commitment.quantity) * 100 : 0;
  const availablePercentage = 100 - usedPercentage;

  const subscriptionOptions = useMemo(() => {
    if (tenant) {
      return subscriptions
        .filter((s) => s.organization.id === tenant.organization.id)
        .map((s) => ({
          label: t("notification:sc.subscriptionOption", {
            value: s.subscriptionID,
          }),
          value: s.id,
        }));
    }
  }, [tenant, subscriptions, t]);

  const commitmentOptions = useMemo(
    () =>
      commitments
        ?.filter(
          (c) =>
            c.subscription?.id === subscriptionId &&
            c.status === CommitmentStatus.Active &&
            c.pricingModel === CommitmentPricingModel.Annual,
        )
        ?.map((c) => ({
          label: t("notification:sc.commitmentOption", {
            value: c.orderNumber,
          }),
          value: c.id,
        })) || [],
    [commitments, subscriptionId, t],
  );

  const handleCreate = () => {
    filterFormRef?.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true }),
    );
  };

  const handleSubmit = () => {
    scNotificationModal.open();
  };

  useEffect(() => {
    if (tenant) filterForm.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  return (
    <Group className="flex-1 items-stretch gap-0 overflow-auto" wrap="nowrap">
      <Stack className="flex-shrink-0 basis-64 border-r p-4">
        <form
          className="flex flex-col gap-4"
          ref={filterFormRef}
          onSubmit={filterForm.onSubmit(handleSubmit)}
        >
          <EnumSelect
            label={t("notification:sc.notificationType")}
            disabled={areNotificationTypesFetching}
            {...filterForm.getInputProps("notificationType")}
            data={notificationTypeEnum}
          />
          <DetailedSelect
            clearable
            placeholder={t("notification:sc.selectSubscription")}
            data={subscriptionOptions}
            disabled={areSubscriptionsFetching}
            {...filterForm.getInputProps("subscriptionId")}
            content={
              <Popover
                width="target"
                position="right-start"
                withArrow
                shadow="md"
                offset={2}
              >
                <Popover.Target>
                  <Box className="flex cursor-pointer items-start justify-between p-3">
                    <Box>
                      <LabelledValue
                        label={t("notification:sc.csiNumber")}
                        value={subscription?.csiNumber}
                      />
                      <LabelledValue
                        label={t("notification:sc.subscription.startDate")}
                        value={
                          subscription?.startDate ?
                            formatToDate(subscription.startDate)
                          : null
                        }
                      />
                      <LabelledValue
                        label={t("notification:sc.subscription.endDate")}
                        value={
                          subscription?.endDate ?
                            formatToDate(subscription.endDate)
                          : null
                        }
                      />
                    </Box>
                    <UnstyledButton>
                      <IconInfoCircle className="size-5 text-mantine-blue-5" />
                    </UnstyledButton>
                  </Box>
                </Popover.Target>
                <Popover.Dropdown style={{ pointerEvents: "none" }}>
                  <LabelledValue
                    label={t("notification:sc.organization")}
                    value={subscription?.organization?.name}
                  />
                  <Divider className="my-2" />
                  <LabelledValue
                    label={t("notification:sc.subscription.title")}
                    value={subscription?.subscriptionID}
                  />
                  <LabelledValue
                    label={t("notification:sc.csiNumber")}
                    value={subscription?.csiNumber}
                  />
                  <LabelledValue
                    label={t("notification:sc.subscription.startDate")}
                    value={
                      subscription?.startDate ?
                        formatToDate(subscription.startDate)
                      : null
                    }
                  />
                  <LabelledValue
                    label={t("notification:sc.subscription.endDate")}
                    value={
                      subscription?.endDate ?
                        formatToDate(subscription.endDate)
                      : null
                    }
                  />
                  <LabelledValue
                    label={t("notification:sc.currency")}
                    value={subscription?.currency}
                  />
                </Popover.Dropdown>
              </Popover>
            }
          />

          {notificationType === SCNotificationType.Commitment && (
            <DetailedSelect
              clearable
              placeholder={t("notification:sc.selectCommitment")}
              data={commitmentOptions}
              disabled={areCommitmentsFetching}
              {...filterForm.getInputProps("commitmentId")}
              content={
                <Popover
                  width="target"
                  position="right-start"
                  withArrow
                  shadow="md"
                  offset={2}
                >
                  <Popover.Target>
                    <Box className="flex cursor-pointer items-start justify-between p-3">
                      <Box>
                        <LabelledValue
                          label={t("notification:sc.orderNumber")}
                          value={commitment?.orderNumber}
                        />
                        <LabelledValue
                          label={t("notification:sc.subscription.title")}
                          value={commitment?.subscription?.subscriptionID}
                        />
                        <LabelledValue
                          label={t("notification:sc.commitment.startDate")}
                          value={
                            commitment?.startDate ?
                              formatToDate(commitment.startDate)
                            : null
                          }
                        />
                        <LabelledValue
                          label={t("notification:sc.commitment.endDate")}
                          value={
                            commitment?.endDate ?
                              formatToDate(commitment.endDate)
                            : null
                          }
                        />
                      </Box>
                      <UnstyledButton>
                        <IconInfoCircle className="size-5 text-mantine-blue-5" />
                      </UnstyledButton>
                    </Box>
                  </Popover.Target>
                  <Popover.Dropdown style={{ pointerEvents: "none" }}>
                    <LabelledValue
                      label={t("notification:sc.orderNumber")}
                      value={commitment?.orderNumber}
                    />
                    <LabelledValue
                      label={t("notification:sc.subscription.title")}
                      value={commitment?.subscription?.subscriptionID}
                    />
                    <Divider className="my-2" />
                    <LabelledValue
                      label={t("notification:sc.commitment.startDate")}
                      value={
                        commitment?.startDate ?
                          formatToDate(commitment.startDate)
                        : null
                      }
                    />
                    <LabelledValue
                      label={t("notification:sc.commitment.endDate")}
                      value={
                        commitment?.endDate ?
                          formatToDate(commitment.endDate)
                        : null
                      }
                    />
                    <Divider className="my-2" />
                    <Box className="flex flex-col gap-2">
                      <LabelledValue
                        label={t("notification:sc.usedAmount")}
                        value={`${formatNumberAsCurrency({
                          value: commitment?.usedAmount,
                          currency: commitmentCurrency,
                          options: { locale: currencyLocale },
                        })} (${formatNumberAsPercentage(usedPercentage)})`}
                      />
                      <LabelledValue
                        label={t("notification:sc.avaliableAmount")}
                        value={`${formatNumberAsCurrency({
                          value: commitment?.avaliableAmount,
                          currency: commitmentCurrency,
                          options: { locale: currencyLocale },
                        })} (${formatNumberAsPercentage(availablePercentage)})`}
                      />
                      <LabelledValue
                        label={t("notification:sc.quantity")}
                        value={formatNumberAsCurrency({
                          value: commitment?.quantity,
                          currency: commitment?.subscription
                            .currency as CurrencyCode,
                          options: { locale: currencyLocale },
                        })}
                      />
                    </Box>
                  </Popover.Dropdown>
                </Popover>
              }
            />
          )}
        </form>
      </Stack>

      <div className="flex flex-1 flex-col gap-2 overflow-auto p-4">
        <Title className="mb-4" size="h2">
          {t("notification:sc.title")}
        </Title>

        <SCNotificationTable
          notificationType={notificationType}
          subscriptionId={subscriptionId}
          commitmentId={commitmentId}
          onCreate={handleCreate}
        />
      </div>

      <Stack className="basis-96 overflow-auto border-l p-4">
        <DashboardKPIReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />

        <DashboardFifthDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />

        <DashboardThirdDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID}
        />
      </Stack>

      <Modal
        opened={isSCNotificationModalOpen}
        onClose={scNotificationModal.close}
        title={t("notification:sc.addNotification")}
      >
        <SCNotificationForm
          subscription={subscription}
          commitment={commitment}
          notificationType={notificationType}
          onSettled={scNotificationModal.close}
        />
      </Modal>
    </Group>
  );
};

interface LabelledValueProps {
  className?: string;
  label: string;
  value?: string | number | null;
}

const LabelledValue = ({ className, label, value }: LabelledValueProps) => {
  return (
    <Text className={className} variant="text" size="xs">
      <span className="font-bold">{label}</span>: {value}
    </Text>
  );
};
