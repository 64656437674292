import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";

import { userKeys } from "./queryKeys";

interface UseDeleteUserMutationOptions
  extends Omit<UseMutationOptions<unknown, Error, string>, "mutationFn"> {}

export const useDeleteUserMutation = ({
  onSettled,
  ...options
}: UseDeleteUserMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (id: string) => Api.users.delete(id),
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: userKeys.all(),
      });
      onSettled?.(...args);
    },
  });
};
