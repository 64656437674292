import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { UpdateUserPayload, UserResponse } from "@/lib/api/dto";

interface UseUpdateUserMutationOptions
  extends Omit<
    UseMutationOptions<UserResponse, Error, UpdateUserPayload>,
    "mutationFn"
  > {}

export const useUpdateUserMutation = (
  options: UseUpdateUserMutationOptions = {}
) => {
  return useMutation({
    ...options,
    mutationFn: (payload) => Api.users.update(payload).then((res) => res.data),
  });
};
