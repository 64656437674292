import { Button, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import { useFetchMetricNamesQuery } from "@/hooks/api/useFetchMetricNamesQuery";
import { useMRT } from "@/hooks/useMRT";
import { MetricNameResponse } from "@/lib/api/dto";

import { columns } from "./columns";
import { MetricNameDetails } from "../MetricNameDetails/MetricNameDetails";

export const MetricNameTable = () => {
  const { t } = useTranslation("entity");
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedMetricName, setSelectedMetricName] =
    useState<MetricNameResponse | null>(null);
  const { data, isLoading, isFetching } = useFetchMetricNamesQuery();

  const memoizedData = useMemo(() => data || [], [data]);

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <ActinIconEdit onClick={() => setSelectedMetricName(row.original)} />
    ),
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={open}>
        {t("administration.metricName.addMetricName")}
      </Button>
    ),
  });

  const handleSettled = () => {
    selectedMetricName ? setSelectedMetricName(null) : close();
  };

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <Title size="h2" className="mb-4">
        {t("administration.metricName.metricName")}
      </Title>

      <MantineReactTable table={table} />

      <Modal
        opened={!!selectedMetricName || opened}
        onClose={handleSettled}
        title={
          selectedMetricName ?
            t("administration.metricName.editMetricName")
          : t("administration.metricName.addMetricName")
        }
        size="lg"
      >
        <MetricNameDetails
          onSettled={handleSettled}
          metricName={selectedMetricName}
        />
      </Modal>
    </div>
  );
};
