import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { Api } from "@/lib/api";

interface UseVerifyEmailMutationOptions
  extends Omit<UseMutationOptions<unknown, Error, string>, "mutationFn"> {}

export const useVerifyEmailMutation = (
  options: UseVerifyEmailMutationOptions = {}
) => {
  return useMutation({
    ...options,
    mutationFn: Api.auth.verifyEmail,
  });
};
