import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { SubscriptionResponse } from "@/lib/api/dto";

import { subscriptionKeys } from "./queryKeys";

export interface UseFetchSubscriptionsQueryOptions<T>
  extends Omit<
    UseQueryOptions<SubscriptionResponse[], Error, T>,
    "queryKey" | "queryFn"
  > {}

export const useFetchSubscriptionsQuery = <T = SubscriptionResponse[]>(
  options: UseFetchSubscriptionsQueryOptions<T> = {}
) => {
  return useQuery({
    ...options,
    queryKey: subscriptionKeys.all(),
    queryFn: () => Api.subscriptions.list().then((res) => res.data),
  });
};
