import { createMRTColumnHelper } from "mantine-react-table";

import { UserResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<UserResponse>();

export const columns = [
  columnHelper.accessor("email", {
    header: "entity:administration.common.email",
  }),

  columnHelper.accessor("name", {
    header: "entity:administration.user.user",
  }),

  columnHelper.accessor("address", {
    header: "entity:administration.common.address",
  }),

  columnHelper.accessor("organization.name", {
    header: "entity:administration.common.organization",
  }),
];
