import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { MetricNotificationResponse } from "@/lib/api/dto";

import { metricNotificationKeys } from "./queryKeys";

interface UseFetchMetricNotificationsQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      MetricNotificationResponse[],
      Error,
      T,
      ReturnType<typeof metricNotificationKeys.byResource>
    >,
    "queryFn" | "queryKey"
  > {
  resourceId?: string | null;
}

export const useFetchMetricNotificationsQuery = <
  T = MetricNotificationResponse[]
>({
  resourceId,
  ...options
}: UseFetchMetricNotificationsQueryOptions<T> = {}) => {
  return useQuery({
    ...options,
    queryKey: metricNotificationKeys.byResource(resourceId),
    queryFn: ({ queryKey }) =>
      Api.metricNotifications.list(queryKey[1]).then((res) => res.data),
  });
};
