import sistemiImg from "@/assets/sistemi.svg";

export const Footer = () => {
  return (
    <footer className="mt-auto flex justify-center p-3 border-t gap-x-1">
      <span>Developed by:</span>
      <img src={sistemiImg} />
    </footer>
  );
};
