import { deepMerge } from "@mantine/core";
import { ChartData, ChartOptions } from "chart.js";
import { format } from "date-fns";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";

import { ChartContainer } from "@/components/ui/chart-container";
import { useDateFnsLocale } from "@/hooks/useDateFnsLocale";
import { ReportMetricResponse, TenantResponse } from "@/lib/api/dto";
import { dayMonthTimeFormat } from "@/lib/dateUtils";

import { columns } from "./columns";

export interface MonitorMetricReportReportProps {
  data: ReportMetricResponse[];
  title?: string;
  options?: ChartOptions<"line">;
  chartData: ChartData<"line">;
  isLoading: boolean;
  isFetching: boolean;
  isDataEmpty: boolean;
}

export interface MonitorMetricReportProps {
  tenant?: TenantResponse | null;
  endDate?: Date | null;
  startDate?: Date | null;
  statisticType?: string | null;
  selectedResourceId?: string | null;
  selectedCompartmentId?: string | null;
}

export const MonitorMetricReportReport = ({
  data,
  title,
  options: propOptions,
  chartData,
  isLoading,
  isFetching,
  isDataEmpty,
}: MonitorMetricReportReportProps) => {
  const dateFnsLocale = useDateFnsLocale();

  const baseOptions = useMemo<ChartOptions<"line">>(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      spanGaps: false,
      elements: {
        point: {
          radius: 0,
          hoverRadius: 0,
        },
      },
      scales: {
        x: {
          type: "time",
          ticks: {
            autoSkipPadding: 5,
            callback: (value) =>
              format(new Date(value), dayMonthTimeFormat, {
                locale: dateFnsLocale,
              }),
          },
          time: {
            displayFormats: {
              second: "HH:mm:ss",
              minute: "HH:mm",
              hour: "dd MMM HH:mm",
              day: "dd MMM yyyy",
              month: "MMM yyyy",
            },
          },
        },
      },
      plugins: {
        tooltip: {
          mode: "nearest",
          callbacks: {
            title: (items) =>
              format(new Date(items[0].parsed.x), dayMonthTimeFormat, {
                locale: dateFnsLocale,
              }),
          },
        },
        legend: {
          display: false,
        },
        zoom: {
          zoom: {
            wheel: {
              modifierKey: "ctrl",
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
          pan: {
            enabled: true,
            mode: "x",
            scaleMode: "x",
          },
          limits: {
            x: {
              min: "original",
              max: "original",
            },
          },
        },
      },
    }),
    [dateFnsLocale],
  );

  const options = useMemo(
    () => deepMerge(baseOptions, propOptions),
    [baseOptions, propOptions],
  );

  return (
    <ChartContainer
      bordered
      title={title}
      enableGridDisplay
      tableOptions={{
        columns,
        data,
        meta: {
          dateFnsLocale,
        },
      }}
      loading={isLoading}
      fetching={isFetching}
      isDataEmpty={isDataEmpty}
      className="min-h-[25rem]"
    >
      <Line options={options as ChartOptions<"line">} data={chartData} />
    </ChartContainer>
  );
};
