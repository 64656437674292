import { create } from "zustand";

import { LocalStorageKeys } from "@/constants";

import { useFetchMeQuery } from "./api/useFetchMeQuery";
import { useLoginMutation } from "./api/useLoginMutation";
import { useLogoutMutation } from "./api/useLogoutMutation";
import { LocalStorageData, localStorageActions } from "./useLocalStorage";

interface AuthStore {
  accessToken: string | null;
  refreshToken: string | null;
  setTokens: (params: {
    accessToken: LocalStorageData[LocalStorageKeys.AccessToken];
    refreshToken: LocalStorageData[LocalStorageKeys.RefreshToken];
  }) => void;
  setAccessToken: (accessToken: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  clearTokens: () => void;
  clearAccessToken: () => void;
  clearRefreshToken: () => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  accessToken: localStorageActions.getItem(LocalStorageKeys.AccessToken),
  refreshToken: localStorageActions.getItem(LocalStorageKeys.RefreshToken),
  setTokens: ({ accessToken, refreshToken }) => {
    set({ accessToken, refreshToken });
    localStorageActions.setItem(LocalStorageKeys.AccessToken, accessToken);
    localStorageActions.setItem(LocalStorageKeys.RefreshToken, refreshToken);
  },
  setAccessToken: (accessToken) => {
    set({ accessToken });
    localStorageActions.setItem(LocalStorageKeys.AccessToken, accessToken);
  },
  setRefreshToken: (refreshToken) => {
    set({ refreshToken });
    localStorageActions.setItem(LocalStorageKeys.RefreshToken, refreshToken);
  },
  clearTokens: () => {
    set({ accessToken: null, refreshToken: null });
    localStorageActions.removeItem(LocalStorageKeys.AccessToken);
    localStorageActions.removeItem(LocalStorageKeys.RefreshToken);
  },
  clearAccessToken: () => {
    set({ accessToken: null });
    localStorageActions.removeItem(LocalStorageKeys.AccessToken);
  },
  clearRefreshToken: () => {
    set({ refreshToken: null });
    localStorageActions.removeItem(LocalStorageKeys.RefreshToken);
  },
}));

export const useAuth = () => {
  const isLoggedIn = useAuthStore((state) => !!state.accessToken);
  const accessToken = useAuthStore((state) => state.accessToken);
  const refreshToken = useAuthStore((state) => state.refreshToken);
  const setAccessToken = useAuthStore((state) => state.setAccessToken);
  const setRefreshToken = useAuthStore((state) => state.setRefreshToken);
  const clearTokens = useAuthStore((state) => state.clearTokens);
  const clearAccessToken = useAuthStore((state) => state.clearAccessToken);
  const clearRefreshToken = useAuthStore((state) => state.clearRefreshToken);
  const login = useLoginMutation();
  const logout = useLogoutMutation();
  const me = useFetchMeQuery({ enabled: !!accessToken });

  return {
    me,
    login,
    logout,
    isLoggedIn,
    accessToken,
    refreshToken,
    clearRefreshToken,
    clearAccessToken,
    setRefreshToken,
    setAccessToken,
    clearTokens,
  };
};
