import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { AccessConfigType } from "@/lib/api/dto";

import { accessConfigTypeKeys } from "./queryKeys";

interface UseFetchAccessConfigTypeQueryOptions<T>
  extends Omit<
    UseQueryOptions<AccessConfigType[], Error, T>,
    "queryKey" | "queryFn"
  > {}

export const useFetchAccessConfigType = <T = AccessConfigType[]>(
  options?: UseFetchAccessConfigTypeQueryOptions<T>,
) => {
  return useQuery({
    ...options,
    queryFn: () => Api.accessConfigType.list().then((res) => res.data),
    queryKey: accessConfigTypeKeys.all(),
  });
};
