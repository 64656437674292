import { createMRTColumnHelper } from "mantine-react-table";

import { StorageResultTypeResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<StorageResultTypeResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:administration.storage.name",
  }),
  columnHelper.accessor("description", {
    header: "entity:administration.storage.description",
  }),
];
