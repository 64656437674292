import { z } from "zod";

import { SCNotificationType, SCNotificationValueType } from "@/lib/api/dto";

const scNotificationSchema = z.object({
  subscriptionId: z
    .string()
    .nullable()
    .refine((value) => !!value, { message: "form:field.required" }),
  commitmentId: z
    .string()
    .min(1, "form:field.required")
    .nullable()
    .refine((value) => !!value, { message: "form:field.required" }),
  valueType: z.nativeEnum(SCNotificationValueType),
  value: z.coerce
    .number({ message: "form:field.number.type" })
    .int({ message: "form:field.number.integer" })
    .positive({ message: "form:field.number.positive" }),
  resources: z.array(z.string()).min(1, "form:field.required"),
});

const subscriptionNotificationSchema = scNotificationSchema
  .pick({ subscriptionId: true })
  .extend({
    notificationType: z.literal(SCNotificationType.Subscription),
  });

const commitmentNotificationSchema = scNotificationSchema
  .pick({
    subscriptionId: true,
    commitmentId: true,
  })
  .extend({
    notificationType: z.literal(SCNotificationType.Commitment),
  });

export const filterFormSchema = z.discriminatedUnion("notificationType", [
  subscriptionNotificationSchema,
  commitmentNotificationSchema,
]);

export const scNotificationFormSchema = filterFormSchema.and(
  scNotificationSchema
    .pick({
      valueType: true,
      value: true,
      resources: true,
    })
    .refine(
      (data) => {
        if (
          data.valueType === SCNotificationValueType.Percent &&
          (data.value <= 0 || data.value > 100)
        ) {
          return false;
        }

        return true;
      },
      {
        path: ["value"],
        message: "form:field.percent",
      },
    ),
);

export type FilterFormValues = z.infer<typeof filterFormSchema>;
export type SCNotificationFormValues = z.infer<typeof scNotificationFormSchema>;
