import { Paper, Button, TextInput, Select } from "@mantine/core";
import { isAxiosError } from "axios";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { z } from "zod";

import { PermissionControl } from "@/components/PermissionControl";
import { toast } from "@/components/ui/use-toast";
import { useCreateOrganizationUserMutation } from "@/hooks/api/useCreateOrganizationUserMutation";
import { useFetchOrganizationsQuery } from "@/hooks/api/useFetchOrganizationsQuery";
import { useAuth } from "@/hooks/useAuth";
import { useTForm } from "@/hooks/useTForm";
import { Claim, CreateOrganizationUserPayload } from "@/lib/api/dto";
import { userHasPermission } from "@/lib/utils";
import { cn } from "@/lib/utils";

interface CreateOrganizationUserPageProps {
  onSettled?: () => void;
  className?: string;
}

const organizationUserSchema = z.object({
  email: z
    .string()
    .min(1, "form:field.required")
    .email("entity:administration.organization.emailValid"),
  name: z.string().min(1, "form:field.required"),
  password: z.string().min(1, "form:field.required"),
  organizationId: z
    .string()
    .nullable()
    .refine((value) => !!value, {
      message: "form:field.required",
    })
    .transform((value) => value ?? ""),
});

type OrganizationUserSchema = z.infer<typeof organizationUserSchema>;

export const CreateOrganizationUserPage = ({
  className,
  onSettled,
}: CreateOrganizationUserPageProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const {
    me: { data: me },
  } = useAuth();

  const isSuperAdmin = userHasPermission(me!, Claim.SUPERADMIN);

  const form = useTForm<OrganizationUserSchema>({
    initialValues: {
      email: "",
      name: "",
      password: "",
      organizationId: isSuperAdmin ? "" : (me?.organization.id ?? ""),
    },
    validate: zodResolver(organizationUserSchema),
  });

  const { mutate, isPending } = useCreateOrganizationUserMutation();
  const { data: organizations = [] } = useFetchOrganizationsQuery();

  const onSubmit = (formData: OrganizationUserSchema) => {
    mutate(
      {
        ...formData,
        organizationId:
          isSuperAdmin ? formData.organizationId : me?.organization.id,
      } as CreateOrganizationUserPayload,
      {
        onSuccess: () => {
          form.reset();
          form.setValues({
            email: "",
            name: "",
            password: "",
            organizationId: isSuperAdmin ? "" : me?.organization.id,
          });
          toast({
            title: t("administration.organization.createUserSuccess"),
          });
        },
        onError: (error) => {
          if (isAxiosError(error) && error.response?.data?.message) {
            toast({
              title: error.response.data.message,
              variant: "destructive",
            });
          } else {
            toast({
              title: t("administration.organization.createUserError"),
              variant: "destructive",
            });
          }
        },
        onSettled,
      },
    );
  };

  return (
    <PermissionControl
      permission={[Claim.SUPERADMIN, Claim.ORG_USERS_WRITE]}
      fallbackContent={<Navigate to="/entity" replace />}
    >
      <Paper
        component="form"
        className={cn("flex flex-col gap-2", className)}
        onSubmit={form.onSubmit(onSubmit)}
      >
        <TextInput
          withAsterisk
          label={t("administration.common.email")}
          {...form.getInputProps("email")}
        />
        <TextInput
          withAsterisk
          label={t("administration.common.personName")}
          {...form.getInputProps("name")}
        />
        <TextInput
          withAsterisk
          label={t("administration.common.password")}
          type="password"
          {...form.getInputProps("password")}
        />
        <Select
          withAsterisk
          label={t("administration.common.organization")}
          {...form.getInputProps("organizationId")}
          data={organizations.map((organization) => ({
            value: organization.id,
            label: organization.name,
          }))}
        />
        <div className="mt-2 flex justify-between gap-2">
          <Button variant="light" disabled={isPending} onClick={onSettled}>
            {t("common:userActions.cancel")}
          </Button>
          <Button type="submit" disabled={isPending}>
            {t("common:userActions.save")}
          </Button>
        </div>
      </Paper>
    </PermissionControl>
  );
};
