import { RingProgress, Skeleton, Text } from "@mantine/core";
import { ParseKeys } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import { Separator } from "@/components/ui/separator";
import { CurrencyCode } from "@/constants/currencies";
import { useFetchFifthDiagramQuery } from "@/hooks/api/useFetchFifthDiagramQuery";
import { useFetchThirdDiagramQuery } from "@/hooks/api/useFetchThirdDiagramQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { ThirdDiagramResponse } from "@/lib/api/dto";
import { formatToDate } from "@/lib/dateUtils";
import { formatNumberAsCurrency } from "@/lib/utils";
import { cn } from "@/lib/utils";

interface DashboardFifthDiagramReportProps {
  subscriptionid?: string;
  className?: string;
}

export const DashboardFifthDiagramReport = ({
  subscriptionid,
  className,
}: DashboardFifthDiagramReportProps) => {
  const { data: subscriptionData, isLoading: isSubscriptionDataLoading } =
    useFetchFifthDiagramQuery({
      params: { subscriptionid },
    });
  const { data: commitmentData, isLoading: isCommitmentDataLoading } =
    useFetchThirdDiagramQuery({
      params: {
        subscriptionid,
      },
    });
  const isLoading = isSubscriptionDataLoading || isCommitmentDataLoading;

  const currentSubscription = subscriptionData?.[0];

  const lastCommitment =
    currentSubscription ?
      commitmentData?.reduce((prev: ThirdDiagramResponse | null, current) => {
        if (!prev) {
          return current;
        }

        return new Date(prev.enddate) > new Date(current.enddate) ?
            prev
          : current;
      }, null)
    : null;

  const percentageUsed =
    currentSubscription ?
      (+currentSubscription.usedamount / +currentSubscription.quantity) * 100
    : 0;

  const currencyLocale = useCurrencyLocale();
  const currency = currentSubscription?.currency as CurrencyCode;

  return (
    <div
      className={cn(
        "flex flex-shrink-0 items-center gap-2 rounded-md border bg-white p-3 shadow-md",
        className,
      )}
    >
      <Skeleton visible={isLoading} className="w-auto self-start">
        <RingProgress
          sections={[{ value: percentageUsed, color: "brand" }]}
          size={140}
          thickness={18}
          label={
            <Text c="brand" ta="center" size="md" fw="bolder">
              {percentageUsed.toFixed(2)}%
            </Text>
          }
        />
      </Skeleton>
      <Separator orientation="vertical" />
      <div className="flex flex-col gap-1">
        <FifthDiagramReportItem
          isLoading={isLoading}
          label="dashboard:common.subscription"
          value={subscriptionid}
        />
        <FifthDiagramReportItem
          isLoading={isLoading}
          label="dashboard:commitment.startDate"
          value={
            currentSubscription?.startdate &&
            formatToDate(currentSubscription.startdate)
          }
        />
        <FifthDiagramReportItem
          isLoading={isLoading}
          label="dashboard:commitment.endDate"
          value={
            <>
              {currentSubscription?.enddate &&
                formatToDate(currentSubscription.enddate)}
              {!currentSubscription?.enddate &&
                lastCommitment &&
                formatToDate(lastCommitment.enddate)}
            </>
          }
        />
        <FifthDiagramReportItem
          isLoading={isLoading}
          label="dashboard:commitment.csiNumber"
          value={currentSubscription?.csinumber}
        />
        <FifthDiagramReportItem
          isLoading={isLoading}
          label="dashboard:commitment.currency"
          value={currency}
        />
        <FifthDiagramReportItem
          isLoading={isLoading}
          label="dashboard:commitment.commitmentNumber"
          value={commitmentData?.length}
        />

        <Separator className="my-1" />

        <FifthDiagramReportItem
          isLoading={isLoading}
          label="dashboard:commitment.usedAmount"
          value={formatNumberAsCurrency({
            value: currentSubscription?.usedamount,
            currency,
            options: { locale: currencyLocale },
          })}
        />
        <FifthDiagramReportItem
          isLoading={isLoading}
          label="dashboard:commitment.availibleAmount"
          value={formatNumberAsCurrency({
            value: currentSubscription?.avaliableamount,
            currency,
            options: { locale: currencyLocale },
          })}
        />
        <FifthDiagramReportItem
          isLoading={isLoading}
          label="dashboard:commitment.quantity"
          value={formatNumberAsCurrency({
            value: currentSubscription?.quantity,
            currency,
            options: { locale: currencyLocale },
          })}
        />
      </div>
    </div>
  );
};

function FifthDiagramReportItem({
  isLoading,
  label,
  value,
}: {
  isLoading: boolean;
  label: string;
  value?: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <Skeleton visible={isLoading}>
      <Text>
        <span className="font-bold">{t(label as ParseKeys)}</span>: {value}
      </Text>
    </Skeleton>
  );
}
