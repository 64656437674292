import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CreateOrganizationAdminPayload } from "@/lib/api/dto";

interface UseCreateOrganizationAdminUserMutationOptions
  extends UseMutationOptions<unknown, Error, CreateOrganizationAdminPayload> {}

export const useCreateOrganizationAdminUserMutation = (
  options: UseCreateOrganizationAdminUserMutationOptions = {}
) => {
  return useMutation({
    ...options,
    mutationFn: Api.users.createOrganizationAdminUser,
  });
};
