import { Paper, TextInput, Button, Select } from "@mantine/core";
import { isAxiosError } from "axios";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { toast } from "@/components/ui/use-toast";
import { useCreateOrUpdateTenantMutation } from "@/hooks/api/useCreateOrUpdateTenantMutation";
import { useFetchOrganizationsQuery } from "@/hooks/api/useFetchOrganizationsQuery";
import { useFetchRegionsQuery } from "@/hooks/api/useFetchRegionsQuery";
import { useFetchSubscriptionsQuery } from "@/hooks/api/useFetchSubscriptionsQuery";
import { useTForm } from "@/hooks/useTForm";
import { CreateTenantPayload, TenantResponse } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

interface TenantDetailsProps {
  tenant?: TenantResponse;
  onSettled?: () => void;
  className?: string;
}

const tenantSchema = z.object({
  name: z.string().min(1, "form:field.required"),
  ocid: z.string().min(1, "form:field.required"),
  csiNumber: z.string().min(1, "form:field.required"),
  currentSubscriptionId: z
    .string()
    .nullable()
    .refine((value) => !!value, {
      message: "form:field.required",
    }),
  regionId: z
    .string()
    .nullable()
    .refine((value) => !!value, {
      message: "form:field.required",
    }),
  organizationId: z
    .string()
    .nullable()
    .refine((value) => !!value, {
      message: "form:field.required",
    }),
});

const mapTenantToFormData = (tenant?: TenantResponse): CreateTenantPayload => ({
  name: tenant?.name ?? "",
  ocid: tenant?.ocid ?? "",
  csiNumber: tenant?.csiNumber ?? "",
  currentSubscriptionId: tenant?.currentSubscription?.id ?? "",
  regionId: tenant?.region?.id ?? "",
  organizationId: tenant?.organization?.id ?? "",
});

export const TenantDetails = ({
  tenant,
  onSettled,
  className,
}: TenantDetailsProps) => {
  const { t } = useTranslation(["entity", "common"]);

  const { data: organizations } = useFetchOrganizationsQuery();
  const { mutate, isPending } = useCreateOrUpdateTenantMutation({
    tenantId: tenant?.id,
  });
  const { data: subscriptions } = useFetchSubscriptionsQuery();
  const { data: regions } = useFetchRegionsQuery();

  const initialFormValues: CreateTenantPayload = mapTenantToFormData(tenant);

  const form = useTForm<CreateTenantPayload>({
    initialValues: initialFormValues,
    validate: zodResolver(tenantSchema),
    enhanceGetInputProps: ({ field, form }) => {
      if (field === "organizationId") {
        return {
          onChange: (value: string) => {
            form.setFieldValue("organizationId", value);
            form.setFieldValue(
              "currentSubscriptionId",
              null as unknown as string,
            );
          },
        };
      }

      if (field === "currentSubscriptionId") {
        return {
          onChange: (value: string) => {
            form.setFieldValue("currentSubscriptionId", value);
            form.setFieldValue(
              "organizationId",
              subscriptions?.find((sub) => sub.id === value)?.organization.id ??
                (null as unknown as string),
            );
          },
        };
      }
    },
  });

  const filteredSubscriptions =
    (form.values.organizationId ?
      subscriptions?.filter(
        (subscription) =>
          subscription.organization?.id === form.values.organizationId,
      )
    : subscriptions) ?? [];

  const onSubmit = (data: CreateTenantPayload) => {
    mutate(data, {
      onSuccess: (response) => {
        toast({
          title: t("administration.tenant.tenantSaveSuccess"),
        });
        form.setInitialValues(mapTenantToFormData(response));
        form.reset();
      },
      onError: (error) => {
        const errorMessage =
          isAxiosError(error) && error.response?.data?.message ?
            error.response.data.message
          : t("administration.tenant.tenantSaveError");
        toast({
          title: "Greška",
          description: errorMessage,
          variant: "destructive",
        });
      },
      onSettled,
    });
  };

  const mappedSubscriptions = filteredSubscriptions.map((subscription) => ({
    value: subscription.id,
    label: t("administration.tenant.subscriptionSelect", {
      value: subscription.subscriptionID,
    }),
  }));

  const mappedRegions = regions?.map((region) => ({
    value: region.id,
    label: region.name,
  }));

  const mappedOrganizations = organizations?.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }));

  return (
    <Paper
      component="form"
      className={cn("flex flex-col gap-2", className)}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <Select
        withAsterisk
        label={t("administration.common.organization")}
        {...form.getInputProps("organizationId")}
        data={mappedOrganizations}
      />
      <Select
        withAsterisk
        label={t("administration.tenant.currentSubscription")}
        {...form.getInputProps("currentSubscriptionId")}
        data={mappedSubscriptions}
      />
      <TextInput
        withAsterisk
        label={t("administration.common.name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        withAsterisk
        label={t("administration.common.ocid")}
        {...form.getInputProps("ocid")}
      />
      <TextInput
        withAsterisk
        label={t("administration.tenant.csiNumber")}
        {...form.getInputProps("csiNumber")}
      />
      <Select
        withAsterisk
        label={t("administration.tenant.region")}
        {...form.getInputProps("regionId")}
        data={mappedRegions}
      />
      <div className="mt-2 flex justify-between gap-2">
        <Button variant="light" disabled={isPending} onClick={onSettled}>
          {t("common:userActions.cancel")}
        </Button>
        <Button type="submit" disabled={isPending}>
          {t("common:userActions.save")}
        </Button>
      </div>
    </Paper>
  );
};
