import { IconCheck } from "@tabler/icons-react";
import { createMRTColumnHelper } from "mantine-react-table";

import { ExtendedCityResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<ExtendedCityResponse>();

export const columns = [
  columnHelper.accessor("name", {
    header: "entity:systemCodebook.common.name",
  }),
  columnHelper.accessor(({ state }) => state?.name, {
    id: "state",
    header: "entity:systemCodebook.cities.state",
  }),
  columnHelper.accessor("postalCode", {
    header: "entity:systemCodebook.cities.postalCode",
  }),
  columnHelper.accessor("capital", {
    header: "entity:systemCodebook.cities.capitalCity",
    filterVariant: "checkbox",
    Cell: ({ cell }) => {
      const value = cell.getValue();
      return value ? <IconCheck className="text-brand-500" /> : null;
    },
  }),
];
