import { Box, Button, Code, Modal, Text, Tooltip } from "@mantine/core";
import {
  IconAlertTriangle,
  IconCircleOff,
  IconInfoCircle,
  IconPlus,
} from "@tabler/icons-react";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconDelete } from "@/components/ui/action-icon-delete";
import { TruncatedText } from "@/components/ui/truncated-text";
import { errorToast, successToast } from "@/components/ui/use-toast";
import { useDeleteSCNotificationMutation } from "@/hooks/api/useDeleteSCNotificationMutation";
import { useFetchSCNotificationsQuery } from "@/hooks/api/useFetchSCNotificationsQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useMRT } from "@/hooks/useMRT";
import { SCNotificationResponse, SCNotificationType } from "@/lib/api/dto";

import { formatValue } from "./columns";
import { columns } from "./columns";

interface SCNotificationTableProps {
  notificationType?: SCNotificationType | null;
  subscriptionId?: string | null;
  commitmentId?: string | null;
  onCreate?: () => void;
}

export const SCNotificationTable = ({
  notificationType,
  subscriptionId,
  commitmentId,
  onCreate,
}: SCNotificationTableProps) => {
  const { t } = useTranslation(["common", "notification"]);
  const currencyLocale = useCurrencyLocale();
  const [selectedSCNotification, setSelectedSCNotification] =
    useState<SCNotificationResponse | null>(null);
  const {
    data: scNotifications = [],
    isLoading,
    isFetching,
  } = useFetchSCNotificationsQuery();

  const { mutate: deleteSCNotification, isPending: isDeleting } =
    useDeleteSCNotificationMutation({
      onSuccess: () => successToast.delete(),
      onError: () => errorToast.delete(),
      onSettled: () => handleClose(),
    });

  const data = useMemo(
    () =>
      scNotifications.filter(
        (sc) =>
          sc.sourceType === notificationType &&
          sc.subscription.id === subscriptionId &&
          (!commitmentId || sc.commitment.id === commitmentId),
      ),
    [scNotifications, notificationType, subscriptionId, commitmentId],
  );

  const handleClose = () => setSelectedSCNotification(null);

  const handleDeleteNotification = () => {
    if (selectedSCNotification) deleteSCNotification(selectedSCNotification.id);
  };

  const table = useMRT({
    enableTopToolbar: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowActions: true,
    data,
    columns,
    meta: { currencyLocale },
    state: {
      isLoading,
      showProgressBars: isFetching,
    },
    renderRowActions: ({ row }) => (
      <ActinIconDelete
        onClick={() => setSelectedSCNotification(row.original)}
      />
    ),
    renderBottomToolbarCustomActions: () => (
      <Button
        onClick={onCreate}
        color="blue"
        size="compact-lg"
        leftSection={<IconPlus />}
      >
        {t("common:userActions.create")}
      </Button>
    ),
  });

  return (
    <>
      <MantineReactTable table={table} />
      <Modal
        opened={!!selectedSCNotification}
        onClose={handleClose}
        title={
          <Box c="red.8" className="flex items-center gap-2">
            <IconAlertTriangle />
            <span className="font-semibold">{t("warning")}</span>
          </Box>
        }
      >
        <Box className="mb-4 flex flex-col gap-2">
          <Text variant="text" size="sm">
            {notificationType === SCNotificationType.Subscription ?
              t("notification:sc.confirmDeleteSubscription")
            : t("notification:sc.confirmDeleteCommitment")}
          </Text>
          <Code className="flex flex-col gap-2 bg-slate-200 p-2">
            <Text variant="text" size="sm">
              {t("notification:sc.subscriptionOption", {
                value: selectedSCNotification?.subscription?.subscriptionID,
              })}
            </Text>
            {notificationType === SCNotificationType.Commitment && (
              <Box className="flex gap-2">
                <Text variant="text" size="sm">
                  {t("notification:sc.commitmentOption", {
                    value: selectedSCNotification?.commitment?.id,
                  })}
                </Text>
                <Tooltip label={selectedSCNotification?.commitment?.id}>
                  <IconInfoCircle className="inline-block size-5 text-mantine-blue-5" />
                </Tooltip>
              </Box>
            )}
            <Box>
              <Text variant="text" size="sm">
                {t("notification:sc.resources")}:{" "}
                {!selectedSCNotification?.resources?.length && (
                  <IconCircleOff className="inline-block size-4" />
                )}
              </Text>
              <ul className="pl-2">
                {selectedSCNotification?.resources?.map(
                  ({ id, name, ocid }) => (
                    <li>
                      <TruncatedText
                        className="block"
                        key={id}
                        size="sm"
                        leadingCharacters={15}
                        trailingCharacters={15}
                        text={name || ocid}
                      />
                    </li>
                  ),
                )}
              </ul>
            </Box>
            <Text variant="text" size="sm">
              {t("notification:sc.value")}:{" "}
              {selectedSCNotification ?
                formatValue({
                  valueType: selectedSCNotification.valueType,
                  currency: selectedSCNotification.subscription.currency,
                  value: selectedSCNotification.value,
                  currencyLocale,
                })
              : null}
            </Text>
          </Code>
        </Box>
        <Box className="flex justify-between gap-2">
          <Button variant="light" onClick={handleClose}>
            {t("userActions.cancel")}
          </Button>
          <Button
            onClick={handleDeleteNotification}
            loading={isDeleting}
            disabled={isDeleting}
          >
            {t("userActions.confirm")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
