import { srLatn, sr } from "date-fns/locale";
import { useTranslation } from "react-i18next";

import { Languages } from "@/constants";

/**
 * Map of language to date-fns locale
 */
export const dateFnsLocaleMap: Record<Languages, Locale> = {
  cyril: sr,
  latin: srLatn,
};

/**
 * Returns the date-fns locale based on the current language
 *
 * @returns `Locale`
 */
export const useDateFnsLocale = () => {
  const { i18n } = useTranslation();

  return dateFnsLocaleMap[i18n.language as Languages];
};
