import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { TreeSelect } from "@/components/ui/tree-select";
import { useTenantContext } from "@/context/TenantContext";
import {
  findCompartment,
  getTreeChildren,
  nestCompartmentStructure,
  useFetchCompartmentStructureQuery,
} from "@/hooks/api/useFetchCompartmentStructureQuery";
import { ResourcesTable } from "@/widgets/ResourcesTable";

export const ResourcesPage = () => {
  const { t } = useTranslation(["control", "common"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { compartmentId, tenantId } = useMemo(
    () => ({
      compartmentId: searchParams.get("compartmentId"),
      tenantId: searchParams.get("tenantId"),
    }),
    [searchParams],
  );

  const [selectedCompartment, setSelectedCompartment] = useState<string | null>(
    compartmentId || null,
  );
  const { tenant, tenants, setTenant, isLoading, isError } = useTenantContext();
  const { data: compartments } = useFetchCompartmentStructureQuery({
    params: {
      tenantocid: tenant?.ocid,
    },
    select: nestCompartmentStructure,
  });

  const compartmentOptions = useMemo(
    () =>
      compartments?.map((c) => getTreeChildren(c, compartments, "ocid")) || [],
    [compartments],
  );

  const compartment = findCompartment(selectedCompartment, compartments);

  useEffect(() => {
    if (tenantId) {
      setTenant(tenantId);
    }
  }, [setSearchParams, setTenant, tenantId]);

  useEffect(() => {
    setSelectedCompartment(null);
  }, [tenant]);

  if (isLoading || !tenant) {
    return <div>{t("common:state.loading")}</div>;
  }

  if (!tenant && tenants && tenants.length > 0) {
    setTenant(tenants[0].ocid);
    return <div>{t("common:state.loading")}§</div>;
  }

  if (isError) {
    return <div>{t("common:errors.generic")}</div>;
  }

  return (
    <div className="flex flex-1 overflow-hidden">
      <div className="basis-96 p-4">
        <TreeSelect
          clearable
          isParentSelectable
          label={t("filters.compartment")}
          data={
            compartmentOptions.length ?
              [
                {
                  value: "tenant",
                  label: tenant?.name,
                  selectable: false,
                  children: compartmentOptions,
                },
              ]
            : []
          }
          value={selectedCompartment}
          onChange={setSelectedCompartment}
        />
      </div>
      <div className="flex flex-1 flex-col overflow-auto border-l-2 p-4">
        <ResourcesTable
          tenant={tenant}
          compartment={compartment?.compartmentOcid}
        />
      </div>
    </div>
  );
};
