import { Button, Title } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useFetchLicensesQuery } from "@/hooks/api/useFetchLicensesQuery";
import { useMRT } from "@/hooks/useMRT";

import { columns } from "./columns";

export const LicensesTable = () => {
  const { t } = useTranslation("entity");
  const { data, isLoading, isFetching } = useFetchLicensesQuery();
  const navigate = useNavigate();

  const handleUploadLicenses = () => {
    navigate("/entity/licenses/upload");
  };

  const memoizedData = useMemo(() => data || [], [data]);

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={handleUploadLicenses}>
        {t("administration.license.addLicense")}
      </Button>
    ),
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
  });

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <Title order={2} className="mb-4">
        {t("administration.license.licenses")}
      </Title>
      <MantineReactTable table={table} />
    </div>
  );
};
