import { Button, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import { useFetchTenantsQuery } from "@/hooks/api/useFetchTenantsQuery";
import { useMRT } from "@/hooks/useMRT";
import { TenantResponse } from "@/lib/api/dto";
import { TenantDetails } from "@/widgets/TenantDetails/TenantDetails";

import { columns } from "./columns";

export const TenantTable = () => {
  const { t } = useTranslation("entity");
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedTenant, setSelectedTenant] = useState<
    TenantResponse | undefined
  >();
  const { data, isLoading, isFetching } = useFetchTenantsQuery();

  const memoData = useMemo(() => data || [], [data]);

  const table = useMRT({
    columns,
    data: memoData,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableGrouping: true,
    enableColumnDragging: false,
    paginateExpandedRows: false,
    initialState: {
      showColumnFilters: true,
      grouping: ["organization.name"],
      expanded: true,
    },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    mantineToolbarAlertBannerProps: {
      display: "none",
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <ActinIconEdit onClick={() => setSelectedTenant(row.original)} />
    ),
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={open}>
        {t("administration.tenant.addTenant")}
      </Button>
    ),
  });

  const handleSettled = () => {
    selectedTenant ? setSelectedTenant(undefined) : close();
  };

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <Title className="mb-4" size="h2">
        {t("administration.tenant.tenants")}
      </Title>
      <MantineReactTable table={table} />
      <Modal
        opened={!!selectedTenant || opened}
        onClose={handleSettled}
        title={
          selectedTenant ?
            t("administration.tenant.editTenant")
          : t("administration.tenant.addTenant")
        }
        size="lg"
      >
        <TenantDetails onSettled={handleSettled} tenant={selectedTenant} />
      </Modal>
    </div>
  );
};
