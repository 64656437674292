import { Title } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useTranslation } from "react-i18next";

import { useFetchOrganizationTypesQuery } from "@/hooks/api/useFetchOrganizationTypesQuery";
import { useMRT } from "@/hooks/useMRT";

import { columns } from "./columns";

export const OrganizationTypesTable = () => {
  const { t } = useTranslation("entity");
  const { data, isLoading, isFetching } = useFetchOrganizationTypesQuery();

  const table = useMRT({
    columns,
    data: data || [],
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
  });

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <Title className="mb-4" size="h2">
        {t("administration.organization.organizationType")}
      </Title>
      <MantineReactTable table={table} />
    </div>
  );
};
