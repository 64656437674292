import { createMRTColumnHelper } from "mantine-react-table";

import { OCIQueryResponseContentItem } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<OCIQueryResponseContentItem>();

export const columns = [
  columnHelper.accessor("compartmentName", {
    header: "common:compartment",
    enableHiding: false,
  }),
  columnHelper.accessor("resourceOcid", {
    header: "entity:ociQuery.resourceOcid",
    enableHiding: false,
  }),
  columnHelper.accessor("metricNamespace.metricNamespace", {
    header: "entity:ociQuery.metricNamespace",
  }),
  columnHelper.accessor("metricName.metricName", {
    header: "entity:ociQuery.metricName",
  }),
  columnHelper.accessor("unitType.name", {
    header: "entity:ociQuery.unitType",
  }),
  columnHelper.accessor("statisticType.name", {
    header: "entity:ociQuery.statisticType",
  }),
  columnHelper.accessor("storageResultType.name", {
    header: "entity:ociQuery.storageResultType",
  }),
  columnHelper.accessor("startTimeOffset", {
    header: "entity:ociQuery.startTimeOffset",
  }),
  columnHelper.accessor("activeLocalized", {
    header: "entity:ociQuery.active",
  }),
];
