import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { CommitmentResponse } from "@/lib/api/dto";

import { commitmentKeys } from "./queryKeys";

interface UseFetchCommitmentsQuery<T>
  extends Omit<
    UseQueryOptions<
      CommitmentResponse[],
      Error,
      T,
      ReturnType<typeof commitmentKeys.all>
    >,
    "queryKey" | "queryFn"
  > {
  subscriptionid?: number;
}

export const useFetchCommitmentsQuery = <T = CommitmentResponse[]>({
  subscriptionid,
  ...options
}: UseFetchCommitmentsQuery<T> = {}) => {
  return useQuery({
    ...options,
    queryKey: commitmentKeys.all(subscriptionid),
    queryFn: ({ queryKey }) =>
      Api.commitments.list(queryKey[1]).then((res) => res.data),
    enabled: !!subscriptionid,
  });
};
