import { Drawer, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AgnosticRouteObject } from "@sentry/react/types/types";
import { IconChevronDown, IconMenu2 } from "@tabler/icons-react";
import { ParseKeys } from "i18next";
import { useTranslation } from "react-i18next";
import { NavLink, matchRoutes, useLocation } from "react-router-dom";

import { Claim } from "@/lib/api/dto";
import { cn } from "@/lib/utils";
import { ExtractKeys } from "@/types/util";

export type EntityTranslationKeys = ParseKeys<"entity">;

type EntitySidebarTranslationKeys = ExtractKeys<
  EntityTranslationKeys,
  "sidebar"
>;

/*
  added because we want autocomplete for the labels,
  but still want to allow strings that don't exist in the string literal intersection
*/
// eslint-disable-next-line @typescript-eslint/ban-types
export type EntityLabels = EntitySidebarTranslationKeys | (string & {});

export type Link = {
  label: EntityLabels;
  path: string;
  requiredAuthorities?: Claim[];
};

export type LinkGroup = {
  label: EntityLabels;
  links: Link[];
  requiredAuthorities?: Claim[];
};

export interface SidebarProps {
  links: (Link | LinkGroup)[];
}

const SidebarGroup = ({ label, links }: { label: string; links: Link[] }) => {
  const location = useLocation();
  const { t } = useTranslation("entity");
  const containsActiveLink = matchRoutes(
    links.map<AgnosticRouteObject>((link) => ({ path: link.path })),
    location,
  )?.some(Boolean);

  const [isOpen, { toggle }] = useDisclosure(containsActiveLink);

  return (
    <div>
      <UnstyledButton
        onClick={toggle}
        className={cn(
          "flex w-full max-w-xs items-center whitespace-nowrap rounded py-2 align-middle text-sm font-medium text-slate-600 transition-colors duration-200 hover:text-black",
          { "text-brand-700 hover:text-brand-900": containsActiveLink },
        )}
      >
        <span className="flex items-center gap-2">
          <IconChevronDown
            className={cn(
              "h-4 w-4 transition-transform duration-200",
              isOpen && "rotate-180",
            )}
          />
          {t(label as ParseKeys<"entity">)}
        </span>
      </UnstyledButton>

      {isOpen && (
        <div className="w-full">
          {links.map((link) => (
            <NavLink
              key={link.path}
              to={link.path}
              className="ml-2 block -translate-x-px border-l-2 p-2 text-sm text-gray-500 hover:bg-gray-100 [&.active]:border-l-brand-400 [&.active]:bg-brand-100 [&.active]:text-gray-700"
            >
              {t(link.label as ParseKeys<"entity">)}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

const SidebarLink = ({ label, path }: Link) => {
  const location = useLocation();
  const { t } = useTranslation("entity");
  const containsActiveLink = matchRoutes([{ path }], location)?.some(Boolean);

  return (
    <NavLink
      to={path}
      className={cn(
        "py-2 pl-6 text-sm font-medium text-slate-600 transition-colors duration-200 hover:text-black",
        { "text-brand-700 hover:text-brand-900": containsActiveLink },
      )}
    >
      {t(label as ParseKeys<"entity">)}
    </NavLink>
  );
};

export const Sidebar = ({ links }: SidebarProps) => {
  const [drawerOpened, { toggle }] = useDisclosure(false);

  const linksAndGroups = links.map((linkOrGroup, index) =>
    "links" in linkOrGroup ?
      <SidebarGroup
        key={index}
        label={linkOrGroup.label}
        links={linkOrGroup.links}
      />
    : <SidebarLink key={index} {...linkOrGroup} />,
  );

  return (
    <>
      <div className="flex w-screen px-4 py-2 lg:hidden">
        <UnstyledButton
          onClick={toggle}
          className="rounded-sm border border-solid border-gray-200 text-slate-600 transition-colors duration-200 hover:text-slate-800 active:bg-gray-100"
        >
          <IconMenu2 className="size-10 p-2" aria-label="Prikaži navigaciju" />
        </UnstyledButton>
      </div>
      <div className="col-span-2 hidden w-64 flex-col gap-x-2 gap-y-1 p-3 lg:flex">
        {linksAndGroups}
      </div>
      <Drawer
        opened={drawerOpened}
        onClose={toggle}
        title="Navigacija"
        position="left"
        className="lg:hidden"
      >
        {linksAndGroups}
      </Drawer>
    </>
  );
};
