import { ActionIcon, Button, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import { toast } from "@/components/ui/use-toast";
import { useDeleteRegionMutation } from "@/hooks/api/useDeleteRegionQuery";
import { useFetchRegionsQuery } from "@/hooks/api/useFetchRegionsQuery";
import { useMRT } from "@/hooks/useMRT";
import { RegionResponse } from "@/lib/api/dto";

import { columns } from "./columns";
import { RegionDetails } from "../RegionDetails/RegionDetails";

export const RegionsTable = () => {
  const { t } = useTranslation(["entity", "common"]);

  const { mutate, isPending } = useDeleteRegionMutation();
  const { data, isLoading, isFetching } = useFetchRegionsQuery();

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedRegion, setSelectedRegion] = useState<
    RegionResponse | undefined
  >();
  const [regionToDelete, setRegionToDelete] = useState<RegionResponse | null>(
    null,
  );

  const memoizedData = useMemo(() => data || [], [data]);

  const showConfirmDeleteModal = (query: RegionResponse) => {
    setRegionToDelete(query);
  };

  const handleCloseDeleteRegionsQueryModal = () => {
    if (isPending) return;
    setRegionToDelete(null);
  };

  const handleDeleteRegionsQuery = () => {
    if (!regionToDelete) return;

    mutate(regionToDelete.id, {
      onSuccess: () => {
        toast({
          title: t("administration.region.deleteRegionModal.success"),
        });
        setRegionToDelete(null);
        handleCloseDeleteRegionsQueryModal();
      },
      onError: () => {
        toast({
          title: t("administration.region.deleteRegionModal.error"),
          variant: "destructive",
        });
      },
    });
  };

  const handleSettled = () => {
    selectedRegion ? setSelectedRegion(undefined) : close();
  };

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <div className="flex gap-2">
        <ActinIconEdit onClick={() => setSelectedRegion(row.original)} />
        <ActionIcon
          color="red"
          onClick={() => showConfirmDeleteModal(row.original)}
        >
          <IconTrash size={18} />
        </ActionIcon>
      </div>
    ),
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={open}>
        {t("administration.region.addRegion")}
      </Button>
    ),
  });

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <Title size="h2" className="mb-4">
        {t("administration.region.region")}
      </Title>

      <MantineReactTable table={table} />

      <Modal
        opened={!!selectedRegion || opened}
        onClose={handleSettled}
        title={
          selectedRegion ?
            t("administration.region.editRegion")
          : t("administration.region.addRegion")
        }
        size="lg"
      >
        <RegionDetails onSettled={handleSettled} region={selectedRegion} />
      </Modal>
      <Modal
        opened={!!regionToDelete}
        onClose={handleCloseDeleteRegionsQueryModal}
        title={t("administration.region.deleteRegionModal.title")}
        classNames={{ body: "flex justify-between gap-2" }}
      >
        <Button
          variant="outline"
          disabled={isPending}
          onClick={handleCloseDeleteRegionsQueryModal}
        >
          {t("common:userActions.cancel")}
        </Button>
        <Button
          onClick={handleDeleteRegionsQuery}
          loading={isPending}
          disabled={isPending}
        >
          {t("common:userActions.confirm")}
        </Button>
      </Modal>
    </div>
  );
};
