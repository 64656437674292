import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { Api } from "@/lib/api";
import {
  CreateOrganizationPayload,
  CreateAndUpdateOrganizationResponse,
} from "@/lib/api/dto";

import { organizationKeys } from "./queryKeys";

interface UseCreateOrUpdateOrganizationMutationOptions
  extends UseMutationOptions<
    CreateAndUpdateOrganizationResponse,
    Error,
    CreateOrganizationPayload
  > {
  organizationId?: string;
}

export const useCreateOrUpdateOrganizationMutation = ({
  organizationId,
  ...options
}: UseCreateOrUpdateOrganizationMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (data) => {
      if (organizationId) {
        return Api.organizations
          .update(organizationId, data)
          .then((res) => res.data);
      }

      return Api.organizations.create(data).then((res) => res.data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.all() });
    },
  });
};
