import { Text } from "@mantine/core";

import { CopyableText, CopyableTextProps } from "./copyable-text";

export interface TruncatedTextProps
  extends Omit<CopyableTextProps, "textCopyable" | "children"> {
  copyable?: boolean;
  leadingCharacters?: number;
  trailingCharacters?: number;
}

export const TruncatedText = ({
  copyable = true,
  leadingCharacters = 3,
  trailingCharacters = 3,
  text,
  ...rest
}: TruncatedTextProps) => {
  const truncatedText = `${text.slice(0, leadingCharacters)}...${text.slice(
    -trailingCharacters
  )}`;
  if (copyable) {
    return (
      <CopyableText text={text} title={text} {...rest} textCopyable>
        {truncatedText}
      </CopyableText>
    );
  }

  return <Text {...rest}>{truncatedText}</Text>;
};
