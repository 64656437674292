import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";

type UseTForm = typeof useForm;

/**
 * Custom hook that wraps `useForm` hook
 *
 * We use this custom hook so we can define `default props` on useForm
 *
 * @param params
 * @returns the form instance
 */
export const useTForm: UseTForm = (params) => {
  const { t } = useTranslation("form");
  return useForm({
    ...params,
    enhanceGetInputProps: (payload) => ({
      error: t(payload.inputProps.error),
      ...params?.enhanceGetInputProps?.(payload),
    }),
  });
};

export type FormTransform<TValues, TOutput> = (values: TValues) => TOutput;
