import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { FirstDiagramParams, FirstDiagramResponse } from "@/lib/api/dto";

import { dashboardReportKeys } from "./queryKeys";

interface UseFetchFirstDiagramQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      FirstDiagramResponse[],
      Error,
      T,
      ReturnType<typeof dashboardReportKeys.firstDiagram>
    >,
    "queryKey" | "queryFn"
  > {
  params: Partial<FirstDiagramParams>;
}

export const useFetchFirstDiagramQuery = <T = FirstDiagramResponse[]>({
  params,
  ...options
}: UseFetchFirstDiagramQueryOptions<T>) =>
  useQuery({
    ...options,
    staleTime: 1000 * 60 * 5,
    queryKey: dashboardReportKeys.firstDiagram(params),
    queryFn: ({ queryKey: [_, keyParams] }) =>
      (
        keyParams?.timetype &&
        keyParams?.tenantocid &&
        keyParams?.datefrom &&
        keyParams?.dateto
      ) ?
        Api.dashboard
          .firstDiagram(keyParams as FirstDiagramParams)
          .then((res) => res.data)
      : [],
  });
