import {
  ActionIcon,
  Button,
  Group,
  MultiSelect,
  NumberInput,
  ScrollArea,
  Stack,
  TagsInput,
  Text,
  TextInput,
} from "@mantine/core";
import { MonthPickerInput } from "@mantine/dates";
import { IconX } from "@tabler/icons-react";
import { zodResolver } from "mantine-form-zod-resolver";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useFetchTenantsQuery } from "@/hooks/api/useFetchTenantsQuery";
import { useTForm } from "@/hooks/useTForm";
import { BudgetNotificationType } from "@/lib/api/dto";
import { cn } from "@/lib/utils";

const budgetNotificationSchema = z.object({
  notificationType: z.enum([
    BudgetNotificationType.All,
    BudgetNotificationType.Single,
    BudgetNotificationType.Multiple,
  ]),
  tenants: z.array(z.string()).nullable(),
  values: z
    .array(
      z.object({
        datefrom: z.date({
          invalid_type_error: "notification:budget.invalidEmail",
        }),
        dateto: z.date(),
        value: z.number(),
      }),
    )
    .refine((v) => {
      return v
        .sort(
          (a, b) =>
            new Date(a.datefrom).getTime() - new Date(b.datefrom).getTime(),
        )
        .every((value, index, self) => {
          const nextValue = self[index + 1];
          return !nextValue || value.dateto <= nextValue.datefrom;
        });
    }, "notification:budget.dateFromToError"),
  emails: z
    .array(z.string())
    .min(1, "form:field.required")
    .refine(
      (emails) =>
        emails.every((email) => z.string().email().safeParse(email).success),
      "notification:bugdet.invalidEmail",
    ),
});

export type BudgetNotificationFormValues = z.infer<
  typeof budgetNotificationSchema
>;

export interface CreateBudgetNotificationFormProps {
  tenant: string;
  subscription: string;
  onSubmit: (values: BudgetNotificationFormValues) => void;
  onCancel?: () => void;
}

export const CreateBudgetNotificationForm = ({
  tenant,
  subscription,
  onSubmit,
  onCancel,
}: CreateBudgetNotificationFormProps) => {
  const { data: tenants = [] } = useFetchTenantsQuery();
  const form = useTForm<BudgetNotificationFormValues>({
    initialValues: {
      notificationType: BudgetNotificationType.All,
      tenants: [],
      values: [
        {
          datefrom: new Date(),
          dateto: new Date(),
          value: 0,
        },
      ],
      emails: [],
    },
    validate: zodResolver(budgetNotificationSchema),
  });

  const tenantOptions = tenants.map((tenant) => ({
    value: tenant.id,
    label: tenant.name!,
  }));

  const { t } = useTranslation(["notification", "common"]);

  const handleAddNewValue = () => {
    form.insertListItem("values", {
      datefrom: new Date(),
      dateto: new Date(),
      value: 0,
    });
  };

  return (
    <Group
      component="form"
      className="flex-1"
      onSubmit={() =>
        form.onSubmit((values) =>
          onSubmit(values as BudgetNotificationFormValues),
        )
      }
      align="stretch"
    >
      <Stack className="flex-1">
        <TextInput readOnly label={t("budget.tenant")} value={tenant} />
        <TextInput
          readOnly
          label={t("budget.subscription")}
          value={subscription}
        />

        <MultiSelect
          label={t("budget.tenants")}
          data={tenantOptions}
          {...form.getInputProps("tenants")}
          onChange={(value) => {
            switch (value.length) {
              case 0:
                form.setFieldValue(
                  "notificationType",
                  BudgetNotificationType.All,
                );
                break;
              case 1:
                form.setFieldValue(
                  "notificationType",
                  BudgetNotificationType.Single,
                );
                break;
              default:
                form.setFieldValue(
                  "notificationType",
                  BudgetNotificationType.Multiple,
                );
                break;
            }

            form.setFieldValue("tenants", value);
          }}
        />

        <TagsInput
          label={t("budget.emails")}
          {...form.getInputProps("emails")}
        />

        <Group justify="end" mt="auto">
          {onCancel && (
            <Button variant="outline" color="gray" onClick={onCancel}>
              {t("budget.cancel")}
            </Button>
          )}
          <Button bg="blue" type="submit">
            {t("budget.save")}
          </Button>
        </Group>
      </Stack>
      <Stack className="flex-1 justify-start self-start">
        <Stack gap={6}>
          <ScrollArea
            h={420}
            className={cn("border border-transparent", {
              "rounded-sm border-red-500": form.errors["values"],
            })}
          >
            <Stack px="sm">
              {form.values.values.map((value, index) => (
                <Group key={index} align="end">
                  <MonthPickerInput
                    allowSingleDateInRange
                    type="range"
                    label={t("common:desiredPeriod")}
                    valueFormat="MMM YYYY"
                    className="flex-1"
                    value={[value.datefrom, value.dateto]}
                    minDate={new Date()}
                    onChange={([datefrom, dateto]) => {
                      if (!datefrom && !dateto) {
                        form.setFieldValue(
                          `values.${index}.datefrom`,
                          value.datefrom,
                        );
                        form.setFieldValue(
                          `values.${index}.dateto`,
                          value.datefrom,
                        );
                        return;
                      }
                      form.setFieldValue(`values.${index}.datefrom`, datefrom);
                      form.setFieldValue(`values.${index}.dateto`, dateto);
                    }}
                    error={form.errors[`values.${index}.datefrom`]}
                  />
                  <NumberInput
                    hideControls
                    label={t("budget.value")}
                    maw={96}
                    value={value.value}
                    onChange={(value) =>
                      form.setFieldValue(`values.${index}.value`, value)
                    }
                  />
                  <ActionIcon
                    size="lg"
                    onClick={() => form.removeListItem("values", index)}
                    disabled={form.values.values.length <= 1}
                  >
                    <IconX />
                  </ActionIcon>
                </Group>
              ))}
            </Stack>
          </ScrollArea>
          {form.errors["values"] && (
            <Text c="red">{form.errors["values"]}</Text>
          )}
        </Stack>
        <Group>
          <Button color="blue" ml="auto" onClick={handleAddNewValue}>
            {t("budget.addNewValue")}
          </Button>
        </Group>
      </Stack>
    </Group>
  );
};
