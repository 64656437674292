import { ActionIcon, ElementProps, Text, TextProps } from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";

import { cn } from "@/lib/utils";

import { toast } from "./use-toast";

export interface CopyableTextProps
  extends TextProps,
    ElementProps<"span", keyof TextProps> {
  text: string;
  errorTitle?: string;
  textCopyable?: boolean;
  notificationTitle?: string;
}

export const CopyableText = ({
  text,
  children,
  notificationTitle = `Uspešno prekopiran tekst:\n${text}`,
  errorTitle = "Greška prilikom kopiranja teksta",
  textCopyable,
  className,
  ...rest
}: CopyableTextProps) => (
  <Text
    {...rest}
    span
    className={cn(
      "group/copyable-text gap-1 inline-flex items-center",
      { "cursor-pointer": textCopyable },
      className
    )}
    onClick={(e) => {
      if (textCopyable) {
        e.stopPropagation();
        window.navigator.clipboard
          .writeText(text)
          .then(() => toast({ title: notificationTitle }))
          .catch(() => {
            toast({
              title: errorTitle,
              variant: "destructive",
            });
          });
      }
    }}
  >
    <span>{children}</span>
    <ActionIcon
      radius="xl"
      size="1em"
      className="invisible p-0.5 group-hover/copyable-text:visible"
      onClick={(e) => {
        e.stopPropagation();
        window.navigator.clipboard
          .writeText(text)
          .then(() => toast({ title: notificationTitle }))
          .catch(() => {
            toast({
              title: errorTitle,
              variant: "destructive",
            });
          });
      }}
    >
      <IconCopy size="100%" />
    </ActionIcon>
  </Text>
);
