import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { ManageableResourceResponse } from "@/lib/api/dto";

import { resourceKeys } from "./queryKeys";

interface UseFetchManageableResourcesQueryProps
  extends Omit<
    UseQueryOptions<ManageableResourceResponse[]>,
    "queryKey" | "queryFn"
  > {
  tenancyOCID: string;
  resourceType: string;
  refetchInterval?: number | false;
}

export const useFetchManageableResourcesQuery = ({
  tenancyOCID,
  resourceType,
  refetchInterval = false,
  ...options
}: UseFetchManageableResourcesQueryProps) => {
  return useQuery({
    ...options,
    queryKey: resourceKeys.manageableResources(tenancyOCID, resourceType),
    queryFn: () =>
      Api.resource
        .manageableResources(tenancyOCID, resourceType)
        .then((res) => res.data),
    enabled: !!tenancyOCID && !!resourceType,
    refetchInterval,
  });
};
