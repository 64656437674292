import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { FifthDiagramParams, FifthDiagramResponse } from "@/lib/api/dto";

import { dashboardReportKeys } from "./queryKeys";

interface UseFetchFifthDiagramQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      FifthDiagramResponse[],
      Error,
      T,
      ReturnType<typeof dashboardReportKeys.fifthDiagram>
    >,
    "queryKey" | "queryFn"
  > {
  params: Partial<FifthDiagramParams>;
}

export const useFetchFifthDiagramQuery = <T = FifthDiagramResponse[]>({
  params,
  ...options
}: UseFetchFifthDiagramQueryOptions<T>) => {
  return useQuery({
    ...options,
    staleTime: 1000 * 60 * 5,
    queryKey: dashboardReportKeys.fifthDiagram(params),
    queryFn: ({ queryKey: [_, keyParams] }) =>
      keyParams.subscriptionid
        ? Api.dashboard
            .fifthDiagram(keyParams as FifthDiagramParams)
            .then((response) => response.data)
        : [],
  });
};
