import { useMantineTheme } from "@mantine/core";
import { TableMeta } from "@tanstack/table-core";
import { ChartData } from "chart.js";
import format from "date-fns/format";
import { createMRTColumnHelper } from "mantine-react-table";
import { ReactNode, useMemo } from "react";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { ChartContainer } from "@/components/ui/chart-container";
import { type CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchFirstDiagramQuery } from "@/hooks/api/useFetchFirstDiagramQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useDateFnsLocale } from "@/hooks/useDateFnsLocale";
import { FirstDiagramResponse, TimeUnit } from "@/lib/api/dto";
import { formatNumberAsCurrency } from "@/lib/utils";

interface DashboardFirstDiagramReportProps {
  header: ReactNode;
  dateto: number;
  datefrom: number;
  timeUnit: TimeUnit;
  tenantocid: string;
}

const dateFormatMap = {
  hour: "dd.MM.yyyy HH:mm",
  day: "dd.MM.yyyy",
  month: "MMM yyyy",
};

const columnHelper = createMRTColumnHelper<FirstDiagramResponse>();

const columns = [
  columnHelper.accessor("timeseries", {
    header: "dashboard:common.date",
    Cell: ({ cell, table }) => {
      const { timeUnit, dateFnsLocale } = table.options
        .meta as TableMeta<FirstDiagramResponse>;

      if (!timeUnit) {
        throw new Error("Missing `timeUnit` meta in table options");
      }

      const value = new Date(cell.getValue());

      return format(value, dateFormatMap[timeUnit], {
        locale: dateFnsLocale,
      });
    },
  }),
  columnHelper.accessor("fullsum", {
    header: "dashboard:common.consumption",
    Cell: ({ cell, table }) =>
      formatNumberAsCurrency({
        value: cell.getValue(),
        currency: (table.options.meta as TableMeta<FirstDiagramResponse>)
          ?.currency,
        options: {
          locale: (table.options.meta as TableMeta<FirstDiagramResponse>)
            ?.currencyLocale,
        },
      }),
  }),
];

export const DashboardFirstDiagramReport = ({
  header,
  dateto,
  datefrom,
  timeUnit,
  tenantocid,
}: DashboardFirstDiagramReportProps) => {
  const theme = useMantineTheme();
  const { t } = useTranslation(["dashboard"]);
  const { tenant } = useTenantContext();
  const { data, isLoading, isFetching } = useFetchFirstDiagramQuery({
    params: {
      datefrom,
      dateto,
      timetype: timeUnit,
      tenantocid,
    },
  });

  const currencyLocale = useCurrencyLocale();
  const dateFnsLocale = useDateFnsLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;
  const gridData = useMemo(() => data || [], [data]);
  const average = useMemo(
    () =>
      gridData.reduce((acc, curr) => acc + curr.fullsum, 0) / gridData.length,
    [gridData],
  );

  const chartData: ChartData<"bar" | "line"> = {
    labels: gridData.map((item) => item.timeseries) || [],
    datasets: [
      {
        type: "bar" as const,
        data: gridData.map((item) => item.fullsum) || [],
        backgroundColor: theme.other.chartColors.greenTransparent,
        borderColor: theme.other.chartColors.green,
        borderWidth: 1,
      },
    ],
  };

  return (
    <ChartContainer
      enableGridDisplay
      tableOptions={{
        columns,
        data: gridData,
        enableSorting: true,
        meta: { timeUnit, currency, currencyLocale, dateFnsLocale },
      }}
      className="min-w-[19.25rem] flex-1"
      title={({ isFullscreen }) =>
        isFullscreen && (
          <div className="flex flex-col gap-2">
            <h2>{tenant?.name}</h2>
            {header}
          </div>
        )
      }
      loading={isLoading}
      fetching={isFetching}
      isDataEmpty={!gridData.length}
    >
      <Chart
        type="bar"
        height={200}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              suggestedMax: gridData.reduce(
                (acc, curr) => Math.max(acc, curr.fullsum + curr.fullsum / 10),
                0,
              ),
              ticks: {
                callback: (tickValue) =>
                  formatNumberAsCurrency({
                    value: tickValue,
                    currency,
                    options: { locale: currencyLocale },
                  }),
              },
            },
            x: {
              type: "time",
              time: {
                unit: timeUnit,
                displayFormats: {
                  hour: "dd MMM HH:mm",
                  day: "dd.MM",
                  month: "MMM",
                },
                round: timeUnit,
              },
              ticks: {
                maxRotation: 0,
              },
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                title: (tooltipItem) =>
                  tooltipItem[0].parsed.x ?
                    format(tooltipItem[0].parsed.x, dateFormatMap[timeUnit])
                  : "",
                label: (tooltipItem) =>
                  `${t("dashboard:commitment.usedAmount")}: ${formatNumberAsCurrency({ value: tooltipItem.parsed.y, currency, options: { locale: currencyLocale } })}`,
              },
            },
            annotation: {
              annotations: {
                average:
                  average ?
                    {
                      type: "line",
                      yMin: average,
                      yMax: average,
                      borderColor: theme.other.chartColors.red,
                      display: true,
                      label: {
                        content: t("common.averageAmount", {
                          value: formatNumberAsCurrency({
                            value: average,
                            currency,
                            options: { locale: currencyLocale },
                          }),
                        }),
                        display: true,
                        position: "end",
                        backgroundColor: theme.colors.brand[6],
                        font: {
                          size: 12,
                        },
                      },
                      borderWidth: 1,
                    }
                  : void 0,
              },
            },
            zoom: {
              pan: {
                enabled: true,
                mode: "x",
              },
              zoom: {
                wheel: { enabled: true, modifierKey: "ctrl" },
                pinch: { enabled: true },
                mode: "x",
              },
            },
          },
        }}
        data={chartData}
      />
    </ChartContainer>
  );
};
