import { createMRTColumnHelper } from "mantine-react-table";

import { MetricMeasuringUnitResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<MetricMeasuringUnitResponse>();

export const columns = [
  columnHelper.accessor("metricMeasuringUnit", {
    header: "entity:administration.metricMeasuringUnit.metricMeasuringUnit",
  }),
  columnHelper.accessor("name", {
    header: "entity:administration.metricMeasuringUnit.name",
  }),
];
