import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";
import {
  DeleteMetricNotificationPayload,
  MetricNotificationResponse,
} from "@/lib/api/dto";

import { metricNotificationKeys } from "./queryKeys";

interface UseDeleteMetricNotificationMutationOptions
  extends UseMutationOptions<
    MetricNotificationResponse,
    unknown,
    DeleteMetricNotificationPayload
  > {}

export const useDeleteMetricNotificationMutation = ({
  onSuccess,
  onError,
  onSettled,
  ...options
}: UseDeleteMetricNotificationMutationOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: async (data: DeleteMetricNotificationPayload) =>
      Api.metricNotifications.delete(data).then((res) => res.data),
    onSuccess: (...args) => {
      successToast.delete();
      onSuccess?.(...args);
    },
    onError: (...args) => {
      errorToast.delete();
      onError?.(...args);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: metricNotificationKeys.all(),
      });
      onSettled?.(...args);
    },
  });
};
