import { createMRTColumnHelper } from "mantine-react-table";

import { TruncatedText } from "@/components/ui/truncated-text";
import { CompartmentResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<CompartmentResponse>();

export const columns = [
  columnHelper.accessor(({ organization }) => organization?.name, {
    header: "entity:administration.common.organization",
  }),
  columnHelper.accessor(({ tenant }) => tenant?.name, {
    header: "entity:administration.tenant.tenant",
  }),
  columnHelper.accessor("ocid", {
    header: "entity:administration.common.ocid",
    Cell: ({ cell }) => (
      <TruncatedText size="sm" leadingCharacters={10} text={cell.getValue()} />
    ),
  }),
  columnHelper.accessor("name", {
    header: "entity:administration.common.name",
  }),
  columnHelper.accessor("description", {
    header: "entity:administration.common.description",
  }),
  columnHelper.accessor(({ compartmentStatus }) => compartmentStatus?.name, {
    header: "entity:administration.compartmentStatus.compartmentStatus",
  }),
];
