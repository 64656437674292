import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { LicenseResponse } from "@/lib/api/dto";

import { licenseKeys } from "./queryKeys";

interface useFetchLicensesQueryOptions<T>
  extends UseQueryOptions<LicenseResponse[], Error, T> {}

export const useFetchLicensesQuery = <T = LicenseResponse[]>(
  options?: useFetchLicensesQueryOptions<T>
) => {
  return useQuery({
    ...options,
    queryKey: licenseKeys.all(),
    queryFn: () => Api.licenses.list().then((res) => res.data),
  });
};
