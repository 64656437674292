import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { MetricNameResponse } from "@/lib/api/dto";

import { metricNamesKeys } from "./queryKeys";

export interface UseMetricNamesQueryParams<T>
  extends Omit<
    UseQueryOptions<
      MetricNameResponse[],
      Error,
      T,
      ReturnType<typeof metricNamesKeys.all>
    >,
    "queryKey" | "queryFn"
  > {
  resourceId?: string | null;
}

export const useFetchMetricNamesQuery = <T = MetricNameResponse[]>({
  resourceId,
  ...options
}: UseMetricNamesQueryParams<T> = {}) => {
  return useQuery({
    ...options,
    queryKey: metricNamesKeys.all(resourceId),
    queryFn: ({ queryKey }) =>
      Api.metricName.list(queryKey[1]).then((res) => res.data),
  });
};
