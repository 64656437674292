import { ActionIcon, Box, Button, Modal, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconAlertTriangle, IconTrash } from "@tabler/icons-react";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconEdit } from "@/components/ui/action-icon-edit";
import { useDeleteMetricNamespaceMutation } from "@/hooks/api/useDeleteMetricNamespaceMutation";
import { useFetchMetricNamespaceQuery } from "@/hooks/api/useFetchMetricNamespaceQuery";
import { useMRT } from "@/hooks/useMRT";
import { MetricNamespaceResponse } from "@/lib/api/dto";

import { columns } from "./columns";
import { MetricNamespaceDetails } from "../MetricNamespaceDetails/MetricNamespaceDetails";

export const MetricNamespaceTable = () => {
  const { mutate, isPending } = useDeleteMetricNamespaceMutation();
  const { t } = useTranslation(["entity", "common"]);
  const { data, isLoading, isFetching } = useFetchMetricNamespaceQuery();

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedMetricNamespace, setSelectedSelectedMetricNamespace] =
    useState<MetricNamespaceResponse | null>(null);
  const [metricNamespaceToDelete, setMetricNamespaceToDelete] =
    useState<MetricNamespaceResponse | null>(null);

  const showConfirmDeleteModal = (query: MetricNamespaceResponse) => {
    setMetricNamespaceToDelete(query);
  };

  const handleCloseDeleteOCIQueryModal = () => {
    if (isPending) return;
    setMetricNamespaceToDelete(null);
  };

  const handleDeleteMetricNamespaceQuery = () => {
    if (!metricNamespaceToDelete) return;

    mutate(metricNamespaceToDelete.metricNamespace, {
      onSettled: handleCloseDeleteOCIQueryModal,
    });
  };

  const handleSettled = () => {
    selectedMetricNamespace ?
      setSelectedSelectedMetricNamespace(null)
    : close();
  };

  const memoizedData = useMemo(() => data || [], [data]);

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <div className="flex gap-2">
        <ActinIconEdit
          onClick={() => setSelectedSelectedMetricNamespace(row.original)}
        />
        <ActionIcon
          color="red"
          onClick={() => showConfirmDeleteModal(row.original)}
        >
          <IconTrash size={18} />
        </ActionIcon>
      </div>
    ),
    renderTopToolbarCustomActions: () => (
      <Button className="ml-auto" onClick={open}>
        {t("administration.metricNamespace.actions.addMetricNamespace")}
      </Button>
    ),
  });

  return (
    <div className="col-span-2 flex flex-1 flex-col overflow-auto lg:col-span-1">
      <div className="mb-4 flex items-center justify-between">
        <Title size="h2">
          {t("administration.metricNamespace.metricNamespace")}
        </Title>
      </div>
      <MantineReactTable table={table} />
      <Modal
        opened={!!selectedMetricNamespace || opened}
        onClose={handleSettled}
        title={
          selectedMetricNamespace ?
            t("administration.metricNamespace.actions.editMetricNamespace")
          : t("administration.metricNamespace.actions.addMetricNamespace")
        }
        size="lg"
      >
        <MetricNamespaceDetails
          onSettled={handleSettled}
          metricNamespace={selectedMetricNamespace}
        />
      </Modal>

      <Modal
        opened={!!metricNamespaceToDelete}
        onClose={handleCloseDeleteOCIQueryModal}
        title={
          <Box c="red.8" className="flex items-center gap-2">
            <IconAlertTriangle />
            <span className="font-semibold">{t("common:warning")}</span>
          </Box>
        }
        classNames={{
          body: "flex flex-col gap-4",
        }}
      >
        <Text variant="text" size="sm">
          {t("administration.metricNamespace.actions.delete", {
            value: metricNamespaceToDelete?.metricNamespace,
          })}
        </Text>

        <div className="flex justify-between gap-2">
          <Button
            variant="outline"
            disabled={isPending}
            onClick={handleCloseDeleteOCIQueryModal}
          >
            {t("common:userActions.cancel")}
          </Button>
          <Button
            onClick={handleDeleteMetricNamespaceQuery}
            loading={isPending}
            disabled={isPending}
          >
            {t("common:userActions.confirm")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
