import { createMRTColumnHelper, type MRT_ColumnDef } from "mantine-react-table";

import { TruncatedText } from "@/components/ui/truncated-text";
import { ResourceResponse } from "@/lib/api/dto";

const columnHelper = createMRTColumnHelper<ResourceResponse>();

export const columns: MRT_ColumnDef<ResourceResponse>[] = [
  columnHelper.accessor("ocid", {
    header: "entity:administration.common.ocid",
    Cell: ({ cell }) => (
      <TruncatedText size="sm" leadingCharacters={10} text={cell.getValue()} />
    ),
  }),

  columnHelper.accessor("name", {
    header: "entity:administration.common.ocidName",
  }),

  columnHelper.accessor("description", {
    header: "entity:administration.common.description",
  }),

  columnHelper.accessor("organization.name", {
    header: "entity:administration.common.organization",
  }),

  columnHelper.accessor("tenant.name", {
    header: "entity:administration.tenant.tenant",
  }),

  columnHelper.accessor("compartment.name", {
    header: "entity:administration.compartment.compartment",
  }),
];
