import { Stack, Group, Button, Title, Modal, Text, Box } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconAlertTriangle, IconPlus } from "@tabler/icons-react";
import { TableMeta } from "@tanstack/table-core";
import { endOfMonth } from "date-fns";
import format from "date-fns/format";
import { MantineReactTable, createMRTColumnHelper } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActinIconDelete } from "@/components/ui/action-icon-delete";
import { EnumSelect } from "@/components/ui/enum-select";
import { TreeSelect } from "@/components/ui/tree-select";
import { toast } from "@/components/ui/use-toast";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useCreateCompartmentBudgetNotification } from "@/hooks/api/useCreateCompartmentBudgetNotification";
import { useDeleteCompartmentBudgetNotificationMutation } from "@/hooks/api/useDeleteCompartmentBudgetNotificationMutation";
import { useFetchCompartmentBudgetNotifications } from "@/hooks/api/useFetchCompartmentBudgetNotifications";
import { useFetchCompartmentBudgetNotificationTypes } from "@/hooks/api/useFetchCompartmentBudgetNotificationTypes";
import {
  getTreeChildren,
  nestCompartmentStructure,
  useFetchCompartmentStructureQuery,
} from "@/hooks/api/useFetchCompartmentStructureQuery";
import { useFetchSubscriptionsQuery } from "@/hooks/api/useFetchSubscriptionsQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useMRT } from "@/hooks/useMRT";
import {
  BudgetNotificationValueDto,
  CompartmentBudgetNotificationResponse,
  CompartmentBudgetNotificationType,
  CompartmentStructureResponse,
  CreateCompartmentBudgetNotificationPayload,
} from "@/lib/api/dto";
import { formatToDate } from "@/lib/dateUtils";
import { formatNumberAsCurrency } from "@/lib/utils";
import {
  CompartmentBudgetNotificationFormValues,
  CreateCompartmentBudgetNotificationForm,
} from "@/widgets/CreateCompartmentBudgetNotificationForm";
import { DashboardFifthDiagramReport } from "@/widgets/DashboardReports/DashboardFifthDiagramReport";
import { DashboardKPIReport } from "@/widgets/DashboardReports/DashboardKPIReport";
import { DashboardThirdDiagramReport } from "@/widgets/DashboardReports/DashboardThirdDiagramReport";

const columnHelper = createMRTColumnHelper<
  CompartmentBudgetNotificationResponse &
    BudgetNotificationValueDto & {
      subRows?: CompartmentBudgetNotificationResponse[];
    }
>();

const columns = [
  columnHelper.accessor(({ subscription }) => subscription?.subscriptionID, {
    header: "notification:compartment.subscription",
  }),
  columnHelper.accessor("compartments", {
    header: "entity:administration.compartment.compartment",
    Cell: ({ row }) =>
      row.original.compartments?.map(({ name }) => name).join(", "),
  }),
  columnHelper.accessor("budgetCompartmentNotificationTypeLabel", {
    header: "notification:common.notificationType",
  }),
  columnHelper.accessor("startDate", {
    header: "notification:common.startDate",
    Cell: ({ cell }) => formatToDate(cell.getValue()),
  }),
  columnHelper.accessor("endDate", {
    header: "notification:common.endDate",
    Cell: ({ cell }) => formatToDate(cell.getValue()),
  }),
  columnHelper.accessor("value", {
    header: "notification:common.value",
    Cell: ({ cell, row, table }) => {
      if (!row.depth) return;
      return formatNumberAsCurrency({
        value: cell.getValue(),
        currency: (table.options.meta as TableMeta<BudgetNotificationValueDto>)
          ?.currency,
        options: {
          locale: (table.options.meta as TableMeta<BudgetNotificationValueDto>)
            ?.currencyLocale,
        },
      });
    },
  }),
  columnHelper.accessor("emails", {
    header: "notification:common.emails",
    Cell: ({ row }) =>
      row.original.emails?.map(({ email }) => email).join(", "),
  }),
];

export const CompartmentBudgetNotificationsPage = () => {
  const { t } = useTranslation(["notification", "common", "entity"]);

  const { tenant } = useTenantContext();
  const currencyLocale = useCurrencyLocale();
  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;
  const [selectedCompartment, setSelectedCompartment] = useState<string | null>(
    null,
  );

  const [selectedNotificationType, setSelectedNotificationType] =
    useState<CompartmentBudgetNotificationType | null>(null);

  const [
    selectedCompartmentBudgetNotification,
    setSelectedCompartmentBudgetNotification,
  ] = useState<CompartmentBudgetNotificationResponse | null>();

  const [isCreateModalOpen, createModal] = useDisclosure(false);
  const { data: subscriptions = [] } = useFetchSubscriptionsQuery();

  const createCompartmentBudgetNotificationMutation =
    useCreateCompartmentBudgetNotification();
  const subscriptionOptions = subscriptions.map((subscription) => ({
    value: subscription.id,
    label: subscription.subscriptionID.toString(),
  }));

  const { data: compartmentBudgetNotifications = [] } =
    useFetchCompartmentBudgetNotifications();

  const { data: compartmentBudgetNotificationTypeEnum } =
    useFetchCompartmentBudgetNotificationTypes();

  const { data: compartmentStructures = [] } =
    useFetchCompartmentStructureQuery({
      params: {
        tenantocid: tenant?.ocid,
      },
    });

  const { mutate: deleteCompartmentBudgetNotification, isPending: isDeleting } =
    useDeleteCompartmentBudgetNotificationMutation();

  const tableData = useMemo(
    () =>
      compartmentBudgetNotifications
        ?.filter(
          (cbn) =>
            (!selectedNotificationType ||
              cbn.budgetCompartmentNotificationType ===
                selectedNotificationType) &&
            (selectedCompartment ?
              cbn.compartments.findIndex(
                (c) => c.id === selectedCompartment,
              ) !== -1
            : true) &&
            tenant?.id === cbn.tenant.tenantId,
        )
        .map((notification) => ({
          ...notification,
          startDate: notification.values.reduce(
            (acc, cv) => (acc < cv.startDate ? acc : cv.startDate),
            notification.values[0].startDate,
          ),
          endDate: notification.values.reduce(
            (acc, cv) => (acc > cv.endDate ? acc : cv.endDate),
            notification.values[0].endDate,
          ),
          subRows: notification.values.map((value) => value),
        }))
        .flat(),
    [
      compartmentBudgetNotifications,
      selectedCompartment,
      selectedNotificationType,
      tenant?.id,
    ],
  );

  const compartmentOptions = nestCompartmentStructure(
    compartmentBudgetNotifications
      ?.flatMap((cbn) =>
        cbn.tenant.tenantId === tenant?.id ?
          cbn.compartments
            .map(
              (compartment) =>
                compartmentStructures.find(
                  (compartmentStructure) =>
                    compartmentStructure.compartmentOcid === compartment.ocid,
                ) as CompartmentStructureResponse,
            )
            .filter(Boolean)
        : [],
      )
      .filter((c, i, arr) => arr.indexOf(c) === i),
  );

  const table = useMRT({
    enableTopToolbar: false,
    enableStickyFooter: true,
    enableStickyHeader: true,
    enableColumnDragging: false,
    paginateExpandedRows: false,
    mantineExpandButtonProps: {
      c: "gray.7",
    },
    mantineExpandAllButtonProps: {
      c: "gray.7",
    },
    mantineTableBodyRowProps: ({ row }) => ({
      style: {
        "--mrt-base-background-color": `var(--mantine-color-gray-${
          (row.originalSubRows?.length || 0) > 0 ? 3 : 1
        })`,
        "--mrt-row-hover-background-color": "var(--mantine-color-gray-2)",
      },
    }),
    getRowCanExpand(row) {
      return row.subRows?.length > 0;
    },
    enableExpanding: true,
    enableRowActions: true,
    renderRowActions: ({ row }) =>
      !row.depth &&
      !isDeleting && (
        <ActinIconDelete
          onClick={() => setSelectedCompartmentBudgetNotification(row.original)}
        />
      ),
    meta: {
      subscriptions: subscriptionOptions,
      currency,
      currencyLocale,
    },
    mantinePaperProps: {
      className: "flex flex-col flex-1",
    },
    mantineTableContainerProps: {
      className: "flex-1",
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: columns as any[],
    data: tableData,
    renderBottomToolbarCustomActions: () => (
      <Button
        color="blue"
        size="compact-lg"
        leftSection={<IconPlus />}
        onClick={createModal.open}
      >
        {t("compartment.create")}
      </Button>
    ),
  });

  const onCreateBudgetNotification = async (
    values: CompartmentBudgetNotificationFormValues,
  ) => {
    if (!tenant) {
      return;
    }

    const payload: CreateCompartmentBudgetNotificationPayload = {
      subscription: tenant.currentSubscription!.id!,
      tenant: tenant.id,
      compartments: values.compartments,
      values: values.values.map((value) => ({
        startDate: format(new Date(value.datefrom), "MM-yyyy"),
        endDate: format(endOfMonth(new Date(value.dateto)), "MM-yyyy"),
        value: value.value,
      })),
      emails: values.emails,
      isShutdown: values.shutdown,
      budgetCompartmentNotificationType:
        values.budgetCompartmentNotificationType,
    };

    await createCompartmentBudgetNotificationMutation.mutateAsync(payload, {
      onSuccess: () => {
        createModal.close();
        toast({
          title: t("compartment.compartmentNotification"),
        });
      },
    });
  };

  const handleDeleteCompartmentBudgetNotification = () => {
    if (selectedCompartmentBudgetNotification?.id) {
      deleteCompartmentBudgetNotification(
        selectedCompartmentBudgetNotification?.id,
        {
          onSettled: () => handleCloseConfirmModal(),
        },
      );
    }
  };

  const handleCloseConfirmModal = () => {
    if (!isDeleting) setSelectedCompartmentBudgetNotification(null);
  };

  useEffect(() => {
    setSelectedCompartment(null);
  }, [tenant]);

  return (
    <Group className="flex-1 items-stretch gap-0 overflow-auto" wrap="nowrap">
      <Stack className="flex-shrink-0 basis-64 border-r p-4">
        <TreeSelect
          clearable
          data={
            compartmentOptions.length ?
              [
                {
                  value: "tenant",
                  label: tenant?.name,
                  selectable: false,
                  children: compartmentOptions.map((c) =>
                    getTreeChildren(c, compartmentOptions, "id"),
                  ),
                },
              ]
            : []
          }
          label={t("entity:administration.compartment.compartment")}
          onChange={(value) => setSelectedCompartment(value)}
          value={selectedCompartment}
        />

        <EnumSelect
          clearable
          allowDeselect
          data={compartmentBudgetNotificationTypeEnum}
          value={selectedNotificationType}
          label={t("common.notificationType")}
          onChange={setSelectedNotificationType}
        />
      </Stack>

      <div className="flex flex-1 flex-col gap-2 overflow-auto p-4">
        <Title className="mb-4" size="h2">
          {t("compartment.compartmentNotification")}
        </Title>

        <MantineReactTable table={table} />
      </div>

      <Stack className="basis-96 overflow-auto border-l p-4">
        <DashboardKPIReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />

        <DashboardFifthDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID.toString()}
        />

        <DashboardThirdDiagramReport
          subscriptionid={tenant?.currentSubscription?.subscriptionID}
        />
      </Stack>

      <Modal
        centered
        size={960}
        title={t("compartment.createCompartmentNotification")}
        opened={isCreateModalOpen}
        onClose={createModal.close}
      >
        <CreateCompartmentBudgetNotificationForm
          tenant={tenant!}
          subscription={
            tenant?.currentSubscription?.subscriptionID.toString() || ""
          }
          onCancel={createModal.close}
          onSubmit={onCreateBudgetNotification}
        />
      </Modal>
      <Modal
        opened={!!selectedCompartmentBudgetNotification}
        onClose={handleCloseConfirmModal}
        title={
          <Box c="red.8" className="flex items-center gap-2">
            <IconAlertTriangle />
            <span className="font-semibold">{t("common:warning")}</span>
          </Box>
        }
      >
        <Text className="mb-4" variant="text" size="sm">
          {t("compartment.confirmDelete")}
        </Text>
        <div className="flex justify-between gap-2">
          <Button variant="light" onClick={handleCloseConfirmModal}>
            {t("common:userActions.cancel")}
          </Button>
          <Button
            onClick={handleDeleteCompartmentBudgetNotification}
            loading={isDeleting}
            disabled={isDeleting}
          >
            {t("common:userActions.confirm")}
          </Button>
        </div>
      </Modal>
    </Group>
  );
};
