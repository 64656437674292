import { Group, Stack, Checkbox, MultiSelect } from "@mantine/core";
import { DatesRangeValue, MonthPickerInput } from "@mantine/dates";
import { useDebouncedCallback } from "@mantine/hooks";
import { subMonths } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { RangeSliderInput } from "@/components/ui/range-slider-input";
import { CurrencyCode } from "@/constants/currencies";
import { useTenantContext } from "@/context/TenantContext";
import { useFetchBillingCostReportQuery } from "@/hooks/api/useFetchBillingCostReportQuery";
import { useFetchProductServiceQuery } from "@/hooks/api/useFetchProductServiceQuery";
import { useCurrencyLocale } from "@/hooks/useCurrencyLocale";
import { useTForm } from "@/hooks/useTForm";
import { formatNumberAsCurrency } from "@/lib/utils";
import { BillingCostReport } from "@/widgets/BillingReports/BillingCostReport";

interface BillingFormValues {
  services: string[];
  withzerocost: boolean;
}

export const BillingReportPage = () => {
  const { tenant } = useTenantContext();
  const { t } = useTranslation(["billing", "common"]);
  const [dateRange, setDateRange] = useState<DatesRangeValue>([
    subMonths(new Date(), 1),
    new Date(),
  ]);
  const [tempRange, setTempRange] = useState<DatesRangeValue>(dateRange);
  const [costRange, setCostRange] = useState<[number, number]>([0, 0]);
  const currencyLocale = useCurrencyLocale();

  const form = useTForm<BillingFormValues>({
    initialValues: {
      services: [],
      withzerocost: false,
    },
  });

  const { data: services = [] } = useFetchProductServiceQuery();

  const { groupedQueries, isFetching } = useFetchBillingCostReportQuery({
    params: {
      datefrom: dateRange[0],
      dateto: dateRange[1],
      tenantocid: tenant?.ocid || "",
      services: form.values.services,
      subscriptionid: tenant?.currentSubscription?.subscriptionID,
      withzerocost: form.values.withzerocost,
    },
  });

  const maxValue = useMemo(
    () =>
      Math.ceil(
        groupedQueries?.reduce(
          (prev, curr) => Math.max(prev, curr?.cost || 0),
          0,
        ) || 0,
      ),
    [groupedQueries],
  );

  const serviceOptions = useMemo(
    () =>
      services.map((service) => ({
        value: service.productServiceId,
        label: service.name,
      })),
    [services],
  );

  const currency = tenant?.currentSubscription?.currency as CurrencyCode | null;

  const handleRangeChange = useDebouncedCallback((value: [number, number]) => {
    setCostRange(value);
  }, 500);

  useEffect(() => {
    form.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  useEffect(() => {
    setCostRange([0, maxValue]);
  }, [maxValue]);

  return (
    <Group className="flex-1 items-stretch gap-0 overflow-auto" wrap="nowrap">
      <Stack className="basis-96 border-r p-4">
        <MonthPickerInput
          disabled={isFetching}
          type="range"
          label={t("common:desiredPeriod")}
          miw={180}
          allowSingleDateInRange
          value={tempRange}
          onChange={(value) => {
            setTempRange(value);
            value[0] && value[1] && setDateRange(value);
          }}
        />
        <MultiSelect
          disabled={isFetching}
          clearable
          label={t("billing:report.servis")}
          data={serviceOptions}
          {...form.getInputProps("services")}
        />
        <RangeSliderInput
          key={maxValue}
          disabled={isFetching}
          label={t("report.rangeValues")}
          value={[0, maxValue]}
          onChange={handleRangeChange}
          max={maxValue}
          step={10}
          tooltip={(value) =>
            formatNumberAsCurrency({
              value: value,
              currency,
              options: { locale: currencyLocale },
            })
          }
          description={{
            firstInput: t("report.rangeFrom", {
              value: formatNumberAsCurrency({
                value: 0,
                currency,
                options: { locale: currencyLocale },
              }),
            }),
            secondInput: t("report.rangeTo", {
              value: formatNumberAsCurrency({
                value: maxValue,
                currency,
                options: { locale: currencyLocale },
              }),
            }),
          }}
        />
        <Checkbox
          disabled={isFetching}
          label={t("report.withzerocost")}
          {...form.getInputProps("withzerocost")}
        />
      </Stack>
      <Stack className="flex-1 overflow-auto p-8">
        <BillingCostReport
          datefrom={dateRange[0]}
          dateto={dateRange[1]}
          tenantocid={tenant?.ocid || ""}
          services={form.values.services}
          subscriptionid={tenant?.currentSubscription?.subscriptionID}
          costfrom={costRange[0]}
          costto={costRange[1]}
          withzerocost={form.values.withzerocost}
        />
      </Stack>
    </Group>
  );
};
