import { Modal, Box, Text, Button } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface AlertModalProps {
  opened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  isPending: boolean;
}

const AlertModal: React.FC<AlertModalProps> = ({
  opened,
  onClose,
  onConfirm,
  title,
  message,
  isPending,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Box c="red.8" className="flex items-center gap-2">
          <IconAlertTriangle />
          <span className="font-semibold">{title}</span>
        </Box>
      }
      classNames={{
        body: "flex flex-col gap-4",
      }}
    >
      <Text size="sm">{message}</Text>

      <div className="flex justify-between gap-2">
        <Button variant="outline" disabled={isPending} onClick={onClose}>
          {t("userActions.cancel")}
        </Button>

        <Button onClick={onConfirm} loading={isPending} disabled={isPending}>
          {t("userActions.confirm")}
        </Button>
      </div>
    </Modal>
  );
};

export default AlertModal;
