import { Tooltip } from "@mantine/core";
import { IconHelpCircle } from "@tabler/icons-react";
import type { TableMeta } from "@tanstack/table-core";
import { BanIcon, PlayIcon } from "lucide-react";
import { createMRTColumnHelper } from "mantine-react-table";
import { useTranslation } from "react-i18next";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { ManageableResourceResponse } from "@/lib/api/dto";
import { formatNumberAsCurrency, formatNumberAsPercentage } from "@/lib/utils";

const columnHelper = createMRTColumnHelper<ManageableResourceResponse>();

export const columns = [
  columnHelper.accessor("displayName", {
    header: "navbar:resources.availabilityDomain",
  }),
  columnHelper.accessor("percentageConsumptionDifference", {
    header: "navbar:resources.overage",
    Header: () => {
      const { t } = useTranslation("navbar");
      return (
        <span className="flex gap-1">
          {t("resources.overage")}
          <Tooltip label={t("resources.differenceExpenses")}>
            <IconHelpCircle size="16" />
          </Tooltip>
        </span>
      );
    },
    Cell: ({ cell, row, table }) => {
      const { t } = useTranslation("navbar");
      const { currency, currencyLocale } = table.options
        .meta as TableMeta<ManageableResourceResponse>;

      return (
        <Tooltip
          multiline
          label={
            row.original.pastAverageConsumption === 0 ?
              t("resources.noData")
            : <>
                <div className="flex justify-between gap-2">
                  <span>{t("resources.averageExpenses")}</span>
                  <span>
                    {formatNumberAsCurrency({
                      value: row.original.pastAverageConsumption,
                      currency,
                      options: {
                        locale: currencyLocale,
                      },
                    })}
                  </span>
                </div>
                <div className="flex justify-between gap-2">
                  <span>{t("resources.currentExpenses")}</span>
                  <span>
                    {formatNumberAsCurrency({
                      value: row.original.currentAverageConsumption,
                      currency,
                      options: {
                        locale: currencyLocale,
                      },
                    })}
                  </span>
                </div>
                <div className="flex justify-between gap-2">
                  <span>{t("resources.difference")}</span>
                  <span>
                    {formatNumberAsCurrency({
                      value: row.original.averageConsumptionDifference,
                      currency,
                      options: {
                        locale: currencyLocale,
                      },
                    })}
                  </span>
                </div>
              </>
          }
        >
          <span className="cursor-help border-b border-dotted border-blue-500">
            {formatNumberAsPercentage(cell.getValue())}
          </span>
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("resourceType", {
    header: "navbar:resources.resourceType",
  }),
  columnHelper.accessor("lifecycleState", {
    header: "navbar:resources.lifecycleState",
  }),
  columnHelper.display({
    id: "actions",
    header: "",
    Cell: (prop) => {
      const { t } = useTranslation("navbar");
      const {
        row,
        table: {
          options: { meta },
        },
      } = prop;
      const { identifier, resourceType } = row.original;
      const { stopHandler, startHandler } =
        (meta as TableMeta<ManageableResourceResponse>) || {};

      return (
        <div className="flex gap-x-2">
          {row.original.lifecycleState === "STOPPED" && (
            <AlertDialog>
              <AlertDialogTrigger onClick={(e) => e.stopPropagation()} asChild>
                <Button className="bg-green-500 hover:bg-green-600" size="icon">
                  <PlayIcon />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent onClick={(e) => e.stopPropagation()}>
                <AlertDialogHeader>
                  <AlertDialogDescription>
                    {t("resources.resourceStart")}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel className="flex-1">
                    {t("resources.cancel")}
                  </AlertDialogCancel>
                  <AlertDialogAction
                    className="flex-1 bg-green-400"
                    onClick={() =>
                      startHandler?.({
                        resourceOcid: identifier,
                        resourceType,
                      })
                    }
                  >
                    {t("resources.start")}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
          {(row.original.lifecycleState === "RUNNING" ||
            (row.original.lifecycleState === "AVAILABLE" &&
              row.original.resourceType === "AutonomousDatabase")) && (
            <AlertDialog>
              <AlertDialogTrigger onClick={(e) => e.stopPropagation()} asChild>
                <Button variant="destructive" size="icon">
                  <BanIcon />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent onClick={(e) => e.stopPropagation()}>
                <AlertDialogHeader>
                  <AlertDialogDescription>
                    {t("resources.resourceStop")}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel className="flex-1">
                    {t("resources.cancel")}
                  </AlertDialogCancel>
                  <AlertDialogAction
                    className="flex-1"
                    variant="destructive"
                    onClick={() =>
                      stopHandler?.({
                        resourceOcid: identifier,
                        resourceType,
                      })
                    }
                  >
                    {t("resources.turnOff")}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      );
    },
  }),
];
