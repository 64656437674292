import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { Api } from "@/lib/api";
import { CompartmentBudgetNotificationResponse } from "@/lib/api/dto";

import { compartmentBudgetNotificationKeys } from "./queryKeys";

export interface UseFetchCompartmentBudgetNotificationsQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      CompartmentBudgetNotificationResponse[],
      Error,
      T,
      ReturnType<typeof compartmentBudgetNotificationKeys.all>
    >,
    "queryKey" | "queryFn"
  > {}

export const useFetchCompartmentBudgetNotifications = <
  T = CompartmentBudgetNotificationResponse[],
>(
  options: UseFetchCompartmentBudgetNotificationsQueryOptions<T> = {},
) => {
  const { t } = useTranslation("enum");

  return useQuery({
    ...options,
    queryKey: compartmentBudgetNotificationKeys.all(),
    queryFn: () =>
      Api.compartmentBudgetNotifications.list().then((res) => res.data),
    select: (data) =>
      data.map((item) => ({
        ...item,
        budgetCompartmentNotificationTypeLabel: t(
          `compartment-budget-notification-type.${item.budgetCompartmentNotificationType}`,
        ),
      })),
  });
};
