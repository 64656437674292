import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { errorToast, successToast } from "@/components/ui/use-toast";
import { Api } from "@/lib/api";
import {
  CreateOCIQueryPayload,
  OCIQueryResponseContentItem,
} from "@/lib/api/dto";

import { ociKeys } from "./queryKeys";

interface UseCreateOrUpdateOCIQueryMutationOptions
  extends Omit<
    UseMutationOptions<
      OCIQueryResponseContentItem,
      Error,
      CreateOCIQueryPayload
    >,
    "mutationFn"
  > {
  id?: string;
}

export const useCreateOrUpdateOCIQueryMutation = ({
  id,
  onSuccess,
  onError,
  onSettled,
  ...rest
}: UseCreateOrUpdateOCIQueryMutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      if (id) {
        return Api.oci.update(id, data).then((res) => res.data);
      }

      return Api.oci.create(data).then((res) => res.data);
    },
    onSuccess: (...args) => {
      id ? successToast.update() : successToast.create();
      onSuccess?.(...args);
    },
    onError: (...args) => {
      id ? errorToast.update() : errorToast.create();
      onError?.(...args);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: ociKeys.all({ tenantId: { ...args }[2].tenantId! }),
      });
      onSettled?.(...args);
    },
    ...rest,
  });
};
