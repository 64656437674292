import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { MetricNamespaceResponse } from "@/lib/api/dto";

import { metricNamespaceKeys } from "./queryKeys";

interface UseMetricNamespaceQueryParams<T>
  extends Omit<
    UseQueryOptions<
      MetricNamespaceResponse[],
      Error,
      T,
      ReturnType<typeof metricNamespaceKeys.all>
    >,
    "queryKey" | "queryFn"
  > {
  params?: {
    resourceId?: string | null;
  };
}

export const useFetchMetricNamespaceQuery = <T = MetricNamespaceResponse[]>({
  params,
  ...options
}: UseMetricNamespaceQueryParams<T> = {}) => {
  return useQuery({
    ...options,
    queryKey: metricNamespaceKeys.all(params?.resourceId),
    queryFn: ({ queryKey }) =>
      Api.metricNamespace.list(queryKey[1]).then((res) => res.data),
  });
};
