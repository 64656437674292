import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import {
  FetchMetricMeasuringUnitParams,
  MetricMeasuringUnitResponse,
} from "@/lib/api/dto";

import { metricMeasuringUnitKeys } from "./queryKeys";

interface UseMetricMeasuringUnitParams<T>
  extends Omit<
    UseQueryOptions<
      MetricMeasuringUnitResponse[],
      Error,
      T,
      ReturnType<typeof metricMeasuringUnitKeys.all>
    >,
    "queryKey" | "queryFn"
  > {
  params?: FetchMetricMeasuringUnitParams;
}

export const useFetchMetricMeasuringUnitQuery = <
  T = MetricMeasuringUnitResponse[],
>({ params, ...options }: UseMetricMeasuringUnitParams<T> = {}) => {
  return useQuery({
    ...options,
    queryKey: metricMeasuringUnitKeys.all(params?.metricMeasuringUnitId),
    queryFn: ({ queryKey }) =>
      Api.metricMeasuringUnit.list(queryKey[1]).then((res) => res.data),
  });
};
