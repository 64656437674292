import { Title } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFetchProductServiceQuery } from "@/hooks/api/useFetchProductServiceQuery";
import { useMRT } from "@/hooks/useMRT";

import { columns } from "./columns";

export const ProductServiceTable = () => {
  const { t } = useTranslation("entity");
  const { data, isLoading, isFetching } = useFetchProductServiceQuery();

  const memoizedData = useMemo(() => data || [], [data]);

  const table = useMRT({
    columns,
    data: memoizedData,
    enableStickyHeader: true,
    enableColumnActions: false,
    initialState: { showColumnFilters: true },
    state: {
      showProgressBars: isFetching,
      isLoading: isLoading,
    },
  });

  return (
    <div className="flex flex-col flex-1 col-span-2 overflow-auto lg:col-span-1">
      <Title className="mb-4" size="h2">
        {t("administration.productService.productService")}
      </Title>
      <MantineReactTable table={table} />
    </div>
  );
};
