import { createMRTColumnHelper } from "mantine-react-table";

import { SubscriptionResponse } from "@/lib/api/dto";
import { formatToDate } from "@/lib/dateUtils";

const columnHelper = createMRTColumnHelper<SubscriptionResponse>();

export const columns = [
  columnHelper.accessor("organization.name", {
    header: "entity:administration.subscription.organizationId",
  }),
  columnHelper.accessor("subscriptionType.name", {
    header: "entity:administration.subscription.subscriptionTypeId",
  }),
  columnHelper.accessor("subscriptionID", {
    header: "entity:administration.subscription.subscriptionId",
  }),
  columnHelper.accessor("csiNumber", {
    header: "entity:administration.subscription.csiNumber",
  }),
  columnHelper.accessor("originalStartDate", {
    header: "entity:administration.subscription.originalStartDate",
    Cell: ({ cell }) => formatToDate(new Date(cell.getValue())),
  }),
  columnHelper.accessor("startDate", {
    header: "entity:administration.subscription.startDate",
    Cell: ({ cell }) => formatToDate(new Date(cell.getValue())),
  }),
  columnHelper.accessor("endDate", {
    header: "entity:administration.subscription.endDate",
    Cell: ({ cell }) => {
      const value = cell.getValue();
      return value ? formatToDate(new Date(value)) : null;
    },
  }),
  columnHelper.accessor("currency", {
    header: "entity:administration.subscription.currency",
  }),
];
