import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Api } from "@/lib/api";
import { SecondDiagramParams, SecondDiagramResponse } from "@/lib/api/dto";

import { dashboardReportKeys } from "./queryKeys";

interface UseFetchSecondDiagramQueryOptions<T>
  extends Omit<
    UseQueryOptions<
      SecondDiagramResponse[],
      Error,
      T,
      ReturnType<typeof dashboardReportKeys.secondDiagram>
    >,
    "queryFn" | "queryKey"
  > {
  params: Partial<SecondDiagramParams>;
}

export const useFetchSecondDiagramQuery = <T = SecondDiagramResponse[]>({
  params,
  ...options
}: UseFetchSecondDiagramQueryOptions<T>) =>
  useQuery({
    ...options,
    queryKey: dashboardReportKeys.secondDiagram(params),
    queryFn: ({ queryKey: [_, keyParams] }) =>
      keyParams?.tenantocid && keyParams?.datefrom && keyParams?.dateto ?
        Api.dashboard
          .secondDiagram(keyParams as SecondDiagramParams)
          .then((res) => res.data)
      : [],
  });
