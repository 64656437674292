import { TableMeta } from "@tanstack/table-core";
import { format } from "date-fns";
import { createMRTColumnHelper } from "mantine-react-table";

import { ReportMetric } from "@/lib/api/dto";
import { dayMonthTimeFormat } from "@/lib/dateUtils";

const columnHelper = createMRTColumnHelper<ReportMetric>();

export const columns = [
  columnHelper.accessor("time", {
    header: "common:time",
    Cell: ({ cell, table }) =>
      format(new Date(cell.getValue()), dayMonthTimeFormat, {
        locale: (table.options.meta as TableMeta<ReportMetric>)?.dateFnsLocale,
      }),
  }),
  columnHelper.accessor("value", { header: "common:value" }),
];
